import {
  faCircle,
  faClipboardList,
  faDownload,
  faEye,
  faHome,
  faPaperclip,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import ReactLoading from 'react-loading'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import useFetch from '../../utils/useFetch'
import { List } from './Styled'

const actionButton = (cell, row, rowIndex, formatExtraData) => {
  return (
    <>
      <Link
        className="btn btn-outline-primary btn-view-detail mb-2"
        to={'/educational-progress/' + row.ID}
      >
        {' '}
        <FontAwesomeIcon icon={faEye} className="" /> ดูรายละเอียด{' '}
      </Link>
    </>
  )
}

const EducationalProgressList = (props) => {
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const { response, loading } = useFetch(`/student/education/progress`)
  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/education/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <div className="d-flex flex-wrap text-left">
              <p className="mb-1 w-100" style={{ textAlign: 'left' }}>
                <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                {item.file_name}
              </p>
              <p
                className="mb-0 w-100"
                style={{ color: '#6c757d', fontSize: '14px', textAlign: 'left' }}
              >
                {item.description}
              </p>
            </div>

            <div>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/education/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </div>
          </List>
        </>
      )
    })
  }

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const sizePerPage = 10

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      แสดง {from} ถึง {to} จากทั้งหมด {size} รายการ
    </span>
  )

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
      history.push(`/petition-student-status?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '80px', minWidth: '80px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        let cal = sizePerPage * currentPage
        cal = cal - (sizePerPage - 1)
        cal = cal + rowIndex
        return cal
      },
    },
    {
      dataField: 'description',
      text: 'รายงานความก้าวหน้า',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'status',
      text: 'สถานะ',
      sort: true,
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <>
            <div className={row.statusClass + ' align-middle'}>
              <FontAwesomeIcon icon={faCircle} className="pe-1" />
              {row?.status}
              <p className="text-secondary fs-14"> {row?.note} </p>
            </div>
            {/* {getStatus(row?.status)}
            <Remark>
              {row?.note !== null && row?.status === 'rejected' ? `*${row?.note}` : ''}
            </Remark> */}
          </>
        )
      },
    },
    {
      dataField: 'created_at',
      text: 'วันที่ยื่นความก้าวหน้า',
      sort: true,
      headerStyle: () => {
        return { width: '280px', minWidth: '280px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.created_at)
      },
    },
    {
      dataField: 'updated_at',
      text: 'วันที่อัปเดตล่าสุด',
      sort: true,
      headerStyle: () => {
        return { width: '280px', minWidth: '280px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.updated_at)
      },
    },
    {
      dataField: 'action',
      text: 'รายละเอียด',
      align: 'center',
      headerStyle: () => {
        return { width: '135px', minWidth: '135px', textAlign: 'center' }
      },
      events: {
        onClick: (e) => {
          e.stopPropagation()
        },
      },
      formatter: actionButton,
    },
  ]

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ความก้าวหน้าทางการศึกษา
          </li>
        </ol>
      </nav>
      <div className="educational-progress-index">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header ">
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div>
                    <h5 className="card-title d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span>ความก้าวหน้าทางการศึกษา </span>
                    </h5>
                  </div>
                  <div className="ms-auto my-auto mt-lg-0 mt-4">
                    <div className="ms-auto my-auto">
                      <Link to="/educational-progress/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} className="" /> ยื่นความก้าวหน้าทางการศึกษา
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div id="p0" data-pjax-container data-pjax-push-state data-pjax-timeout={1000}>
                    <div id="w0" className="grid-view table-responsive">
                      {!loading ? (
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />

                              <BootstrapTable
                                id="tableResize"
                                // remote={{ pagination: true, filter: false, sort: false }}
                                classes="resizable"
                                bootstrap4
                                hover
                                striped={false}
                                bordered={false}
                                keyField="id"
                                data={response}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                wrapperClasses="table-responsive"
                                noDataIndication={'ไม่พบข้อมูล'}
                                // selectRow={selectRow}
                                // onTableChange={handleTableChange}
                                {...paginationTableProps}
                              />
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          )}
                        </PaginationProvider>
                      ) : (
                        <table id="petition" className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th style={{ width: 220, minWidth: 220 }}>รายงานความก้าวหน้า</th>
                              <th style={{ width: 150, minWidth: 150 }}>สถานะ</th>
                              <th style={{ width: 280, minWidth: 280 }}>วันที่ยื่นความก้าวหน้า </th>
                              <th style={{ width: 280, minWidth: 280 }}>วันที่อัปเดตล่าสุด </th>
                              <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                                รายละเอียด
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!loading ? (
                <>
                  <Modal
                    show={show}
                    size="lg"
                    dialogClassName="modal-preview"
                    centered
                    onHide={() => setShow(false)}
                    //fullscreen={true}
                    aria-labelledby="file-name"
                  >
                    <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                      <Modal.Title id="file-name">{fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <PDFViewer pdf={filePreview} />
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default EducationalProgressList
