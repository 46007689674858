import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserEdit, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import PortfolioContainer from './Portfolio/List'
import StudentAwardContainer from './StudentAward/List'
import StudentActivityContainer from './StudentActivity/List'
import ResearchContainer from './Research/List'
import { Card, Container, Tabs, Tab, Row, Col } from 'react-bootstrap'
import { dateHelper } from '../../helpers/date.helper'

import Skeleton from 'react-loading-skeleton'

const PersonalInformationContainer = (props) => {
  const { response: studentData, loading } = useFetch(`/student/personal`)

  return (
    <>
      {!studentData?.pending_scholarship_student ? (
        <></>
      ) : (
        <section className="bg-white py-3 mb-3">
          <div className="container">
            <div className="alert alert-warning-soft mb-0" role="alert">
              <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
              ข้อมูลส่วนบุคคลอยู่ในระหว่างดำเนินการอนุมัติ แก้ไขเมื่อ{' '}
              {dateHelper.getDateTime(studentData?.pending_scholarship_student?.created_at)}
            </div>
          </div>
        </section>
      )}
      <div className="site-dashboard">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faUser} className="" />
                      </div>
                      <span>ข้อมูลส่วนบุคคล </span>
                    </h5>
                  </div>
                  <div className="ms-auto my-auto mt-lg-0 mt-4">
                    <div className="ms-auto my-auto">
                      {studentData?.scholarship_student ? (
                        <Link className="btn btn-outline-primary" to="/personal-information/update">
                          <FontAwesomeIcon icon={faUserEdit} className="me-1" /> แก้ไข
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body card-personal-information">
                <Tabs defaultActiveKey="info" id="student-info" className="mb-0">
                  <Tab eventKey="info" title="ข้อมูลส่วนบุคคล">
                    <Card>
                      <Card.Body>
                        <h6 className="text-primary fw-bolder ps-5">ข้อมูลทั่วไป</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '40%' }}>ชื่อ-นามสกุล</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {`${studentData?.scholarship_student?.name_title_th}${studentData?.scholarship_student?.firstname_th} ${studentData?.scholarship_student?.lastname_th}`}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="align-middle" style={{ width: '40%' }}>
                                  Full Name
                                </td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {`${
                                        studentData?.scholarship_student?.name_title_en || ''
                                      }${' '}${
                                        studentData?.scholarship_student?.firstname_en || ''
                                      } ${studentData?.scholarship_student?.lastname_en || ''}`}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className="align-middle">วันเดือนปีเกิด</td>
                                <td className="align-middle">
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.scholarship_student?.birth_date
                                        ? dateHelper.getDate(
                                            studentData?.scholarship_student?.birth_date
                                          )
                                        : ''}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">สถานะ</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="align-middle" style={{ width: '40%' }}>
                                  สถานะปัจจุบัน
                                </td>
                                <td className="align-middle">
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.scholarship_student?.student_status}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">ข้อมูลการติดต่อ</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '40%' }}>หมายเลขโทรศัพท์</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.scholarship_student?.primary_phone_number ? (
                                        <>
                                          {studentData?.scholarship_student?.primary_phone_number}
                                          <span className="text-blue-soft">
                                            <i className="fas fa-circle font-12" /> ใช้งานหลัก
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อีเมล</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.scholarship_student?.primary_email ? (
                                        <>
                                          {studentData?.scholarship_student?.primary_email}
                                          <span className="text-blue-soft">
                                            <i className="fas fa-circle font-12" /> ใช้งานหลัก  (อีเมลที่ใช้ในการเข้าสู่ระบบ)
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                                  <td scope="col" />
                                  <td>sandee@gmail.com</td>
                                </tr> */}
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">ที่อยู่ตามทะเบียนบ้าน</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td scope="col" style={{ width: '40%' }}>
                                  ที่อยู่
                                </td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.scholarship_student?.address}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">ที่อยู่ปัจจุบัน</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td scope="col" style={{ width: '40%' }}>
                                  ที่อยู่
                                </td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.scholarship_student?.current_address}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab eventKey="parents" title="บิดา/มารดา/ผู้ปกครอง">
                    <Card>
                      <Card.Body>
                        <h6 className="text-primary fw-bolder ps-5">บิดา</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td scope="col" style={{ width: '40%' }}>
                                  ชื่อ (ภาษาไทย)
                                </td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.parents?.father?.name_title_th}
                                      {!studentData?.parents?.father?.firstname_th
                                        ? ''
                                        : studentData?.parents?.father?.firstname_th + ' '}
                                      {!studentData?.parents?.father?.lastname_th
                                        ? ''
                                        : studentData?.parents?.father?.lastname_th}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อ (ภาษาอังกฤษ)</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.parents?.father?.name_title_en}
                                      {!studentData?.parents?.father?.firstname_en
                                        ? ''
                                        : studentData?.parents?.father?.firstname_en + ' '}
                                      {!studentData?.parents?.father?.lastname_en
                                        ? ''
                                        : studentData?.parents?.father?.lastname_en}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>เลขประจำตัวบัตรประชาชน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.citizen_id_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>หมายเลขโทรศัพท์</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.phone_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อีเมล</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.email} </> 
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อาชีพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.occupation}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานที่ทำงาน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.organization}</>
                                  )}
                                </td>
                              </tr>                             
                              <tr>
                                <td>สัญชาติ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.nationality}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานภาพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.parents_status_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานะการมีชีวิต</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.alive_status}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อคู่สมรส</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.spouse_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ที่อยู่</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.father?.address?.address_full}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">มารดา</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '40%' }}>ชื่อ (ภาษาไทย)</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.parents?.mother?.name_title_th}
                                      {!studentData?.parents?.mother?.firstname_th
                                        ? ''
                                        : studentData?.parents?.mother?.firstname_th + ' '}
                                      {!studentData?.parents?.mother?.lastname_th
                                        ? ''
                                        : studentData?.parents?.mother?.lastname_th}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อ (ภาษาอังกฤษ)</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.parents?.mother?.name_title_en}
                                      {!studentData?.parents?.mother?.firstname_en
                                        ? ''
                                        : studentData?.parents?.mother?.firstname_en + ' '}
                                      {!studentData?.parents?.mother?.lastname_en
                                        ? ''
                                        : studentData?.parents?.mother?.lastname_en}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>เลขประจำตัวบัตรประชาชน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.citizen_id_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>หมายเลขโทรศัพท์</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.phone_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อีเมล</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.email}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อาชีพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.occupation}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานที่ทำงาน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.organization}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สัญชาติ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.nationality}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานภาพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.parents_status_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานะการมีชีวิต</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.alive_status}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อคู่สมรส</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.spouse_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ที่อยู่</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.mother?.address?.address_full}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6 className="text-primary fw-bolder ps-5">ผู้ปกครอง</h6>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: '40%' }}>ชื่อ (ภาษาไทย)</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {`${studentData?.parents?.other?.name_title_th || ''}${
                                        studentData?.parents?.other?.firstname_th || ''
                                      } ${studentData?.parents?.other?.lastname_th || ''}`}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อ (ภาษาอังกฤษ)</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>
                                      {studentData?.parents?.other?.name_title_en}
                                      {!studentData?.parents?.other?.firstname_en
                                        ? ''
                                        : studentData?.parents?.other?.firstname_en + ' '}
                                      {!studentData?.parents?.other?.lastname_en
                                        ? ''
                                        : studentData?.parents?.other?.lastname_en}
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>เลขประจำตัวบัตรประชาชน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.citizen_id_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>หมายเลขโทรศัพท์</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.phone_number}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อีเมล</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.email}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>อาชีพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.occupation}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานที่ทำงาน</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.organization}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สัญชาติ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.nationality}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานภาพ</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.parents_status_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานะการมีชีวิต</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.alive_status}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ชื่อคู่สมรส</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.spouse_name}</>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>ที่อยู่</td>
                                <td>
                                  {loading ? (
                                    <Skeleton />
                                  ) : (
                                    <>{studentData?.parents?.other?.address?.address_full}</>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab>
                </Tabs>
              </div>
            </div>

            <PortfolioContainer student={studentData?.scholarship_student} />

            <StudentAwardContainer student={studentData?.scholarship_student} />

            <StudentActivityContainer student={studentData?.scholarship_student} />

            <ResearchContainer student={studentData?.scholarship_student} />
          </div>
        </section>
      </div>
    </>
  )
}

export default PersonalInformationContainer
