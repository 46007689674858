import StudentActivityCreate from '../../pages/student-activity/Create';
import StudentActivityView from '../../pages/student-activity/View'

export const StudentActivity = [
    //การเข้าค่าย/กิจกรรมใหม่
    {
        path: '/student-activity/create',
        name: 'เพิ่มการเข้าค่าย/กิจกรรมใหม่', 
        component: StudentActivityCreate,
        layout: 'student-activity',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'เพิ่มการเข้าค่าย/กิจกรรมใหม่' },
    },
    {
        path: '/student-activity/:id',
        name: 'การเข้าค่าย/กิจกรรมใหม่', 
        component: StudentActivityView,
        layout: 'student-activity',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'รายละเอียดการเข้าค่าย/กิจกรรมใหม่' },
    },
]