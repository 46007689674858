import PortfolioCreate from '../../pages/portfolio/Create';
import PortfolioView from '../../pages/portfolio/View'

export const Portfolio = [
  //ผลงาน
    {
        path: '/portfolio/create',
        name: 'เพิ่มผลงาน', 
        component: PortfolioCreate,
        layout: 'portfolio',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'เพิ่มผลงาน' },
    },
    {
        path: '/portfolio/:id',
        name: 'ผลงาน', 
        component: PortfolioView,
        layout: 'portfolio',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'รายละเอียดผลงาน' },
    },

  
]