
import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';

import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, Redirect,Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil'

import './assets/bootstrap/bootstrap.css';
import './assets/font/stylesheet.css';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css' 
//import Main from './layouts/Main' 
import MainNotlogged from './layouts/MainNotlogged'
import Main from './layouts/Main'
import { history } from './helpers/history'

import  AuthContext, { AuthContextProvider } from './store/auth-context';
import ForgotPassword from './pages/forgot-password/ForgotPassword'
import NotFound404 from './pages/NotFound404'

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
//const mainPanel = useRef(null)
// const getRoutes = (routes) => {
 
//     return routes.map((prop, key) => { 
//         return <Route path={prop.path}  name={prop.name}  layout={prop.layout} render={(props) => <prop.component {...props} />} key={key} />
//     })
// }
// ReactDOM.render(
//   <AuthContextProvider >
//     <BrowserRouter>
//         <Switch>
          
//           {getRoutes(Routes)}
//           <Redirect from="/" to="/" />
//         </Switch>
        
//     </BrowserRouter>
//   </AuthContextProvider>,
//   document.getElementById('root')
// )
const userAuth = localStorage.getItem('user')

// if(userAuth ){
//     const auth = JSON.parse(userAuth)
//     const currentTime = new Date().getTime();
//     const adjExpirationTime = new Date(auth.expired).getTime();
//     remainingDuration = adjExpirationTime - currentTime;
//     if(remainingDuration < 0){
//     }
// }
 
const rootElement = document.getElementById('root')
ReactDOM.render(
  <AuthContextProvider>
      <Router history={history}>
          <Switch>
              {userAuth === null || userAuth === undefined ? ( 
                <Route path="/" render={(props) => <MainNotlogged {...props} />} />   
              ) : ( 
                <Route path="/" render={(props) => <Main {...props} />} />    
              )}
          </Switch>
      </Router>
  </AuthContextProvider>,
  rootElement
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
