import { faClipboardList, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { forwardRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { history } from '../../helpers/history'
import http from '../../utils/http'
import _ from '../../utils/utils'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import useFetch from '../../utils/useFetch'
import { format } from 'date-fns'
import addDays from 'date-fns/addDays'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { DateButton, DatePickerWrapperStyles, IconCalendar } from '../../components/Filter/styles'
import { Alert } from '../../actions/alert.action'
import { componentsToColor } from 'pdf-lib' 
import {
  usePetitionTypeOptions,
  useRevokeReasonOptions,
  useRevokingStatusOptions,
  useStudentOptions,
  useStudentStatusOptions,
  useEducationLevelOptions,
  useSubjectFieldOptions,
  useInstitutionOptions,
  useAcademicYearBcOptions,
  useOrganizationOptions,
  useSemesterOptions,
  useSubjectAreaOptions,
  useScholarshipOptions,
  useCourseOptions
} from '../../hooks/options/useOptions'
import AsyncSelect from 'react-select/async'



const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`

const StatusCreate = (props) => {
  const [files, setFiles] = useState([])

  const urlAction = '/petition-student-status'
  const urlActionCallAPI = '/student/petition/create'
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const [expandType, setExpandType] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())


  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = yup.object().shape({
    petition_type_id: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกประเภทคำร้อง'),
        value: yup
          .string()
          .required('กรุณาเลือกประเภทคำร้อง')
          .nullable()
          .required('กรุณาเลือกประเภทคำร้อง'),
      })
      .nullable()
      .required('กรุณาเลือกประเภทคำร้อง'),
    description: yup.string().required('กรุณากรอกรายละเอียด'),
    reason: yup.string().required('กรุณากรอกรายละเอียด'),
    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
    // file: yup.string().required('ไฟล์ต้องไม่ว่าง'),
  })

  const [contract, setContract] = useState({
    options: [],
    defaultValue: null,
    startDate: null,
    endDate: null,
    isDisabled: true,
  })

  let petitionType = null

  const { options: petitionTypeOptions, loading: petitionTypeLoading } = usePetitionTypeOptions({
    petition_group_id: 1,
  })
  const { options: revokeReasonOptions, loading: revokeReasonLoading } = useRevokeReasonOptions()

  const { options:educationLevelOptions, loading:educationLevelLoading} = useEducationLevelOptions()

  const { options:subjectFieldOptions, loading:subjectFieldLoading} = useSubjectFieldOptions()

  const { options:institutionOptions, loading:institutionLoading} = useInstitutionOptions()

  const { options: subjectAreaOptions, loading: subjectAreaLoading } = useSubjectAreaOptions()
  const { options: academicYearOptions, loading: academicYearLoading } = useAcademicYearBcOptions()
  const { options: semesterOptions, loading: semesterLoading } = useSemesterOptions()
  const { options: courseOptions, loading: courseLoading } = useCourseOptions()
  const { options: scholarshipOptions, loading: scholarshipLoading } = useScholarshipOptions()
  let subjectArea = null
  let institution = null
  let academicYearStart = null
  let academicYearEnd = null
  let educationLevel = null
  let scholarship = null
  let default_courses = null
  let default_contract = null
  let default_current_education_level = null
  let default_current_subject_field_id = null
  let default_current_institution_id = null

  const [studentStatus, setStudentStatus] = useState({
    isRevoke: false,
    isShowStatus: false,
    revorkDefaultValues: null,
    studentStatusDefaultValues: null,
    isHigherLevel:false, 
  })


  const [initData, setInitData] = useState({
    newOptionContractNo:[]
  })

  const getContractStudent = async () => {
    const res = await http.get(`/student/contract/list`)
    // setValue('contract_id', contract_id)
    let new_options = []
    if (res.data) {
      let data = res.data
      new_options = data?.map((item, index) => {
        return {
          value: item?.id,
          label: `${item?.contract_no} - ${item?.education_level_name_th}`,
        }
      })

      setContract({
        ...contract,
        options: new_options,
      })
    }
  }

  const onChangeSelectedOption = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }

      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleStudentStatusChange = (value) => {
    let options = {
      value: value?.value,
      label: value?.label,
    }

    let isRevoke = value?.group_id === '3'

    setStudentStatus({
      ...studentStatus,
      isRevoke: isRevoke,
    })

    setValue('student_status_id', options)
  }

  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    clearErrors,
    resetField,
    setError,
    getValues,
  } = useForm(formOptions)

  const { errors } = formState


  const [datepickerItem, setDatepickerItem] = useState({
    upload_load: false,
    first_load_contract: false,
    first_load: false,
    display: false,
    start_date: getValues('start_date') ? new Date(getValues('start_date')) : null,
    end_date: getValues('end_date') ? new Date(getValues('end_date')) : null,
    newOptionContractNo: [],
  })

  const handlePetitionTypeChange = async (value) => {
    console.log(value)
    let is_higher_level = false

    let options = {
      value: value?.value,
      label: value?.label,
      expand_type: value?.expand_type,
    }

    clearErrors('petition_type_id')

    const petitionType = await http.get(`/student/masterdata/petition-type-detail/${value?.value}`)

    const studentStatusPetition = await http.get(
      `/student/masterdata/student-status?petition_type_id=${value?.value}`
    )

    const data = studentStatusPetition?.data

    if(value?.type === "study_higher_level")
    {
      is_higher_level = true
    }
    console.log(data)
    let studentStatusValue = null
    
    if (data?.status_id !== null) {
      studentStatusValue = {
        value: data?.status_id,
        label: data?.status_name,
      }

      // setDefaultStudentStatus(studentStatus)

      setValue('student_status_id', studentStatusValue)
    } else {
      setValue('student_status_id', {})
    }

    setStudentStatus({
      ...studentStatus,
      studentStatusDefaultValues: studentStatusValue,
      isRevoke:  data?.group_id === '3',
      isHigherLevel:is_higher_level
    })

    setExpandType(petitionType?.data?.expand_type || 0)

    let isExpandOrLeave =
      petitionType?.data?.expand_type === '1' || petitionType?.data?.expand_type === '2'

    if (isExpandOrLeave) {
      getContractStudent()
      setDatepickerItem({ ...datepickerItem, display: true })
      setValue('petition_type_id', options)
      // setStudentStatus({
      //   ...studentStatus,
      //   isShowStatus: false,
      // })
    } else {
      setDatepickerItem({ ...datepickerItem, display: false })
      setValue('petition_type_id', options)
      setValue('start_date', '')
      setValue('end_date', '')
    }

  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      let dataPost = {
        petition_group_id: 1,
        petition_type_id: dataObj?.petition_type_id?.value,
        description: dataObj.description,
        reason: dataObj?.reason,
        files: files,
        start_date: dataObj?.start_date,
        end_date: dataObj?.end_date,
        contract_id: dataObj?.contract_id,
        expand_type: expandType,
        student_status_id: dataObj?.student_status_id?.value,
        revoking_status_id: dataObj?.revoking_status_id?.value,
        revoke_reason_id: dataObj?.revoke_reason_id?.value,
        is_recoup: +dataObj?.is_recoup,
      }

      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.status === 201) {
        setIsLoading(false)
        Alert.success('บันทึกข้อมูลเรียบร้อย', '')
        history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
        Alert.error('ไม่สามารถบันทึกข้อมูลได้', '')
      }
    }
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    setFiles(newFileInput)
  }

  const handleRemoveFile = (index, file) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))
  // useEffect(() => {
  //  getContractStudent()
  // },[]); 
  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton id="contract-date" onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

  const getContractData = async (value) => {
    if (value?.value && value.value !== '' && value.value !== 0) {
      clearValues()
      setInitData({ ...initData, load_contract_id: true })

      const res = await http.get(`/student/contract/${value.value}`)
      const json = res

      if (json.data) {
        let data = json.data
        let scholarship_id = {
          label:data?.scholarship_name,
          value:data?.scholarship_id
        }
        let current_education_level = {
          label:data?.education_level_name_th,
          value:data?.education_level_id
        }
        let current_subject_field_id = {
          label:data?.subject_field_name,
          value:data?.subject_field
        }
        let current_institution_id = {
          label:data?.institution_name,
          value:data?.institution_id
        }

        setValue('current_education_level',current_education_level)
        setValue('current_subject_field_id',current_subject_field_id)
        setValue('current_institution_id',current_institution_id)
        setValue('scholarship_id',scholarship_id)

        if(data?.end_date !== undefined && data?.end_date !== null && data?.end_date !== ''){
          setDatepickerItem({ ...datepickerItem, start_date: new Date(data.end_date) })
        }
        // setValue('start_date',data?.end_date)

      }
      setInitData({ ...initData, load_contract_id: false })
    }
  }

  const clearValues = () =>{
    setValue('scholarship_id',null)
    setValue('current_education_level',null)
    setValue('current_subject_field_id',null)
    setValue('current_institution_id',null)
    setDatepickerItem({ ...datepickerItem, start_date: null })
  }
console.log(studentStatus)
  return (
    <div className="site-petition-create">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className=" d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>ยื่นคำร้องใหม่</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="petition-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12} className="mb-3">
                      <label htmlFor="petition_type_id" className="label-with-required">
                        ประเภทคำร้อง
                      </label>
                      <Controller
                        name="petition_type_id"
                        control={control}
                        render={(field) => (
                          <Select
                            {...field}
                            label={'ประเภทคำร้อง'}
                            isLoading={petitionTypeLoading}
                            sDisabled={petitionTypeLoading}
                            isClearable
                            isSearchable={true}
                            styles={selectStyles}
                            options={petitionTypeOptions}
                            placeholder="เลือกประเภทคำร้อง"
                            defaultValue={petitionType}
                            noOptionsMessage={() => 'ไม่พบข้อมูล'}
                            onChange={(value) => {
                              handlePetitionTypeChange(value)
                            }}
                          />
                        )}
                      />
                      {errors?.petition_type_id && (
                        <div className="help-block">
                          {errors.petition_type_id?.message ||
                            errors.petition_type_id?.label.message}
                        </div>
                      )}
                    </Col>

                  {studentStatus.isHigherLevel === true && (
                      <>
                        <Col md="6" className="mb-3">
                          <label htmlFor="contract_id" className="label-with-required">
                            เลขที่สัญญา
                          </label>
                          <Controller
                            name="contract_id"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={initData.newOptionContractNo}
                                defaultValue={default_contract}
                                isLoading={initData.load_contract_id}
                                placeholder="เลือกเลขที่สัญญา"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => {
                                  onChangeSelectedOption(value, 'contract_id')
                                  getContractData(value)
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6"></Col>
                        <Col md={6} className="mb-3">
                          <label htmlFor="current_education_level" className="label-with-required">
                            ระดับการศึกษาปัจจุบันที่ศึกษาอยู่
                          </label>
                          <Controller
                            name="current_education_level"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isDisabled={true}
                                isClearable
                                isSearchable
                                styles={selectStyles}
                                options={educationLevelOptions}
                                defaultValue={default_current_education_level}
                                isLoading={educationLevelLoading}
                                placeholder="ระดับการศึกษาปัจจุบันที่ศึกษาอยู่"
                                onChange={(value) => onChangeSelectedOption(value, 'current_education_level')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <label htmlFor="education_level_id" className="label-with-required">
                            ระดับการศึกษาที่ต้องการศึกษาต่อ
                          </label>
                          <Controller
                            name="education_level_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label={'ระดับการศึกษาที่ต้องการศึกษาต่อ'}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={educationLevelOptions}
                                defaultValue={educationLevel}
                                isLoading={educationLevelLoading}
                                placeholder="เลือกระดับการศึกษาที่ต้องการศึกษาต่อ"
                                onChange={(value) => onChangeSelectedOption(value, 'education_level_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <label htmlFor="current_subject_field_id" className="label-with-required">
                            สาขาวิชาปัจจุบัน 
                          </label>
                          <Controller
                            name="current_subject_field_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isDisabled={true}
                                isClearable
                                isSearchable
                                styles={selectStyles}
                                options={subjectFieldOptions}
                                defaultValue={default_current_subject_field_id}
                                isLoading={subjectFieldLoading}
                                placeholder="สาขาวิชาปัจจุบัน"
                                onChange={(value) => onChangeSelectedOption(value, 'current_subject_field_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <label htmlFor="subject_area" className="label-with-required">
                            สาขาวิชาที่ต้องการศึกษาต่อ
                          </label>
                          <Controller
                            name="subject_area"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'สาขาวิชาที่ต้องการศึกษาต่อ'}
                                isLoading={subjectAreaLoading}
                                loadingMessage={() => 'กำลังโหลดข้อมูล...'}
                                isDisabled={subjectAreaLoading}
                                isClearable
                                isSearchable={false}
                                styles={selectStyles}
                                options={subjectAreaOptions}
                                defaultValue={subjectArea}
                                placeholder="เลือกสาขาวิชาที่ต้องการศึกษาต่อ"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => onChangeSelectedOption(value, 'subject_area')}
                              />
                            )}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <label htmlFor="current_institution_id" className="label-with-required">
                            สถานศึกษาปัจจุบัน 
                          </label>
                          <Controller
                            name="current_institution_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isDisabled={true}
                                isClearable
                                isSearchable
                                styles={selectStyles}
                                options={institutionOptions}
                                defaultValue={default_current_institution_id}
                                isLoading={institutionLoading}
                                placeholder="สถานศึกษาปัจจุบัน"
                                onChange={(value) => onChangeSelectedOption(value, 'current_institution_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <label htmlFor="institution_id" className="label-with-required">
                            สถานศึกษาที่ต้องการศึกษาต่อ
                          </label>
                          <Controller
                            name="institution_id"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'สถานศึกษาที่ต้องการศึกษาต่อ'}
                                isLoading={institutionLoading}
                                isDisabled={institutionLoading}
                                isClearable
                                isSearchable
                                styles={selectStyles}
                                options={institutionOptions}
                                defaultValue={institution}
                                placeholder="เลือกสถานศึกษาที่ต้องการศึกษาต่อ"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => onChangeSelectedOption(value, 'institution_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <label htmlFor="scholarship_id" className="label-with-required">
                            ทุนที่รับ
                          </label>
                          <Controller
                            name="scholarship_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label={'ทุนที่รับ'}
                                isClearable
                                isSearchable
                                styles={selectStyles}
                                options={scholarshipOptions}
                                defaultValue={scholarship}
                                isLoading={scholarshipLoading}
                                placeholder="เลือกทุนที่รับ"
                                onChange={(value) => onChangeSelectedOption(value, 'scholarship_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <label htmlFor="courses" className="label-with-required">
                            หลักสูตร
                          </label>
                          <Controller
                            name="courses"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'หลักสูตร'}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={courseOptions}
                                isLoading={courseLoading}
                                placeholder="เลือกหลักสูตร"
                                defaultValue={default_courses}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => {
                                  onChangeSelectedOption(value, 'courses')
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-2">
                          <label htmlFor="academic_year_start" className="label-with-required">
                            ปีการศึกษาที่เริ่มเรียน
                          </label>
                          <Controller
                            name="academic_year_start"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="ปีการศึกษาที่เริ่มเรียน"
                                isClearable
                                isDisabled={academicYearLoading}
                                isLoading={academicYearLoading}
                                isSearchable
                                styles={selectStyles}
                                options={academicYearOptions}
                                defaultValue={academicYearStart}
                                placeholder="เลือกปีการศึกษาที่เริ่มเรียน"
                                onChange={(value) => onChangeSelectedOption(value, 'academic_year_start')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-2">
                          <label htmlFor="semester_start_id" className="label-with-required">
                            ภาคการศึกษาที่เริ่มเรียน
                          </label>
                          <Controller
                            name="semester_start_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="ภาคการศึกษาที่เริ่มเรียน"
                                isClearable
                                isDisabled={semesterLoading}
                                isLoading={semesterLoading}
                                isSearchable
                                styles={selectStyles}
                                options={semesterOptions}
                                placeholder="เลือกภาคการศึกษาที่เริ่มเรียน"
                                onChange={(value) => onChangeSelectedOption(value, 'semester_start_id')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-2">
                          <label htmlFor="academic_year_end" className="label-with-required">
                            ปีการศึกษาที่สิ้นสุด
                          </label>
                          <Controller
                            name="academic_year_end"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="ปีการศึกษาที่สิ้นสุด"
                                isClearable
                                isDisabled={academicYearLoading}
                                isLoading={academicYearLoading}
                                isSearchable={true}
                                styles={selectStyles}
                                options={academicYearOptions}
                                defaultValue={academicYearEnd}
                                placeholder="เลือกปีการศึกษาที่สิ้นสุด"
                                onChange={(value) => onChangeSelectedOption(value, 'academic_year_end')}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" className="mb-2">
                          <label htmlFor="semester_end_id" className="label-with-required">
                            ภาคการศึกษาที่สิ้นสุด
                          </label>
                          <Controller
                            name="semester_end_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="ภาคการศึกษาที่สิ้นสุด"
                                isClearable
                                isDisabled={semesterLoading}
                                isLoading={semesterLoading}
                                isSearchable
                                styles={selectStyles}
                                options={semesterOptions}
                                placeholder="เลือกภาคการศึกษาที่สิ้นสุด"
                                onChange={(value) => onChangeSelectedOption(value, 'semester_end_id')}
                              />
                            )}
                          />
                          {errors?.semester_end_id && (
                            <div className="help-block">{errors.semester_end_id?.message}</div>
                          )}
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="control-label label-with-required" htmlFor="start_date">
                              วันที่เริ่มต้น
                            </label>
                            <div className="position-relative">
                              <DatePicker
                                {...register('start_date', {
                                  required: true,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('start_date')
                                  setValue('start_date', format(event, 'yyyy-MM-dd'))
                                  setDatepickerItem({ ...datepickerItem, start_date: event })
                                }}
                                selected={datepickerItem?.start_date}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                preventOpenOnFocus={true}
                                placeholder="วันที่เริ่มต้น"
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute" />
                            </div>
                            <DatePickerWrapperStyles />
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group ">
                            <label className="control-label label-with-required">วันที่สิ้นสุด</label>
                            <div className="position-relative">
                              <DatePicker
                                {...register('end_date', {
                                  required: true,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('end_date')
                                  setValue('end_date', format(event, 'yyyy-MM-dd'))
                                  setDatepickerItem({ ...datepickerItem, end_date: event })
                                }}
                                selected={datepickerItem?.end_date}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                preventOpenOnFocus={true}
                                placeholder="วันที่สิ้นสุด"
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute" />
                            </div>
                            <DatePickerWrapperStyles />
                          </div>
                        </Col>
                        <Col md="6" className="mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="is-scholarship-other">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register('is_scholarship_other')}
                                value={1}
                                id="is-scholarship-other"
                              />
                              รับทุนอื่น
                            </label>
                          </div>
                        </Col>
                    </>
                  )}
                   
                    {studentStatus?.isRevoke && (
                      <> 
                        <Col md={12} className="mb-3">
                          <label htmlFor="revoke_reason_id" className="label-with-required">
                            สาเหตุที่ออกจากโครงการ
                          </label>
                          <Controller
                            name="revoke_reason_id"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'สาเหตุที่ออกจากโครงการ'}
                                isLoading={revokeReasonLoading}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={revokeReasonOptions}
                                placeholder="สาเหตุที่ออกจากโครงการ"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => {
                                  onChangeSelectedOption(value, 'revoke_reason_id')
                                }}
                              />
                            )}
                          />
                          {errors?.revoke_reason_id && (
                            <div className="help-block">{errors.revoke_reason_id?.message}</div>
                          )}
                        </Col>
                      </>
                    )}

                    {datepickerItem.display === true && (
                      <>
                        <Col md={6} className="mt-3 mb-3">
                          <label htmlFor="start_date" className="label-with-required">
                            วันที่เริ่มต้น
                          </label>
                          <>
                            <div className="position-relative">
                              <DatePicker
                                {...register('start_date', {
                                  required: datepickerItem.display,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('start_date')
                                 // setValue('start_date', format(event, 'yyyy-MM-dd'))
                                 if (event) {
                                    // setStartDate(format(event, 'yyyy-MM-dd'))
                                    setValue('start_date', format(event, 'yyyy-MM-dd'))
                                  } else {
                                    setValue('start_date', '')
                                  }
                                  setStartDate(event)
        
                                  setDatepickerItem({
                                    ...datepickerItem,
                                    start_date: event,
                                  })
                                }}
                                selected={datepickerItem.start_date}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                               // maxDate={addDays(new Date(), 0)}
                                preventOpenOnFocus={true}
                                placeholder="วันที่เริ่มต้น"
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                            </div>
                            <DatePickerWrapperStyles />
                          </>
                          {errors?.start_date?.type === 'required' && (
                            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
                          )}
                        </Col>
                        <Col md={6} className="mt-3 mb-3">
                          <label htmlFor="end_date" className="label-with-required">
                            วันที่สิ้นสุด
                          </label>
                          <>
                            <div className="position-relative">
                              <DatePicker
                                {...register('end_date', {
                                  required: datepickerItem.display,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('end_date')
                                  //setValue('end_date', format(event, 'yyyy-MM-dd'))
                                  if (event) {
                                    setValue('end_date', format(event, 'yyyy-MM-dd'))
                                  } else {
                                    setValue('end_date', '')
                                  }
                                  setEndDate(event)
          
                                  setDatepickerItem({
                                    ...datepickerItem,
                                    end_date: event,
                                  })
                                }}
                                selected={datepickerItem.end_date}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                //minDate={addDays(new Date(), 0)}
                                preventOpenOnFocus={true}
                                placeholder="วันที่สิ้นสุด"
                                isClearable={true}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={addDays(new Date(startDate), 1)}
          

                           
          
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                            </div>
                            <DatePickerWrapperStyles />
                          </>
                          {errors?.end_date?.type === 'required' && (
                            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
                          )}
                        </Col>
                        <Col md={6} className="mb-3">
                          <label htmlFor="contract_id" className="label-with-required">
                            เลขที่สัญญา
                          </label>
                          <Controller
                            name="contract_id"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'เลขที่สัญญา'}
                                isClearable
                                isSearchable={false}
                                styles={selectStyles}
                                options={contract?.options}
                                placeholder="เลือกเลขสัญญา"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(event) => {
                                  clearErrors('contract_id')
                                  if (event !== null) {
                                    setValue('contract_id', event.value)
                                  } else {
                                    setValue('contract_id', '')
                                  }
                                }}
                              />
                            )}
                          />
                          {errors?.contract_id?.type === 'required' && (
                            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
                          )}
                        </Col>
                        <Col md="6">
                          
                          <label className="form-check-label mt-4" htmlFor="is_recoup">
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              {...register('is_recoup')}
                            />
                            { expandType == 2 ? 'นับระยะเวลารับทุน':'นับเวลาชดใช้ทุน' }
                          </label>
                        </Col>
                      </>
                    )}

                    <Col sm={12} className="mt-3">
                      <label className="label-with-required">ความประสงค์</label>
                      <textarea
                        {...register('description')}
                        className="form-control"
                        maxLength={214}
                      />

                      {errors.description && (
                        <div className="help-block">{errors.description.message}</div>
                      )}
                    </Col>
                    <Col sm={12} className="mt-3">
                      <label className="label-with-required">เหตุผลและความจำเป็น</label>
                      <textarea {...register('reason')} className="form-control" maxLength={214} />

                      {errors.description && (
                        <div className="help-block">{errors.reason.message}</div>
                      )}
                    </Col>

                    <Col md={12} className="mt-3">
                      <div className="form-group field-petitionexpense-0-files">
                        <label> ไฟล์เอกสาร </label>
                        <div className="file-caption">
                          <div className="upload-select">
                            <div className="d-flex justify-content-start">
                              <div className="btn-file-upload">
                                <span role="img" aria-label="upload" className="icon-upload">
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="upload"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload</span>
                                <input
                                  type="file"
                                  // style={{ display: "none" }}
                                  //  className={"btn-input-upload"}
                                  id="icon-button-file"
                                  {...register('file')}
                                  onChange={(e) => handleChangeFile(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <aside style={thumbsContainer} className="upload-list">
                            {thumbs}
                          </aside>
                        </div>

                        <span className="text-danger font-size-xs">
                          *ขนาดไฟล์แนะนำไม่เกิน 10 MB
                        </span>
                        {errors.file && <div className="help-block">{errors.file.message}</div>}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-group text-end mt-4">
                        {!isLoading ? (
                          <button type="submit" className="btn btn-primary">
                            บันทึก
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary" disabled>
                            กำลังบันทึก...
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default StatusCreate
