import { faBusinessTime, faFileAlt, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import React, { forwardRef, useEffect, useState } from 'react'
import { AlertError, AlertSuccess } from '../../components/Alert'
import { Col, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import * as yup from 'yup'
import { DateButton, DatePickerWrapperStyles, IconCalendar } from '../../components/Filter/styles'
import { history } from '../../helpers/history'
import { useWorkplaceOptions } from '../../hooks/options/useOptions'
import http from '../../utils/http'
import useFetch from '../../utils/useFetch'
import _ from '../../utils/utils'
import addDays from 'date-fns/addDays'

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`

const OperationalCreate = (props) => {


  const { options: workplaceOptions, loading: workplaceLoading } = useWorkplaceOptions({
    limit: 20,
  })
  
  const loadWorkplaceOptions = async (inputValue, callback) => {
    const response = await http.get(
      `/common/organization?workplace=1&limit=10&search=${inputValue}`
    )
    callback(
      response?.data?.map((item) => ({
        value: item?.id,
        label: `${item.name_th} ${
          item.parent_name_th
            ? `(${item.parent_name_th} - ${
                item?.grand_parent_name_th !== null ? item?.grand_parent_name_th : ''
              })`
            : ''
        }`,
        organization_label: item.name_th,

        parent_id: item?.parent_id,
        parent_label: item.parent_name_th,

        grand_parent_id: item?.grand_parent_id,
        grand_parent_label: item.grand_parent_name_th,
      }))
    )
  }

  const [files, setFiles] = useState([])
  const [organizationOptions, setOrganizationOptions] = useState([])
  const [organizationLoading, setOrganizationLoading] = useState(true)
  const organizationApi = `/student/operational/organization`

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [organization, setOrganization] = useState({
    parent_id: null,
    grand_parent_id: null,
    id: null,

    root_organization_id: null,
    root_organization_name: null,
    root_organization_value: null,

    sub1_organization_id: null,
    sub1_organization_name: null,
    sub1_organization_value: null,
    sub1_organization_disabled: true,
    sub1_organization_is_loading: false,

    sub2_organization_id: null,
    sub2_organization_name: null,
    sub2_organization_value: null,
    sub2_organization_disabled: true,

    organization_id: null,
    organization_name: null,

    root_organization_options: [],
    sub1_organization_options: [],
    sub2_organization_options: [],
  })

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))

  const urlAction = '/operational'
  const urlActionCallAPI = '/student/operational/create'

  let getOrganizationOptions = []
  let getSubOrganizationOptions = []
  const [isLoading, setIsLoading] = useState(false)
  // const [organizationOptions, setOrganizationOptions] = useState(getOrganizationOptions)
  const [subOrganizationOptions, setSubOrganizationOptions] = useState(getSubOrganizationOptions)
  

  const validationSchema = yup.object().shape({
    root_organization_id: yup
      .object()
      .shape({
        label: yup.string().required('หน่วยงานต้องไม่เป็นค่าว่าง'),
        value: yup
          .string()
          .required('หน่วยงานต้องไม่เป็นค่าว่าง')
          .nullable()
          .required('หน่วยงานต้องไม่เป็นค่าว่าง'),
      })
      .nullable()
      .required('หน่วยงานต้องไม่เป็นค่าว่าง'),

    position_group: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกกลุ่มตำแหน่งงาน'),
        value: yup
          .string()
          .required('กรุณาเลือกกลุ่มตำแหน่งงาน')
          .nullable()
          .required('กรุณาเลือกกลุ่มตำแหน่งงาน'),
      })
      .nullable()
      .required('กรุณาเลือกกลุ่มตำแหน่งงาน'),

    position: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกตำแหน่ง'),
        value: yup.string().required('กรุณาเลือกตำแหน่ง').nullable().required('กรุณาเลือกตำแหน่ง'),
      })
      .nullable()
      .required('กรุณาเลือกตำแหน่ง'),

    // position_group: yup.string().required('กรุณาเลือกกลุ่มตำแหน่งงาน'),
    // position: yup.string().required('กรุณาเลือกตำแหน่ง'),
    start_work: yup.string().required('วันที่บรรจุต้องไม่ว่าง'),
    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
    //end_work: yup.string().required('วันที่สิ้นสุดในการปฏิบัติงานต้องไม่ว่าง'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      position_group: null,
      position: null,
      organization_id: null,
    },
  }

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    clearErrors,
    resetField,
    setError,
    getValues,
  } = useForm(formOptions)
  const { errors } = formState

  const [datepickerItem, setDatepickerItem] = useState({
    start_work: getValues('start_work') ? new Date(getValues('start_work')) : null,
    end_work: getValues('end_work') ? new Date(getValues('end_work')) : null,
  })

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

  const isHasValue = {
    grandParentOrg: (value) => {
      return value > 0
    },

    parentOrg: (value) => {
      return value > 0
    },
  }

  const onChangeOrgSelectedOption = async (value, fieldName) => {
    const grandParentID = value?.grand_parent_id
    let parentID = value?.parent_id || 0
    let root_organization_id = null
    let root_organization_name = null
    let rootOrganizationValue = null

    let sub1_organization_id = null
    let sub1_organization_name = null
    let sub1OrganizationValue = null
    let sub1OrganizationOptions = null
    let sub1OrganizationIsLoading = false

    let sub2_organization_id = null
    let sub2_organization_name = null
    let sub2OrganizationValue = null
    let sub2OrganizationOptions = []
    let sub2OrganizationIsLoading = false

    let organization_id = null
    let organization_name = null

    let options = ''
    if (!_.isNull(value)) {
      if (fieldName === 'root_organization_id') {
        // parentID = value?.value
        //console.log(value)

        root_organization_id = value?.value
        root_organization_name = value?.label
 
        let dataRes = await http.get(`/student/operational/organization?parent_id=${value?.value}`)
        sub1OrganizationIsLoading = true
        if (dataRes.status === 200) {
          const organizations = dataRes.data
          
          sub1OrganizationOptions = organizations?.map((item, index) => {
            return {
              value: item.id,
              label: item.name_th,
            }
          })

          sub1OrganizationIsLoading = false
        }
        
        organization_id = root_organization_id
        organization_name = root_organization_name

        options = {
          label: root_organization_name,
          value: root_organization_id,
        }

        sub1_organization_id = null
        sub1_organization_name = null

        resetField('sub1_organization_id')
        resetField('sub2_organization_id')
     
     
      } else if (fieldName === 'sub1_organization_id') {
        let dataRes = await http.get(`/student/operational/organization?parent_id=${value?.value}`)

        if (dataRes.status === 200) {
          const organizations = dataRes.data
          sub2OrganizationOptions = organizations?.map((item, index) => {
            return {
              value: item.id,
              label: item.name_th,
            }
          })

          sub2OrganizationIsLoading = false
        }

        sub2_organization_id = null
        sub2_organization_name = null
      }

      if (isHasValue.grandParentOrg(grandParentID)) {         
        root_organization_id = grandParentID
        root_organization_name = value?.grand_parent_label

        sub1_organization_id = parentID
        sub1_organization_name = value?.parent_label
               
        sub2_organization_id = value?.value
        sub2_organization_name = value?.organization_label
       
        organization_id = value?.value
        organization_name = value?.organization_label

        // if (isHasValue.grandParentOrg(grandParentID)) {
        //   options = {
        //     value: value?.value,
        //     label: value?.organization_label,
        //   }

        //   root_organization_id = grandParentID
        //   root_organization_name = value?.grand_parent_label

        //   sub1_organization_id = parentID
        //   sub1_organization_name = value?.parent_label

        //   sub2_organization_id = value?.value
        //   sub2_organization_name = value?.organization_label

        //   organization_id = value?.value
        //   organization_name = value?.organization_label
        // }
      } else if (isHasValue.parentOrg(parentID)) {     
        root_organization_id = parentID
        root_organization_name = value?.parent_label

        sub1_organization_id = value?.value
        sub1_organization_name = value?.organization_label

        sub2_organization_id = null
        sub2_organization_name = null

        organization_id = value?.value
        organization_name = value?.label

      }

      setValue('root_organization_id', options)

      setValue('sub1_organization_id', {
        value: sub1_organization_id,
        label: sub1_organization_name
      })
 
      setValue('sub2_organization_id', {
        value: sub2_organization_id,
        label: sub2_organization_name
      })
      console.log('sub2_organization_id ', getValues('sub2_organization_id'));
      setOrganization({
        ...organization,
        root_organization_id: root_organization_id,
        root_organization_name: root_organization_name,
        root_organization_value: {
          value: root_organization_id,
          label: root_organization_name,
        },
  
        sub1_organization_id: sub1_organization_id,
        sub1_organization_name: sub1_organization_name,
        sub1_organization_value: {
          value: sub1_organization_id,
          label: sub1_organization_name
        },
        sub1_organization_options: sub1OrganizationOptions,
        sub1_organization_disabled: false,
        sub1_organization_is_loading: sub1OrganizationIsLoading,
  
        sub2_organization_id: sub2_organization_id,
        sub2_organization_name: sub2_organization_name,
        sub2_organization_value: {
          value: sub2_organization_id,
          label: sub2_organization_name
        },
        sub2_organization_options: sub2OrganizationOptions,
        sub2_organization_disabled: false,
        sub2_organization_is_loading: sub2OrganizationIsLoading,
   
        organization_id: organization_id,
        organization_name: organization_name,
  
      })
    }

    clearErrors('root_organization_id')
  }

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      let dataPost = {
        action: 'create',
        organization_id: organization?.organization_id,
        root_organization_id: dataObj.root_organization_id?.value,
        sub1_organization_id: dataObj.sub1_organization_id?.value,
        sub2_organization_id: dataObj.sub2_organization_id?.value,

        position_group: dataObj.position_group?.value,
        position: dataObj.position?.value,
        start_work: dataObj.start_work,
        end_work: dataObj.end_work,
        files: files,
      }
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.status === 201) {
        setIsLoading(false)
        AlertSuccess('เพิ่มข้อมูลเรียบร้อย', '')
        history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
        AlertError('เกิดข้อผิดพลาด', '')
      }
    }
  }

  const { response: responseParentOrganization, loading: loadingParentOrganization } = useFetch(
    '/student/operational/organization',
    {
      parent_id: 0,
    }
  )

  const handleSub1OrganizationChange = async (value, fieldName) => {
    let options = ''
    let parentID = value?.parent_id || 0

    let sub2_organization_id = null
    let sub2_organization_name = null
    let sub2OrganizationValue = null
    let sub2OrganizationOptions = null
    let sub2OrganizationIsLoading = false

    let organization_id = null
    let organization_name = null

    let organizationOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }

      organization_id = value?.value
      organization_name = value?.label

      let dataRes = await http.get(`/student/operational/organization?parent_id=${value?.value}`)
      if (dataRes.data) {
        const organizations = dataRes.data
        organizationOptions = organizations?.map((item, index) => {
          return {
            value: item.id,
            label: item.name_th,
          }
        })
      }
      clearErrors(fieldName)
    }

    setOrganization({
      ...organization,
      sub2_organization_id: sub2_organization_id,
      sub2_organization_name: sub2_organization_name,
      sub2_organization_value: sub2OrganizationValue,
      sub2_organization_options: organizationOptions,
      sub2_organization_disabled: false,
      sub2_organization_is_loading: sub2OrganizationIsLoading,
      organization_id: organization_id,
      organization_name: organization_name,
    })

    setValue('sub1_organization_id', options)
  }

  const handleSub2OrganizationChange = async (value, fieldName) => {
    let options = ''
    let organization_id = null
    let organization_name = null

    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }

      organization_id = value?.value
      organization_name = value?.label

      clearErrors(fieldName)
    }

    setOrganization({
      ...organization,
      organization_id: organization_id,
      organization_name: organization_name,
    })

    setValue(fieldName, options)
  }

  // const handleOrganizationChange = async (value, fieldName) => {
  //   let options = ''
  //   // let districtOptions = []
  //   if (!_.isNull(value)) {
  //     options = {
  //       value: value?.value,
  //       label: value?.label,
  //     }
  //     // /api/office/masterdata/organization/list

  //     let dataRes = await http.get(`/student/operational/organization?parent_id=${value?.value}`)
  //     if (dataRes.data) {
  //       const organizations = dataRes.data
  //       let organizationOptions = organizations?.map((item, index) => {
  //         return {
  //           value: item.id,
  //           label: item.name_th,
  //         }
  //       })
  //       if (fieldName === 'parent_organization_id') {
  //         setOrganizationOptions(organizationOptions)
  //         setValue('organization_id', {})
  //         setValue('sub_organization_id', {})
  //       } else if (fieldName === 'organization_id') {
  //         setSubOrganizationOptions(organizationOptions)
  //         setValue('sub_organization_id', {})
  //       }
  //     }
  //     //clearErrors(fieldName)
  //   } else {
  //     if (fieldName === 'parent_organization_id') {
  //       setOrganizationOptions('')
  //       setValue('organization_id', {})
  //       setValue('sub_organization_id', {})
  //     } else if (fieldName === 'organization_id') {
  //       setSubOrganizationOptions('')
  //       setValue('sub_organization_id', {})
  //     }
  //   }

  //   setValue(fieldName, options)
  // }

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const { response: responsePositionGroup, loading: loadingPositionGroup } = useFetch(
    '/student/masterdata/position-group'
  )

  const positionGroupOptions = responsePositionGroup?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const { response: responsePosition, loading: loadingPosition } = useFetch(
    '/student/masterdata/position'
  )

  const positionOptions = responsePosition?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '50px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB

    if (fileInput.length === 0) {
      setError('file', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }

    if (!fileInput[0].name.match(/\.(pdf)$/)) {
      setError('file', {
        type: 'manual',
        message: 'ไฟล์นามสกุล .pdf เท่านั้น',
      })
      return false
    }
    if (fileSize > 10) {
      setError('file', {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      })
      return false
    }

    clearErrors('file')

    if (!errors.file) {
      setFiles(newFileInput)
    }
  }

  const handleRemoveFile = (i, e) => {
    let newFileInput = [...files]
    newFileInput.splice(i, 1)
    setFiles(newFileInput)
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let dataRes = await http.get(organizationApi)
  //     if (dataRes.status === 200) {
  //       setOrganizationLoading(false)
  //       let options = dataRes.data.map((item, index) => {
  //         return {
  //           value: item.id,
  //           label: item.name_th,
  //         }
  //       })
  //       setOrganizationOptions(options)
  //     }
  //   }

  //   fetchData()
  // }, [])


  // const auth = localStorage.getItem('user')
  // const userData = JSON.parse(auth)
  
  // const allowOperational = userData?.allow_operational 

  // if (!allowOperational) { 
  //   return (
  //    <div className="mt-4">
  //       <section className="bg-light">
  //         <div className="container">
  //           <div className="card border-0 mb-3 d-flex py-4">
            
  //             <h1 className="text-center">ไม่สามารถเพิ่มข้อมูลปฏิบัติงานได้</h1>
  //           </div>
  //         </div>
  //       </section>
  //     </div>
  //   )
  // }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/operational"> การปฏิบัติงาน </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            <span>เพิ่มข้อมูลการปฏิบัติงาน </span>
          </li>
        </ol>
      </nav>
      <div className="academic-result-create">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header">
                <div className="d-md-flex pt-4 bg-white border-0 ps-0 w-100  my-0 mx-auto">
                  <div>
                    <h5 className="card-title d-flex align-items-center me-auto text-start ps-0">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faBusinessTime} />
                      </div>
                      <span>เพิ่มข้อมูลการปฏิบัติงาน </span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="operational-form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mt-3">
                      <Col md={6}>
                        <div className="form-group required">
                          <label className="control-label" htmlFor="parent_organization_id">
                            หน่วยงานต้นสังกัด
                          </label>
                          <Controller
                            name="root_organization_id"
                            control={control}
                            render={(field) => (
                              <AsyncSelect
                                {...field}
                                isSearchable={true}
                                isClearable
                                isLoading={workplaceLoading}
                                isDisabled={workplaceLoading}
                                defaultOptions={workplaceOptions}
                                defaultValue={organization?.root_organization_value}
                                loadOptions={loadWorkplaceOptions}
                                styles={selectStyles}
                                placeholder="เลือกหน่วยงานต้นสังกัด"
                                onChange={(value) =>
                                  onChangeOrgSelectedOption(value, 'root_organization_id')
                                }
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                loadingMessage={() => 'กำลังค้นหา...'}
                              />
                            )}
                          />
                          {errors.root_organization_id && (
                            <div className="help-block">{errors.root_organization_id.message}</div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="control-label" htmlFor="sub1_organization_id">
                            หน่วยงาน
                          </label>
                          <Controller
                            name="sub1_organization_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                label={'หน่วยงาน'}
                                isClearable
                                isLoading={organization?.sub1_organization_is_loading}
                                isSearchable={true}
                                isDisabled={organization?.sub1_organization_disabled}
                                defaultValue={organization?.sub1_organization_value}
                                styles={selectStyles}
                                options={organization?.sub1_organization_options}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                placeholder="เลือกหน่วยงาน"
                                onChange={(value) => {
                                  handleSub1OrganizationChange(value, 'sub1_organization_id')
                                }}
                              />
                            )}
                          />
                          {errors?.organization_id && (
                            <div className="help-block">{errors.organization_id?.message}</div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="control-label" htmlFor="sub2_organization_id">
                            หน่วยงานย่อย
                          </label>
                          <Controller
                            name="sub2_organization_id"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'หน่วยงานย่อย'}
                                isClearable
                                isSearchable={true}
                                isDisabled={organization?.sub2_organization_disabled}
                                styles={selectStyles}
                                options={organization?.sub2_organization_options}
                                defaultValue={organization?.sub2_organization_value}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                placeholder="เลือกหน่วยงานย่อย"
                                onChange={(value) => {
                                  handleSub2OrganizationChange(value, 'sub2_organization_id')
                                }}
                              />
                            )}
                          />
                          {errors.sub2_organization_id && (
                            <div className="help-block">{errors.sub2_organization_id.message}</div>
                          )}
                        </div>
                      </Col>
                    </div>

                    <div className="row mt-3">
                      <Col md={6}>
                        <div className="form-group required">
                          <label className="control-label" htmlFor="position_group">
                            กลุ่มตำแหน่งงาน
                          </label>
                          <Controller
                            name="position_group"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'กลุ่มตำแหน่งงาน'}
                                isLoading={loadingPositionGroup}
                                sDisabled={loadingPositionGroup}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={positionGroupOptions}
                                placeholder="เลือกกลุ่มตำแหน่งงาน"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => {
                                  handleSelectChange(value, 'position_group')
                                }}
                              />
                            )}
                          />
                          {errors.position_group && (
                            <div className="help-block"> กรุณาเลือกกลุ่มตำแหน่งงาน </div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group field-academicresult-type required">
                          <label className="control-label" htmlFor="academicresult-type">
                            ตำแหน่งงาน
                          </label>
                          <Controller
                            name="position"
                            control={control}
                            render={(field) => (
                              <Select
                                {...field}
                                label={'ตำแหน่งงาน'}
                                isLoading={loadingPosition}
                                sDisabled={loadingPosition}
                                isClearable
                                isSearchable={true}
                                styles={selectStyles}
                                options={positionOptions}
                                placeholder="เลือกตำแหน่งงาน"
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                onChange={(value) => {
                                  handleSelectChange(value, 'position')
                                }}
                              />
                            )}
                          />

                          {errors.position && (
                            <div className="help-block"> กรุณาเลือกตำแหน่งงาน </div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group  required">
                          <label className="control-label" htmlFor="start_work">
                            วันที่เริ่มทำงาน
                          </label>
                          <>
                            <div className="position-relative">
                              <DatePicker
                                {...register('start_work', {
                                  required: true,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('start_work')
                                  //setValue('start_work', format(event, 'yyyy-MM-dd'))
                                  if (event) {
                                    setValue('start_work', format(event, 'yyyy-MM-dd'))
                                  } else {
                                    setValue('start_work', '')
                                  }
                                  setDatepickerItem({ ...datepickerItem, start_work: event })
                                }}
                                selected={datepickerItem?.start_work}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                preventOpenOnFocus={true}
                                placeholder="วันที่เริ่มทำงาน"
                                startDate={startDate}
                                endDate={endDate}
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                            </div>
                            <DatePickerWrapperStyles />
                          </>
                          {errors.start_work && (
                            <div className="help-block">{errors.start_work.message}</div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label className="control-label" htmlFor="end_work">
                            วันที่สิ้นสุด
                          </label>
                          <>
                            <div className="position-relative">
                              <DatePicker
                                {...register('end_work', {
                                  required: true,
                                })}
                                dateFormat="d MMM yyyy"
                                onChange={(event) => {
                                  clearErrors('end_work')
                                  //setValue('end_work', format(event, 'yyyy-MM-dd'))
                                  if (event) {
                                    setValue('end_work', format(event, 'yyyy-MM-dd'))
                                  } else {
                                    setValue('end_work', '')
                                  }
                                  setDatepickerItem({ ...datepickerItem, end_work: event })
                                }}
                                selected={datepickerItem?.end_work}
                                customInput={<InputDate />}
                                className="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                preventOpenOnFocus={true}
                                placeholder="วันที่สิ้นสุด"
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={addDays(new Date(startDate), 1)}
                              />
                              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                            </div>
                            <DatePickerWrapperStyles />
                          </>
                          {errors.end_work && (
                            <div className="help-block">{errors.end_work.message}</div>
                          )}
                        </div>
                      </Col>
                    </div>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="file" className="label-with-required">
                          แนบไฟล์
                        </label>
                        <div className="file-caption">
                          <div className="upload-select">
                            <div className="d-flex justify-content-start">
                              <div className="btn-file-upload">
                                <span role="img" aria-label="upload" className="icon-upload">
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="upload"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload</span>
                                <input
                                  type="file"
                                  // style={{ display: "none" }}
                                  //  className={"btn-input-upload"}
                                  id="icon-button-file"
                                  {...register('file')}
                                  onChange={(e) => handleChangeFile(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <aside style={thumbsContainer} className="upload-list">
                            {thumbs}
                          </aside>
                        </div>
                        <span className="text-secondary  font-size-xs">
                          ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น
                        </span>
                        {errors.file && <div className="help-block">{errors.file.message}</div>}
                      </Col>
                    </Row>

                    <div className="form-group text-end">
                      {!isLoading ? (
                        <button type="submit" className="btn btn-primary">
                          บันทึก
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary" disabled>
                          กำลังบันทึก...
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default OperationalCreate
