import _ from '../utils/utils'
import PropTypes from 'prop-types'

export const convertCurrency = (value) => {
  value = !_.isNull(value) ? value : 0

  return Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  })
    .format(value)
    .replace('฿', '')
}

PropTypes.convertCurrency = {
  value: PropTypes.number,
}

export const convertCurrencyToNumber = (value) => {
  if (!_.isNu(value) && !_.isEmpty(value)) {
    return value?.replace(/\,/g, '')
  }
  return 0
}

export const currencyHelper = {
  isBaht: (currency) => currency === 'บาท',
}
