import { faCircle, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import useFetch from '../../utils/useFetch'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import { history } from '../../helpers/history'
import { dateHelper } from '../../helpers/date.helper'
import { ButtonViewDetail } from '../../components/Button'
import { petitionStatusHelper } from '../../helpers/petition.helper'
import { PaginationHelper } from '../../helpers/pagination.helper'
import { Link, useParams } from 'react-router-dom'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Breadcrumb from '../../components/BreadcrumbBS'

const History = () => {
  const { id } = useParams()
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const sizePerPage = 10

  const actionButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <ButtonViewDetail link={`/petition-student-status/history/detail/${row?.id}`} />
      </>
    )
  }

  const { response, loading } = useFetch(`/student/petition-status/history/${id}`)

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: PaginationHelper.getTotalRenderer,
    onPageChange: (page, sizePerPage) => {
      history.push(`/petition-student-status?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: 'เลขที่คำร้อง',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
    },
    {
      dataField: 'petition_type_name',
      text: 'ประเภทคำร้อง',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },

    {
      dataField: 'status',
      text: 'สถานะ',
      sort: true,
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return petitionStatusHelper.getLabel(row?.status, row?.note)
      },
    },
    {
      dataField: 'created_at',
      text: 'วันที่ยื่นคำร้อง',
      sort: true,
      headerStyle: () => {
        return { width: '280px', minWidth: '280px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.created_at)
      },
    },
    {
      dataField: 'updated_at',
      text: 'วันที่แก้ไข',
      sort: true,
      headerStyle: () => {
        return { width: '280px', minWidth: '280px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.updated_at)
      },
    },
    {
      dataField: 'action',
      text: 'รายละเอียด',
      align: 'center',
      headerStyle: () => {
        return { width: '135px', minWidth: '135px', textAlign: 'center' }
      },
      events: {
        onClick: (e) => {
          e.stopPropagation()
        },
      },
      formatter: actionButton,
    },
  ]

  const pageName = 'คำร้องสถานภาพ'
  const pageTitle = 'ประวัติการแก้ไข'

  const routes = [
    { path: '/petition-student-status', breadcrumb: pageName },
    { path: `/petition-student-status/${id}`, breadcrumb: 'รายละเอียดคำร้อง' },
    {
      path: '',
      breadcrumb: pageTitle,
    },
  ]

  return (
    <>
      <div className="petition-index">
        <Breadcrumb isLoading={loading} routes={routes} />
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header ">
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div>
                    <h5 className="card-title d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span> ประวัติการแก้ไข </span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    <div>
                      {!loading ? (
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />

                              <BootstrapTable
                                id="tableResize"
                                // remote={{ pagination: true, filter: false, sort: false }}
                                classes="resizable"
                                bootstrap4
                                hover
                                striped={false}
                                bordered={false}
                                keyField="id"
                                data={response}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                wrapperClasses="table-responsive"
                                noDataIndication={'ไม่พบข้อมูล'}
                                {...paginationTableProps}
                              />
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          )}
                        </PaginationProvider>
                      ) : (
                        <table id="petition" className="table">
                          <thead>
                            <tr>
                              <th style={{ width: 220, minWidth: 220 }}>รหัสคำร้อง</th>
                              <th style={{ width: 220, minWidth: 220 }}>ประเภทคำร้อง</th>
                              <th style={{ width: 220, minWidth: 220 }}>วันที่ยื่นคำร้อง</th>
                              <th style={{ width: 220, minWidth: 220 }}>วันที่แก้ไข</th>
                              <th style={{ width: 180, minWidth: 180 }}>สถานะ</th>
                              <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                                รายละเอียด
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default History
