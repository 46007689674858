import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

const useFetchGET = (endpoint, params = null) => {
  let headers = {
    "Content-Type": "application/json",
  };
  let options = {
    url: baseUrl + endpoint,
    method: "GET",
    headers: headers,
  };
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(async () => {
    await axios(options)
      .then((response) => {
        const json = response.data;
        setResponse(json.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  return { response, error, loading };
};

export default useFetchGET;
