export const TYPE_NUMBER = 'number'
export const TYPE_TEXT = 'text'
export const TYPE_RESEARCH = 'research'

export const academicResultHelper = {
  isTypeNumber: (type) => type === TYPE_NUMBER,
  isTypeText: (type) => type === TYPE_TEXT,
  isTypeResearch: (type) => type === TYPE_RESEARCH,

  getText: (type) => {
    const typeMapper = {
      [TYPE_NUMBER]: 'แบบตัวเลข',
      [TYPE_TEXT]: 'แบบตัวหนังสือ',
      [TYPE_RESEARCH]: 'แบบวิจัย',
    }[type]

    return typeMapper || ''
  },

  getTypeOptions: () => {
    return [
      {
        value: TYPE_NUMBER,
        label: academicResultHelper.getText(TYPE_NUMBER),
      },
      {
        value: TYPE_TEXT,
        label: academicResultHelper.getText(TYPE_TEXT),
      },
      {
        value: TYPE_RESEARCH,
        label: academicResultHelper.getText(TYPE_RESEARCH),
      },
    ]
  },

  getClassYearOptions: () => {
    return [
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 4,
        label: 4,
      },
      {
        value: 5,
        label: 5,
      },
      {
        value: 6,
        label: 6,
      },
    ]
  },
}
