import fontkit from '@pdf-lib/fontkit'
import { degrees, PDFDocument, rgb, StandardFonts, PDFArray, PDFName, PDFNumber, PDFHexString, PDFString,pushGraphicsState,rotateDegrees,translate,popGraphicsState, PDFPage } from 'pdf-lib';
import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useParams, Link } from 'react-router-dom'
import { Breadcrumb, Card, Container, Row, Col } from 'react-bootstrap'
import Iframe from 'react-iframe'
import useFetch from '../../utils/useFetch'
import capital_form from '../../assets/contract/capital/form_1.pdf'
import TH_sarabun from '../../assets/fonts/thsarabunnew.ttf'

const Template = () => {
  const { id } = useParams()

  const { response: contractData, loading } = useFetch(`/student/contract/print/${id}`)

  const [initData, setInitData] = useState({
    data: [],
    template: capital_form,
    pdfData: null,
    isLoading: true,
    isLodingInitData: false,
    giverName: '',
    contractName: '',
    contractNo: '',
  })

  const writeCodeTextMultiline = async (txt, page, label) => {
    const {
      x,
      y,
      size,
      font,
      color,
      opacity = 1,
      maxWidth,
      lineHeight,
    } = label;

    // Code text
    // page.drawCircle({ x: x, y: y, size: 3, color: PDFLib.cmyk(1, 0, 1, 0) });

    page.pushOperators(
      pushGraphicsState(),
      translate(x, y),
      // rotateDegrees(angle),
      // translate(0, -(x - adjustedX) * Math.sin((angle * Math.PI) / 180))
    );

    page.drawText(txt, {
      font: font,
      size: size,
      opacity: opacity,
      //x: x, //adjustedX,
     // y: y, //y,
      //rotate: degrees(angle),
      color:color,
      //สำหรับตัดข้อความขึ้นบรรทัดใหม่
      maxWidth: maxWidth,
      lineHeight:lineHeight,
      wordBreaks: ['และเทคโนโลยีฯ','',' '],
    });

    page.pushOperators(popGraphicsState());

    return page
  };

  async function modifyPdf() {
    var option_pdf = {
      font_url: TH_sarabun,
      font_size: 9,
      font_rotage: degrees(-45), //สำหรับลายน้ำ
      font_color: rgb(0, 0, 0), //สีดำ
      //font_color: rgb(0.95, 0.1, 0.1),//สีแดง
      lineHeight: 12,
    }

    const font_url = option_pdf.font_url
    const fontBytes = await fetch(font_url).then((res) => res.arrayBuffer())

    const url = initData.template
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    pdfDoc.registerFontkit(fontkit)
    const sarabunFont = await pdfDoc.embedFont(fontBytes)

    const pages = pdfDoc.getPages()
    //console.log('Total page: ' + pages.length)
    let page_setup = ''
    const { width, height } = pages[0].getSize() // ขนาดกระดาษ
    //console.log('Page size (per page): ' + width + ' : ' + height)

    for (let index = 0; index < pages.length; index++) {
      page_setup = pages[index]
      page_setup.setLineHeight(option_pdf.lineHeight)

      if (initData.data[index] !== undefined && initData.data[index].length > 0) {
        for (let i = 0; i < initData.data[index].length; i++) {
          let data = initData.data[index][i]
          if (data.value !== undefined) {
            let show_value = data.value
            if (data.value === null || data.value === undefined || data.value === '') {
              show_value = '-'
            }
            if (data?.type !== undefined && data.type === 'giver_full_name') {
              writeCodeTextMultiline(String(show_value), page_setup, {
                x: data.x,
                y: data.y,
                size: data.size !== undefined && data.size !== '' ? data.size : option_pdf.font_size,
                font: sarabunFont,
                color: option_pdf.font_color,
                lineHeight: data.lineHeight !== undefined && data.lineHeight !== '' ? data.lineHeight : 20,
                maxWidth: data.maxWidth !== undefined && data.maxWidth !== '' ? data.maxWidth : 200,
              });
            } else { 
              page_setup.drawText(String(show_value), {
                x: data.x,
                y: data.y,
                leading: 1,
                size: data.size !== undefined && data.size !== '' ? data.size : option_pdf.font_size,
                font: sarabunFont,
                color: option_pdf.font_color,
                // maxWidth:data.max_width !== undefined && data.max_width !== '' ? Number(data.max_width) : width,
              })
            }
          }
        }
      }
    }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
    setInitData({ ...initData, pdfData: pdfDataUri })
  }

  async function loadTemplate() {

    let isDpstScholarship = false
    if (contractData.scholarship_id === 1 || contractData.scholarship_id === 10) {
      isDpstScholarship = true
    }
    let isOlympicsScholarship = contractData.scholarship_id === 2
    let isPsmtScholarship = contractData.scholarship_id === 3

    let isDomestic = contractData.country_type === 'domestic'
    // let isAbroad = contractData.country_type === 'abroad'

    let isSchool = contractData.education_level === 'school'
    let isUniversity = contractData.education_level === 'university'

    let isCommitment_0 = String(contractData.commitment) === '0'
    let isCommitment_1 = String(contractData.commitment) === '1'
    let template_ = capital_form
    let data_ = []
    let template_form = ''
    let template_pdf = ''

    if (isDpstScholarship && isCommitment_0 && contractData.contract_type === '2') {
      template_form = await import('.//template/capital_contract/modify/no_obligation')
      if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
        template_pdf = await import(
          '../../assets/contract/approved/capital/modify/form_no_obligation.pdf'
        )
      } else {
        template_pdf = await import('../../assets/contract/capital/modify/form_no_obligation.pdf')
      }
    } else if (isDpstScholarship && isCommitment_1 && contractData.contract_type === '2') {
      template_form = await import('.//template/capital_contract/modify/obligation')
      if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
        template_pdf = await import(
          '../../assets/contract/approved/capital/modify/form_obligation.pdf'
        )
      } else {
        template_pdf = await import('../../assets/contract/capital/modify/form_obligation.pdf')
      }
    } else if (isOlympicsScholarship && isCommitment_0 && contractData.contract_type === '2') {
      template_form = await import('.//template/olympic/modify/form')
      if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
        template_pdf = await import('../../assets/contract/olympic/approved/modify/form.pdf')
      }else{
        template_pdf = await import('../../assets/contract/olympic/modify/form.pdf')
      }
    } else if (isDpstScholarship) {
      if (isSchool) {
        template_form = await import('.//template/capital_contract/form_1')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/capital/form_1.pdf')
        } else {
          template_pdf = await import('../../assets/contract/capital/form_1.pdf')
        }
      } else if (isUniversity && isDomestic) {
        template_form = await import('.//template/capital_contract/form_2')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/capital/form_2.pdf')
        } else {
          template_pdf = await import('../../assets/contract/capital/form_2.pdf')
        }
        template_ = template_pdf.default
        data_ = template_form.SetDataForm(contractData)
      } else if (isUniversity && !isDomestic) {
        template_form = await import('.//template/capital_contract/form_3')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/capital/form_3.pdf')
        } else {
          template_pdf = await import('../../assets/contract/capital/form_3.pdf')
        }
        template_ = template_pdf.default
        data_ = template_form.SetDataForm(contractData)
      } else {
        template_form = await import('.//template/capital_contract/form_1')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/capital/form_1.pdf')
        } else {
          template_pdf = await import('../../assets/contract/capital/form_1.pdf')
        }
      }
    } else if (isOlympicsScholarship) {
      template_form = await import('.//template/olympic/form')
      if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
        template_pdf = await import('../../assets/contract/olympic/approved/form.pdf')
        //template_pdf = await import('../../assets/contract/approved/olympic/form.pdf')
      } else {
        template_pdf = await import('../../assets/contract/olympic/form.pdf')
      }
      template_ = template_pdf.default
      data_ = template_form.SetDataForm(contractData)
      //template_ = 'olympic/form.js'
    } else if (isPsmtScholarship) {
      template_form = await import('.//template/psmt/form_2')
      if (contractData?.scholarship_type_id === 10 || contractData?.scholarship_type_id === "10") {
        template_form = await import('.//template/psmt/form_2')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/psmt/form_2.pdf')
        } else {
          template_pdf = await import('../../assets/contract/psmt/form_2.pdf')
        }
      } else {
        template_form = await import('.//template/psmt/form_1')
        if (contractData.status === 'waiting_information' || contractData.status === 'pending' || contractData.status === 'waiting_verification' ) {
          template_pdf = await import('../../assets/contract/approved/psmt/form_1.pdf')
        } else {
          template_pdf = await import('../../assets/contract/psmt/form_1.pdf')
        }
      }
      //template_pdf = await import('../../../assets/contract/psmt/psmt_more.pdf') //แบบสัญญาเพิ่มเติม
      template_ = template_pdf.default
      data_ = template_form.SetDataForm(contractData)
      //template_ = 'psmt/form.js'
    } else {
      template_form = await import('.//template/capital_contract/form_1')
      template_pdf = await import('../../assets/contract/capital/form_1.pdf')
    }
    //fix template for dev
      //  template_form = await import('.//template/olympic/form')
      //  template_pdf = await import('../../assets/contract/olympic/form.pdf')

    // template_form = await import('.//template/capital_contract/modify/no_obligation')
    // template_pdf = await import('../../../assets/contract/capital/modify/form_no_obligation.pdf')
    // template_form = await import('.//template/petition/form')
    // template_pdf = await import('../../../assets/petition/form.pdf')

    template_ = template_pdf.default
    data_ = template_form.SetDataForm(contractData)

    let giver_name = ''
    if (
      contractData?.giver_firstname &&
      contractData.giver_firstname !== null &&
      contractData.giver_firstname !== undefined
    ) {
      giver_name += contractData.giver_firstname + ' '
    }
    if (
      contractData?.giver_lastname &&
      contractData.giver_lastname !== null &&
      contractData.giver_lastname !== undefined
    ) {
      giver_name += contractData.giver_lastname + ' '
    }
    let contract_name = ''
    if (
      contractData?.firstname_th &&
      contractData.firstname_th !== null &&
      contractData.firstname_th !== undefined
    ) {
      contract_name += contractData.firstname_th + ' '
    }
    if (
      contractData?.lastname_th &&
      contractData.lastname_th !== null &&
      contractData.lastname_th !== undefined
    ) {
      contract_name += contractData.lastname_th + ' '
    }
    setInitData({
      ...initData,
      template: template_,
      isLodingInitData: true,
      isLoading: false,
      data: data_,
      giverName: giver_name,
      contractName: contract_name,
      contractNo: contractData.contract_no,
    })
  }
  useEffect(() => {

    return setInitData({
      data: [],
      template: capital_form,
      pdfData: null,
      isLoading: true,
      isLodingInitData: false,
      giverName: '',
      contractName: '',
      contractNo: '',
    })
  }, [])

  const pageTitle = contractData?.contract_no
// console.log(contractData)
// console.log(loading)
if (contractData) {
    //console.log(contractData)
    if (initData.isLoading) {
      loadTemplate()
    } else {
      if (initData.isLodingInitData && initData.pdfData === null) {
        setInitData({
          ...initData,
          isLodingInitData: false,
          isLoading: false,
        })
        modifyPdf()
      }
    }
  }
  return (
    <>
      <div className="contract">
        <section className="bg-light">
          <div className="container">
            {!loading ? (
              <Row>
                <Col md="12">
                  <Card className="card-plain table-plain-bg">
                    <Card.Body>
                      <div className="btn-group mb-3 float-right">
                        {/* <ButtonImport link={'/masterdata/bank/import'} /> */}
                        <Link className="btn btn-primary" to={`/contract/${contractData?.id}`}>
                          ย้อนกลับ
                        </Link>
                      </div>
                      <h4>แบบสัญญาให้ทุนเลขที่: {contractData?.contract_no}</h4>
                      <p className="card-category">ผู้ให้ทุน: {initData?.giverName}</p>
                      <p className="card-category">ผู้รับทุน: {initData?.contractName}</p>
                      {/* <Button onClick={(e) => modifyPdf()}>ทดสอบเติมคำลงใน pdf</Button> */}
                      <div className="p-3">
                        <Iframe
                          title="name pdf"
                          url={initData.pdfData}
                          width="100%"
                          height="800px"
                          display="initial"
                          position="relative"
                          className="p-3"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              <>
                <ReactLoading type="bubbles" color="#26b9d1" className="mx-auto my-4" />
              </>
            )}
          </div>
        </section>
      </div>
    </>
  )
}

export default Template
