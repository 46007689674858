import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import Skeleton from 'react-loading-skeleton'
import { contractHelper } from '../../helpers/contract.helper'
import { dateHelper } from '../../helpers/date.helper'
import { ButtonAction } from '../../components/Button'
import Header from '../../components/Header'
import { Table } from 'react-bootstrap'

const List = (props) => {
  const { response: contractData, loading } = useFetch(`/student/contract`)

  return (
    <div className="contract">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <Header title="สัญญา" icon={faClipboardList} isLoading={!loading} />
            <div className="card-body">
              {loading ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>เลขที่สัญญา</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>ทุนที่รับ</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>ประเภททุน</th>
                      <th style={{ width: '240px', minWidth: '240px' }}>ระดับการศึกษา</th>
                      <th style={{ width: '250px', minWidth: '250px' }}>แบบสัญญา</th>
                      <th style={{ width: '220px', minWidth: '220px' }}>วันที่ทำสัญญา</th>
                      <th style={{ width: '250px', minWidth: '250px' }}>สถานะ</th>
                      <th className="text-center" style={{ width: '250px', minWidth: '250px' }}>
                        รายละเอียด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>เลขที่สัญญา</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>ทุนที่รับ</th>
                      <th style={{ width: '150px', minWidth: '150px' }}>ประเภททุน</th>
                      <th style={{ width: '250px', minWidth: '250px' }}>ระดับการศึกษา</th>
                      <th style={{ width: '240px', minWidth: '240px' }}>แบบสัญญา</th>
                      <th style={{ width: '220px', minWidth: '220px' }}>วันที่ทำสัญญา</th>
                      <th style={{ width: '250px', minWidth: '250px' }}>สถานะ</th>
                      <th className="text-center" style={{ width: '250px', minWidth: '250px' }}>
                        รายละเอียด
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contractData?.length > 0 ? (
                      contractData?.map((item, key) => {
                        return (
                          <React.Fragment key={`${item.id}-${key}`}>
                            <tr>
                              <td>{key + 1}</td>
                              <td className="align-middle">{item.contract_no} </td>
                              <td className="align-middle">{item.name} </td>
                              <td className="align-middle">{item.scholarship_type_name}</td>
                              <td className="align-middle">{item.education_level_name_th}</td>
                              <td className="align-middle">
                                {contractHelper.getContractTypeText(item.contract_type)}
                              </td>
                              <td className="align-middle">
                                {dateHelper.getDate(item.contract_date)}
                              </td>
                              <td className="align-middle">
                                {contractHelper?.getStatusLabel(item?.status, item.note)}
                              </td>
                              <td className="align-middle text-center action px-0">
                                <ButtonAction
                                  link={`/contract/${item.id}`}
                                  label="ดูรายละเอียด"
                                  variant="outline-primary"
                                  icon={faSearch}
                                  width="160px"
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <tr>
                        <td valign="top" colSpan={8} className="text-center">
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default List
