
import React, { useState, useCallback,useRef } from 'react';
import { history } from '../helpers/history';

const AuthContext = React.createContext({
    user: {},
    isLoggedIn: false,
    login: (user, expirationTime) => {},
    logout: () => {},

});
const calcutateRemainingTime = (expirationTime)=>{
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

export const AuthContextProvider =  (props) => {
    const initailUser = localStorage.getItem('user');
    const [user, setUser] = useState(initailUser) 
  
    const userIsLoggedIn = !!user;//user.length > 0 ? true : false ; 
    const userFirstLoggedIn = localStorage.getItem('firstLoggedIn'); 

    const loginHandler =  (user, expirationTime) => {


        const userJson = JSON.stringify(user);
        setUser(userJson);
        localStorage.setItem('user',userJson)

       // if(user && (typeof user.lastLoginAt === null || user.lastLoginAt === null || user.lastLoginAt == 'null' || user.resetPassword == 1)){
        let firstLoggedIn = false
        if (user && (user.resetPassword === 1 || user.resetPassword === true )) { //|| user.resetPassword === false || user.resetPassword === null
          localStorage.setItem("firstLoggedIn", true);
          firstLoggedIn = true
        } else {
          localStorage.setItem("firstLoggedIn", false);
        }

        // const remianingTime = calcutateRemainingTime(expirationTime);
        // setTimeout(logoutHandler, remianingTime);

        window.location.href =  "/dashboard";

    }

    const logoutHandler = () => {
        setUser(null); 
        localStorage.removeItem('user');
        localStorage.removeItem('firstLoggedIn');
        window.location.href = "/";
        

    }

    const contextValue = {
        user: user,
        isLoggedIn: userIsLoggedIn,
        firstLoggedIn: userFirstLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    }

    return ( 
        <AuthContext.Provider value={contextValue}> 
            {props.children} 
        </AuthContext.Provider>
        );
};

export default AuthContext