import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCog,
  faCamera,
  faUserLock,
  faUserShield,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { Card, Table, Container, Form, Row, Col, Spinner } from 'react-bootstrap'

import { useForm } from 'react-hook-form'

import useFetch from '../../utils/useFetch'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import http from '../../utils/http'
import axios from 'axios'
import { history } from '../../helpers/history'
import TimezoneSelect from 'react-timezone-select'
import logo from '../../assets/images/logo/static_IPST_Logo.png'
import { Modal } from 'react-bootstrap'

const Resetpassword = (props) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const urlAction = '/'
  const urlActionCallAPI = '/student/setting/resetpassword'

  const [errorReset, setErrorReset] = useState(null)
  const [isLoadingReset, setIsLoadingReset] = useState(false)
  const acceptTerms = useRef(null)
  const [errorsAcceptTerms, setErrorsAcceptTerms] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  let subtitle

  const [currentPasswordHidden, setCurrentPasswordHidden] = useState('password')
  const [passwordHidden, setPasswordHidden] = useState('password')
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState('password')

  const togglePassword = (type) => {
    if (type == 'password') {
      if (passwordHidden === 'password') {
        setPasswordHidden('text')
      } else {
        setPasswordHidden('password')
      }
    } else if (type === 'currentPassword') {
      if (currentPasswordHidden === 'password') {
        setCurrentPasswordHidden('text')
      } else {
        setCurrentPasswordHidden('password')
      }
    } else if (type == 'confirmPassword') {
      if (confirmPasswordHidden === 'password') {
        setConfirmPasswordHidden('text')
      } else {
        setConfirmPasswordHidden('password')
      }
    }
  }

  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required('รหัสผ่านปัจจุบัน ต้องไม่เป็นค่าว่าง'),
    password: yup
      .string()
      .required('รหัสผ่านใหม่ ต้องไม่เป็นค่าว่าง')
      .min(6, 'รหัสผ่านใหม่ ต้องมีอย่างน้อย 6 ตัวอักษร'),
    confirmPassword: yup
      .string()
      .required('ยืนยันรหัสผ่านใหม่ต้องไม่เป็นค่าว่าง')
      .oneOf([yup.ref('password')], 'รหัสผ่านไม่ตรงกัน'),
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions)
  const { errors } = formState

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const onSubmit = async (data) => {
    //  console.log(data);
    setIsLoadingReset(true)
    let acceptTermsValue = data.acceptTerms
    if (!acceptTermsValue) {
      setErrorsAcceptTerms(
        'กรุณากดยอมรับเงื่อนไขการใช้งานเว็บไซต์ และ พรบ. คุ้มครองข้อมูลส่วนบุคคล'
      )
    }
    // setErrorsAcceptTerms('กรุณากดยอมรับเงื่อนไขการใช้งานเว็บไซต์ และ พรบ. คุ้มครองข้อมูลส่วนบุคคล');

    // display form data on success
    //console.log('onSubmit ',data);

    if (data) {
      let dataObj = {
        current_password: data.currentPassword,
        password: data.password,
        confirm_password: data.confirmPassword,
      }

      let dataRes = await http.post(`${urlActionCallAPI}`, dataObj)

      if (dataRes.status == 200) {
        setIsLoadingReset(false)
        setErrorReset(
          <div class="alert alert-success text-white" role="alert">
            {' '}
            {dataRes.data}{' '}
          </div>
        )
        localStorage.setItem('firstLoggedIn', false)
        history.push(`${urlAction}`)
      } else if (dataRes.status == 400) {
        setIsLoadingReset(false)
        setErrorReset(
          <div class="alert alert-danger text-white" role="alert">
            {' '}
            {dataRes.data}{' '}
          </div>
        )
      }
    }

    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    //return false;
  }
  return (
    <>
      <div
        className="account-page"
        style={{
          fontSize: '.9375rem',
          fontWeight: 1.5,
          color: '#1f1f1f',
          backgroundColor: '#f7f7f7',
          minHeight: '100%',
          overflowX: 'hidden',
        }}
      >
        <div className="main-wrapper">
          <div className="account-content reset-password">
            <div className="container">
              <div className="account-logo text-center">
                <img src={logo} width={100} alt="สสวท." className="m-auto" />
                <h4 className="mt-3">ระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา</h4>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <div className="account-title mb-3">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      {errorReset}
                      <Row>
                        <Col lg={12} className="mb-3">
                          <div className="form-group  d-flex flex-wrap">
                            <label>รหัสผ่านปัจจุบัน</label>
                            <input
                              type={currentPasswordHidden}
                              {...register('currentPassword')}
                              name="currentPassword"
                              className={`form-control ${
                                errors.currentPassword ? 'is-invalid' : ''
                              }`}
                              placeholder="รหัสผ่านปัจจุบัน"
                            />
                            {currentPasswordHidden === 'password' ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('currentPassword')}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('currentPassword')}
                              />
                            )}
                            {/* <input 
                              name="currentPassword" 
                              type="password" 
                              {...register('currentPassword')} 
                              placeholder="รหัสผ่านปัจจุบัน" 
                              className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`} /> */}
                            <div className="invalid-feedback">
                              {errors.currentPassword?.message}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} className="mb-3">
                          <div className="form-group  d-flex flex-wrap">
                            <label>รหัสผ่านใหม่</label>
                            <input
                              type={passwordHidden}
                              {...register('password')}
                              name="password"
                              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                              placeholder="รหัสผ่านใหม่"
                            />
                            {passwordHidden === 'password' ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('password')}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('password')}
                              />
                            )}
                            {/* <input 
                              name="password" 
                              type="password" 
                              {...register('password')} 
                              placeholder="รหัสผ่านใหม่" 
                              className={`form-control ${errors.password ? 'is-invalid' : ''}`} /> */}
                            <div className="invalid-feedback">{errors.password?.message}</div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} className="mb-3">
                          <div className="form-group  d-flex flex-wrap">
                            <label>ยืนยันรหัสผ่านใหม่</label>
                            <input
                              type={confirmPasswordHidden}
                              {...register('confirmPassword')}
                              name="confirmPassword"
                              className={`form-control ${
                                errors.confirmPassword ? 'is-invalid' : ''
                              }`}
                              placeholder="ยืนยันรหัสผ่านใหม่"
                            />
                            {confirmPasswordHidden === 'password' ? (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('confirmPassword')}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="togglePasswordType"
                                onClick={(e) => togglePassword('confirmPassword')}
                              />
                            )}
                            {/* <input 
                              name="confirmPassword" 
                              type="password" 
                              {...register('confirmPassword')} 
                              placeholder="ยืนยันรหัสผ่านใหม่"
                              className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} /> */}
                            <div className="invalid-feedback">
                              {errors.confirmPassword?.message}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12} className="mb-3">
                          <div className="form-check">
                            <input
                              className="form-check-input me-2 "
                              type="checkbox"
                              value=""
                              id="invalidCheck"
                              // ref={acceptTerms}
                              {...register('acceptTerms')}
                              required
                            />
                            <label className="form-check-label " htmlFor="invalidCheck">
                              ยอมรับเงื่อนไขการใช้งานเว็บไซต์ และ พรบ. คุ้มครองข้อมูลส่วนบุคคล
                            </label>
                            <div
                              className="fst-italic cursor-pointer text-primary"
                              onClick={openModal}
                            >
                              เงื่อนไขการใช้งานเว็บไซต์ และ พรบ. คุ้มครองข้อมูลส่วนบุคคล
                            </div>

                            {errorsAcceptTerms && (
                              <div className="help-block">{errorsAcceptTerms}</div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group pt-3 text-end col-md-12">
                        {!isLoadingReset ? (
                          <button type="submit" className="btn btn-primary mr-1">
                            ยืนยัน
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary mr-1">
                            กำลังบันทึก...
                          </button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalIsOpen}
        size="lg"
        dialogClassName="modal-preview"
        centered
        fullscreen={true}
        aria-labelledby="file-name"
      >
        <Modal.Body>
          <button className="btn btn-close" onClick={closeModal}>
            {' '}
          </button>
          <div className="d-flex justify-content-center align-items-center my-4">
            <div className="bg-icon">
              <FontAwesomeIcon icon={faUserShield} />
            </div>

            <h4 ref={(_subtitle) => (subtitle = _subtitle)} className="text-center">
              <span> เงื่อนไขการใช้งานเว็บไซต์ และ พรบ. คุ้มครองข้อมูลส่วนบุคคล </span>
            </h4>
          </div>
          <div>
            <p className="mb-0">1. วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล</p>
            <p>
              การจัดเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการ สสวท.
              จะใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
              และจัดเก็บข้อมูลเท่าที่จําเป็นแก่การให้บริการตามวัตถุประสงค์ในการดําเนินงานของ สสวท.
              และตามวัตถุประสงค์ในการจัดตั้ง
              พระราชบัญญัติสถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี พ.ศ. 2541 แก้ไขเพิ่มเติม
              (ฉบับที่ 2) พ.ศ. 2548 ที่กําหนดไว้เท่านั้น หากมีการเปลี่ยนแปลงวัตถุประสงค์ สสวท.
              จะขอความยินยอมจากผู้ใช้บริการก่อนทําการเก็บ รวบรวม
              และบันทึกการเปลี่ยนแปลงวัตถุประสงค์ดังกล่าวเพิ่มเติมไว้เป็นหลักฐาน
            </p>
            <p className="mb-0">2. แหล่งที่มาของข้อมูลส่วนบุคคล</p>
            <p className="mb-0">
              ในการปฏิบัติงานตามวัตถุประสงค์ในการดําเนินงานของ สสวท. และตามวัตถุประสงค์ในการจัดตั้ง
              สสวท. มีความจําเป็นต้องมีการรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการ สสวท.
              ใช้วิธีการในการเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการ 2 วิธีดังนี้
            </p>
            <p className="mb-0">2.1 ใช้การบันทึกเป็นเอกสารหรือผ่านแบบฟอร์มของ สสวท.</p>
            <p className="mb-0">2.2 ใช้การบันทึกข้อมูลในระบบให้บริการสารสนเทศของ สสวท.</p>
            <p>
              ข้อมูลที่เก็บรวบรวมมามีจุดประสงค์สําคัญคือเพื่อใช้ในการติดต่อ การให้บริการ
              ประชาสัมพันธ์ หรือให้ข้อมูลข่าวสารของ สสวท. แก่ผู้ใช้บริการเพียงเท่านั้น
            </p>

            <p className="mb-0">3. การประมวลผลข้อมูลส่วนบุคคลและข้อจํากัดในการนําข้อมูลไปใช้</p>
            <p className="mb-0 ps-1">3.1 การเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจํากัด</p>
            <p className="mb-0 ps-1">
              สสวท. จะเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการอย่างจํากัดและเท่าที่จําเป็น
              โดยขึ้นอยู่กับลักษณะของงานหรือบริการที่ผู้ใช้บริการจําเป็นต้องให้ข้อมูลส่วนบุคคลแก่
              สสวท. เช่น การลงทะเบียนเพื่อขอใช้งานคลังความรู้ออนไลน์
              การลงทะเบียนเพื่อเข้ารับการฝึกอบรมออนไลน์ การลงทะเบียนเพื่อการเข้าสอบออนไลน์
              การสมัครเป็นพนักงานของ สสวท.
              ซึ่งจะมีการเก็บรวบรวมข้อมูลส่วนบุคคลดังกล่าวเท่าที่จําเป็นเท่านั้น
            </p>
            <p className="mb-0 ps-1">3.2 คุณภาพของข้อมูลส่วนบุคคล</p>
            <p className="mb-0 ps-1">
              สสวท. มีการรวบรวม จัดเก็บ และประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการโดยให้
              ความสําคัญกับความถูกต้อง ครบถ้วน และความเป็นปัจจุบันของข้อมูล
            </p>
            <p className="mb-0 ps-1">3.3 การระบุวัตถุประสงค์ในการเก็บรวบรวมข้อมูล</p>
            <p className="mb-0 ps-1">
              สสวท. มีการแจ้งวัตถุประสงค์และระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคลของ
              ผู้ใช้บริการให้ผู้ใช้บริการได้รับทราบก่อนเก็บรวบรวม หากภายหลัง สสวท.
              มีการเปลี่ยนแปลงวัตถุประสงค์ในการรวบรวมข้อมูลส่วนบุคคล
              จะต้องแจ้งให้ผู้ใช้บริการทราบและขอความยินยอม รวมทั้ง สสวท.
              ได้กําหนดให้มีการบันทึกการแก้ไขเพิ่มเติมเก็บไว้เป็นหลักฐานด้วย
            </p>
            <p className="mb-0 ps-1">3.4 ข้อจํากัดในการนําข้อมูลไปใช้</p>
            <p className="ps-1">
              สสวท.
              จะไม่เปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการที่นอกเหนือจากวัตถุประสงค์ในการรวบรวมและจัดเก็บข้อมูล
              เว้นแต่ได้รับความยินยอมจากเจ้าของข้อมูล ตามภาระผูกพันตามสัญญา
              ตามที่กฎหมายกําหนดให้เปิดเผย หรือตามคําสั่งศาล
            </p>
            <p className="ps-1">
              ทั้งนี้ กรณีที่ต้องเปิดเผยต่อผู้ให้บริการภายนอก สสวท. จะกําหนดให้ผู้ให้บริการ
              ภายนอกต้องลงนามรักษาความลับและความปลอดภัยของข้อมูลของผู้ใช้บริการ
              โดยห้ามนําข้อมูลไปใช้นอกเหนือจากที่กําหนดให้ใช้งานได้เท่านั้น
            </p>

            <p className="mb-0">4. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</p>
            <p>
              สสวท.
              จะเก็บรักษาข้อมูลส่วนบุคคลของผู่ใช้บริการตามระยะเวลาการจัดเก็บข้อมูลที่ได้มีการแจ้งให้ผู้ใช้บริการได้รับทราบ
              และเมื่อพันระยะเวลาดังกล่าวแล้ว สสวท. จะดําเนินการทําลายข้อมูลส่วนบุคคลนั้น
            </p>

            <p className="mb-0">5. สิทธิและการมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</p>
            <p className="mb-0">
              ผู้ใช้บริการมีสิทธิและมีส่วนร่วมในการดําเนินการดังต่อไปนี้กับข้อมูลส่วนบุคคลของตนเอง
            </p>
            <p className="mb-0 ps-1">5.1 สิทธิในการเพิกถอนความยินยอม (right to withdraw consent)</p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิเพิกถอนความยินยอมในการจัดเก็บและรวบรวมข้อมูลส่วนบุคคลที่ผู้ใช้บริการ
              ได้ให้ความยินยอมไว้แก่ สสวท. ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของตนอยู่กับ สสวท.
              โดยส่งคําขอแจ้งให้ สสวท. ทราบอย่างเป็นลายลักษณ์อักษรหรือผ่านทางอีเมล dpo@ipst.ac.th
            </p>
            <p className="mb-0 ps-1">5.2 สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access)</p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิเข้าถึงข้อมูลส่วนบุคคลของตนและขอให้ สสวท. ทําสําเนา
              ข้อมูลส่วนบุคคลดังกล่าว ให้แก่ผู้ใช้บริการ รวมถึงมีสิทธิขอให้ สสวท.
              เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ผู้ใช้บริการไม่ได้ให้ความยินยอม ต่อ สสวท. ไว้
            </p>
            <p className="mb-0 ps-1">
              5.3 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to rectification)
            </p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิขอให้ สสวท. ดําเนินการให้ข้อมูลส่วนบุคคลของตนเองให้ถูกต้อง
              เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
            </p>
            <p className="mb-0 ps-1">5.4 สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure)</p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิในการขอให้ สสวท. ทําการลบ ทําลาย
              หรือทําให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้
            </p>
            <p className="mb-0 ps-1">
              5.5 สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of processing)
            </p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิขอให้ สสวท. ระงับการใช้ข้อมูลส่วนบุคคลของตนเองได้
            </p>
            <p className="mb-0 ps-1">
              5.6 สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data portability)
            </p>
            <p className="mb-0 ps-1">
              ผู้ใช้บริการมีสิทธิให้ สสวท. ส่งหรือโอนย้ายข้อมูลส่วนบุคคลซึ่งตนได้ให้ไว้กับ สสวท.
              ไปยังผู้ควบคุม ข้อมูลส่วนบุคคลรายอื่น หรือมีสิทธิขอรับข้อมูลด้วยตนเอง
            </p>
            <p className="mb-0 ps-1">
              5.7 สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object)
            </p>
            <p className="ps-1">
              ผู้ใช้บริการมีสิทธิคัดค้านการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลของตนเองด้วยเหตุผลบางประการได้ สสวท.
              จะบันทึกการร้องขอการใช้สิทธิในข้างต้นของผู้ใช้บริการไว้เป็นหลักฐานทั้งกรณีที่ได้ดําเนินการให้และที่ไม่ได้ดําเนินการให้ตามคําร้อง
              กรณีที่ปฏิเสธคําร้องขอ สสวท. จะต้องบันทึกสาเหตุของการปฏิเสธไว้ด้วย สสวท.
              เคารพสิทธิในการตัดสินใจเพิกถอนความยินยอมของผู้ใช้บริการ แต่อย่างไรก็ตาม สสวท.
              ขอแจ้งให้ผู้ใช้บริการทราบว่าอาจมีข้อจํากัดสิทธิในการเพิกถอนความยินยอมโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่ผู้ใช้บริการ
            </p>

            <p className="mb-0">6. การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</p>
            <p>
              สสวท. มีมาตรการในการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคลของผู้ใช้บริการ
              เพื่อป้องกันมิให้ข้อมูลสูญหาย การเข้าถึงการใช้ข้อมูลการทําลายข้อมูล
              และการนําข้อมูลไปใช้โดยมิชอบ รวมถึงการเปลี่ยนแปลง แก้ไข
              หรือเปิดเผยข้อมูลโดยไม่ได้รับอนุญาต โดยเป็นไปตามประกาศ สสวท. เรื่อง
              นโยบายและแนวปฏิบัติการรักษาความมั่นคงปลอดภัยด้านสารสนเทศของ สสวท.
              และสอดคล้องกับมาตรฐานความมั่นคงปลอดภัย สารสนเทศ ISO/IEC 27001
            </p>

            <p className="mb-0">
              7. การเปิดเผยเกี่ยวกับการดําเนินการ แนวปฏิบัติ
              และนโยบายที่เกี่ยวข้องกับข้อมูลส่วนบุคคล
            </p>
            <p>
              สสวท. มีการดําเนินการตามนโยบายและแนวปฏิบัติการคุ้มครองข้อมูลส่วนบุคคล
              โดยจะเปิดเผยนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคล และเผยแพร่ผ่านทางเว็บไซต์
              https://www.ipst.ac.th
              รวมทั้งหากมีการปรับปรุงแก้ไขนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคล
              ก็จะดําเนินการเผยแพร่ผ่านช่องทางดังกล่าว
            </p>

            <p className="mb-0">
              8. ความรับผิดชอบของบุคคลที่เกี่ยวข้องกับการรวบรวม จัดเก็บ ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ
            </p>
            <p>
              สสวท. กําหนดให้ผู้ที่เกี่ยวข้องกับการรวบรวม จัดเก็บ ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการต้องให้ความสําคัญและรับผิดชอบในการจัดเก็บและคุ้มครองข้อมูลส่วนบุคคลที่ตนจัดเก็บตามนโยบายและแนวปฏิบัติการคุ้มครองข้อมูลส่วนบุคคลของ
              สสวท. ที่กําหนดไว้อย่างเคร่งครัด
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Resetpassword
