 

import Home from '../pages/home/Home' 
import PagesNewsList from '../pages/news/List'
import PagesNewsView from '../pages/news/View' 
import ForgotPassword from '../pages/forgot-password/ForgotPassword'
import ResetPassword from '../pages/forgot-password/ResetPassword'
import NotFound404 from '../pages/NotFound404'
import TermsCondition from '../pages/TermsCondition'
import PrivacyPolicy from '../pages/PrivacyPolicy'

const Routes = [
  //News

  {
    path: '/news/:id',
    name: 'ข่าวประชาสัมพันธ์',
    component: PagesNewsView,
    layout: 'news',
    breadcrumb: { parent: '', child: 'ข่าวประชาสัมพันธ์' },
  },
  {
    path: '/news',
    name: 'ข่าวประชาสัมพันธ์',
    component: PagesNewsList,
    layout: 'news',
    breadcrumb: { parent: '', child: 'ข่าวประชาสัมพันธ์' },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    layout: 'forgot-password',
    breadcrumb: { parent: '', child: 'Forgot password' },
  },
  {
    path: '/terms-and-condition',
    name: 'Terms and condition',
    component: TermsCondition,
    layout: 'terms-and-condition',
    breadcrumb: { parent: '', child: 'Terms and condition' },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicy,
    layout: 'privacy-policy',
    breadcrumb: { parent: '', child: 'Privacy policy' },
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: ResetPassword,
    layout: 'reset-password',
    breadcrumb: { parent: '', child: 'Reset password' },
  },
  {
    path: '/news',
    name: 'ข่าวประชาสัมพันธ์',
    component: PagesNewsList,
    layout: 'news',
    breadcrumb: { parent: '', child: 'ข่าวประชาสัมพันธ์' },
  },

  {
    path: '/',
    name: 'หน้าแรก',
    component: Home,
    layout: 'home',
  },
  {
    path: '*',
    name: 'Not Found 404',
    component: NotFound404,
    layout: '',
  },
]

export default Routes
