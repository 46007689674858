import axios from 'axios'

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

const getFormData = (data) => {
  const formData = new FormData()
  buildFormData(formData, data)

  return formData
}

const parseJSON = (response) => {
  return new Promise((resolve) =>
    response.json().then((json) =>
      resolve({
        status: response.status,
        ok: response.ok,
        json,
      })
    )
  )
}

// https://github.com/github/fetch/issues/203#issuecomment-266034180

// const authCtx = useContext(AuthContext);
// const isLogged = authCtx.isLoggedIn;
//const authUser = JSON.parse(authCtx.user);

const apiKey = process.env.REACT_APP_API_KEY
const apiVersion = process.env.REACT_APP_API_VERSION
const baseUrl = process.env.REACT_APP_API_URL

const auth =
  JSON.parse(localStorage.getItem('user')) !== null ? JSON.parse(localStorage.getItem('user')) : {}
const token = auth.token

const post = (url, data) => {
  return new Promise(async (resolve, reject) => {
    let formData = getFormData(data)
    // let user = JSON.parse(localStorage.getItem('user'))
    // let token = user.token
    await axios({
      method: 'POST',
      url: baseUrl + url,
      headers: {
        'x-api-key': apiKey,
        'x-api-version': apiVersion,
        'access-token': token,
      },
      data: formData,
    })
      .then((response) => {
        //console.log(response)
        if (response.data) {
          return resolve(response.data)
        }
        // extract the error from the server's json
        return reject(response.data)
      })
      .catch((error) => {
        console.log(error)
        reject({
          networkError: error.message,
        })
      })
  })
}

const patch = (url, data) => {
  return new Promise(async (resolve, reject) => {
    let formData = getFormData(data)
    // let user = JSON.parse(localStorage.getItem('user'))
    // let token = user.token
    await axios({
      method: 'PATCH',
      url: baseUrl + url,
      headers: {
        'x-api-key': apiKey,
        'x-api-version': apiVersion,
        'access-token': token,
      },
      data: formData,
    })
      .then((response) => {
        //console.log(response)
        if (response.data) {
          return resolve(response.data)
        }
        // extract the error from the server's json
        return reject(response.data)
      })
      .catch((error) => {
        console.log(error)
        reject({
          networkError: error.message,
        })
      })
  })
}

const deleted = (url, data) => {
  let user = JSON.parse(localStorage.getItem('user'))
  let token = user.token
  return new Promise(async (resolve, reject) => {
    //let formData = getFormData(data)
    await axios({
      method: 'DELETE',
      url: baseUrl + url,
      headers: {
        'x-api-key': apiKey,
        'x-api-version': apiVersion,
        'access-token': token,
      },
      //data: formData
    })
      .then((response) => {
        //console.log(response)
        if (response.data) {
          return resolve(response.data)
        }
        // extract the error from the server's json
        return reject(response.data)
      })
      .catch((error) => {
        console.log(error)
        reject({
          networkError: error.message,
        })
      })
  })
}

const setUrlParams = (url, params = null) => {
  url = new URL(url)
  url.search = new URLSearchParams(params)

  return url
}

const get = async (url, params = null) => {
  // url = setUrlParams(url, params)
  return new Promise(async (resolve, reject) => {
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        'x-api-version': apiVersion,
        'access-token': token,
      },
    }

    await fetch(baseUrl + url, options)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          if (response.json.status === 401) {
            localStorage.removeItem('user')
            window.location.href = '/'
          }
          return resolve(response.json)
        }
        // extract the error from the server's json
        return reject(response.json)
      })
      .catch((error) => {
        reject({
          networkError: error.message,
        })
      })
  })
}

const http = {
  get,
  post,
  patch,
  deleted,
}

export default http
