import styled, { createGlobalStyle } from 'styled-components'

export const IconCalendar = styled.i`
  right: 0;
  margin-right: 8px;
  color: #777;
  top: 13px;
`
export const DateButton = styled.div`
  background-color: ${(props) => (props.disabled ? '#e9ecef' : '#fff')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid #e3e3e3;
  border-radius: 0.3rem;
  text-align: left;
  padding-left: 8px;
  padding: 0.5rem;
  width: 100% !important;
  height: 50px;
  font-size: 14px;
  color: #333;
  outline: none;
  font-weight: 400;
  align-items: center;
  display: flex;
  &:hover {
    border: 1px solid #ccc;
  }
`

export const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker__year-wrapper {
      max-width: 204px;
    }
    .react-datepicker__header  {
       background-color: #0d3996!important;
    }
    .react-datepicker__current-month, .react-datepicker-year-header{
      color: #FFF!important;
    }
    .react-datepicker__day-names .react-datepicker__day-name {
      color: #FFF!important;
    }
    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__year-text--selected {
      background-color: #0d3996!important;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      border-bottom-color: #0d3996!important;
    }
    .react-datepicker__navigation-icon::before {
      border-color: #fff!important;
    }
    .react-datepicker__close-icon::after {
      background-color: #fff!important;
      color: #f62d51!important;
      font-size: 24px;
      font-weight: bold;
      padding-right: 22px;
    }
    .react-datepicker__day {
      border: 1px solid #FFF!important;
    }
    .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
      border: dashed 1px #0d3996 !important;
      border-radius: 0.3rem !important;
    }
    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
      background-color: #dfe9ff !important;
      border: dashed 1px #0d3996 !important;
    }
    .react-datepicker__day--keyboard-selected:hover {
      background-color: #0d3996 !important;

    }
    .react-datepicker__input-container {
      width: 100% !important;
      display: block !important;
    }`
