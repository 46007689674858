import Swal from 'sweetalert2'
import http from '../utils/http'

export const Alert = {
  success: (title, text) => {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      confirmButtonColor: '#0d3996',
      confirmButtonText: 'ตกลง',
      footer: '',
    })
  },

  error: (title, text) => {
    Swal.fire({
      icon: 'error',
      title: title,
      html: text,
      confirmButtonColor: '#0d3996',
      confirmButtonText: 'ตกลง',
      footer: '',
    })
  },

  confirm: (result) => {
    let { data, value, link, redirectUrl } = result
    Swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: `คุณต้องการลบข้อมูล${data} <span style="color: 0d3996;font-weight: 600">${value}</span> ใช่หรือไม่ ?`,
      showCancelButton: true,
      confirmButtonColor: '#0d3996 ',
      cancelButtonColor: '#bbb',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let dataRes = await http.deleted(link)
        if (dataRes.data) {
          Swal.fire({
            icon: 'success',
            title: 'ลบเรียบร้อย',
            confirmButtonColor: '#0d3996',
            confirmButtonText: 'ตกลง',
            text: '',
          }).then(async (result) => {
            window.location.href = redirectUrl
          })
        }
      }
    })
  },
}
