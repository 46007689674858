import React, { Fragment, useState, useRef, useCallback } from 'react'; 

//import PortfolioFormCreate from '../../components/portfolio/PortfolioCreate'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { 
    faMedal,
    faHome,
    faFileAlt
  } from "@fortawesome/free-solid-svg-icons";
import { Card, Table, Container, Form, Row, Col, Spinner } from 'react-bootstrap';

import { useForm, Controller } from 'react-hook-form'
//import { useParams } from 'react-router-dom'
import useFetch from '../../utils/useFetch'; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';

import http from '../../utils/http';
import { history } from '../../helpers/history'; 
import { Link } from "react-router-dom";
import styled from 'styled-components';

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`


const StudentAwardCreate = (props) => {
    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: '#e3e3e3',
          paddingRight: '0',
          paddingLeft: '0',
          '&:hover': {
            color: '#e3e3e3',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
          color: state.isFocused || state.isSelected ? '#fff' : '#000',
          '&:active': {
            backgroundColor: '#0d3996',
            color: '#fff',
          },
        }),
        menu: (provided, state) => ({
          ...provided,
          fontWeight: 500,
          paddingTop: '0',
          paddingBottom: '0',
        }),
        control: (state) => ({
          fontWeight: 500,
          fontSize: '15px',
          display: 'flex',
          backgroundColor: '#fff',
          height: '44px',
          borderRadius: '4px',
          border: '1px solid #e3e3e3',
          boxShadow: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: '#e3e3e3',
          paddingRight: '4px',
          paddingLeft: '0',
          border: 'none',
        }),
    
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1
          const transition = 'opacity 300ms'
    
          return { ...provided, opacity, transition }
        },
    }

    const [isLoading, setIsLoading] = useState(false); 

    const title = "เพิ่มรางวัลใหม่";
    const breadcrumb = {'link':'/personal-information','name':'ข้อมูลของฉัน'}; 
    const pathname = props.match.url;

    const [files, setFiles] = useState([])


    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    }

    const thumbs = files?.map((item, index) => ( 
        <ListItem key={index + item.file.name}  className={'success'}>
         
         <div className="upload-list-container">
            <div className="upload-list-item">
              <div className="upload-list-item-info">
                <span className="upload-span">
    
                  <FontAwesomeIcon icon={faFileAlt} className="" />
                  <span className="upload-list-item-name">
                  {item.file.name} ({item.file.size / 1024} KB)
                  </span>
    
                  <span className="upload-list-item-card-actions picture">
                    <button
                      title="Remove file"
                      type="button"
                      className="btn-item-card-actions-remove"
                      onClick={e=>handleRemoveFile(index, e)}
                    >
                      <span
                        role="img"
                        aria-label="delete"
                        className="anticon anticon-delete"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="delete"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                        </svg>
                      </span>
                    </button>
                  </span>
                </span>
              </div>
              <div className="upload-list-item-description">
                  <div className="input-group">
                    <span className="input-group-text">คำอธิบาย</span>
                    <input
                    type="text"
                    name="description"
                    placeholder="คำอธิบาย"
                    className="form-control"
                    value={item.description || ""} 
                    onChange={e => handleChangeFileDescription(index, e)}/>
                  
                  </div>       
              </div> 
            </div>
          </div>
        </ListItem>
    ))
     
    
    const baseUrl = process.env.REACT_APP_API_URL
    const urlAction = "/personal-information";
    const urlActionCallAPI = "/student/student-award/create";
     
    //const { id } = useParams()

    const validationSchema = yup.object().shape({
        award_name: yup
        .object()
        .shape({
          label: yup.string().required('กรุณาเลือกชื่อรางวัล'),
          value: yup
            .string()
            .required('กรุณาเลือกชื่อรางวัล')
            .nullable()
            .required('กรุณาเลือกชื่อรางวัล'),
        })
        .nullable()
        .required('กรุณาเลือกชื่อรางวัล'),

        award_group: yup
        .object()
        .shape({
          label: yup.string().required('กรุณาเลือกกลุ่มรางวัล'),
          value: yup
            .string()
            .required('กรุณาเลือกกลุ่มรางวัล')
            .nullable()
            .required('กรุณาเลือกกลุ่มรางวัล'),
        })
        .nullable()
        .required('กรุณาเลือกกลุ่มรางวัล'),

        education_level_id: yup
        .object()
        .shape({
          label: yup.string().required('กรุณาเลือกประเทศ'),
          value: yup
            .string()
            .required('กรุณาเลือกประเทศ')
            .nullable()
            .required('กรุณาเลือกประเทศ'),
        })
        .nullable()
        .required('กรุณาเลือกประเทศ'),

        country_id: yup
        .object()
        .shape({
          label: yup.string().required('กรุณาเลือกประเทศ'),
          value: yup
            .string()
            .required('กรุณาเลือกประเทศ')
            .nullable()
            .required('กรุณาเลือกประเทศ'),
        })
        .nullable()
        .required('กรุณาเลือกประเทศ'),

        year: yup.string().required('ปีที่ได้รับ ต้องไม่เป็นค่าว่าง'),

        organization_name: yup.string().required('องค์กร ต้องไม่เป็นค่าว่าง'),
        file: yup
        .mixed()
        .test("fileSize", "กรุณาแนบไฟล์", value => {
          return value[0];
        })
    })

    const formOptions = {
        resolver: yupResolver(validationSchema),
    }

    //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
    const { register, handleSubmit, reset, clearErrors,control, setError, formState, setValue } = useForm(formOptions)

    const { errors } = formState

    const onSubmit = async (dataObj) => {

        setIsLoading(true);
        if (dataObj) { 
            let dataPost = { 
                name: dataObj.award_name.label,
                // description: dataObj.description,
                award_group: dataObj.award_group.value,
                organization_name: dataObj.organization_name,
                country_id: dataObj.country_id.value,
                education_level_id: dataObj.education_level_id.value,
                // type: dataObj.type,
                year: dataObj.year,
                files: files
              
            };  
         //   console.log(dataPost);
            let dataRes = await http.post(`${urlActionCallAPI}`, dataPost);
        
            if(dataRes.message === 'Accepted'){
                setIsLoading(false); 
                history.push(`${urlAction}`)
            }else{
                setIsLoading(false); 
            }
    
        }

    }

    let awardName = null
    let awardGroup = null

    const { response: responseAwardGroup, loading: loadingAwardGroup } = useFetch(
        '/student/masterdata/award-group'
      ) 
    
    const awardGroupOption = responseAwardGroup?.map((item, index) => {
        return {
            value: `${item.id}`,
            label: `${item.name}`,
        }
    })

    const [awardNameOptions, setAwardNameOptions] = useState([])

    const { response: responseCountry, loading: loadingCountry } = useFetch(
        '/student/masterdata/country'
      ) 
    
    const countryOption = responseCountry?.map((item, index) => {
        return {
            value: `${item.id}`,
            label: `${item.name_th}`,
        }
    })

   
    const { response: responseEducationLevel, loading: loadingEducationLevel } = useFetch(
        '/student/masterdata/education-level'
      ) 
    
    const educationLevel = responseEducationLevel?.map((item, index) => {
        return {
            value: `${item.id}`,
            label: `${item.name_th}`,
        }
    })
    const typeOption =  [
        {
            value: `ระหว่างการศึกษา`,
            label: `ระหว่างการศึกษา`,
        },
        {
            value: `หลังสำเร็จการศึกษา`,
            label: `หลังสำเร็จการศึกษา`,
        },
    ]

    const handleChange = (name, e) => {
        console.log(name, e.value)
        setValue(name, e.value); 
    };



    const handleChangeFile = (e) => {
        let fileInput = e?.target?.files ?? null; 
    
        let newFileInput = [...files];
        let indexFile =  files.length; 
        newFileInput[indexFile]  = {file:fileInput[0], description:''};
    
        let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB
    
    
        if (fileInput.length === 0) {
        setError('file', {
            message: 'กรุณาอัปโหลดไฟล์',
        })
        return false
        }
        
        if (!fileInput[0].name.match(/\.(pdf)$/)) {
            setError('file', {
                type: 'manual',
                message: 'ไฟล์นามสกุล .pdf เท่านั้น',
            })
            return false
        }
        if (fileSize > 10) {
        setError('file', {
            message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
        })
        return false
        }

        clearErrors('file')

        if (!errors.file) {
        setFiles(newFileInput)
        }
    };

    const handleRemoveFile = (i, e) => {
        let newFileInput = [...files];
        newFileInput.splice(i, 1); 
        setFiles(newFileInput);
      };

    const handleChangeFileDescription = (i, e) => {
        let newFileInput = [...files];
        newFileInput[i]['description'] = e?.target?.value;
        setFiles(newFileInput)
    }    
    
    const handleSelectAwardGroupChange = async (value) => {

        let options = ''
        // let districtOptions = []
        if (value) {
          options = {
            value: value?.value,
            label: value?.label,
          }
          const awardNameList = await fetch(`${baseUrl}/student/masterdata/award-name?award_group_id=${value?.value}`)
         
          clearErrors('award_group')
          const json = await awardNameList.json()
          let awardNameOptions = json?.data.map((item, index) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
          setAwardNameOptions(awardNameOptions)
        } else {
          //setDistrictDefault('')
        }
    
        setValue('award_group', options)
    }
    
    const handleSelectChange = async (value, field) => {

        let options = ''
        // let districtOptions = []
        if (value) {
          options = {
            value: value?.value,
            label: value?.label,
          }
    
        } else {
          //setDistrictDefault('')
        }
    
        setValue(field, options)
    }

    return (
        <>
        <nav aria-label="breadcrumb">
            <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
                <li className="breadcrumb-item">
                <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/personal-information"> ข้อมูลของฉัน</Link>
                </li>
                <li
                    className="breadcrumb-item active font-sarabun"
                    aria-current="page"  >
                   เพิ่มรางวัลใหม่
                </li> 
            </ol>
        </nav>
        <div className="portfolio-create" >
            <section className="bg-light">
                <div className="container" >
                    <div className="card border-0 mb-3 d-flex align-items-center" > 
                        <div className="card-header pt-4 bg-white border-0">
                            <div className="d-md-flex">
                                <div>
                                    <h5 className="card-title me-auto">
                                        <div className="bg-icon"> 
                                            <FontAwesomeIcon icon={faMedal}  />
                                        </div>
                                        <span> เพิ่มรางวัลใหม่ </span>
                                    </h5>
                                </div>
                            </div>
                        
                        </div>

                        <div className="card-body col-12 col-md-8">

                            <div className="portfolio-form">

                                <Form onSubmit={handleSubmit(onSubmit)}>


                                    {/* <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="description" className="label-with-required">
                                            คำอธิบาย
                                            </label>
                                            <textarea 
                                                {...register('description')}
                                                id="description"
                                                className="form-control font-16"
                                                rows="2"
                                                placeholder="คำอธิบาย"
                                                aria-required="true" />
                                       
                                            {errors.description && <div className="help-block">{errors.description.message}</div>}
                                        </Col>
                                    </Row> */}
                                    <Row>
                                       <Col md={12} className="mb-3">
                                       <label htmlFor="award_group" className="label-with-required">
                                       กลุ่มรางวัล
                                            </label>
                                         <Controller
                                            name="award_group"
                                            control={control}
                                            render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="กลุ่มรางวัล"
                                                isClearable
                                                isDisabled={loadingAwardGroup}
                                                isLoading={loadingAwardGroup}
                                                isSearchable={true}
                                                styles={customStyles}
                                                options={awardGroupOption}
                                                placeholder="เลือกกลุ่มรางวัล"
                                                onChange={(value) => handleSelectAwardGroupChange(value)}
                                            />
                                            )}
                                        />
                                        {errors.award_group && <div className="help-block">กลุ่มรางวัลต้องไม่เป็นค่าว่าง</div>}
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md={12} className="mb-3">
                                        <label htmlFor="award_name" className="label-with-required">
                                        ชื่อรางวัล
                                        </label>
                                        <Controller
                                        name="award_name"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                            {...field}
                                            label="ชื่อรางวัล"
                                            isClearable
                                            isDisabled={loadingAwardGroup}
                                            isLoading={loadingAwardGroup}
                                            isSearchable={true}
                                            styles={customStyles}
                                            options={awardNameOptions}
                                            placeholder="เลือกชื่อรางวัล"
                                            onChange={(value) => handleSelectChange(value,field.name)}
                                            />
                                        )}
                                        />

                                        {errors.award_name && <div className="help-block">ชื่อรางวัลต้องไม่เป็นค่าว่าง</div>}
                                        </Col>
                                    </Row>
                                   
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="education_level_id" className="label-with-required">
                                            ระดับการศึกษา
                                            </label>
                                            <Controller
                                                name="education_level_id"
                                                control={control}
                                                render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label="ระดับการศึกษา"
                                                    isClearable
                                                    isDisabled={loadingEducationLevel}
                                                    isLoading={loadingEducationLevel}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    options={educationLevel}
                                                    placeholder="เลือกระดับการศึกษา"
                                                    onChange={(value) => handleSelectChange(value,field.name)}
                                                />
                                                )}
                                            />
                                            {errors.education_level_id && <div className="help-block">ระดับการศึกษาต้องไม่เป็นค่าว่าง</div>}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="country_id" className="label-with-required">
                                            ประเทศ
                                            </label>
                                            <Controller
                                                name="country_id"
                                                control={control}
                                                render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label="ประเทศ"
                                                    isClearable
                                                    isDisabled={loadingCountry}
                                                    isLoading={loadingCountry}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    options={countryOption}
                                                    placeholder="เลือกประเทศ"
                                                    onChange={(value) => handleSelectChange(value,field.name)}
                                                />
                                                )}
                                            />
                                            {errors.country_id && <div className="help-block">ประเทศต้องไม่เป็นค่าว่าง</div>}
                                        </Col>
                                    </Row>

                                 
                                    {/* <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="type" className="label-with-required">
                                            การรับรางวัล
                                            </label>

                                            <Select 
                                            isMulti={false} 
                                            options={typeOption}  
                                            styles={customStyles}
                                            isSearchable={true}
                                            onChange={e => handleChange('type',e)}
                                            placeholder="เลือกการรับรางวัล"
                                            
                                            />  
                                            {errors.type && <div className="help-block">{errors.type.message}</div>} 
                                        </Col>
                                    </Row> */}
                                  
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="year" className="label-with-required">
                                            ปีที่ได้รับ (ค.ศ.)
                                            </label>
                                            <input
                                                type="text"
                                                {...register('year')}
                                                id="year"
                                                className="form-control font-16"
                                                placeholder="ปีที่ได้รับ (ค.ศ.)"
                                                aria-required="true" />
                                       
                                            {errors.year && <div className="help-block">{errors.year.message}</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="organization_name" className="label-with-required">
                                            สถาบันผู้มอบรางวัล
                                            </label>
                                            <input
                                                type="text"
                                                {...register('organization_name')}
                                                id="organization_name"
                                                className="form-control font-16"
                                                placeholder="สถาบันผู้มอบรางวัล"
                                                aria-required="true" />
                                       
                                            {errors.organization_name && <div className="help-block">{errors.organization_name.message}</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <label htmlFor="file" className="label-with-required">
                                            แนบไฟล์
                                            </label> 
                                            <div className="file-caption">
                                          
                                              <div className="upload-select">
                                                <div className="d-flex justify-content-start">
                                                  <div className="btn-file-upload">
                                                    <span
                                                        role="img"
                                                        aria-label="upload"
                                                        className="icon-upload" >
                                                        <svg
                                                        viewBox="64 64 896 896"
                                                        focusable="false"
                                                        data-icon="upload"
                                                        width="1em"
                                                        height="1em"
                                                        fill="currentColor"
                                                        aria-hidden="true" >
                                                        <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                                        </svg>
                                                    </span>
                                                    <span>Upload</span>
                                                    <input
                                                        type="file"
                                                      // style={{ display: "none" }}
                                                      //  className={"btn-input-upload"}
                                                        id="icon-button-file"
                                                        {...register('file')}
                                                        onChange={(e) => handleChangeFile(e)} />
                                                  </div>
                                                </div>
                                              
                                              </div>

                                                
                                                <aside style={thumbsContainer} className="upload-list">{thumbs}</aside>
                                            </div>
                                            <span className="text-secondary  font-size-xs">ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น</span>
                                            {errors.file && <div className="help-block">{errors.file.message}</div>}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} className="my-3 d-flex justify-content-end"> 
                                            <Link className="btn btn-outline-primary btn-add mx-3" to="/personal-information">ยกเลิก</Link>        
                                            {  !isLoading ? (
                                                <button type="submit" className="btn btn-primary btn-add">บันทึก</button>
                                                ):(
                                                <button type="submit" className="btn btn-primary btn-add" disabled>กำลังบันทึก...</button>
                                            ) }  
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    </>
        )
}

    


export default StudentAwardCreate