import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { Card, Table } from 'react-bootstrap'
import { CardLoading } from '../../components/Skeleton'

const Agency = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <>
          <Card className="border-0 mb-3">
            <Card.Body>
              <h5 className="card-title">
                <div className="bg-icon">
                  <FontAwesomeIcon icon={faUserFriends} className="font-28" />
                </div>
                <span>ข้อมูลศูนย์ผู้ดูแล</span>
              </h5>
              <div className="table-responsive">
                {data ? (
                  <Table>
                    <tbody>
                      <tr>
                        <td>ชื่อศูนย์ผู้ดูแล </td>
                        <td className="agency_name">{data?.organization_name_th}</td>
                      </tr>
                      <tr>
                        <td className="align-middle">หมายเลขโทรศัพท์</td>
                        <td className="align-middle">
                          {data?.contact_number ? (
                            JSON.parse(data?.contact_number).map((item) => {
                              return <p>{item.value}</p>
                            })
                          ) : (
                            <>ไม่พบข้อมูล</>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">อีเมล</td>
                        <td className="align-middle">{data?.email}</td>
                      </tr>
                      <tr>
                        <td className="align-middle">ที่อยู่</td>
                        <td className="align-middle">
                          {data?.address_no + data?.village_no + data?.alley + data?.road}
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">ผู้ประสานงาน</td>
                        <td className="align-middle">{data?.organization_contributor}</td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Table>
                    <tbody>
                      <tr>
                        <td valign="top" colSpan={4} className="text-center">
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <CardLoading />
        </>
      )}
    </>
  )
}

export default Agency
