import React, { useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboardList,
  faUserEdit,
  faStar,
  faMedal,
  faCampground,
  faPlus,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useParams, NavLink, Link } from 'react-router-dom'
import { Nav, Container, Row, Col, Form, Tab, Placeholder } from 'react-bootstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import http from '../../utils/http'
import { history } from '../../helpers/history'

import useFetch from '../../utils/useFetch'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import styled from 'styled-components'
import { Alert } from '../../actions/alert.action'

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`

const OtherCreate = (props) => {
  const [files, setFiles] = useState([])
  const urlAction = '/petition-other'
  const urlActionCallAPI = '/student/petition/create'
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = yup.object().shape({
    petition_type_id: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกประเภทคำร้อง'),
        value: yup
          .string()
          .required('กรุณาเลือกประเภทคำร้อง')
          .nullable()
          .required('กรุณาเลือกประเภทคำร้อง'),
      })
      .nullable()
      .required('กรุณาเลือกประเภทคำร้อง'),

    description: yup.string().required('กรุณากรอกรายละเอียด'),
    reason: yup.string().required('กรุณากรอกรายละเอียด'),
    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
  })

  let petitionType = null

  const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
    clearErrors,
    resetField,
    setError,
  } = useForm(formOptions)

  const { errors } = formState

  const { response: responsePetitionType, loading: loadingPetitionType } = useFetch(
    '/student/masterdata/petitiontype?petition_group_id=3'
  )

  const petitionTypeOptions = responsePetitionType?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const handlePetitionTypeChange = (value) => {
    let options = {
      value: value?.value,
      label: value?.label,
    }
    setValue('petition_type_id', options)
  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      let dataPost = {
        petition_group_id: 3,
        petition_type_id: dataObj?.petition_type_id?.value,
        description: dataObj.description,
        reason: dataObj.reason,
        files: files,
      }

      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.status === 201) {
        setIsLoading(false)
        Alert.success('บันทึกข้อมูลเรียบร้อย', '')
        history.push(`${urlAction}`)
      }
    }
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    setFiles(newFileInput)
  }

  const handleRemoveFile = (index, file) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))

  return (
    <div className="site-petition-create">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className=" d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>ยื่นคำร้องใหม่</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="petition-form">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12} className="mb-3">
                      <label htmlFor="petition_type_id" className="label-with-required">
                        ประเภทคำร้อง
                      </label>
                      <Controller
                        name="petition_type_id"
                        control={control}
                        render={(field) => (
                          <Select
                            {...field}
                            label={'ประเภทคำร้อง'}
                            isLoading={loadingPetitionType}
                            sDisabled={loadingPetitionType}
                            isClearable
                            isSearchable={true}
                            styles={selectStyles}
                            options={petitionTypeOptions}
                            placeholder="เลือกประเภทคำร้อง"
                            defaultValue={petitionType}
                            noOptionsMessage={() => 'ไม่พบข้อมูล'}
                            onChange={(value) => {
                              handlePetitionTypeChange(value)
                            }}
                          />
                        )}
                      />
                      {errors?.petition_type_id && (
                        <div className="help-block">
                          {errors.petition_type_id?.message ||
                            errors.petition_type_id?.label.message}
                        </div>
                      )}
                    </Col>
                    <Col sm={12} className="mt-3">
                      <label className="label-with-required">ความประสงค์</label>
                      <textarea
                        placeholder="ความประสงค์"
                        {...register('description')}
                        className="form-control"
                        maxLength={214}
                      />

                      {errors.description && (
                        <div className="help-block">{errors.description.message}</div>
                      )}
                    </Col>
                    <Col sm={12} className="mt-3">
                      <label className="label-with-required">เหตุผลและความจำเป็น</label>
                      <textarea {...register('reason')} className="form-control" maxLength={214} />

                      {errors.description && (
                        <div className="help-block">{errors.reason.message}</div>
                      )}
                    </Col>
                    <Col sm={12} className="mt-3">
                      <div className="file-caption">
                        <div className="upload-select">
                          <div className="d-flex justify-content-start">
                            <div className="btn-file-upload">
                              <span role="img" aria-label="upload" className="icon-upload">
                                <svg
                                  viewBox="64 64 896 896"
                                  focusable="false"
                                  data-icon="upload"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                </svg>
                              </span>
                              <span>Upload</span>
                              <input
                                type="file"
                                // style={{ display: "none" }}
                                //  className={"btn-input-upload"}
                                id="icon-button-file"
                                {...register('file')}
                                onChange={(e) => handleChangeFile(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <aside style={thumbsContainer} className="upload-list">
                          {thumbs}
                        </aside>
                      </div>

                      <span className="text-danger font-size-xs">*ขนาดไฟล์แนะนำไม่เกิน 10 MB</span>
                      {errors.file && <div className="help-block">{errors.file.message}</div>}
                    </Col>

                    <Col md={12}>
                      <div className="form-group text-end mt-4">
                        {!isLoading ? (
                          <button type="submit" className="btn btn-primary">
                            บันทึก
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary" disabled>
                            กำลังบันทึก...
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OtherCreate
