import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ExpenseView from '../../components/petition/Template'
import StatusView from '../../components/petition-student-status/Template'
import OtherView from '../../components/petition-other/Template'
import useFetch from '../../utils/useFetch'
import ReactLoading from 'react-loading'

const PetitionTemplate = (props) => {
  const [bankAccount, setBankAccount] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const pathname = props.match.path

  let title = 'คำร้องสถานภาพนักเรียนทุน'
  let content = ''
  let pathbreadcrumb = '/petition-student-status'
  if (pathname == '/petition-student-status/print/:id') {
    title = 'คำร้องสถานภาพนักเรียนทุน'
    pathbreadcrumb = '/petition-student-status'
    content = <StatusView id={props.match.params.id} />
  } else if (pathname == '/petition-expense/print/:id') {
    title = 'คำร้องค่าใช้จ่าย'
    pathbreadcrumb = '/petition-expense'
    content = <ExpenseView id={props.match.params.id} />
  } else if (pathname == '/petition-other/print/:id') {
    title = 'คำร้องอื่น ๆ'
    pathbreadcrumb = '/petition-other'
    content = <OtherView id={props.match.params.id} />
  }

  if (error) {
    content = <p>{error}</p>
  }
  console.log('test')
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={pathbreadcrumb}> {title} </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            รายละเอียดคำร้อง
          </li>
        </ol>
      </nav>
      {content}
    </>
  )
}

export default PetitionTemplate
