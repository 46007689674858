import ContractList from '../../pages/contract/List';
import ContractView from '../../pages/contract/View'
import ContractUpdate from '../../pages/contract/Update'
import ContractUpload from '../../pages/contract/Upload'
import ContractTemplate from "../../pages/contract/Template";
export const Contract = [
  //ทุนและสัญญา
  {
    path: "/contract/upload/:id",
    name: "อัปโหลดเอกสาร",
    component: ContractUpload,
    layout: "contract",
    breadcrumb: { parent: "ทุนและสัญญา", child: "อัปโหลดเอกสาร" },
  },
  {
    path: "/contract/update/:id",
    name: "แก้ไขทุนและสัญญา",
    component: ContractUpdate,
    layout: "contract",
    breadcrumb: { parent: "ทุนและสัญญา", child: "แก้ไขทุนและสัญญา" },
  },
  {
    path: "/contract/:id",
    name: "ทุนและสัญญา",
    component: ContractView,
    layout: "contract",
    breadcrumb: { parent: "", child: "ทุนและสัญญา" },
  },
  {
    path: "/contract/print-preview/:id",
    name: "พิมพ์ทุนและสัญญา",
    component: ContractTemplate,
    layout: "contract",
    breadcrumb: { parent: "", child: "พิมพ์ทุนและสัญญา" },
  },
  {
    path: "/contract",
    name: "ทุนและสัญญา",
    component: ContractList,
    layout: "contract",
    breadcrumb: { parent: "", child: "ทุนและสัญญา" },
  },
];