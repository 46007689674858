import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const STATUS_WAITING_INFORMATION = 'waiting_information'
export const STATUS_WAITING_VERIFICATION = 'waiting_verification'
export const STATUS_PENDING_UPDATE = 'pending_update'
export const STATUS_WAITING_PENDING_SIGN = 'pending_sign'
export const STATUS_PENDING_APPROVAL = 'pending_approval'
export const STATUS_APPROVED = 'approved'

export const CONTRACT_TYPE_MAIN = '1'
export const CONTRACT_TYPE_AMENDMENT = '2'

export const contractHelper = {
  isMain: (type) => type === CONTRACT_TYPE_MAIN,
  isAmendment: (type) => type === CONTRACT_TYPE_AMENDMENT,

  isStatusWaitingInformation: (status) => status === STATUS_WAITING_INFORMATION,
  isStatusWaitingVerification: (status) => status === STATUS_WAITING_VERIFICATION,
  isStatusPendingUpdate: (status) => status === STATUS_PENDING_UPDATE,

  isStatusPendingSign: (status) => status === STATUS_WAITING_PENDING_SIGN,
  isStatusPendingApproval: (status) => status === STATUS_PENDING_APPROVAL,
  isStatusApproved: (status) => status === STATUS_APPROVED,

  getContractTypeText: (type) => {
    const typeMapper = {
      [CONTRACT_TYPE_MAIN]: 'สัญญาหลัก',
      [CONTRACT_TYPE_AMENDMENT]: 'สัญญาแก้ไขเพิ่มเติม',
    }[type]

    return typeMapper || '-'
  },

  getContractTypeOptions: () => {
    return [
      {
        value: CONTRACT_TYPE_MAIN,
        label: contractHelper.getContractTypeText(CONTRACT_TYPE_MAIN),
      },
      {
        value: CONTRACT_TYPE_AMENDMENT,
        label: contractHelper.getContractTypeText(CONTRACT_TYPE_AMENDMENT),
      },
    ]
  },

  getContractStatus: (status) => {
    const statusKey = {
      [STATUS_WAITING_INFORMATION]: 'รอข้อมูลนักเรียนทุน',
      [STATUS_PENDING_UPDATE]: 'รอนักเรียนทุนแก้ไขข้อมูล',
      [STATUS_WAITING_VERIFICATION]: 'รอ สสวท. ตรวจสอบ',
      [STATUS_WAITING_PENDING_SIGN]: 'รอผู้รับทุนลงนาม',
      [STATUS_PENDING_APPROVAL]: 'รอเจ้าหน้าที่อนุมัติ',
      [STATUS_APPROVED]: 'ลงนามแล้ว',
    }
    return statusKey[status.toLowerCase()] ?? ''
  },

  getContractStatusOptions: () => {
    return [
      {
        value: STATUS_WAITING_INFORMATION,
        label: contractHelper.getContractStatus(STATUS_WAITING_INFORMATION),
      },
      {
        value: STATUS_PENDING_UPDATE,
        label: contractHelper.getContractStatus(STATUS_PENDING_UPDATE),
      },
      {
        value: STATUS_WAITING_VERIFICATION,
        label: contractHelper.getContractStatus(STATUS_WAITING_VERIFICATION),
      },
      {
        value: STATUS_WAITING_PENDING_SIGN,
        label: contractHelper.getContractStatus(STATUS_WAITING_PENDING_SIGN),
      },
      {
        value: STATUS_PENDING_APPROVAL,
        label: contractHelper.getContractStatus(STATUS_PENDING_APPROVAL),
      },
      {
        value: STATUS_APPROVED,
        label: contractHelper.getContractStatus(STATUS_APPROVED),
      },
    ]
  },

  getColor: (status) => {
    const statusMapper = {
      [STATUS_WAITING_INFORMATION]: 'warning',
      [STATUS_PENDING_UPDATE]: 'warning',
      [STATUS_WAITING_VERIFICATION]: 'warning',
      [STATUS_WAITING_PENDING_SIGN]: 'warning',
      [STATUS_PENDING_APPROVAL]: 'warning',
      [STATUS_APPROVED]: 'info',
    }[status]

    return statusMapper || ''
  },

  getStatusLabel: (status, note) => {
    return (
      <>
        <div className={`text-${contractHelper.getColor(status)} align-middle`}>
          <FontAwesomeIcon icon={faCircle} className="pe-1" />
          {contractHelper.getContractStatus(status)}
          {note && <p className="text-secondary fs-14"> {note} </p>}
        </div>
      </>
    )
  },
}
