import {
  faArrowUp,
  faClipboardList,
  faDownload,
  faEdit,
  faFile,
  faFileInvoice,
  faPrint,
  faUpload,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Card, Container, Button, Col, Row, Table } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonAction, ButtonDeleteConfirm } from '../../components/Button'
import { contractHelper } from '../../helpers/contract.helper'
import { dateHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import useFetch from '../../utils/useFetch'

import Swal from 'sweetalert2'
const List = styled.div`
  border: 1px dashed #e3e3e3;
  padding: 0.75rem 1.25rem;
  position: relative;
  margin-bottom: 1rem;
`
const View = (props) => {
  const baseUrl = process.env.REACT_APP_API_URL
  const { response: contractData, loading } = useFetch(`/student/contract/${props?.id}`)
  const { response: contract_document } = useFetch(`/student/contract/document/${props.id}`)
  const { response: contract_document_approved } = useFetch(`/student/contract/document-apprvoed/${props.id}`)

  const [initData, setInitData] = useState({
    tab_key: 1,
    is_submit: false,
  })

  const convertDefaultValue = (value) => {
    if (Array.isArray(value)) {
      return value
    } else if (value === '' || value === null || value === undefined) {
      return ''
    }
    return value
  }

  if (contractData && contractData != null && !contractData?.is_change_value) {
    for (var key in contractData) {
      if (contractData.hasOwnProperty(key) && key !== 'guaratee_detail') {
        contractData[key] = convertDefaultValue(contractData[key])
        contractData.is_change_value = true
      }
    }
  }
  const auth = JSON.parse(localStorage.getItem('user'))
  const token = auth.token
  const FileDocument = ({ data, isShow }) => {
    if (data) {
      return data?.map((document, index) => {
        return (
          <React.Fragment key={`${index}_${index}`}>
            <List>
              <Row>
                <Col md={9} sm={12}>
                  <i className="fas fa-paperclip"></i> {document.file_name}
                  <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
                    {document.description}
                  </p>
                </Col>
                <Col md={3} sm={12} className="text-end">
                  <ButtonDeleteConfirm
                    data={'เอกสารสัญญา'}
                    value={document.file_name}
                    link={`/student/contract/document/delete/${document.file_code}?contract_id=${props?.id}`}
                    className="me-2 text-white"
                    redirectUrl={`/contract/${props?.id}`}
                    isIcon={true}
                    isShow={isShow}
                  />

                  <a
                    href={baseUrl + '' + document.url_download + '?token=' + token}
                    download
                    className="btn btn-success"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </Col>
              </Row>
            </List>
          </React.Fragment>
        )
      })
    }
    return <></>
  }

  const submitForm = async (event) => {
    setInitData({ ...initData, is_submit: true })

    try {
      let formData = new FormData() //formdata object
      formData.append('id', props.id)
      formData.append('status', 'waiting_verification')

      const apiKey = process.env.REACT_APP_API_KEY
      const apiVersion = process.env.REACT_APP_API_VERSION
      const baseUrl = process.env.REACT_APP_API_URL
      const auth = JSON.parse(localStorage.getItem('user'))

      const token = auth.token
      const response = await fetch(`${baseUrl}/student/contract/update-status/${props.id}`, {
        method: 'PATCH',
        body: formData,
        headers: {
          'x-api-key': apiKey,
          'x-api-version': apiVersion,
          'access-token': token,
        },
      })

      if (!response.ok) {
        setInitData({ ...initData, is_submit: false })
        throw new Error('ไม่สามารถบันทึกข้อมูลได้')
      }

      if (response.ok) {
        const data = await response.json()
        if (
          data.status === '201' ||
          data.status === 201 ||
          data.status === 202 ||
          data.status === '202'
        ) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonColor: '#0d3996',
            confirmButtonText: 'ตกลง',
          })
          //setInitData({ ...initData, is_submit: true });
          history.push('/contract')
          //history.push("/contract/" + data.data.root_id);
        } else {
          setInitData({ ...initData, is_submit: false })
          Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
            text: data.message,
            confirmButtonColor: '#0d3996',
            confirmButtonText: 'ตกลง',
          })
          throw new Error('ไม่สามารถบันทึกข้อมูลได้')
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        text: error,
        confirmButtonColor: '#0d3996',
        confirmButtonText: 'ตกลง',
      })
    }
  }

  const GurateeLandDetail = ({ data }) => {
    if (data?.length > 0) {
      let count = 0
      return data?.map((data, index) => {
        if (data.type === 'land') {
          count++
          return (
            <React.Fragment key={`${index}`}>
              <div>
                <p>
                  {count}. เลขที่โฉนด: {data.title_deed_number} เลขที่ดิน: {data.percel_no}{' '}
                  หน้าสำรวจ: {data.dealing_file_no} ระวาง: {data.sheet_number} ไร่: {data.rai} งาน:{' '}
                  {data.ngan} ตารางวา: {data.wha}
                </p>
                <p>
                  ตำบล/แขวง: {data.land_subdistrict_text} อำเภอ/เขต: {data.land_district_text}{' '}
                  จังหวัด: {data.land_province_text}
                </p>
              </div>
            </React.Fragment>
          )
        } else {
          return <></>
        }
      })
    }

    return <></>
  }

  const GurateeAssetDetail = ({ data }) => {
    if (data?.length > 0) {
      let count = 0
      return data?.map((data, index) => {
        if (data?.type === 'asset') {
          count++
          return (
            <React.Fragment key={`${index}`}>
              <div>
                <p>
                  {count}. หลักทรัพย์: {data.asset_name} ราคาประมาณ: {data.asset_value}
                </p>
              </div>
            </React.Fragment>
          )
        } else {
          return <></>
        }
      })
    }

    return <></>
  }

  let canEditData =
    contractHelper.isStatusPendingUpdate(contractData?.status) ||
    contractHelper.isStatusWaitingInformation(contractData?.status) ||
    contractHelper.isStatusWaitingVerification(contractData?.status)

  const isShowContractNo =
    contractHelper.isStatusPendingSign(contractData?.status) ||
    contractHelper.isStatusPendingApproval(contractData?.status) ||
    contractHelper.isStatusApproved(contractData?.status)

  return (
    <div className="contract">
      <section className="bg-light">
        <Container>
          <Card className="border-0 mb-3">
            <Card.Header>
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>สัญญา </span>
                  </h5>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                {String(contractData?.read_only) !== '1' && canEditData && !initData.is_submit ? (
                  <>
                    <ButtonAction
                      link={'/contract/update/' + props.id}
                      label="แก้ไขข้อมูล"
                      variant="outline-primary"
                      icon={faEdit}
                      width="140px"
                    />{' '}
                    <ButtonAction
                      link={'/contract/upload/' + props.id}
                      label="อัปโหลดเอกสารประกอบเพิ่มเติม"
                      variant="outline-warning"
                      icon={faUpload}
                      width="280px"
                    />{' '}
                  </>
                ) : (
                  <></>
                )}
                <br></br>
                {canEditData && String(contractData?.read_only) !== '1' && !initData.is_submit ? (
                  <Button className="btn btn-success mt-2 px-3 me-0" onClick={(e) => submitForm(e)}>
                    <FontAwesomeIcon icon={faArrowUp} /> ยืนยันและส่งข้อมูลให้ สสวท.
                  </Button>
                ) : (
                  <></>
                )}
              </div>
              <div id="step">
                <ul className="nav nav-pills w-100 steps ps-0" id="pills-tab" role="tablist">
                  <li className="step step-first" role="presentation">
                    <Link
                      className={
                        initData.tab_key === 1 ? 'nav-link bullet active' : 'nav-link bullet '
                      }
                      id="pills-contract-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contract"
                      role="tab"
                      aria-controls="pills-contract"
                      aria-selected="true"
                      onClick={(e) => {
                        setInitData({ ...initData, tab_key: 1 })
                      }}
                    >
                      <FontAwesomeIcon icon={faFileInvoice} className="font-28" />
                    </Link>
                    <span>ข้อมูลประกอบสัญญา</span>
                  </li>
                  <li className="step yellow" role="presentation">
                    <Link
                      className={
                        initData.tab_key === 2 ? 'nav-link bullet active' : 'nav-link bullet '
                      }
                      id="pills-guarantor-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-guarantor"
                      role="tab"
                      aria-controls="pills-guarantor"
                      aria-selected="false"
                      onClick={(e) => {
                        setInitData({ ...initData, tab_key: 2 })
                      }}
                    >
                      <FontAwesomeIcon icon={faUsers} className="font-28" />
                    </Link>
                    <span>ผู้ค้ำประกัน</span>
                  </li>
                  <li className="step orange" role="presentation">
                    <Link
                      className={
                        initData.tab_key === 3 ? 'nav-link bullet active' : 'nav-link bullet '
                      }
                      id="pills-document-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-document"
                      role="tab"
                      aria-controls="pills-document"
                      aria-selected="false"
                      onClick={(e) => {
                        setInitData({ ...initData, tab_key: 3 })
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} className="font-28" />
                    </Link>
                    <span>สัญญาและเอกสารประกอบ</span>
                  </li>
                </ul>
              </div>
              {loading || !contractData?.is_change_value || initData.is_submit ? (
                <ReactLoading type="bubbles" color="#26b9d1" className="mx-auto my-4" />
              ) : (
                <div className="tab-content mt-3" id="pills-tabContent">
                  <div
                    className={
                      initData.tab_key === 1 ? 'tab-pane fade show active' : 'tab-pane fade'
                    }
                    id="pills-contract"
                    role="tabpanel"
                    aria-labelledby="pills-contract-tab"
                  >
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>ชื่อ-นามสกุลผู้รับทุน</th>
                          <td>
                            {contractData?.name_title_th +
                              ' ' +
                              contractData?.firstname_th +
                              ' ' +
                              contractData?.lastname_th}{' '}
                          </td>
                        </tr>
                        <tr>
                          <th>เลขที่สัญญา</th>
                          <td>
                            {isShowContractNo ? contractData?.contract_no : 'ยังไม่มีเลขที่สัญญา'}
                          </td>
                        </tr>
                        <tr>
                          <th>สถาบันการศึกษา</th>
                          <td>{contractData?.institution_name_th}</td>
                        </tr>
                        <tr>
                          <th>คณะ</th>
                          <td>{contractData?.faculty}</td>
                        </tr>
                        <tr>
                          <th>สาขา</th>
                          <td>{contractData?.subject_field_name}</td>
                        </tr>
                        <tr>
                          <th>ศูนย์ผู้ดูแล</th>
                          <td>{contractData?.agency_name}</td>
                        </tr>
                        <tr>
                          <th>แบบสัญญา</th>
                          <td>{contractHelper.getContractTypeText(contractData.contract_type)}</td>
                        </tr>
                        <tr>
                          <th>เลขที่สัญญาก่อนหน้า</th>
                          <td>{contractData?.contract_ref.contract_no}</td>
                        </tr>
                        <tr>
                          <th>ข้อผูกมัด</th>
                          <td>{contractData?.commitment_text}</td>
                        </tr>
                        <tr>
                          <th>ประเภทการให้ทุน</th>
                          <td className="w-60"> {contractData?.scholarship_type_name} </td>
                        </tr>
                        <tr>
                          <th>ประเภทสัญญา</th>
                          <td className="w-60"> {contractData?.contract_type_name} </td>
                        </tr>
                        <tr>
                          <th>ระดับการศึกษาที่ให้ทุน</th>
                          <td>{contractData?.education_level_name_th}</td>
                        </tr>
                        <tr>
                          <th>ชื่อทุนที่ได้รับ</th>
                          <td className="w-60"> {contractData?.scholarship_name}</td>
                        </tr>
                        <tr>
                          <th>ผู้ให้ทุน</th>
                          <td className="w-60">
                            {contractData?.giver_name_title_th +
                              contractData?.giver_firstname +
                              ' ' +
                              contractData?.giver_lastname}
                          </td>
                        </tr>
                        <tr>
                          <th>วันที่ทำสัญญา</th>
                          <td className="w-60">
                            {dateHelper.getDate(contractData?.contract_date)}
                          </td>
                        </tr>
                        <tr>
                          <th>วันที่เริ่มรับทุน</th>
                          <td className="w-60">{dateHelper.getDate(contractData?.start_date)} </td>
                        </tr>
                        <tr>
                          <th>วันที่ครบสัญญา</th>
                          <td className="w-60">{dateHelper.getDate(contractData?.end_date)} </td>
                        </tr>
                        <tr>
                          <th>ปีการศึกษา</th>
                          <td className="w-60">{contractData?.academic_year}</td>
                        </tr>
                        <tr>
                          <th>ชื่อพยาน (คนที่ 1)</th>
                          <td className="w-60">
                            {`${contractData?.witness_name_title_first_th || ''}${
                              contractData?.witness_firstname_first || ''
                            } ${contractData?.witness_lastname_first || ''}`}
                          </td>
                        </tr>
                        <tr>
                          <th>ชื่อพยาน (คนที่ 2)</th>
                          <td className="w-60">
                            {`${contractData?.witness_name_title_second_th}${contractData?.witness_firstname_second} ${contractData?.witness_lastname_second}`}
                          </td>
                        </tr>
                        <tr>
                          <th>สถานะ</th>
                          <td className="align-middle">
                            {contractHelper?.getStatusLabel(contractData?.status)}
                          </td>
                        </tr>
                        <tr>
                          <th>วันที่สร้าง</th>
                          <td className="w-60">
                            {dateHelper.getDateTime(contractData?.created_at)}{' '}
                          </td>
                        </tr>

                        <tr>
                          <th>วันที่แก้ไข</th>
                          <td className="w-60">
                            {dateHelper.getDateTime(contractData?.updated_at)}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {contractData?.before_high_school_contract_no !== 'null' &&
                      contractData?.before_high_school_contract_no !== undefined &&
                      contractData?.before_high_school_contract_no !== '' && (
                        <>
                          <h5>สัญญารับทุนก่อนหน้า (ระดับมัธยมศึกษาตอนปลาย)</h5>
                          <table className="table">
                            <tbody>
                              <tr>
                                <th>เลขที่สัญญา</th>
                                <td className="w-60">
                                  {' '}
                                  {contractData?.before_high_school_contract_no}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>ปีการศึกษา</th>
                                <td className="w-60">
                                  {contractData?.before_high_school_academic_year}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>วันที่ทำสัญญา</th>
                                <td className="w-60">
                                  {dateHelper.getDate(
                                    contractData?.before_high_school_contract_date
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    {contractData?.before_university_contract_no !== 'null' &&
                      contractData?.before_university_contract_no !== undefined &&
                      contractData?.before_university_contract_no !== '' && (
                        <>
                          <h5>สัญญารับทุนก่อนหน้า (ระดับอุดมศึกษา)</h5>
                          <table className="table">
                            <tbody>
                              <tr>
                                <th>เลขที่สัญญา</th>
                                <td className="w-60">
                                  {contractData?.before_university_contract_no}
                                </td>
                              </tr>
                              <tr>
                                <th>ปีการศึกษา</th>
                                <td className="w-60">
                                  {contractData?.before_university_academic_year}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>วันที่ทำสัญญา</th>
                                <td className="w-60">
                                  {dateHelper.getDate(
                                    contractData?.before_university_contract_date
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    {/* <Duration data={contractData} /> */}
                  </div>

                  <div
                    className={
                      initData.tab_key === 2 ? 'tab-pane fade show active' : 'tab-pane fade'
                    }
                    id="pills-guarantor"
                    role="tabpanel"
                    aria-labelledby="pills-guarantor-tab"
                  >
                    <h5>ข้อมูลค้ำประกัน</h5>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>ชื่อ-นามสกุลผู้ค้ำประกัน (ภาษาไทย)</th>
                          <td className="w-60">
                            {contractData?.guarantor_name_title_th +
                              contractData?.guarantor_firstname_th +
                              ' ' +
                              contractData?.guarantor_lastname_th}{' '}
                          </td>
                        </tr>
                        <tr>
                          <th>ชื่อ-นามสกุลผู้ค้ำประกัน (English)</th>
                          <td className="w-60">
                            {contractData?.guarantor_firstname_en
                              ? contractData?.guarantor_name_title_en
                              : ''}{' '}
                            {contractData?.guarantor_firstname_en +
                              ' ' +
                              contractData?.guarantor_lastname_en}{' '}
                          </td>
                        </tr>
                        <tr>
                          <th>วัน/เดือน/ปี เกิด</th>
                          <td className="w-60">
                            {dateHelper.getDate(contractData?.guarantor_birth_date) || '-'}
                          </td>
                        </tr>
                        <tr>
                          <th>อายุ</th>
                          <td className="w-60"> {contractData?.guarantor_age + ' ปี'}</td>
                        </tr>
                        <tr>
                          <th>อาชีพ</th>
                          <td>{contractData?.guarantor_occupation || '-'}</td>
                        </tr>
                        <tr>
                          <th>ตำแหน่ง</th>
                          <td>{contractData?.guarantor_position || '-'}</td>
                        </tr>
                        <tr>
                          <th>สังกัดหน่วยงาน</th>
                          <td className="w-60">{contractData?.guarantor_organization || '-'}</td>
                        </tr>
                        <tr>
                          <th>ที่อยู่</th>
                          <td className="w-60">
                            {contractData?.guarantor_address_no} หมู่ที่{' '}
                            {contractData?.guarantor_village_no} ซอย {contractData?.guarantor_alley}{' '}
                            ถนน {contractData?.guarantor_road} แขวง/ตำบล{' '}
                            {contractData?.guarantor_subdistrict_name} เขต/อำเภอ{' '}
                            {contractData?.guarantor_district_name} จังหวัด{' '}
                            {contractData?.guarantor_province_name}{' '}
                            {contractData?.guarantor_zipcode}
                          </td>
                        </tr>
                        <tr>
                          <th>เบอร์โทรศัพท์</th>
                          <td className="w-60">{contractData?.guarantor_phone_number || '-'}</td>
                        </tr>
                        <tr>
                          <th>อีเมล</th>
                          <td className="w-60"> {contractData?.guarantor_email || '-'}</td>
                        </tr>
                        <tr>
                          <th>เลขประจำตัวประชาชน</th>
                          <td className="w-60">
                            {contractData?.guarantor_citizen_id_number || '-'}
                          </td>
                        </tr>
                        <tr>
                          <th>ความสัมพันธ์</th>
                          <td className="w-60">{contractData?.guarantor_relationship || '-'}</td>
                        </tr>
                      </tbody>
                    </table>{' '}
                    <h5>หลักฐานค้ำประกัน</h5>
                    <Table>
                      <tbody>
                        <tr>
                          <th>วงเงินค้ำประกัน (บาท)</th>
                          <td className="w-60">{contractData?.guarantor_amount_baht || '-'}</td>
                        </tr>
                        <tr>
                          <th>วงเงินค้ำประกัน</th>
                          <td>
                            <span className="not-set">
                              {contractData?.guarantor_amount}
                              {' ' + contractData?.guarantor_currency_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>ที่ดิน</th>
                          <td>
                            <GurateeLandDetail data={contractData?.guaratee_detail} />
                          </td>
                        </tr>
                        <tr>
                          <th>หลักทรัพย์</th>
                          {/* <td>{guaratee_detail_asset()}</td> */}
                          <td>
                            <GurateeAssetDetail data={contractData?.guaratee_detail} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <h5>คู่สมรส</h5>
                    <Table>
                      <tbody>
                        <tr>
                          <th>ชื่อ-นามสกุล</th>
                          <td className="w-60">
                            {contractData?.spouse_name_title_th} {contractData?.spouse_firstname}{' '}
                            {contractData?.spouse_lastname}{' '}
                          </td>
                        </tr>
                        <tr>
                          <th>สัญชาติ</th>
                          <td>
                            <span className="not-set"> {contractData?.spouse_nationality} </span>
                          </td>
                        </tr>
                        <tr>
                          <th>เลขประจำตัวประชาชน</th>
                          <td>
                            <span className="not-set"> {contractData?.spouse_citizen_id_number} </span>
                          </td>
                        </tr>
                        <tr>
                          <th>ที่อยู่</th>
                          <td>
                            {contractData?.spouse_address_no} หมู่ที่{' '}
                            {contractData?.spouse_village_no} ซอย {contractData?.spouse_alley} ถนน{' '}
                            {contractData?.spouse_road} แขวง/ตำบล{' '}
                            {contractData?.spouse_subdistrict_name} เขต/อำเภอ{' '}
                            {contractData?.spouse_district_name} จังหวัด{' '}
                            {contractData?.spouse_province_name}{' '}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div
                    className={
                      initData.tab_key === 3 ? 'tab-pane fade show active' : 'tab-pane fade'
                    }
                    id="pills-document"
                    role="tabpanel"
                    aria-labelledby="pills-document-tab"
                  >
                    <table className="table">
                      <tbody>
                        <tr>
                          <th style={{ paddingLeft: 0 }}>เอกสารสัญญา</th>
                          <td className="w-60">
                            <a
                              className="btn btn-outline-primary"
                              href={'/contract/print-preview/' + props.id}
                              title="View"
                              aria-label="View"
                              target="blank"
                            >
                              <FontAwesomeIcon icon={faPrint} className="me-1" /> พิมพ์
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="font-weight-500">เอกสารประกอบ</p>
                    <div>
                      <FileDocument
                        data={contract_document}
                        isShow={!contractHelper.isStatusApproved(contractData.status)}
                      />
                    </div>

                    {contractData?.status === 'approved' && (
                      <div>
                        <p className="font-weight-500">เอกสารที่ลงนามแล้ว</p>
                        <FileDocument
                            data={contract_document_approved}
                          />
                      </div>
                    )}

                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </section>
    </div>
  )
}

export default View
