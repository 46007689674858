import { faClipboardList, faCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { dateHelper } from '../../helpers/date.helper'
import { Link } from 'react-router-dom'

const Contract = ({ data, isShow }) => {
  return (
    <>
      {isShow ? (
        <>
          <div className="card-body">
            <h5 className="card-title">
              <div className="bg-icon">
                <FontAwesomeIcon icon={faClipboardList} className="" />
              </div>
              <span>ทำสัญญารับทุน</span>
            </h5>
            <div className="table-responsive">
              <table id="contract" className="table">
                <thead>
                  <tr>
                    <th scope="col">รายการทุน</th>
                    <th scope="col">วันที่ลงนาม</th>
                    <th scope="col">สถานะ</th>
                    <th scope="col" className="text-center">
                      รายละเอียด
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle"> {item.name} </td>
                          <td className="align-middle">
                            {dateHelper.getDateTime(item.contract_date)}
                          </td>
                          <td className={item.classStatus + ' align-middle'}>
                            <FontAwesomeIcon icon={faCircle} className="" />
                            {item.status}
                          </td>
                          <td className="align-middle text-center px-1">
                            <Link
                              className="btn btn-outline-primary px-2"
                              to={'/contract/' + item.id}
                            >
                              <FontAwesomeIcon icon={faSearch} className="" />
                              ดูรายละเอียด
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td valign="top" colSpan={4} className="text-center">
                        ไม่พบข้อมูล
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Contract
