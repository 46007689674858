import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { faClipboardList, faEye, faHome } from '@fortawesome/free-solid-svg-icons'
import { Card } from 'react-bootstrap'
import { dateHelper } from '../../helpers/date.helper'
import useFetch from '../../utils/useFetch'
import NotFound404 from '../NotFound404'
import { Link, useParams } from 'react-router-dom'

const PagesNewsView = () => {
  const { id } = useParams()

  const { response, loading, status } = useFetch(`/student/news/${id}`)

  const Description = () => <div dangerouslySetInnerHTML={{ __html: response?.description }} />

  if (status === 404) {
    return <NotFound404 />
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/news"> ข่าวประชาสัมพันธ์ </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            {response?.title}
          </li>
        </ol>
      </nav>
      <div className="site-news">
        <section className="bg-light">
          <div className="container">
            <Card className="border-0 mb-3">
              <Card.Header className="card-header ">
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div className="w-75">
                    <h5 className="title card-title d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span className="font-prompt"> {response?.title} </span>
                    </h5>
                  </div>
                  <div className="d-flex align-items-center ms-auto px-3 text-secondary">
                    <FontAwesomeIcon icon={faEye} className="me-1" />
                    ผู้เข้าชม: <span className="ms-2"> {response?.views || 0} </span>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="col-xl-12 col-md-12 col-xs-12 mb-3">
                  {!loading ? (
                    <>
                      <div className="d-flex flex-lg-row justify-content-center">
                        <img
                          className="img-fluid"
                          src={response?.thumbnail}
                          alt={response?.title}
                        />
                      </div>
                      <div className="col-12 py-4">
                        <Description />
                      </div>
                      <p className="py-4">
                        วันที่เผยแพร่: {dateHelper.getDateTime(response?.publish_date)}
                      </p>
                    </>
                  ) : (
                    <>
                      <Skeleton height={300} />
                      <Skeleton count={3} />
                      <br />
                      <Skeleton count={1} />
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </section>
      </div>
    </>
  )
}

export default PagesNewsView
