import { faClipboardList, faFileAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { Alert as AlertBS, Col, Form, Row, Table } from 'react-bootstrap'
import * as yup from 'yup'
import { history } from '../../helpers/history'
import http from '../../utils/http'
import NumberFormat from 'react-number-format'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import styled from 'styled-components'
import { Alert } from '../../actions/alert.action'
import useFetch from '../../utils/useFetch'
import { convertCurrencyToNumber } from '../../helpers/currency.helper'
import {
  useAcademicYearOptions,
} from '../../hooks/options/useOptions'
import _ from '../../utils/utils'
import BgWarning from '../../assets/images/warning.png'
const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`
const ExpenseCreate = (props) => {
  const urlAction = '/petition-expense'
  const urlActionCallAPI = '/student/petition/create'
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const { response: petitionData, loading } = useFetch(`/student/petition/create`)

  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [formValues, setFormValues] = useState([
    { expense_type_id: '', amount: '', currency_id: '', description: '', files: [] },
  ])
  const { options: academicYearOptions, loading: academicYearLoading } = useAcademicYearOptions({
    publish: 1, 
  })

  const { response: responseBudget, loading: loadingBudget } = useFetch(
    '/student/masterdata/budget-list',
  )

  const budgetOptions = !loadingBudget && responseBudget?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.budget_year_be}`,
    }
  })

  const validationSchema = yup.object().shape({
    // file: yup.string().required('ไฟล์ต้องไม่ว่าง'),
    petition_type_id: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกประเภทคำร้อง'),
        value: yup
          .string()
          .required('กรุณาเลือกประเภทคำร้อง')
          .nullable()
          .required('กรุณาเลือกประเภทคำร้อง'),
      })
      .nullable()
      .required('กรุณาเลือกประเภทคำร้อง'),

    academic_year_id: yup
      .object()
      .shape({
        label: yup.string().required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),
        value: yup
          .string()
          .required('ปีการศึกษาต้องไม่เป็นค่าว่าง')
          .nullable()
          .required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),
      })
      .nullable()
      .required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),

    description: yup.string().required('กรุณากรอกรายละเอียด'),
    reason: yup.string().required('กรุณากรอกรายละเอียด'),
  })

  let petitionType = null
  let academic_year = null
  let budget_year = null
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, formState, setValue, control, clearErrors } = useForm(formOptions)

  const { errors } = formState

  const { response: responsePetitionType, loading: loadingPetitionType } = useFetch(
    '/student/masterdata/petitiontype',
    {
      petition_group_id: 2,
    }
  )

  const petitionTypeOptions = responsePetitionType?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const handlePetitionTypeChange = (value) => {
    let options = {
      value: value?.value,
      label: value?.label,
    }
    setValue('petition_type_id', options)
  }

  const { response: responseCurrency, loading: loadingCurrency } = useFetch(
    '/student/petition/currency'
  )

  const responseCurrencyOptions = responseCurrency?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const handleCurrencyChange = (i, value) => {
    let options = {
      value: value?.value,
      label: value?.label,
    }
    // setValue('currency_id', options)

    let newFormValues = [...formValues]
    newFormValues[i]['currency_id'] = options
    setFormValues(newFormValues)
  }

  const { response: responseExpenseType, loading: loadingExpenseType } = useFetch(
    '/student/petition/expensetype'
  )

  const expenseTypeOptions = responseExpenseType?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name}`,
    }
  })

  const handleExpenseTypeChange = (i, e) => {
    let options = {
      value: e?.value,
      label: e?.label,
    }
    let newFormValues = [...formValues]
    newFormValues[i]['expense_type_id'] = options
    setFormValues(newFormValues)
    //setValue('expense_type_id', options)
  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '50px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i][e.target.name] = convertCurrencyToNumber(e.target.value)
    setFormValues(newFormValues)
  }

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { expense_type_id: '', amount: '', currency_id: '', description: '', files: [] },
    ])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues]
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
  }

  const onSubmit = async (dataObj) => {
    let validate_currency = 0
    setIsLoading(true)
    if (dataObj) {
      formValues.forEach(element => {
        if(!element?.currency_id || element?.currency_id === "")
        {
          validate_currency = 1
        }
      });
      let dataPost = {
        petition_group_id: 2,
        petition_type_id: dataObj?.petition_type_id?.value,
        description: dataObj.description,
        reason: dataObj.reason,
        expanse: formValues,
        files: files,
        academic_year_id: dataObj.academic_year_id?.value,
      }

      if(validate_currency===1)
      {
        setIsLoading(false)
        Alert.error('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุสกุลเงินให้ครบ')
        return false
      }
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)
      if (dataRes.status === 201) {
        setIsLoading(false)
        Alert.success('บันทึกข้อมูลเรียบร้อย', '')
        history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
        Alert.error('ไม่สามารถบันทึกข้อมูลได้', '')
      }
    }
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    setFiles(newFileInput)
  }

  const handleRemoveFile = (index, file) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }
  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))

  const ShowAlert = () => {
    
    if(petitionData?.ended_contract){
        return (
                  <>
                    <AlertBS variant={'warning'}>
                      {petitionData?.set_message}
                    </AlertBS>
                    <div className='text-center'>
                      <img src={BgWarning} alt=" "  style={{maxWidth:'600px'}}/>
                    </div>
                  </>
                )
    } else if(petitionData?.not_have_contract ){
      return (
          <>
            <AlertBS variant={'warning'}>
              {petitionData?.set_message}
            </AlertBS>
            <div className='text-center'>
              <img src={BgWarning} alt=" "  style={{maxWidth:'600px'}}/>
            </div>
          </>
        )
    } else if(petitionData?.has_personal_scholarship) {
      return (
        <>
          <AlertBS variant={'warning'}>
            ไม่สามารถยื่นคำร้องใหม่ได้ เนื่องจากเป็นการศึกษาด้วยทุนส่วนตัว กรุณาติดต่อเจ้าหน้าที่
          </AlertBS>
          <div className="text-center">
            <img src={BgWarning} alt=" " style={{ maxWidth: '600px' }} />
          </div>
        </>
      )
      
    } else if (petitionData?.has_status_student?.student_status_group_id != 1) {
      return (
        <>
          <AlertBS variant={'warning'}>
            ไม่สามารถยื่นคำร้องใหม่ได้ เนื่องจากไม่ได้อยู่ในสถานะกำลังศึกษา
          </AlertBS>
          <div className="text-center">
            <img src={BgWarning} alt=" " style={{ maxWidth: '600px' }} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <AlertBS variant={'warning'}>
            ต้องยื่นรายงานความก้าวหน้าทางการศึกษาหรือผลการศึกษาก่อนทำรายการคำร้องค่าใช้จ่าย
          </AlertBS>
          <div className="text-center">
            <img src={BgWarning} alt=" " style={{ maxWidth: '600px' }} />
          </div>
        </>
      )
    }
  }
 
 

  return (
    <div className="site-petition-create">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className=" d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>ยื่นคำร้องใหม่</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              {!loading ? (
                <>
                  {!petitionData?.has_educational ||
                  petitionData?.ended_contract ||
                  petitionData?.not_have_contract ||
                  petitionData?.has_personal_scholarship ||
                  petitionData?.has_status_student?.student_status_group_id != 1 ? (
                    <>
                      <ShowAlert />
                    </>
                  ) : (
                    <>
                      <div className="petition-form">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Row>
                            <Col md={12} className="mb-3">
                              <label htmlFor="petition_type_id" className="label-with-required">
                                ประเภทคำร้อง
                              </label>
                              <Controller
                                name="petition_type_id"
                                control={control}
                                render={(field) => (
                                  <Select
                                    {...field}
                                    label={'ประเภทคำร้อง'}
                                    isLoading={loadingPetitionType}
                                    sDisabled={loadingPetitionType}
                                    isClearable
                                    isSearchable={true}
                                    styles={selectStyles}
                                    options={petitionTypeOptions}
                                    placeholder="เลือกประเภทคำร้อง"
                                    defaultValue={petitionType}
                                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                    onChange={(value) => {
                                      handlePetitionTypeChange(value)
                                    }}
                                  />
                                )}
                              />
                              {errors?.petition_type_id && (
                                <div className="help-block">
                                  {errors.petition_type_id?.message ||
                                    errors.petition_type_id?.label.message}
                                </div>
                              )}
                            </Col>
                            <Col sm={12} className="mt-3">
                              <label className="label-with-required">ความประสงค์</label>
                              <textarea
                                {...register('description')}
                                className="form-control"
                                maxLength={214}
                              />
                              {errors?.description && (
                                <div className="help-block">{errors.description?.message}</div>
                              )}
                            </Col>
                            <Col sm={12} className="mt-3">
                              <label className="label-with-required">เหตุผลและความจำเป็น</label>
                              <textarea
                                {...register('reason')}
                                className="form-control"
                                maxLength={214}
                              />

                              {errors.description && (
                                <div className="help-block">{errors.reason.message}</div>
                              )}
                            </Col>
                            <Col md={6} className="mt-3">
                              <label htmlFor="academic_year_id" className="label-with-required">
                                ปีการศึกษา
                              </label>
                              <Controller
                                name="academic_year_id"
                                control={control}
                                render={(field) => (
                                  <Select
                                    {...field}
                                    label={'ปีการศึกษา'}
                                    isLoading={academicYearLoading}
                                    isDisabled={academicYearLoading}
                                    loadingMessage={() => 'กำลังโหลดข้อมูล'}
                                    isClearable
                                    isSearchable={true}
                                    styles={selectStyles}
                                    options={academicYearOptions}
                                    placeholder="เลือกปีการศึกษา"
                                    defaultValue={academic_year}
                                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                    onChange={(value) => {
                                      handleSelectChange(value, 'academic_year_id')
                                    }}
                                  />
                                )}
                              />
                              {errors.academic_year_id && (
                                <div className="help-block">{errors.academic_year_id.message}</div>
                              )}
                            </Col>
                            <Col md={12} className="mt-3">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>ลำดับ</th>
                                    <th>ประเภทค่าใช้จ่าย</th>
                                    <th>จำนวนเงิน</th>
                                    <th>สกุลเงิน</th>
                                    <th>รายละเอียด</th>
                                    <th>#</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formValues.map((element, indexElement) => (
                                    <React.Fragment key={`${indexElement}`}>
                                      <tr>
                                        <td className="text-center">{indexElement + 1}</td>
                                        <td>
                                          <Controller
                                            name="expense_type_id"
                                            control={control}
                                            render={(field) => (
                                              <Select
                                                {...field}
                                                label={'ประเภทคำร้อง'}
                                                isLoading={loadingExpenseType}
                                                sDisabled={loadingExpenseType}
                                                isClearable
                                                isSearchable={false}
                                                styles={selectStyles}
                                                options={expenseTypeOptions}
                                                placeholder="เลือกประเภทค่าใช้จ่าย"
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={element.expense_type_id || ''}
                                                onChange={(value) => {
                                                  handleExpenseTypeChange(indexElement, value)
                                                }}
                                              />
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <Controller
                                            control={control}
                                            name="amount"
                                            render={({ field: { onChange, name, value } }) => (
                                              <NumberFormat
                                                name={name}
                                                value={value}
                                                onChange={(e) => handleChange(indexElement, e)}
                                                thousandSeparator
                                                className="form-control font-16"
                                                placeholder="จำนวนทุนที่ได้รับ"
                                              />
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <Controller
                                            name="currency_id"
                                            control={control}
                                            render={(field) => (
                                              <Select
                                                {...field}
                                                label={'สกุลเงิน'}
                                                isLoading={loadingCurrency}
                                                sDisabled={loadingCurrency}
                                                isClearable
                                                isSearchable={true}
                                                styles={selectStyles}
                                                options={responseCurrencyOptions}
                                                placeholder="เลือกสกุลเงิน"
                                                defaultValue={responseCurrency}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={element.currency_id || ''}
                                                onChange={(value) => {
                                                  handleCurrencyChange(indexElement, value)
                                                }}
                                              />
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <textarea
                                            name="description"
                                            className={'form-control'}
                                            rows="2"
                                            placeholder="รายละเอียด"
                                            value={element.description || ''}
                                            onChange={(e) => handleChange(indexElement, e)}
                                          />
                                        </td>
                                        <td>
                                          {indexElement ? (
                                            <button
                                              type="button"
                                              className="btn btn-danger remove"
                                              onClick={() => removeFormFields(indexElement)}
                                            >
                                              x
                                            </button>
                                          ) : null}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                            <Col md={12} className="mb-3">
                              <div className="button-section">
                                <button
                                  className="btn btn-info add text-white"
                                  type="button"
                                  onClick={() => addFormFields()}
                                >
                                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                                  เพิ่มค่าใช้จ่าย
                                </button>
                              </div>
                            </Col>

                            <Col md={12} className="mt-3">
                              <div className="form-group field-petitionexpense-0-files">
                                <label> ไฟล์เอกสาร </label>
                                <div className="file-caption">
                                  <div className="upload-select">
                                    <div className="d-flex justify-content-start">
                                      <div className="btn-file-upload">
                                        <span
                                          role="img"
                                          aria-label="upload"
                                          className="icon-upload"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="upload"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                          </svg>
                                        </span>
                                        <span>Upload</span>
                                        <input
                                          type="file"
                                          // style={{ display: "none" }}
                                          //  className={"btn-input-upload"}
                                          id="icon-button-file"
                                          {...register('file')}
                                          onChange={(e) => handleChangeFile(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <aside style={thumbsContainer} className="upload-list">
                                    {thumbs}
                                  </aside>
                                </div>

                                <span className="text-danger font-size-xs">
                                  *ขนาดไฟล์แนะนำไม่เกิน 10 MB
                                </span>
                                {errors.file && (
                                  <div className="help-block">{errors.file.message}</div>
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-group text-end mt-4">
                                {!isLoading ? (
                                  <button type="submit" className="btn btn-primary">
                                    บันทึก
                                  </button>
                                ) : (
                                  <button type="submit" className="btn btn-primary" disabled>
                                    กำลังบันทึก...
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ExpenseCreate
