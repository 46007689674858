import React, { useState } from 'react'
import { faClipboardList, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import { history } from '../../helpers/history'
import { TableLoading } from '../../components/Skeleton'
import Header from '../../components/Header'

const EducationHistoryList = () => {
  const title = 'ประวัติการศึกษาของฉัน'
  const { response, loading } = useFetch(`/student/education/history`)

  const sizePerPage = 10

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      แสดง {from} ถึง {to} จากทั้งหมด {size} รายการ
    </span>
  )

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onPageChange: (page, sizePerPage) => {
      //  setCurrentPage(page)
      history.push(`/education-history?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'organization',
      text: 'สถานศึกษา',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'education_level_name',
      text: 'ระดับการศึกษา',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'faculty',
      text: 'คณะ/สาขา',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {row?.faculty} {row?.subject_field}
            </span>
          </>
        )
      },
    },
    {
      dataField: 'year_class',
      text: 'ชั้นปี',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '130px', minWidth: '130px', textAlign: 'center' }
      },
    },
    {
      dataField: 'semester',
      text: 'ภาคการศึกษา',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '180px', minWidth: '180px', textAlign: 'center' }
      },
    },
    {
      dataField: 'academic_year',
      text: 'ปีการศึกษา',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
  ]

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <div className="academic-result-index">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <Header title={title} icon={faClipboardList} isLoading={!loading} />
              <div className="card-body">
                <div>
                  {!loading ? (
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <div>
                          <PaginationTotalStandalone {...paginationProps} />

                          <BootstrapTable
                            id="tableResize"
                            classes="resizable"
                            bootstrap4
                            hover
                            striped={false}
                            bordered={false}
                            keyField="id"
                            data={response}
                            columns={columns}
                            filter={filterFactory()}
                            filterPosition="top"
                            wrapperClasses="table-responsive"
                            noDataIndication={'ไม่พบข้อมูล'}
                            // selectRow={selectRow}
                            // onTableChange={handleTableChange}
                            {...paginationTableProps}
                          />
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      )}
                    </PaginationProvider>
                  ) : (
                    <TableLoading col={6} row={5} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default EducationHistoryList
