import ResearchCreate from '../../pages/research/Create';
import ResearchView from '../../pages/research/View'

export const Research = [
//การเข้าค่าย/กิจกรรมใหม่
    {
        path: '/research/create',
        name: 'เพิ่มการทำวิจัย (ในประเทศ/ต่างประเทศ)', 
        component: ResearchCreate,
        layout: 'research',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'เพิ่มการทำวิจัย (ในประเทศ/ต่างประเทศ)' },
    },
    {
        path: '/research/:id',
        name: 'การทำวิจัย', 
        component: ResearchView,
        layout: 'research',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'การทำวิจัย' },
    },
]