import { faClipboardList, faDownload, faEye, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import { statusHelper } from '../../helpers/status.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'
import { academicResultHelper } from '../../helpers/academic-result.helper'

const AcademicResultView = (props) => {
  let id = props.match.params.id

  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const { response: studentEducation, loadingStudentEducation } = useFetch(
    `/student/education/studenteducation`
  )
  const { response: responseData, loading } = useFetch('/student/academic-result/' + id)
  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/academic-result/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <React.Fragment key={`${item.file_name}_${index}`}>
          <List>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/academic-result/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/academic-result">ผลการศึกษา</Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            รายละเอียดผลการศึกษา
          </li>
        </ol>
      </nav>
      <div className="portfolio-view">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div className="w-100">
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} />
                      </div>
                      <span>ผลการศึกษา</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>ศูนย์การศึกษา</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ระดับการศึกษา</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ปีการศึกษา</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ภาคเรียน</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>รูปแบบผลการศึกษา</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ผลการเรียน</th>
                        <td className="w-60">
                          <Skeleton />{' '}
                        </td>
                      </tr>
                      <tr>
                        <th>ไฟล์</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>สถานะ</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>เหตุผล</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่สร้าง</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่อัปเดต</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <Table>
                    <tbody>
                      <tr>
                        <th>ศูนย์การศึกษา</th>
                        <td className="w-60">{responseData?.institution_name_th || '-'}</td>
                      </tr>
                      <tr>
                        <th>ระดับการศึกษา</th>
                        <td className="w-60">{responseData?.education_level_name_th}</td>
                      </tr>
                      <tr>
                        <th>ปีการศึกษา</th>
                        <td className="w-60">{responseData?.academic_year}</td>
                      </tr>
                      <tr>
                        <th>ภาคเรียน</th>
                        <td className="w-60">{responseData?.semester}</td>
                      </tr>
                      <tr>
                        <th>รูปแบบผลการศึกษา</th>
                        <td className="w-60">{responseData?.type_name} </td>
                      </tr>
                      {academicResultHelper?.isTypeNumber(responseData?.type) && (
                        <>
                          <tr>
                            <th>ผลการเรียนเฉลี่ยสะสม (GPAX)</th>
                            <td className="w-60">{responseData?.gpax}</td>
                          </tr>
                          <tr>
                            <th>ผลการเรียนเฉลี่ย (GPA)</th>
                            <td className="w-60">{responseData?.gpa}</td>
                          </tr>
                        </>
                      )}

                      {academicResultHelper?.isTypeText(responseData?.type) && (
                        <>
                          <tr>
                            <th>ผลการเรียน</th>
                            <td className="w-60">{responseData?.gpax}</td>
                          </tr>
                        </>
                      )}

                      {academicResultHelper?.isTypeResearch(responseData?.type) && (
                        <tr>
                          <th>รายละเอียด</th>
                          <td className="w-60">{responseData?.description}</td>
                        </tr>
                      )}

                      <tr>
                        <th>ไฟล์แนบ</th>
                        <td>
                          {responseData?.document?.length > 0
                            ? renderFileDocument(responseData?.document)
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th>สถานะ</th>
                        <td>{statusHelper.getLabel(responseData?.status)}</td>
                      </tr>
                      <tr>
                        <th>เหตุผล</th>
                        <td>{responseData?.data?.note || '-'}</td>
                      </tr>
                      <tr>
                        <th>วันที่สร้าง</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(responseData?.data?.created_at)}
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่อัปเดต</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(responseData?.data?.updated_at)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}

                {!loading ? (
                  <>
                    <Modal
                      show={show}
                      size="lg"
                      dialogClassName="modal-preview"
                      centered
                      onHide={() => setShow(false)}
                      aria-labelledby="file-name"
                    >
                      <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                        <Modal.Title id="file-name">{fileName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <PDFViewer pdf={filePreview} />
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default AcademicResultView
