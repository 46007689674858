import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Loading from '../Loading'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
/*
issue: https://github.com/wojtekmaj/react-pdf/issues/680#issuecomment-723656449
 */

export default function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const onLoadings = () => {
    return (
      <>
        <Loading />
      </>
    )
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setIsLoading(true)
    setNumPages(numPages)
    setPageNumber(1)
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  let { pdf } = props

  return (
    <>
      <Document
        file={pdf}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={onLoadings}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {isLoading ? (
        <>
          <div className="page-controls">
            <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              ‹
            </button>
            <span>
              หน้า {pageNumber || (numPages ? 1 : '--')} จาก {numPages || '--'}
            </span>
            <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              ›
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
