export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '150px',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: () => ({
    backgroundColor: '#fff',
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  }),
  placeholder: () => ({
    color: '#fff',
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    boxSizing: 'border-box',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    paddingRight: '0',
    paddingLeft: '0',
    '&:hover': {
      color: '#fff',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused || state.isSearchable ? '#39559E' : '#fff',
    color: state.isFocused || state.isSelected ? '#fff' : '#000',
    '&:active': {
      backgroundColor: '#39559E',
      color: '#fff',
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    fontWeight: 500,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  control: (state) => ({
    fontWeight: 500,
    fontSize: '15px',
    display: 'flex',
    backgroundColor: '#39559E',
    color: '#fff',
    height: '44px',
    borderRadius: '25px',
    border: '2px solid #39559E',
    boxShadow: 'none',
    position: 'relative',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#39559E',
      color: '#fff',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    textAlign: 'right',
    color: '#fff',
    '&:before': {
      content: '"\\f0e2"',
      position: 'absolute',
      fontFamily: '"Font Awesome 5 Free"',
      fontWeight: 600,
      fontSize: '18px',
      marginLeft: '20px',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#fff',
    paddingRight: '14px',
    paddingLeft: '12px',
    border: 'none',
    '&:hover': {
      color: '#fff',
    },
  }),

  singleValue: (provided, state) => {
    const opacity = 1 //state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, color: '#fff', textAlign: 'right', opacity, transition }
  },
}
