import React, { Fragment, useState, useEffect, useCallback } from "react";

import Template from "../../components/contract/Template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ContractTemplate = (props) => {
  const [error, setError] = useState(null);

  const title = "พิมพ์สัญญา";

  let content = <Template/>;

  if (error) {
    content = <p>{error}</p>;
  }
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/contract"> สัญญา </Link>
          </li>
          <li
            className="breadcrumb-item active font-sarabun"
            aria-current="page"
          >
            พิมพ์สัญญา
          </li>
        </ol>
      </nav>
      {content}
    </>
  );
};

export default ContractTemplate;
