import React, { Fragment, useState, useEffect, useCallback } from 'react'; 
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faFileAlt,
  faHome, 
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Card, Table, Container, Form, Row, Col, Spinner } from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import useFetch from '../../utils/useFetch'; 
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import http from '../../utils/http'
import axios from 'axios'
import { history } from '../../helpers/history' 

import styled from 'styled-components'
import warning from '../../assets/images/warning.png'


const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`


const EducationalProgressCreate = (props) => {
  const [files, setFiles] = useState([]);
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const auth = localStorage.getItem('user')
  const userData = JSON.parse(auth)
  
  const hasContract = userData?.has_contract
  const hasEducation = userData?.has_education

  const title = "รายงานความก้าวหน้าในการศึกษา";
  const urlAction = "/educational-progress";
  const urlActionCallAPI = "/student/education/progress-create";
  const FILE_SIZE = 10 * 1024;
  const SUPPORTED_FORMATS = [
    "application/pdf",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = yup.object().shape({
    description: yup.string().required('คำอธิบาย ต้องไม่เป็นค่าว่าง'), 
    file: yup
    .mixed()
    .test("fileSize", "กรุณาแนบไฟล์", value => {
      return value[0];
    })
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
  }

  //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
  const { register, handleSubmit, reset, clearErrors, setError, formState, setValue } = useForm(formOptions)

  const { errors } = formState

  const onSubmit = async (dataObj) => {
    setIsLoading(true);
    if (dataObj) { 
      let dataPost = { 
        description: dataObj.description,
        files: files,
      
      }; 
      
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost);
       
      if(dataRes.message === 'Accepted'){
        setIsLoading(false); 
          history.push(`${urlAction}`)
      }
    }
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files];
    newFileInput[i]['description'] = e?.target?.value;
    setFiles(newFileInput)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null; 
  
    let newFileInput = [...files];
    let indexFile =  files.length; 
    newFileInput[indexFile]  = {file:fileInput[0], description:''};

    let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB
   
   
    if (fileInput.length === 0) {
      setError('file', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }
    
    if (!fileInput[0].name.match(/\.(pdf)$/)) {
        setError('file', {
            type: 'manual',
            message: 'ไฟล์นามสกุล .pdf เท่านั้น',
        })
        return false
    }
    if (fileSize > 10) {
      setError('file', {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      })
      return false
    }

    clearErrors('file')

    if (!errors.file) {
      setFiles(newFileInput)
    }
  };


  const handleRemoveFile = (i, e) => {
    let newFileInput = [...files];
    newFileInput.splice(i, 1); 
    setFiles(newFileInput);
  };

  const thumbs = files?.map((item, index) => ( 
    <ListItem key={index + item.file.name}  className={'success'}>
     
     <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">

              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
              {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={e=>handleRemoveFile(index, e)}
                >
                  <span
                    role="img"
                    aria-label="delete"
                    className="anticon anticon-delete"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
              <div className="input-group">
                <span className="input-group-text">คำอธิบาย</span>
                <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ""} 
                onChange={e => handleChangeFileDescription(index, e)}/>
              
              </div>       
          </div> 
        </div>
      </div>
    </ListItem>
  ))

  if (!hasContract || !hasEducation) {
    return (
      <div className="mt-4">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex py-4">
            
              <img src={warning} className="img-alert-warning"/>
              <h1 className="text-center text-primary">สัญญาของคุณยังไม่สมบูรณ์</h1>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <>
       <nav aria-label="breadcrumb">
            <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
                <li className="breadcrumb-item">
                    <Link to="/">
                    <FontAwesomeIcon icon={faHome} />
                    </Link>
                </li>
             
                <li className="breadcrumb-item">
                    <Link to="/educational-progress"> ความก้าวหน้าทางการศึกษา </Link>
                </li>
                <li
                    className="breadcrumb-item active font-sarabun"
                    aria-current="page"  >
                รายงานความก้าวหน้าในการศึกษา
                </li>
            </ol>
        </nav> 
    <div className="educational-progress-create">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header">
              <div className="d-md-flex pt-4 bg-white border-0 ps-0 w-100  my-0 mx-auto">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto texr-start ps-0">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>รายงานความก้าวหน้าในการศึกษา </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="educational-progress-form">
                
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <form
                  id="w0"
                  action="/educational-progress/create"
                  method="post"
                  encType="multipart/form-data"
                > */}

                  <div className="form-group field-educationalprogress-description">
                    <label
                      className="control-label"
                      htmlFor="educationalprogress-description"
                    >
                      ขอรายงานความก้าวหน้าและปัญหาหรืออุปสรรคในการศึกษา
                      ในระยะเวลา 6 เดือนที่ผ่านมา ดังต่อไปนี้
                    </label>
                    <textarea
                      id="educationalprogress-description"
                      className="form-control font-16"
                      name="EducationalProgress[description]"
                      rows={6} 
                      {...register('description')}
                    />
                    
                    {errors.description && <div className="help-block">{errors.description.message}</div>}
                  </div>
                  <div className="font-14">
                    (สำหรับผู้ศึกษาชั้นปริญญาตรีหรือต่ำกว่าโปรดระบุวิชาต่าง ๆ
                    ที่กำลังศึกษา ในกรณีทำการวิจัยในระดับปริญญาโทและเอก
                    ให้ระบุว่าทำการวิจัยเรื่องอะไร ตั้งแต่เมื่อใด
                    โดยวิธีการอย่างไร เพื่อผลอะไร
                    และทำมาแล้วเป็นสัดส่วนเท่าไหร่ของงานทั้งหมด โดยประมาณ)
                  </div>
                  <div className="form-group field-educationalprogress-files required">
                    <label
                      className="control-label"
                      htmlFor="educationalprogress-files"
                    >
                      ไฟล์
                    </label> 

                    <div className="file-caption">
                      
                      <div className="upload-select">
                        <div className="d-flex justify-content-start">
                          <div className="btn-file-upload">
                            <span
                                role="img"
                                aria-label="upload"
                                className="icon-upload" >
                                <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="upload"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true" >
                                <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                </svg>
                            </span>
                            <span>Upload</span>
                            <input
                                type="file"
                              // style={{ display: "none" }}
                              //  className={"btn-input-upload"}
                                id="icon-button-file"
                                {...register('file')}
                                onChange={(e) => handleChangeFile(e)} />
                          </div>
                          
                        </div>
                      
                      </div>
                          
                      <aside style={thumbsContainer} className="upload-list">{thumbs}</aside>
                    </div> 

                    <span className="text-secondary  font-size-xs">ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น</span>
                    {errors.file && <div className="help-block">{errors.file.message}</div>}

                  </div>

                  <div className="form-group text-end">
                  {  !isLoading ? (
                              <button type="submit" className="btn btn-primary">บันทึก</button>
                              ):(
                              <button type="submit" className="btn btn-primary" disabled>กำลังบันทึก...</button>
                          ) }  
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default EducationalProgressCreate;
