import { createGlobalStyle } from 'styled-components'

export const TapsStyles = createGlobalStyle`
  .tab-content {
    padding-top: 0
  }

  .nav-tabs {
    padding: 0
  }

  .nav-tabs .nav-link {
    background-color: #fff;
    border: none;
    margin-bottom: 0;
    border-bottom: 4px solid transparent;
  }

  .nav-tabs .nav-link.active {
    font-weight: 600;
    border-bottom: solid #0d3996  4px;
  }

  .nav-tabs .nav-item.show .nav-link {
    border-bottom: solid transparent  4px;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    font-weight: 600;
    border-bottom: solid #0d3996  4px;
    background-color: #fff;
  }
`

export const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#e3e3e3',
    paddingRight: '0',
    paddingLeft: '0',
    '&:hover': {
      color: '#e3e3e3',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
    color: state.isFocused || state.isSelected ? '#fff' : '#000',
    '&:active': {
      backgroundColor: '#0d3996',
      color: '#fff',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  control: (provided, state) => {
    const default_opt = {
      fontWeight: 400,
      fontSize: '14px',
      display: 'flex',
      height: '50px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }
    if (state.isDisabled === true) {
      return {
        ...default_opt,
        backgroundColor: '#e9ecef',
        opacity: 1,
        color: '#495057',
        cursor: 'not-allowed',
      }
    } else {
      return {
        ...default_opt,
        backgroundColor: '#fff',
      }
    }
  },
  placeholder: (provided, state) => {
    if (state.isDisabled) {
      return { ...provided, color: '#495057' }
    } else {
      return { ...provided }
    }
  },

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#e3e3e3',
    paddingRight: '4px',
    paddingLeft: '0',
    border: 'none',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 1 : 1
    const transition = 'opacity 300ms'
    if (state.isDisabled) {
      return { ...provided, opacity, transition, color: '#495057' }
    } else {
      return { ...provided, opacity, transition }
    }
  },
}
