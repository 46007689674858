import React from 'react'
import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import FormField from './FormField'
import warning from '../../assets/images/warning.png'
import { Alert as AlertBS, Col, Row } from 'react-bootstrap' 

const Create = () => {
  const auth = localStorage.getItem('user')
  const userData = JSON.parse(auth)

  const hasContract = userData?.has_contract
  const hasEducation = userData?.has_education

  const { response: responseData, loading: studyPlanLoading } = useFetch(
    '/student/education/current'
  )

  if (!hasContract || !hasEducation) {
    return (
      <div className="mt-4">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex py-4">
              <img src={warning} className="img-alert-warning"/>
              <h1 className="text-center text-primary">สัญญาของคุณยังไม่สมบูรณ์</h1>
            </div>
          </div>
        </section>
      </div>
    )
  }
   const ShowAlert = () => {
     
      return (
        <>
          <AlertBS variant={'warning'}>
            ไม่สามารถสร้างคำขอเลื่อนชั้นได้ กรุณาติดต่อเจ้าหน้าที่
          </AlertBS>
          <div className="text-center">
            <img src={warning} alt=" " style={{ maxWidth: '600px' }} />
          </div>
        </>
      )
}

  
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/move-classes">การเลื่อนชั้น</Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            สร้างคำขอเลื่อนชั้น
          </li>
        </ol>
      </nav>
      <div className="portfolio-create">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex">
              <div className="card-header">
                <div className="d-md-flex pt-4">
                  <div>
                    <h5 className="d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      <span>สร้างคำขอเลื่อนชั้น</span>
                    </h5>
                  </div>
                </div>
              </div>

              <div className="card-body col-12">
                {!studyPlanLoading ? (
                  <>
                    {!responseData?.academic_year ? (
                      <ShowAlert />
                    ) : (
                      <FormField data={responseData} />
                    )}
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                      <Col md="6" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                      <Col md="12" className="mb-3">
                        <Skeleton height={20} />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Create
