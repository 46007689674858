
import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton'

const NewsLoader = (props) => {
 
    let Loader = (event) => {
        
        let loaderElement = [];
        for (let index = 0; index < event.loop; index++) {
            loaderElement.push(<div className="col-xl-3 col-md-6 col-xs-12 mb-3"><Skeleton height={250} /> <Skeleton count={3} /> </div>);
            
        }
        
         return loaderElement;
    };
  
    return ( 

    <Fragment> 

        <Loader loop={props.loop}/> 
    </Fragment>

    )
}

export default NewsLoader


                
           