import { PetitionStudentStatus } from './routes/PetitionStudentStatus'
import { PetitionExpense } from './routes/PetitionExpense'
import { PetitionOther } from './routes/PetitionOther'
import { EducationalProgress } from './routes/EducationalProgress'
import { Contract } from './routes/Contract'
import { Research } from './routes/Research'
import { StudentActivity } from './routes/StudentActivity'
import { Portfolio } from './routes/Portfolio'
import { StudentAward } from './routes/StudentAward'
import { PersonalInformation } from './routes/PersonalInformation'
import { Setting } from './routes/Setting'
import { NotificationMessageView } from './routes/NotificationMessage'
import { MoveClasses } from './routes/MoveClasses'
import Home from '../pages/home/Home'
import Dashboard from '../pages/dashboard'
import PagesNewsList from '../pages/news/List'
import PagesNewsView from '../pages/news/View'
import ExpenseList from '../pages/expense/List'
import EducationHistoryList from '../pages/education-history/List'
import AcademicResultList from '../pages/academic-result/List'
import AcademicResultCreate from '../pages/academic-result/Create'
import AcademicResultView from '../pages/academic-result/View'
import OperationalList from '../pages/operational/List'
import RetitutionView from '../pages/retitution/View'
import OperationalView from '../pages/operational/View'
import OperationalCreate from '../pages/operational/Create'
import OperationalUpdate from '../pages/operational/Update'
import TermsCondition from '../pages/TermsCondition'
import PrivacyPolicy from '../pages/PrivacyPolicy'

import NotFound404 from '../pages/NotFound404'

const Routes = [
  ...Setting,
  ...PetitionExpense,
  ...PetitionOther,
  ...PetitionStudentStatus,
  ...EducationalProgress,
  ...Contract,
  ...Research,
  ...StudentActivity,
  ...StudentAward,
  ...Portfolio,
  ...PersonalInformation,
  ...NotificationMessageView,
  ...MoveClasses,

  //News
  {
    path: '/news/:id',
    name: 'ข่าวประชาสัมพันธ์',
    component: PagesNewsView,
    layout: 'news',
    breadcrumb: { parent: '', child: 'ข่าวประชาสัมพันธ์' },
  },
  {
    path: '/news',
    name: 'ข่าวประชาสัมพันธ์',
    component: PagesNewsList,
    layout: 'news',
    breadcrumb: { parent: '', child: 'ข่าวประชาสัมพันธ์' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    layout: 'dashboard',
    breadcrumb: { parent: '', child: 'Dashboard' },
  },

  //ประวัติค่าใช้จ่าย
  {
    path: '/expense',
    name: 'ประวัติค่าใช้จ่าย',
    component: ExpenseList,
    layout: 'expense',
    breadcrumb: { parent: '', child: 'ประวัติค่าใช้จ่าย' },
  },
  //ประวัติการศึกษาของฉัน
  {
    path: '/education-history',
    name: 'ประวัติการศึกษาของฉัน',
    component: EducationHistoryList,
    layout: 'education-history',
    breadcrumb: { parent: '', child: 'ประวัติการศึกษาของฉัน' },
  },

  //ผลการศึกษา
  {
    path: '/academic-result/create',
    name: 'ยื่นผลการศึกษา',
    component: AcademicResultCreate,
    layout: 'academic-result',
    breadcrumb: { parent: 'ผลการศึกษา', child: 'ยื่นผลการศึกษา' },
  },
  {
    path: '/academic-result/:id',
    name: 'รายละเอียดผลการศึกษา',
    component: AcademicResultView,
    layout: 'academic-result',
    breadcrumb: { parent: 'ผลการศึกษา', child: 'รายละเอียดผลการศึกษา' },
  },

  {
    path: '/academic-result',
    name: 'ผลการศึกษา',
    component: AcademicResultList,
    layout: 'academic-result',
    breadcrumb: { parent: '', child: 'ผลการศึกษา' },
  },

  //ข้อมูลการปฏิบัติงาน
  {
    path: '/operational/create',
    name: 'ข้อมูลการปฏิบัติงาน',
    component: OperationalCreate,
    layout: 'operational',
    breadcrumb: { parent: '', child: 'ข้อมูลการปฏิบัติงาน' },
  },

  {
    path: '/operational/:id',
    name: 'รายละเอียดข้อมูลการปฏิบัติงาน',
    component: OperationalView,
    layout: 'operational',
    breadcrumb: { parent: 'ข้อมูลการปฏิบัติงาน', child: 'รายละเอียดข้อมูลการปฏิบัติงาน' },
  },
  {
    path: '/operational/update/:id',
    name: 'ข้อมูลการปฏิบัติงาน',
    component: OperationalUpdate,
    layout: 'operational',
    breadcrumb: { parent: '', child: 'ข้อมูลการปฏิบัติงาน' },
  },

  {
    path: '/operational',
    name: 'ข้อมูลการปฏิบัติงาน',
    component: OperationalList,
    layout: 'operational',
    breadcrumb: { parent: '', child: 'ข้อมูลการปฏิบัติงาน' },
  },

  {
    path: '/retitution',
    name: 'คำนวณการตอบแทนทุน',
    component: RetitutionView,
    layout: 'retitution',
    breadcrumb: { parent: '', child: 'คำนวณการตอบแทนทุน' },
  },
  {
    path: '/terms-and-condition',
    name: 'Terms and condition',
    component: TermsCondition,
    layout: 'terms-and-condition',
    breadcrumb: { parent: '', child: 'Terms and condition' },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    component: PrivacyPolicy,
    layout: 'privacy-policy',
    breadcrumb: { parent: '', child: 'Privacy policy' },
  },
  {
    path: '/',
    name: 'Scholarship Self-service',
    component: Home,
    layout: 'home',
  },
  {
    path: '*',
    name: 'Not Found 404',
    component: NotFound404,
    layout: '',
  },
]

export default Routes
