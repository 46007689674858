import React, { Fragment, useState, useEffect, useCallback } from "react";

import BankAccountUpdate from "../../components/setting/BankAccountUpdate";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SettingBankAccountUpdate = (props) => {
  const [bankAccount, setBankAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const title = "ตั้งค่าบัญชีธนาคาร";
  const pathname = props.match.url;

  let content = <BankAccountUpdate />;

  if (error) {
    content = <p>{error}</p>;
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/bank-account"> ตั้งค่าบัญชีธนาคาร</Link>
          </li>
          <li
            className="breadcrumb-item active font-sarabun"
            aria-current="page"
          >
            แก้ไขบัญชีธนาคาร
          </li>
        </ol>
      </nav>
      {content}
    </>
  );
};

export default SettingBankAccountUpdate;
