import { faClipboardList, faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { ButtonAction, ButtonHistory } from '../../components/Button'
import PDFViewer from '../../components/Pdf'
import { convertCurrency } from '../../helpers/currency.helper'
import { dateHelper } from '../../helpers/date.helper'
import { petitionStatusHelper } from '../../helpers/petition.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/BreadcrumbBS'

const View = (props) => {
  const { id } = useParams()
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const { response: petitionData, loading } = useFetch(`/student/petition/${id}`)

  const showFile = (fileName, fileCode) => {
    console.log(fileName, fileCode)
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/petition/expense-preview/${fileCode}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/petition/expense-download/${item?.file_code}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </>
      )
    })
  }

  const ExpanseStudent = ({ data, summary }) => {
    return (
      <>
        <Table responsive bordered>
          <thead>
            <tr>
              <th className="text-center">ลำดับ</th>
              <th className="text-center">ประเภทค่าใช้จ่าย</th>
              <th className="text-center">รายละเอียด</th>
              <th className="text-center">จำนวน</th>
              <th className="text-center">สกุลเงิน</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              <>
                {data?.map((item, index) => {
                  return (
                    <React.Fragment key={`${index}-${item?.expense_type_id}`}>
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td>{item?.expense_type}</td>
                        <td>{item?.description}</td>
                        <td className="text-end">{`${convertCurrency(item?.amount)}`}</td>
                        <td className="text-end">{item?.currency_name}</td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </>
            ) : (
              <>
                <tr>
                  <td colSpan={'100%'}>ไม่พบข้อมูล</td>
                </tr>
              </>
            )}
          </tbody>
          <tfoot>
            {summary?.map((item, index) => (
              <>
                <tr>
                  {index === 0 && (
                    <>
                      <td
                        rowSpan={summary?.length}
                        colSpan="3"
                        className="text-start align-middle font-weight-bold"
                      >
                        รวมทั้งหมด
                      </td>
                    </>
                  )}
                  <td className="text-end">
                    {convertCurrency(item?.amount_baht > 1 ? item?.amount_baht : item.amount)}
                  </td>
                  <td className="text-end">{item?.currency_name}</td>
                </tr>
              </>
            ))}
          </tfoot>
        </Table>
      </>
    )
  }

  const pageName = 'คำร้องค่าใช้จ่าย'
  const pageTitle = 'รายละเอียด'

  const routes = [
    { path: '/petition-expense', breadcrumb: pageName },
    {
      path: '',
      breadcrumb: pageTitle,
    },
  ]

  return (
    <div className="site-petition">
      <Breadcrumb isLoading={loading} routes={routes} />
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className=" d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>รายละเอียดคำร้อง</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                // <ReactLoading type="bubbles" color="#26b9d1" className="m-auto"/>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>ชื่อ - นามสกุลนักเรียน</td>
                      <td className="w-60">
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>ประเภทคำร้อง</td>
                      <td className="w-60">
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>รายละเอียด</td>
                      <td className="w-60">
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>สถานะคำร้อง</td>
                      <td className="w-60">
                        <Skeleton />{' '}
                      </td>
                    </tr>

                    <tr>
                      <td>เหตุผล</td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="w-60" style={{ textAlign: 'right' }}>
                          <ButtonHistory
                            link={`/petition-expense/history/${id}`}
                            amount={petitionData?.data?.amount_history}
                          />
                          <ButtonAction
                            link={`/petition-expense/print/${id}`}
                            label="พิมพ์"
                            variant="outline-primary"
                            icon={faPrint}
                            width="120px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>ชื่อ - นามสกุลนักเรียน</th>
                        <td className="w-60"> {petitionData?.data?.fullname} </td>
                      </tr>
                      <tr>
                        <th>ประเภทคำร้อง</th>
                        <td>{petitionData?.data?.petition_type_name}</td>
                      </tr>
                      <tr>
                        <th>ความประสงค์</th>
                        <td>{petitionData?.data?.description}</td>
                      </tr>
                      <tr>
                        <th>เหตุผลและความจำเป็น</th>
                        <td>{petitionData?.data?.reason}</td>
                      </tr>
                      <tr>
                        <th>ปีการศึกษา</th>
                        <td>{petitionData?.data?.academic_year}</td>
                      </tr>
                      <tr>
                        <th>ปีงบประมาณ</th>
                        <td>{petitionData?.data?.budget}</td>
                      </tr>
                      <tr>
                        <th>สถานะคำร้อง</th>
                        <td className="align-middle">
                          {petitionStatusHelper?.getLabel(petitionData?.data?.status)}
                        </td>
                      </tr>
                      <tr>
                        <th>เหตุผล</th>
                        <td>{petitionData?.data?.note}</td>
                      </tr>
                      <tr>
                        <th>วันที่สร้าง</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(petitionData?.data?.created_at)}
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่อัปเดต</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(petitionData?.data?.updated_at)}
                        </td>
                      </tr>
                      <tr>
                        <th>ไฟล์แนบ</th>
                        <td className=" w-60">
                          {petitionData?.data.files?.length > 0
                            ? renderFileDocument(petitionData?.data?.files)
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col className="p-4">
                      <h5>รายละเอียดค่าใช้จ่าย</h5>
                      <ExpanseStudent
                        data={petitionData?.data?.expense}
                        summary={petitionData?.data?.expense_summary}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {!loading ? (
                <>
                  <Modal
                    show={show}
                    size="lg"
                    dialogClassName="modal-preview"
                    centered
                    onHide={() => setShow(false)}
                    //fullscreen={true}
                    aria-labelledby="file-name"
                  >
                    <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                      <Modal.Title id="file-name">{fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <PDFViewer pdf={filePreview} />
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default View
