
import React from 'react';
import { Link } from 'react-router-dom';

import Slide from '../slide/Slide'
import Status from './Status'
import Login from './Login'

const HomeDetail = (props) => {

    return (
        <div className="site-index">
            <Slide />
            <div className='text-gray-900 dark:text-white mt-5 text-base font-medium tracking-tight">Writes Upside-Down'></div>
            <section id="content" className="content">
                <div className="container">
                    <div className="row d-flex">
                        <Status isLogged={props.isLogged} newsBlock={props.newsBlock}/>
                        
                        {  !props.isLogged  && <Login />  }
                    </div>
                </div>
            </section>
            <section >
                {  props.isLogged ? (
                    <div className="container">
                        <h4 className="my-3 title font-prompt fst-italic">ข่าวประชาสัมพันธ์</h4>
                        <div className="row scroll-element js-scroll fade-in-bottom scrolled">

                            { props.newsBlock }
                        </div>
                        <div className="text-center scroll-element js-scroll fade-in-bottom scrolled pt-3">

                            <Link to="/news" className="btn btn-info text-white font-prompt"> ดูทั้งหมด </Link>
                        </div>
                    </div>
                ):(
                    <></>
                )
                   
                 }
            </section>
        </div>
    )
}

export default HomeDetail
