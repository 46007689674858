import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import PersonalInformationContainer from '../../components/personal-information/View'

const PersonalInformationView = (props) => {
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ข้อมูลของฉัน
          </li>
        </ol>
      </nav>
      <PersonalInformationContainer />
    </>
  )
}

export default PersonalInformationView
