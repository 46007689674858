import MoveClassesCreate from '../../pages/move-classes/Create'
import MoveClassesList from '../../pages/move-classes/List'

export const MoveClasses = [
  {
    path: '/move-classes',
    name: 'การเลื่อนชั้นปี',
    component: MoveClassesList,
    layout: 'move-classes',
    breadcrumb: { parent: 'การเลื่อนชั้นปี', child: 'การเลื่อนชั้นปี' },
  },
  {
    path: '/move-classes/create',
    name: 'การเลื่อนชั้นปี',
    component: MoveClassesCreate,
    layout: 'move-classes',
    breadcrumb: { parent: 'การเลื่อนชั้นปี', child: 'การเลื่อนชั้นปี' },
  },
]
