import React, { forwardRef, useState } from 'react'
import { faFileAlt, faHome, faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Form, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'
import * as yup from 'yup'
import useFetch from '../../utils/useFetch'
import { Controller, useForm } from 'react-hook-form'
import { format } from 'date-fns'
import addDays from 'date-fns/addDays'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DateButton, DatePickerWrapperStyles, IconCalendar } from '../../components/Filter/styles'
import { history } from '../../helpers/history'
import http from '../../utils/http'
import NumberFormat from 'react-number-format'
import { useCountryOptions } from '../../hooks/options/useOptions'
import { convertCurrencyToNumber } from '../../helpers/currency.helper'

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`

const ResearchCreate = (props) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const [isLoading, setIsLoading] = useState(false)

  const [files, setFiles] = useState([])
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))

  const urlAction = '/personal-information'
  const urlActionCallAPI = '/student/research/create'

  //const { id } = useParams()

  const validationSchema = yup.object().shape({
    title: yup.string().required('หัวข้องานวิจัย ต้องไม่เป็นค่าว่าง'),
    abstract: yup.string().required('บทคัดย่อ ต้องไม่เป็นค่าว่าง'),
    co_author: yup.string().required('ผู้ร่วมทำวิจัย ต้องไม่เป็นค่าว่าง'),
    start_date: yup.string().required('วันที่เริ่มทำวิจัย ต้องไม่เป็นค่าว่าง'),
    publish_date: yup.string().required('วันที่ตีพิมพ์ ต้องไม่เป็นค่าว่าง'),
    budget_source: yup.string().required('ได้รับทุนจาก ต้องไม่เป็นค่าว่าง'),
    budget: yup.string().required('จำนวนทุนที่ได้รับ ต้องไม่เป็นค่าว่าง'),
    country_id: yup.string().required('ประเทศ ต้องไม่เป็นค่าว่าง'),
    reference_link: yup.string().required('อ้างอิง ต้องไม่เป็นค่าว่าง'),
    place: yup.string().required('สถานที่ ต้องไม่เป็นค่าว่าง'),
    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
  }

  //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
  const { register, handleSubmit, control, clearErrors, setError, formState, setValue, getValues } =
    useForm(formOptions)

  const { errors } = formState

  const [datepickerItem, setDatepickerItem] = useState({
    start_date: getValues('start_date') ? new Date(getValues('start_date')) : null,
    publish_date: getValues('publish_date') ? new Date(getValues('publish_date')) : null,
  })

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      let dataPost = {
        title: dataObj.title,
        abstract: dataObj.abstract,
        co_author: dataObj.co_author,
        start_date: dataObj.start_date,
        publish_date: dataObj.publish_date,
        budget_source: dataObj.budget_source,
        budget: convertCurrencyToNumber(dataObj.budget || 0),
        country_id: dataObj.country_id,
        reference_link: dataObj.reference_link,
        place: dataObj.place,
        files: files,
      }
      // console.log(dataPost)
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.message === 'Accepted') {
        setIsLoading(false)
        history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
      }
    }
  }

  const { options: countryOptions, loading: countryLoading } = useCountryOptions()

  const handleChange = (name, e) => {
    setValue(name, e.value)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB

    if (fileInput.length === 0) {
      setError('file', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }

    if (!fileInput[0].name.match(/\.(pdf)$/)) {
      setError('file', {
        type: 'manual',
        message: 'ไฟล์นามสกุล .pdf เท่านั้น',
      })
      return false
    }
    if (fileSize > 10) {
      setError('file', {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      })
      return false
    }

    clearErrors('file')

    if (!errors.file) {
      setFiles(newFileInput)
    }
  }

  const handleRemoveFile = (i, e) => {
    let newFileInput = [...files]
    newFileInput.splice(i, 1)
    setFiles(newFileInput)
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/personal-information"> ข้อมูลของฉัน</Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            เพิ่มการทำวิจัย (ในประเทศ/ต่างประเทศ)
          </li>
        </ol>
      </nav>
      <div className="portfolio-create">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex align-items-center">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faMedal} />
                      </div>
                      <span> เพิ่มการทำวิจัย (ในประเทศ/ต่างประเทศ) </span>
                    </h5>
                  </div>
                </div>
              </div>

              <div className="card-body col-12 col-md-8">
                <div className="portfolio-form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="title" className="label-with-required">
                          หัวข้องานวิจัย
                        </label>
                        <input
                          type="text"
                          {...register('title')}
                          id="title"
                          className="form-control font-16"
                          placeholder="หัวข้องานวิจัย"
                          aria-required="true"
                        />

                        {errors.title && <div className="help-block">{errors.title.message}</div>}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="abstract" className="label-with-required">
                          บทคัดย่อ
                        </label>
                        <textarea
                          {...register('abstract')}
                          id="abstract"
                          className="form-control font-16"
                          placeholder="บทคัดย่อ"
                        />

                        {errors.abstract && (
                          <div className="help-block">{errors.abstract.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="budget_source" className="label-with-required">
                          ได้รับทุนจาก
                        </label>
                        <input
                          type="text"
                          {...register('budget_source')}
                          id="budget_source"
                          className="form-control font-16"
                          placeholder="ได้รับทุนจาก"
                          aria-required="true"
                        />

                        {errors.budget_source && (
                          <div className="help-block">{errors.budget_source.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="budget" className="label-with-required">
                          จำนวนทุนที่ได้รับ
                        </label>
                        <Controller
                          control={control}
                          name="budget"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              name={name}
                              value={value}
                              onChange={onChange}
                              thousandSeparator
                              className="form-control font-16"
                              placeholder="จำนวนทุนที่ได้รับ"
                            />
                          )}
                        />
                        {errors.budget && <div className="help-block">{errors.budget.message}</div>}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="reference_link" className="label-with-required">
                          ลิงก์อ้างอิง
                        </label>
                        <input
                          type="text"
                          {...register('reference_link')}
                          id="reference_link"
                          className="form-control font-16"
                          placeholder="อ้างอิง"
                          aria-required="true"
                        />

                        {errors.reference_link && (
                          <div className="help-block">{errors.reference_link.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="co_author" className="label-with-required">
                          ผู้ร่วมทำวิจัย
                        </label>
                        <input
                          type="text"
                          {...register('co_author')}
                          id="co_author"
                          className="form-control font-16"
                          placeholder="ผู้ร่วมทำวิจัย"
                          aria-required="true"
                        />

                        {errors.co_author && (
                          <div className="help-block">{errors.co_author.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <label htmlFor="start_date" className="label-with-required">
                          วันที่เริ่มทำวิจัย
                        </label>
                        <>
                          <div className="position-relative">
                            <DatePicker
                              {...register('start_date', {
                                required: true,
                              })}
                              dateFormat="d MMM yyyy"
                              onChange={(event) => {
                                clearErrors('start_date')
                                setValue('start_date', format(event, 'yyyy-MM-dd'))
                                setDatepickerItem({ ...datepickerItem, start_date: event })
                              }}
                              selected={datepickerItem?.start_date}
                              customInput={<InputDate />}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={addDays(new Date(), 0)}
                              preventOpenOnFocus={true}
                              placeholder="วันที่เริ่มทำวิจัย"
                            />
                            <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                          </div>
                          <DatePickerWrapperStyles />
                        </>

                        {errors.start_date && (
                          <div className="help-block">{errors.start_date.message}</div>
                        )}
                      </Col>
                      <Col md={6} className="mb-3">
                        <label htmlFor="publish_date" className="label-with-required">
                          วันที่ตีพิมพ์
                        </label>
                        <>
                          <div className="position-relative">
                            <DatePicker
                              {...register('publish_date', {
                                required: true,
                              })}
                              dateFormat="d MMM yyyy"
                              onChange={(event) => {
                                clearErrors('publish_date')
                                setValue('publish_date', format(event, 'yyyy-MM-dd'))
                                setDatepickerItem({ ...datepickerItem, publish_date: event })
                              }}
                              selected={datepickerItem?.publish_date}
                              customInput={<InputDate />}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={addDays(new Date(), 0)}
                              preventOpenOnFocus={true}
                              placeholder="วันที่ตีพิมพ์"
                            />
                            <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                          </div>
                          <DatePickerWrapperStyles />
                        </>

                        {errors.publish_date && (
                          <div className="help-block">{errors.publish_date.message}</div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="place" className="label-with-required">
                          สถานที่
                        </label>
                        <input
                          type="text"
                          {...register('place')}
                          id="place"
                          className="form-control font-16"
                          placeholder="สถานที่"
                          aria-required="true"
                        />

                        {errors.place && <div className="help-block">{errors.place.message}</div>}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="country_id" className="label-with-required">
                          ประเทศ
                        </label>
                        <Select
                          options={countryOptions}
                          styles={customStyles}
                          isSearchable={true}
                          onChange={(e) => handleChange('country_id', e)}
                          placeholder="เลือกประเทศ"
                        />
                        {errors.country_id && (
                          <div className="help-block">{errors.country_id.message}</div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="file" className="label-with-required">
                          แนบไฟล์
                        </label>
                        <div className="file-caption">
                          <div className="upload-select">
                            <div className="d-flex justify-content-start">
                              <div className="btn-file-upload">
                                <span role="img" aria-label="upload" className="icon-upload">
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="upload"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload</span>
                                <input
                                  type="file"
                                  // style={{ display: "none" }}
                                  //  className={"btn-input-upload"}
                                  id="icon-button-file"
                                  {...register('file')}
                                  onChange={(e) => handleChangeFile(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <aside style={thumbsContainer} className="upload-list">
                            {thumbs}
                          </aside>
                        </div>
                        <span className="text-secondary  font-size-xs">
                          ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น
                        </span>
                        {errors.file && <div className="help-block">{errors.file.message}</div>}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="my-3 d-flex justify-content-end">
                        <Link
                          className="btn btn-outline-primary btn-add mx-3"
                          to="/personal-information"
                        >
                          ยกเลิก
                        </Link>
                        {!isLoading ? (
                          <button type="submit" className="btn btn-primary btn-add">
                            บันทึก
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary btn-add" disabled>
                            กำลังบันทึก...
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ResearchCreate
