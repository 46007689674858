import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPhone, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import http from '../../utils/http'
import { history } from '../../helpers/history'
import { Nav, Container, Row, Col, Form, Tab, Placeholder } from 'react-bootstrap'
import useFetch from '../../utils/useFetch'
import useService from '../../utils/useService'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import Address from './form/Address'
import Parents from './form/Parents'
import ReactLoading from 'react-loading'
import { lineTo } from 'pdf-lib'
import {
  usePrefixOptions,
  useNationalityOptions,
  useRelationshipOptions,
} from '../../hooks/options/useOptions'
import { AlertError, AlertSuccess } from '../../components/Alert'

const FormField = (props) => {
  // const { response: studentData, loading } = useFetch(`/student/personal`)

  const studentData = props?.data

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDefault, setIsLoadingDefault] = useState(false)

  const urlAction = '/personal-information'
  const urlActionCallAPI = '/student/personal/create'

  const validationSchema = yup.object().shape({
    address_no: yup.string().required('บ้านเลขที่ต้องไม่เป็นค่าว่าง'),
    village_no: yup.string().required('หมู่ที่ต้องไม่เป็นค่าว่าง'),

    current_address_no: yup.string().required('บ้านเลขที่ต้องไม่เป็นค่าว่าง'),
    current_village_no: yup.string().required('หมู่ที่ต้องไม่เป็นค่าว่าง'),
  })

  // const [formOptions, setFormOptions] = useState({
  //   resolver: yupResolver(validationSchema),
  // })
  const formOptions = {
    resolver: yupResolver(validationSchema),
  }

  // let fatherNationality = {
  //   label: studentData?.parents?.father?.nationality,
  //   value: studentData?.parents?.father?.nationality,
  // }

  let fatherProvince = {
    label: studentData?.parents?.father?.address?.province?.name_th,
    value: studentData?.parents?.father?.address?.province?.id,
  }

  let fatherStatus = {
    value: studentData?.parents?.father?.parents_status_name,
    label: studentData?.parents?.father?.parents_status_name,
  }

  formOptions.defaultChecked = {
    address_type: studentData?.scholarship_student?.address_type,
  }

  formOptions.defaultValue = {
    // address_type: '',
   // father_province_id: fatherProvince,
    // father_nationality: fatherNationality,

   // father_parents_status: fatherStatus,

    //father_occupation: '',

    address_type: studentData?.scholarship_student?.address_type,
  }

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    control,
    clearErrors,
    resetField,
    setError,
  } = useForm(formOptions)

  const { errors } = formState

  let nameTitle = null

  let religion = null

  let nameTitleFather = null

  const [formPhoneNumber, setFormPhoneNumber] = useState([{ phone_number: '' }])

  const [formEmail, setFormEmail] = useState([{ email: '' }])

  if (!studentData) {
    let province = {
      value: studentData?.scholarship_student?.province_id,
      label: studentData?.scholarship_student?.province_name,
    }
    setValue('province_id', province)



  }

  //   district = {
  //     value: studentData?.scholarship_student?.district_id,
  //     label: studentData?.scholarship_student?.district_name,
  //   }
  //   setValue('district_id',district);

  //   subDistrict = {
  //     value: studentData?.scholarship_student?.subdistrict_id,
  //     label: studentData?.scholarship_student?.subdistrict_name,
  //   }
  //   setValue('subdistrict_id',subDistrict);

  //   currentProvince = {
  //     value: studentData?.scholarship_student?.current_province_id,
  //     label: studentData?.scholarship_student?.current_province_name,
  //   }
  //   setValue('current_province_id',currentProvince);

  //   currentDistrict = {
  //     value: studentData?.scholarship_student?.current_district_id,
  //     label: studentData?.scholarship_student?.current_district_name,
  //   }
  //   setValue('current_district_id',currentDistrict);

  //   currentSubDistrict = {
  //     value: studentData?.scholarship_student?.current_subdistrict_id,
  //     label: studentData?.scholarship_student?.current_subdistrict_name,
  //   }
  //   setValue('current_subdistrict_id',currentSubDistrict);

  //   if (checkEmpty(studentData?.parents?.father?.name_title_id)) {
  //     fatherNameTitle = {
  //       value: studentData?.parents?.father?.name_title_id,
  //       label: studentData?.parents?.father?.name_title_th,
  //     }
  //     setValue('father_name_title_id',fatherNameTitle);
  //   }

  fatherProvince = {
    value: studentData?.parents?.father?.province_id,
    label: studentData?.parents?.father?.province_name,
  }
  //   setValue('father_province_id',fatherProvince);

  //   fatherDistrict = {
  //     value: studentData?.parents?.father?.district_id,
  //     label: studentData?.parents?.father?.district_name,
  //   }
  //   setValue('father_district_id',fatherDistrict);

  //   fatherSubDistrict = {
  //     value: studentData?.parents?.father?.subdistrict_id,
  //     label: studentData?.parents?.father?.subdistrict_name,
  //   }
  //   setValue('father_subdistrict_id',fatherSubDistrict);

  //   if (checkEmpty(studentData?.parents?.mother?.name_title_id)) {
  //     motherNameTitle = {
  //       value: studentData?.parents?.mother?.name_title_id,
  //       label: studentData?.parents?.mother?.name_title_th,
  //     }
  //     setValue('mother_name_title_id',motherNameTitle);
  //   }

  //   motherProvince = {
  //     value: studentData?.parents?.mother?.province_id,
  //     label: studentData?.parents?.mother?.province_name,
  //   }
  //   setValue('mother_province_id',motherProvince);

  //   motherDistrict = {
  //     value: studentData?.parents?.mother?.district_id,
  //     label: studentData?.parents?.mother?.district_name,
  //   }
  //   setValue('mother_district_id',motherDistrict);

  //   motherSubDistrict = {
  //     value: studentData?.parents?.mother?.subdistrict_id,
  //     label: studentData?.parents?.mother?.subdistrict_name,
  //   }
  //   setValue('mother_subdistrict_id',motherSubDistrict);

  //   if (checkEmpty(studentData?.parents?.other?.name_title_id)) {
  //     otherNameTitle = {
  //       value: studentData?.parents?.other?.name_title_id,
  //       label: studentData?.parents?.other?.name_title_th,
  //     }
  //     setValue('other_name_title_id',otherNameTitle);
  //   }

  //   otherProvince = {
  //     value: studentData?.parents?.other?.province_id,
  //     label: studentData?.parents?.other?.province_name,
  //   }
  //   setValue('other_province_id',otherProvince);

  //   otherDistrict = {
  //     value: studentData?.parents?.other?.district_id,
  //     label: studentData?.parents?.other?.district_name,
  //   }
  //   setValue('other_district_id',otherDistrict);

  //   otherSubDistrict = {
  //     value: studentData?.parents?.other?.subdistrict_id,
  //     label: studentData?.parents?.other?.subdistrict_name,
  //   }
  //   setValue('other_subdistrict_id',otherSubDistrict);

  // }

  const isPublish = {
    publish: 1,
  }

  const genderOptions = [
    { value: 'male', label: 'ชาย' },
    { value: 'female', label: 'หญิง' },
  ]

  const { options: prefixOptions, loading: prefixLoading } = usePrefixOptions()

  const { options: nationalityOptions, loading: loadingNationality } = useNationalityOptions()

  const { response: responseReligion } = useFetch('/student/masterdata/religion', isPublish)

  const religionOptions = responseReligion?.map((item) => {
    return {
      value: item?.name,
      label: item?.name,
    }
  })

  const { response: responseOccupation, loading: loadingOccupation } = useFetch(
    '/student/masterdata/occupation',
    isPublish
  )

  const occupationOptions = responseOccupation?.map((item) => {
    return {
      value: item?.name,
      label: item?.name,
    }
  })

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const checkEmpty = (value) => {
    if (value !== null && value !== undefined && value !== '') {
      return true
    }
    return false
  }

  if (studentData && isLoadingDefault === false) {
    if (checkEmpty(studentData?.scholarship_student.province_id)) {
      setValue('province_id', {
        value: studentData?.scholarship_student.province_id,
        label: studentData?.scholarship_student.province_name,
      })
    }
    if (checkEmpty(studentData?.scholarship_student.district_id)) {
      setValue('district_id', {
        value: studentData?.scholarship_student.district_id,
        label: studentData?.scholarship_student.district_name,
      })
    }
    if (checkEmpty(studentData?.scholarship_student.subdistrict_id)) {
      setValue('subdistrict_id', {
        value: studentData?.scholarship_student.subdistrict_id,
        label: studentData?.scholarship_student.subdistrict_name,
      })
    }
    if (checkEmpty(studentData?.scholarship_student.current_province_id)) {
      setValue('current_province_id', {
        value: studentData?.scholarship_student.current_province_id,
        label: studentData?.scholarship_student.current_province_name,
      })
    }
    if (checkEmpty(studentData?.scholarship_student.current_district_id)) {
      setValue('current_district_id', {
        value: studentData?.scholarship_student.current_district_id,
        label: studentData?.scholarship_student.current_district_name,
      })
    }
    if (checkEmpty(studentData?.scholarship_student.current_subdistrict_id)) {
      setValue('current_subdistrict_id', {
        value: studentData?.scholarship_student.current_subdistrict_id,
        label: studentData?.scholarship_student.current_subdistrict_name,
      })
    }
    if (checkEmpty(studentData?.parents.father.province_id)) {
      setValue('father_province_id', {
        value: studentData?.parents.father.province_id,
        label: studentData?.parents.father.province_name,
      })
    }
    if (checkEmpty(studentData?.parents.father.district_id)) {
      setValue('father_district_id', {
        value: studentData?.parents.father.district_id,
        label: studentData?.parents.father.district_name,
      })
    }
    if (checkEmpty(studentData?.parents.father.subdistrict_id)) {
      setValue('father_subdistrict_id', {
        value: studentData?.parents.father.subdistrict_id,
        label: studentData?.parents.father.subdistrict_name,
      })
    }
    if (checkEmpty(studentData?.parents.mother.province_id)) {
      setValue('mother_province_id', {
        value: studentData?.parents.mother.province_id,
        label: studentData?.parents.mother.province_name,
      })
    }
    if (checkEmpty(studentData?.parents.mother.district_id)) {
      setValue('mother_district_id', {
        value: studentData?.parents.mother.district_id,
        label: studentData?.parents.mother.district_name,
      })
    }
    if (checkEmpty(studentData?.parents.mother.subdistrict_id)) {
      setValue('mother_subdistrict_id', {
        value: studentData?.parents.mother.subdistrict_id,
        label: studentData?.parents.mother.subdistrict_name,
      })
    }
    if (checkEmpty(studentData?.parents.other.province_id)) {
      setValue('other_province_id', {
        value: studentData?.parents.other.province_id,
        label: studentData?.parents.other.province_name,
      })
    }
    if (checkEmpty(studentData?.parents.other.district_id)) {
      setValue('other_district_id', {
        value: studentData?.parents.other.district_id,
        label: studentData?.parents.other.district_name,
      })
    }
    if (checkEmpty(studentData?.parents.other.subdistrict_id)) {
      setValue('other_subdistrict_id', {
        value: studentData?.parents.other.subdistrict_id,
        label: studentData?.parents.other.subdistrict_name,
      })
    }
    
    if (checkEmpty(studentData?.parents.father.parents_status_name)) {
      setValue('father_parents_status', {
        value: studentData?.parents.father.parents_status_name,
        label: studentData?.parents.father.parents_status_name,
      })
    }
    if (checkEmpty(studentData?.parents.mother.parents_status_name)) {
      setValue('mother_parents_status', {
        value: studentData?.parents.mother.parents_status_name,
        label: studentData?.parents.mother.parents_status_name,
      })
    }
    if (checkEmpty(studentData?.parents.other.parents_status_name)) {
      setValue('other_parents_status', {
        value: studentData?.parents.other.parents_status_name,
        label: studentData?.parents.other.parents_status_name,
      })
    }

    setIsLoadingDefault(true)
  }

  const methods = {
    register,
    setValue,
    errors,
    control,
    getValues,
    clearErrors,
    formPhoneNumber,
    setFormPhoneNumber,
    formEmail,
    setFormEmail,

    prefixOptions,
    prefixLoading,

    genderOptions,
    religionOptions,

    occupationOptions,
    loadingOccupation,

    studentData,
    nameTitle,
    religion,

    nameTitleFather,
    selectStyles,

    nationalityOptions,
    loadingNationality,

    isLoadingDefault,

    //fatherStatus,
    //isCreateMode,

    // methodAction,
  }

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      //console.log(dataObj)
      let data = {
        // subject_field_id: dataObj?.subject_field?.value,
        // subject_area: dataObj?.subject_area?.value,
        primary_phone_number: dataObj?.primary_phone_number,
        primary_email: dataObj?.primary_email,
        phone_number: dataObj?.formPhoneNumber,
        email: dataObj?.formEmail,

        address_no: dataObj?.address_no,
        village_no: dataObj?.village_no,
        alley: dataObj?.alley,
        road: dataObj?.road,
        province_id: dataObj?.province_id?.value,
        district_id: dataObj?.district_id?.value,
        subdistrict_id: dataObj?.subdistrict_id?.value,
        zipcode: dataObj?.zipcode,

        address_type: dataObj?.address_type,

        current_address_no: dataObj?.current_address_no,
        current_village_no: dataObj?.current_village_no,
        current_alley: dataObj?.current_alley,
        current_road: dataObj?.current_road,
        current_province_id: dataObj?.current_province_id?.value,
        current_district_id: dataObj?.current_district_id?.value,
        current_subdistrict_id: dataObj?.current_subdistrict_id?.value,
        current_zipcode: dataObj?.current_zipcode,

        street_address1: dataObj?.street_address1,
        street_address2: dataObj?.street_address2,

        parents: [
          {
            father: {
              name_title_id: dataObj?.father_name_title_id?.value,
              firstname_th: dataObj?.father_firstname_th,
              lastname_th: dataObj?.father_lastname_th,
              firstname_en: dataObj?.father_firstname_en,
              lastname_en: dataObj?.father_lastname_en,
              citizen_id_number: dataObj?.father_citizen_id_number,
              phone_number: dataObj?.father_phone_number,
              email: dataObj?.father_email,
              nationality: dataObj?.father_nationality?.value,
              spouse_name: dataObj?.father_spouse_name,
              occupation: dataObj?.father_occupation?.value,
              organization: dataObj?.father_organization,
              parents_status: dataObj?.father_parents_status?.value,
              alive_status: dataObj?.father_alive_status,
              address_no: dataObj?.father_address_no,
              village_no: dataObj?.father_village_no,
              alley: dataObj?.father_alley,
              road: dataObj?.father_road,
              subdistrict_id: dataObj?.father_subdistrict_id?.value,
              district_id: dataObj?.father_district_id?.value,
              province_id: dataObj?.father_province_id?.value,
              zipcode: dataObj?.father_zipcode,
            },
          },
          {
            mother: {
              name_title_id: dataObj?.mother_name_title_id?.value,
              firstname_th: dataObj?.mother_firstname_th,
              lastname_th: dataObj?.mother_lastname_th,
              firstname_en: dataObj?.mother_firstname_en,
              lastname_en: dataObj?.mother_lastname_en,
              citizen_id_number: dataObj?.mother_citizen_id_number,
              phone_number: dataObj?.mother_phone_number,
              email: dataObj?.mother_email,
              nationality: dataObj?.mother_nationality?.value,
              spouse_name: dataObj?.mother_spouse_name,
              occupation: dataObj?.mother_occupation?.value,
              organization: dataObj?.mother_organization,
              parents_status: dataObj?.mother_parents_status?.value,
              alive_status: dataObj?.mother_alive_status,
              address_no: dataObj?.mother_address_no,
              village_no: dataObj?.mother_village_no,
              alley: dataObj?.mother_alley,
              road: dataObj?.mother_road,
              subdistrict_id: dataObj?.mother_subdistrict_id?.value,
              district_id: dataObj?.mother_district_id?.value,
              province_id: dataObj?.father_province_id?.value,
              zipcode: dataObj?.mother_zipcode,
            },
          },
          {
            other: {
              name_title_id: dataObj?.other_name_title_id?.value,
              firstname_th: dataObj?.other_firstname_th,
              lastname_th: dataObj?.other_lastname_th,
              firstname_en: dataObj?.other_firstname_en,
              lastname_en: dataObj?.other_lastname_en,
              citizen_id_number: dataObj?.other_citizen_id_number,
              phone_number: dataObj?.other_phone_number,
              email: dataObj?.other_email,
              relationship: dataObj?.other_relationship?.value,
              nationality: dataObj?.other_nationality?.value,
              spouse_name: dataObj?.other_spouse_name,
              occupation: dataObj?.other_occupation?.value,
              organization: dataObj?.other_organization,
              parents_status: dataObj?.other_parents_status?.value,
              alive_status: dataObj?.other_alive_status,
              address_no: dataObj?.other_address_no,
              village_no: dataObj?.other_village_no,
              alley: dataObj?.other_alley,
              road: dataObj?.other_road,
              subdistrict_id: dataObj?.other_subdistrict_id?.value,
              district_id: dataObj?.other_district_id?.value,
              province_id: dataObj?.other_province_id?.value,
              zipcode: dataObj?.other_zipcode,
            },
          },
        ],
      }
     // console.log('onSubmit ',data);
      let dataRes = await http.post(`${urlActionCallAPI}`, data)

      if (dataRes.message === 'Accepted') {
        let textSuccess = '';
        
        if(studentData?.scholarship_student?.primary_email != dataObj?.primary_email){
          textSuccess = 'ใช้อีเมลเดิมในการเข้าสู่ระบบจนกว่าจะได้รับอีเมลยืนยันการอนุมัติใช้งานอีเมลใหม่ส่งไปยังอีเมลใหม่ที่แจ้งมา';
        }
       
        setIsLoading(false)
        AlertSuccess('แก้ไขข้อมูลเรียบร้อย', textSuccess)
        history.push(`${urlAction}`)
      } else {
        AlertError('ไม่สามารถบันทึกข้อมูลได้')
      }
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Address {...methods}></Address>
        <Parents {...methods}></Parents>
        <div className="form-group mt-4 d-flex justify-content-end col-11">
          <a className="btn btn-outline-primary btn-add mx-3" href="/personal-information">
            ยกเลิก
          </a>
          {!isLoading ? (
            <button
              type="submit"
              id="submitPersonalInformation"
              className="btn btn-primary btn-add"
            >
              บันทึก
            </button>
          ) : (
            <button
              type="submit"
              id="submitPersonalInformation"
              className="btn btn-primary btn-add"
              disabled
            >
              กำลังบันทึก...
            </button>
          )}
        </div>
      </Form>
    </>
  )
}

export default FormField
