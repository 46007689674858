import SkeletonLoading from '../../components/SkeletonLoading'
import { Container, Breadcrumb as BreadcrumbBS } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const Breadcrumb = ({ isLoading, routes }) => {
  return (
    <>
      <Container className="py-2">
        {!isLoading ? (
          <BreadcrumbBS bsPrefix="breadcrumb mb-0">
            <BreadcrumbBS.Item linkAs={Link} linkProps={{ to: '/' }}>
              <FontAwesomeIcon icon={faHome} />
            </BreadcrumbBS.Item>
            {routes.map((item, index) => {
              return routes?.length - 1 === index || routes?.length === 1 ? (
                <React.Fragment key={`breadcrumb_${index}`}>
                  <BreadcrumbBS.Item active className="font-sarabun">
                    {item?.breadcrumb}
                  </BreadcrumbBS.Item>
                </React.Fragment>
              ) : (
                <React.Fragment key={`breadcrumb_${index}`}>
                  <BreadcrumbBS.Item
                    linkAs={Link}
                    linkProps={{ to: item?.path }}
                    className="font-sarabun"
                  >
                    {item?.breadcrumb}
                  </BreadcrumbBS.Item>
                </React.Fragment>
              )
            })}
          </BreadcrumbBS>
        ) : (
          <>
            <SkeletonLoading />
          </>
        )}
      </Container>
    </>
  )
}

export default Breadcrumb
