export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#e3e3e3',
    paddingRight: '0',
    paddingLeft: '0',
    '&:hover': {
      color: '#e3e3e3',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
    color: state.isFocused || state.isSelected ? '#fff' : '#000',
    '&:active': {
      backgroundColor: '#0d3996',
      color: '#fff',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontWeight: 500,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  control: (state) => ({
    fontWeight: 500,
    fontSize: '15px',
    display: 'flex',
    backgroundColor: '#fff',
    height: '44px',
    borderRadius: '4px',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#e3e3e3',
    paddingRight: '4px',
    paddingLeft: '0',
    border: 'none',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}
