import React, { Fragment, useState, useEffect, useCallback } from 'react'
import NewsList from '../../components/news/NewsList'
import NewsContainer from '../../components/news/NewsContainer'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const PagesNewsList = (props) => {
  const [news, setNews] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const apiKey = process.env.REACT_APP_API_KEY
  const apiVersion = process.env.REACT_APP_API_VERSION
  const baseUrl = process.env.REACT_APP_API_URL
  const title = 'ข่าวประชาสัมพันธ์'
  const pathname = props.match.path

  const fetchNewsHandler = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      let headers = {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        'x-api-version': apiVersion,
      }

      let options = {
        url: baseUrl + '/student/news?offset=0&limit=12',
        // method: 'GET',
        headers: headers,
      }

      await axios(options)
        .then((response) => {
          const data = response.data
          const transformedNews = data.data.map((item) => {
            return {
              id: item.id,
              title: item.title,
              description: item.description,
              thumbnail: item.thumbnail,
              publish_date: item.publish_date,
              views: item.views,
            }
          })
          setNews(transformedNews)
          // setLoading(false)
        })
        .catch((error) => {
          setError(error)
          // setLoading(false)
        })
    } catch (error) {
      setError(error.message)
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchNewsHandler()
    document.title = title
  }, [fetchNewsHandler, title])

  const NewsLoader = (event) => {
    let loaderElement = []

    for (let index = 0; index < event.loop; index++) {
      loaderElement.push(
        <div className="col-xl-3 col-md-6 col-xs-12 mb-3">
          <Skeleton height={250} /> <Skeleton count={3} />{' '}
        </div>
      )
    }

    return loaderElement
  }

  let newsList = <p>Not found news.</p>

  if (news.length > 0) {
    newsList = <NewsList news={news} />
  }

  if (error) {
    newsList = <p>{error}</p>
  }

  if (isLoading) {
    newsList = <NewsLoader loop={8} />
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <NewsContainer title={title} newsBlock={newsList} />
    </>
  )
}

export default PagesNewsList
