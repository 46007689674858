import useFetch from '../../utils/useFetch'

export const usePrefixOptions = (params) => {
  const { response: responsePrefix, loading } = useFetch('/student/masterdata/prefix', {
    publish: 1,
    ...params,
  })

  const prefixPrename = responsePrefix
    ?.filter((item) => {
      return item?.type === 'prename'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name_th,
      }
    })

  const prefixAcademic = responsePrefix
    ?.filter((item) => {
      return item?.type === 'academic'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name_th,
      }
    })

  const prefixOther = responsePrefix
    ?.filter((item) => {
      return item?.type === 'other'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name_th,
      }
    })

  const data = [
    {
      label: 'คำนำหน้าทั่วไป',
      options: prefixPrename,
    },
    {
      label: 'คำนำหน้าทางการศึกษา',
      options: prefixAcademic,
    },
    {
      label: 'คำนำหน้าอื่นๆ',
      options: prefixOther,
    },
  ]

  return {
    options: data,
    loading,
  }
}

export const useNationalityOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/nationality', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.name,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useParentsStatusOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/parents-status', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.name,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useAcademicYearBcOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/academic-year', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.year_bc,
      label: item.year,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useStudyPlanClassYearOptions = (params) => {
  const { response, loading } = useFetch('/student/study-plan/class-year', {
    publish: 1,
    ...params,
  })

  const data = response
    ?.map((item, index) => {
      return {
        value: item.class_year,
        label: item.class_year,
      }
    })
    .sort((a, b) => {
      return a.value - b.value
    })
    .reverse()

  return {
    options: data,
    loading,
  }
}

export const useStudyPlanSemesterOptions = (params) => {
  const { response, loading } = useFetch('/student/study-plan/semester', {
    publish: 1,
    ...params,
  })

  const data = response
    ?.map((item, index) => {
      return {
        value: item.semester,
        label: item.semester,
      }
    })
    .sort((a, b) => {
      return a.value - b.value
    })
    .reverse()

  return {
    options: data,
    loading,
  }
}

export const useStudyPlanAcademicYearOptions = (params) => {
  const { response, loading } = useFetch('/student/study-plan/academic-year', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.academic_year,
      label: item.academic_year_bc,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useExpenseYearOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/expense-year', {
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.year,
      label: item.year_bc,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useSemesterOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/semester', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name_th,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useRelationshipOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/relationship', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.name,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useWorkplaceOptions = (params) => {
  const { response, loading } = useFetch('/common/organization', {
    publish: 1,
    workplace: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: `${item.name_th} ${
        item.parent_name_th ? `(${item.parent_name_th} - ${item?.grand_parent_name_th})` : ''
      }`,

      organization_label: item.name_th,

      parent_id: item.parent_id,
      parent_label: item.parent_name_th,

      grand_parent_id: item.grand_parent_id,
      grand_parent_label: item.grand_parent_name_th,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useContractTypeOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/contract-type', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useCurrencyOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/currency', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useFaculty = (params) => {
  const { response, loading } = useFetch('/student/masterdata/faculty', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.name,
      label: item.name,
    }
  })
  return {
    options: data,
    loading,
  }
}

export const useStudentStatusOptions = (params) => {
  const { response, loading } = useFetch('/office/masterdata/student-status/list', {
    publish: 1,
    ...params,
  })

  const studyingStatus = response
    ?.filter((item) => {
      return item?.student_status_group_id === '1'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
        group_id: item?.student_status_group_id,
      }
    })

  const graduateStatus = response
    ?.filter((item) => {
      return item?.student_status_group_id === '2'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
        group_id: item?.student_status_group_id,
      }
    })

  const revokingStatus = response
    ?.filter((item) => {
      return item?.student_status_group_id === '3'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
        group_id: item?.student_status_group_id,
      }
    })

  const contractStatus = response
    ?.filter((item) => {
      return item?.student_status_group_id === '4'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
        group_id: item?.student_status_group_id,
      }
    })

  const workingStatus = response
    ?.filter((item) => {
      return item?.student_status_group_id === '5'
    })
    ?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
        group_id: item?.student_status_group_id,
      }
    })

  const data = [
    {
      label: 'กำลังเรียน',
      options: studyingStatus,
    },
    {
      label: 'สำเร็จการศึกษา',
      options: graduateStatus,
    },
    {
      label: 'ออกจากทุน',
      options: revokingStatus,
    },
    {
      label: 'ทำสัญญา',
      options: contractStatus,
    },
    {
      label: 'สถานภาพการปฏิบัติงาน',
      options: workingStatus,
    },
  ]

  return {
    options: data,
    loading,
  }
}

export const useRevokingStatusOptions = (params) => {
  const { response, loading } = useFetch('/office/masterdata/revoking-status/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item?.id,
      label: item?.name,
    }
  })
  return {
    options: data,
    loading,
  }
}

export const useRevokeReasonOptions = (params) => {
  const { response, loading } = useFetch('/office/masterdata/revoke-reason/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item?.id,
      label: item?.name,
    }
  })
  return {
    options: data,
    loading,
  }
}

export const useCountryOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/country', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: `${item?.name_th} ${item?.name_en !== null ? `(${item?.name_en})` : ''}`,
    }
  })

  return {
    options: data,
    loading,
  }
}


export const useEducationLevelOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/education-level/list', {
    publish: 1,
    ...params,
  })


  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name_th,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useSubjectFieldOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/subject-field/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useInstitutionOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/organization/list', {
    institution: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item?.name_th + ' ' + (item?.name_en ? `(${item?.name_en})` : ''),
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useClassYearOptions = () => {
 let loading = false
 let response  =[1,2,3,4,5,6]
   const data = response?.map((item, index) => {
    return {
      value: item,
      label: item,
    }
  })
 return {
  options:data,
  loading,
 }
}

export const useStudentStatusGroupOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/student-status-group/list', {
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item?.name,
    }
  })

  return {
    options: data,
    loading,
  }
}


export const useSubjectAreaOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/subject-area/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.name,
      label: item.name,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useCourseOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/course/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
  return {
    options: data,
    loading,
  }
}

export const useScholarshipOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/scholarship/list', {
    publish: 1,
    ...params,
  })


  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name, 
    }
  })

  return {
    options: data,
    loading,
  }
}

export const usePetitionTypeOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/petitiontype', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.name,
      type: item.type,
      expand_type: item?.expand_type,
    }
  })

  return {
    options: data,
    loading,
  }
}

export const useAcademicYearOptions = (params) => {
  const { response, loading } = useFetch('/student/masterdata/academic-year/list', {
    publish: 1,
    ...params,
  })

  const data = response?.map((item, index) => {
    return {
      value: item.id,
      label: item.year,
    }
  })

  return {
    options: data,
    loading,
  }
}
