import EducationalProgressCreate from '../../pages/educational-progress/Create';
import EducationalProgressList from '../../pages/educational-progress/List'; 
import EducationalProgressView from '../../pages/educational-progress/View';
import EducationalProgressUpdate from '../../pages/educational-progress/Update';

export const EducationalProgress = [

//ความก้าวหน้าทางการศึกษา
    // {
    //     path: '/educational-progress/delete/:id',
    //     name: 'แก้ไขความก้าวหน้าทางการศึกษา', 
    //     component: EducationalProgressDelete,
    //     layout: 'educational-progress',
    //     breadcrumb: {'parent': 'ความก้าวหน้าทางการศึกษา','child': 'แก้ไขความก้าวหน้าทางการศึกษา' },
    // },
    // {
    //     path: '/educational-progress/update/:id',
    //     name: 'แก้ไขความก้าวหน้าทางการศึกษา', 
    //     component: EducationalProgressUpdate,
    //     layout: 'educational-progress',
    //     breadcrumb: {'parent': 'ความก้าวหน้าทางการศึกษา','child': 'แก้ไขความก้าวหน้าทางการศึกษา' },
    // },

    {
        path: '/educational-progress/edit/:id',
        name: 'ความก้าวหน้าในการศึกษา', 
        component: EducationalProgressUpdate,
        layout: 'educational-progress',
        breadcrumb: {'parent': 'ความก้าวหน้าทางการศึกษา','child': 'ความก้าวหน้าทางการศึกษา' },
    },
    {
        path: '/educational-progress/create',
        name: 'ความก้าวหน้าทางการศึกษา', 
        component: EducationalProgressCreate,
        layout: 'educational-progress',
        breadcrumb: {'parent': 'ความก้าวหน้าทางการศึกษา','child': 'ความก้าวหน้าทางการศึกษา' },
    },

    {
        path: '/educational-progress/:id',
        name: 'ความก้าวหน้าในการศึกษา', 
        component: EducationalProgressView,
        layout: 'educational-progress',
        breadcrumb: {'parent': 'ความก้าวหน้าทางการศึกษา','child': 'ความก้าวหน้าทางการศึกษา' },
    },
    {
        path: '/educational-progress',
        name: 'ความก้าวหน้าทางการศึกษา', 
        component: EducationalProgressList,
        layout: 'educational-progress',
        breadcrumb: {'parent': '','child': 'ความก้าวหน้าทางการศึกษา' },
    },

]