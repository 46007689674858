import React from 'react'
import { useHistory } from 'react-router-dom'

const NotFound404 = () => {
  let history = useHistory()
  const url = "/";
  const goBack = () => {
    return history.push(`${url}`)
  }
  return (
    <>
     <div className="error-box text-center py-4">
        <h1 className="text-info">404</h1>
        <h3>
          <i className="fas fa-exclamation-triangle"></i> อ๊ะ! ไม่พบหน้านี้!
        </h3>
        <p>ไม่พบหน้าที่คุณร้องขอ</p>
        <button onClick={goBack} className="btn btn-info">
          กลับ
        </button>
      </div>
    </>
  )
}

export default NotFound404
