import React, { useState } from 'react'

import { Col, Row, Button } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import useFetch from '../../../utils/useFetch'
import useService from '../../../utils/useService'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPhone, faHome, faMinus } from '@fortawesome/free-solid-svg-icons'
import { selectStyles } from './styled'
import _ from '../../../utils/utils'
import { useEffect } from 'react'

const Address = (props) => {
  const { register, setValue, errors, clearErrors, control, studentData, getValues } = props
  let getDistrictOptions = []
  let getSubDistrictOptions = []
  if (studentData.scholarship_student) {
    getDistrictOptions = studentData?.scholarship_student.district.map((item, index) => {
      return {
        value: item.id,
        label: item.name_th,
      }
    })
    getSubDistrictOptions = studentData?.scholarship_student.subdistrict.map((item, index) => {
      return {
        value: item.id,
        label: item.name_th,
      }
    })
  }
  const [districtOptions, setDistrictOptions] = useState(getDistrictOptions)
  const [subdistrictOptions, setSubdistrictOptions] = useState(getSubDistrictOptions)
  const [zipcode, setZipcode] = useState(studentData?.scholarship_student?.zipcode)

  const [currentDistrictOptions, setCurrentDistrictOptions] = useState([])
  const [currentSubdistrictOptions, setCurrentSubdistrictOptions] = useState([])
  const [currentZipcode, setCurrentZipcode] = useState(studentData?.scholarship_student?.current_zipcode)

  const [districtDefault, setDistrictDefault] = useState(null)
  const [currentDistrictDefault, setCurrentDistrictDefault] = useState(null)

  const [duplicateAddress, setDuplicateAddress] = useState(null)
  const [addressTypeDomestic, setAddressTypeDomestic] = useState('')
  const [addressTypeAbroad, setAddressTypeAbroad] = useState('d-none')
  const [formPhoneNumber, setFormPhoneNumber] = useState(null)
  const [formEmail, setFormEmail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [otherProvinceItems, setOtherProvinceItems] = useState(null)
  const [otherDistrictItems, setOtherDistrictItems] = useState(null)
  const [otherSubdistrictItems, setOtherSubdistrictItems] = useState(null)
  const [otherSubdistricts, setOtherSubdistrict] = useState(null)
  const [otherZipcode, setOtherZipcode] = useState('')

  const addPhoneNumberFields = () => {
    if (typeof formPhoneNumber === null || formPhoneNumber === null || formPhoneNumber == 'null') {
      setFormPhoneNumber([{ phone_number: '' }])
    } else {
      setFormPhoneNumber([...formPhoneNumber, { phone_number: '' }])
    }
  }

  let handlePhoneNumberChange = (i, e) => {
    let newFormValues = [...formPhoneNumber]
    newFormValues[i][e.target.name] = e.target.value
    setFormPhoneNumber(newFormValues)
    setValue('formPhoneNumber', newFormValues)
  }

  const addEmailFields = () => {
    if (typeof formEmail === null || formEmail === null || formEmail == 'null') {
      setFormEmail([{ email: '' }])
    } else {
      setFormEmail([...formEmail, { email: '' }])
    }
  }

  let handleEmailChange = (i, e) => {
    let newFormValues = [...formEmail]
    newFormValues[i][e.target.name] = e.target.value
    setFormEmail(newFormValues)
    setValue('formEmail', newFormValues)
  }

  const removeFormFields = (type, i) => {
    if (type == 'email') {
      let newFormValues = [...formEmail]
      newFormValues.splice(i, 1)
      setFormEmail(newFormValues)
    } else if (type == 'phone_number') {
      let newFormValues = [...formPhoneNumber]
      newFormValues.splice(i, 1)
      setFormPhoneNumber(newFormValues)
    }
  }

  //const { results: responseProvince, error, loading: loadingProvince } = useService('/province')

  const { response: responseProvince, loading: loadingProvince } = useFetch(
    '/student/masterdata/province'
  )

  const provinceOptions = responseProvince?.map((item) => {
    return {
      value: item.id,
      label: item.name_th,
    }
  })

  const currenctProvinceOptions = responseProvince?.map((item) => {
    return {
      value: item.id,
      label: item.name_th,
    }
  })

  const onChangeInput = (event) => {
    if (getValues('duplicate_address')) {
      setValue('current_' + event.target.name, event.target.value)
    }
  }

  // const handleSelectChange = (value, fieldName) => {
  //   let options = ''
  //   if (!_.isNull(value)) {
  //     options = {
  //       value: value?.value,
  //       label: value?.label,
  //     }
  //     clearErrors(fieldName)
  //   }
  //   setValue(fieldName, options)
  // }

  const baseUrl = process.env.REACT_APP_API_URL

  const handleSelectProvinceChange = async (value, fieldName) => {
    let options = ''
    // let districtOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const district = await fetch(`${baseUrl}/district?province_id=${value?.value}`)

      clearErrors(fieldName)
      const json = await district.json()
      let districtOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setDistrictOptions(districtOptions)
    } else {
      setDistrictDefault('')
    }

    setValue(fieldName, options)
  }

  const handleSelectDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const currentSubDistrict = await fetch(`${baseUrl}/sub-district?district_id=${value?.value}`)
      const json = await currentSubDistrict.json()
      let subDistrictOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setSubdistrictOptions(subDistrictOptions)
      clearErrors(fieldName)
    }
    
    setValue(fieldName, options)
  }

  const handleSelectSubDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const subDistrict = await fetch(`${baseUrl}/zipcode?sub_district_id=${value?.value}`)
      const json = await subDistrict.json()

      setZipcode(json?.data[0]?.zipcode)
      setValue('zipcode', json?.data[0]?.zipcode)

    }

    setValue(fieldName, options)
  }

  const handleSelectCurrenctProvinceChange = async (value, fieldName) => {
    let options = ''
    // let districtOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const district = await fetch(`${baseUrl}/district?province_id=${value?.value}`)

      clearErrors(fieldName)
      const json = await district.json()
      let districtOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setCurrentDistrictOptions(districtOptions)
    } else {
      setCurrentDistrictDefault('')
    }

    setValue(fieldName, options)
  }

  const handleSelectCurrenctDistrictChange = async (value, fieldName) => {
    //console.log(fieldName)
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const currentSubDistrict = await fetch(`${baseUrl}/sub-district?district_id=${value?.value}`)
      const json = await currentSubDistrict.json()
      let subDistrictOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setCurrentSubdistrictOptions(subDistrictOptions)
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleSelectCurrentSubDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const subDistrict = await fetch(`${baseUrl}/zipcode?sub_district_id=${value?.value}`)
      const json = await subDistrict.json()
      setCurrentZipcode(json?.data[0]?.zipcode)
      setValue('current_zipcode', json?.data[0]?.zipcode)

    }

    setValue(fieldName, options)
  }

  const changeDuplicateAddress = (event) => {


    if (event.target.checked) {
      setDuplicateAddress('d-none')
      setValue('current_address_no', getValues('address_no'))
      setValue('current_village_no', getValues('village_no'))
      setValue('current_alley', getValues('alley'))
      setValue('current_road', getValues('road'))
      setValue('current_province_id', getValues('province_id'))
      setValue('current_district_id', getValues('district_id'))
      setValue('current_subdistrict_id', getValues('subdistrict_id'))
      setValue('current_zipcode', getValues('zipcode'))

    } else {
      setDuplicateAddress('')
    }
  }
  const changeAddressType = (event) => {
    //console.log(event.target.value);
    if (event.target.value === 'domestic') {
      setAddressTypeDomestic('d-block')
      setAddressTypeAbroad('d-none')
    } else {
      setAddressTypeDomestic('d-none')
      setAddressTypeAbroad('d-block')
    }
  }

  let nameTitle = null
  let religion = null

  let province = null
  let district = null
  let subDistrict = null

  let currentProvince = null
  let currentDistrict = null
  let currentSubDistrict = null

  if (studentData?.scholarship_student?.name_title_id) {
    province = {
      value: studentData?.scholarship_student?.province_id,
      label: studentData?.scholarship_student?.province_name,
    }
    //setValue('province_id', province)

    district = {
      value: studentData?.scholarship_student?.district_id,
      label: studentData?.scholarship_student?.district_name,
    }
    //setValue('district_id', district)

    subDistrict = {
      value: studentData?.scholarship_student?.subdistrict_id,
      label: studentData?.scholarship_student?.subdistrict_name,
    }
    //setValue('subdistrict_id', subDistrict)

    currentProvince = {
      value: studentData?.scholarship_student?.current_province_id,
      label: studentData?.scholarship_student?.current_province_name,
    }
    //setValue('current_province_id', currentProvince)

    currentDistrict = {
      value: studentData?.scholarship_student?.current_district_id,
      label: studentData?.scholarship_student?.current_district_name,
    }
    //setValue('current_district_id', currentDistrict)

    currentSubDistrict = {
      value: studentData?.scholarship_student?.current_subdistrict_id,
      label: studentData?.scholarship_student?.current_subdistrict_name,
    }
    //setValue('current_subdistrict_id', currentSubDistrict)
  }

  useEffect(() => {
    if (studentData?.scholarship_student?.email !== null) {
      setFormEmail([{ email: studentData?.scholarship_student?.email }])
    }
  }, [])

  return (
    <>
      <Row>
        <Col md={12}>
          <h5 className="card-title d-flex align-items-left">
            <div className="bg-icon">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <span className="fw-bolder">ข้อมูลการติดต่อ</span>
          </h5>
        </Col>
        <Col md={12} className="ps-7 ">
          <label className="py-2 fw-bolder">หมายเลขโทรศัพท์</label>
          <div className="block_primary_phone_number">
            <div className="d-flex primary_phone_number  mb-3 ">
              <div className="col-2  pe-3">
                <label className="text-primary">
                  <input
                    type="radio"
                    name="phone_number_primary"
                    defaultValue={1}
                    defaultChecked
                    className="me-2"
                  />
                  ใช้งานหลัก
                </label>
              </div>
              <div className="col-md-10">
                <div className="item-phone-number">
                  <div className="col-md-6  pe-3">
                    <input
                      type="number"
                      className="form-control phone_number font-16"
                      name="primary_phone_number"
                      {...register('primary_phone_number')}
                      defaultValue={studentData?.scholarship_student?.primary_phone_number}
                      placeholder="xx xxx xxxx"
                    />
                  </div>
                </div>
                {formPhoneNumber ? (
                  formPhoneNumber.map((element, index) => (
                    <div className="item-phone-number">
                      <div className="col-md-6  pe-3">
                        <input
                          type="number"
                          className="form-control phone_number font-16"
                          name="phone_number"
                          value={element.phone_number || ''}
                          placeholder="xx xxx xxxx"
                          onChange={(event) => handlePhoneNumberChange(index, event)}
                        />
                      </div>
                      <div class="item-personal col">
                        <button
                          type="button"
                          className="btn btn-danger remove"
                          onClick={(event) => removeFormFields('phone_number', index)}
                        >
                          x
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="col-8 offset-2  pe-3">
            <button
              type="button"
              className="btn btn-info text-white px-3 py-1 rounded-3 btn_add_phone"
              onClick={() => addPhoneNumberFields()}
            >
              <FontAwesomeIcon icon={faPlus} className="" /> เพิ่มรายการ
            </button>
          </div>
        </Col>
        <Col md={12} className="ps-7 ">
          <label className="py-2 fw-bolder"> อีเมล </label>
          <div className="block_primary_email">
            <div className="d-flex primary_email  mb-3 ">
              <div className="col-2  pe-3">
                <label className="text-primary">
                  <input
                    type="radio"
                    className="me-2"
                    name="primary_email"
                    defaultValue={1}
                    defaultChecked
                  />
                  ใช้งานหลัก
                </label>
              </div>
              <div className="col  pe-3">
                <div className="item-email pe-3">
                  <div className="col-8  pe-3">
                    <input
                      type="email"
                      className="form-control mb-3 font-16"
                      name="email"
                      {...register('primary_email')}
                      defaultValue={studentData?.scholarship_student?.primary_email}
                      placeholder="example@mail.com"
                    />
                  </div>
                </div>
                {formEmail ? (
                  formEmail.map((element, index) => (
                    <div className="item-email pe-3">
                      <div className="col-8  pe-3">
                        <input
                          type="email"
                          className="form-control mb-3 font-16"
                          name="email"
                          value={element.email || ''}
                          placeholder="example@mail.com"
                          onChange={(e) => handleEmailChange(index, e)}
                        />
                      </div>

                      <div class="item-personal col">
                        <Button
                          className="rounded-3 text-white"
                          variant="danger"
                          size="sm"
                          onClick={(event) => removeFormFields('email', index)}
                        >
                          <FontAwesomeIcon icon={faMinus} className="me-2 text-white" />
                          ลบรายการ
                        </Button>
                        {/* <button
                          style={{ width: '150px' }}
                          type="button"
                          className="btn btn-danger remove"
                          onClick={(event) => removeFormFields('email', index)}
                        >
                          x
                        </button> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <Col md={12} className="offset-2  pe-3">
            <button
              type="button"
              className="btn btn-info text-white px-3 py-1 rounded-3 btn_add_email"
              onClick={() => addEmailFields()}
            >
              <FontAwesomeIcon icon={faPlus} className="" /> เพิ่มรายการ
            </button>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-4">
          <h5 className="card-title d-flex align-items-left">
            <div className="bg-icon">
              <FontAwesomeIcon icon={faHome} className="" />
            </div>
            <span className="fw-bolder">ที่อยู่ตามทะเบียนบ้าน</span>
          </h5>
        </Col>
        <Col md={12} className="ps-7">
          <div className="d-flex">
            <Row className="">
              <Col md={3} className="mb-3">
                <label className="w-100">
                  บ้านเลขที่
                  <div className="form-group field-address_no required">
                    <input
                      type="text"
                      id="address_no"
                      className="form-control font-16"
                      {...register('address_no')}
                      defaultValue={studentData?.scholarship_student?.address_no}
                      placeholder="บ้านเลขที่"
                      onChange={(event) => onChangeInput(event)}
                    />
                  </div>
                  {errors.address_no && (
                    <div className="help-block">{errors.address_no.message}</div>
                  )}
                </label>
              </Col>
              <Col md={3} className="mb-3">
                <label className="w-100">
                  ซอย
                  <div className="form-group field-alley required">
                    <input
                      type="text"
                      id="alley"
                      className="form-control font-16 alley"
                      {...register('alley')}
                      defaultValue={studentData?.scholarship_student?.alley}
                      placeholder="ซอย"
                      onChange={(event) => onChangeInput(event)}
                    />
                  </div>
                </label>
              </Col>
              <Col md={3} className="mb-3">
                <label className="w-100">
                  หมู่ที่
                  <div className="form-group field-scholarshipstudent-village_no required">
                    <input
                      type="text"
                      id="scholarshipstudent-village_no"
                      className="form-control font-16 village_no"
                      {...register('village_no')}
                      defaultValue={studentData?.scholarship_student?.village_no}
                      placeholder="หมู่ที่"
                      onChange={(event) => onChangeInput(event)}
                    />
                  </div>
                  {errors.village_no && (
                    <div className="help-block">{errors.village_no.message}</div>
                  )}
                </label>
              </Col>
              <Col md={3} className="mb-3">
                <label className="w-100">
                  ถนน
                  <div className="form-group road required">
                    <input
                      type="text"
                      id="road"
                      className="form-control font-16 road"
                      {...register('road')}
                      defaultValue={studentData?.scholarship_student?.road}
                      placeholder="ถนน"
                      onChange={(event) => onChangeInput(event)}
                    />
                  </div>
                </label>
              </Col>
              <Col md={6} className="mb-3">
                <label htmlFor="province_id">จังหวัด</label>
                <Controller
                  name="province_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isClearable
                      isDisabled={loadingProvince}
                      isLoading={loadingProvince}
                      isSearchable={true}
                      styles={selectStyles}
                      options={provinceOptions}
                      defaultValue={province}
                      placeholder="เลือกจังหวัด"
                      onChange={(value) => handleSelectProvinceChange(value, field.name)}
                    />
                  )}
                />
                {errors.province_id && (
                  <div className="help-block">{errors.province_id.message}</div>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <label htmlFor="district_id">อำเภอ/เขต</label>
                <Controller
                  name="district_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="อำเภอ/เขต"
                      isClearable
                      isDisabled={loadingProvince}
                      isLoading={loadingProvince}
                      isSearchable={true}
                      styles={selectStyles}
                      options={districtOptions}
                      defaultValue={district}
                      placeholder="เลือกอำเภอ/เขต"
                      onChange={(value) => handleSelectDistrictChange(value, field.name)}
                    />
                  )}
                />
                {errors.district_id && (
                  <div className="help-block">{errors.district_id.message}</div>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <label htmlFor="subdistrict_id">ตำบล/แขวง</label>
                <Controller
                  name="subdistrict_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="ตำบล/แขวง"
                      isClearable
                      isDisabled={loadingProvince}
                      isLoading={loadingProvince}
                      isSearchable={true}
                      styles={selectStyles}
                      options={subdistrictOptions}
                      defaultValue={subDistrict}
                      placeholder="เลือกตำบล/แขวง"
                      onChange={(value) => handleSelectSubDistrictChange(value, field.name)}
                    />
                  )}
                />
                {errors.subdistrict_id && (
                  <div className="help-block">{errors.subdistrict_id.message}</div>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <label htmlFor="zipcode" className="">
                  รหัสไปรษณีย์
                </label>
                <input
                  placeholder="รหัสไปรษณีย์"

                  name='zipcode'
                  {...register('zipcode')}
                  defaultValue={zipcode ? zipcode : studentData?.scholarship_student?.zipcode}
                  className="form-control"
              
                />
                {errors.zipcode && <div className="help-block">{errors.zipcode.message}</div>}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <label className="text-primary ps-7 mt-4">
          <div className="form-group duplicate_address">
            <label>
              <input
                type="checkbox"
                id="duplicate_address"
                className="duplicate_address"
                name="duplicate_address"
                {...register('duplicate_address')}
                defaultValue={1}
                onChange={(event) => {
                  changeDuplicateAddress(event)
                  setValue('duplicate_address', event.target.checked)
                }}
              />{' '}
              ที่อยู่ปัจจุบันเดียวกับที่อยู่ตามทะเบียนบ้าน
            </label>
          </div>
        </label>

        <div className={'current_address p-0 ' + duplicateAddress}>
          <Col md={12} className="mt-4 ps-7">
            <div className="form-group address_type">
              <div id="address_type" role="radiogroup">
                <label className="me-2">
                  <input
                    type="radio"
                    defaultChecked="domestic"
                    {...register('address_type')}
                    value="domestic"
                    onChange={changeAddressType}
                  />{' '}
                  ที่อยู่ในประเทศ
                </label>
                <label>
                  <input
                    type="radio"
                    {...register('address_type')}
                    value="abroad"
                    onChange={changeAddressType}
                  />{' '}
                  ที่อยู่ต่างประเทศ
                </label>
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-4">
            <h5 className="card-title d-flex align-items-left">
              <div className="bg-icon">
                <FontAwesomeIcon icon={faHome} className="" />
              </div>
              <span className="fw-bolder">ที่อยู่ปัจจุบัน</span>
            </h5>
          </Col>
          <Col md={12} className={'ps-7 domestic ' + addressTypeDomestic}>
            <div className="d-flex">
              <Row className="">
                <Col md={3} className="mb-3">
                  <label className="w-100">
                    บ้านเลขที่
                    <div className="form-group current_address_no">
                      <input
                        type="text"
                        id="current_address_no"
                        className="form-control  font-16 current_address_no"
                        {...register('current_address_no')}
                        defaultValue={studentData?.scholarship_student?.current_address_no}
                        placeholder="บ้านเลขที่"
                      />
                      {errors.current_address_no && (
                        <div className="help-block">{errors.current_address_no.message}</div>
                      )}
                    </div>
                  </label>
                </Col>
                <Col md={3} className="mb-3">
                  <label className="w-100">
                    ซอย
                    <div className="form-group current_alley">
                      <input
                        type="text"
                        id="current_alley"
                        className="form-control font-16 current_alley"
                        {...register('current_alley')}
                        defaultValue={studentData?.scholarship_student?.current_alley}
                        placeholder="ซอย"
                      />
                    </div>
                  </label>
                </Col>
                <Col md={3} className="mb-3">
                  <label className="w-100">
                    หมู่ที่
                    <div className="form-group current_village_no">
                      <input
                        type="text"
                        id="current_village_no"
                        className="form-control font-16 current_village_no"
                        {...register('current_village_no')}
                        defaultValue={studentData?.scholarship_student?.current_village_no}
                        placeholder="หมู่ที่"
                      />
                      {errors.current_village_no && (
                        <div className="help-block">{errors.current_village_no.message}</div>
                      )}
                    </div>
                  </label>
                </Col>
                <Col md={3} className="mb-3">
                  <label className="w-100">
                    ถนน
                    <div className="form-group current_road">
                      <input
                        type="text"
                        id="current_road"
                        className="form-control font-16 current_road"
                        {...register('current_road')}
                        defaultValue={studentData?.scholarship_student?.current_road}
                        placeholder="ถนน"
                      />
                    </div>
                  </label>
                </Col>

                <Col md={6} className="mb-3">
                  <label htmlFor="current_province_id" className="">
                    จังหวัด
                  </label>

                  <Controller
                    name="current_province_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        isDisabled={loadingProvince}
                        isLoading={loadingProvince}
                        isSearchable={true}
                        styles={selectStyles}
                        options={currenctProvinceOptions}
                        defaultValue={currentProvince}
                        placeholder="เลือกจังหวัด"
                        onChange={(value) => handleSelectCurrenctProvinceChange(value, field.name)}
                      />
                    )}
                  />
                  {errors.current_province_id && (
                    <div className="help-block">{errors.current_province_id.message}</div>
                  )}
                </Col>

                <Col md={6} className="mb-3">
                  <label htmlFor="current_district_id" className="">
                    เขต/อำเภอ
                  </label>
                  <Controller
                    name="current_district_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="อำเภอ/เขต"
                        isClearable
                        isDisabled={loadingProvince}
                        isLoading={loadingProvince}
                        isSearchable={true}
                        styles={selectStyles}
                        options={currentDistrictOptions}
                        defaultValue={currentDistrict}
                        placeholder="เลือกอำเภอ/เขต"
                        onChange={(value) => handleSelectCurrenctDistrictChange(value, field.name)}
                      />
                    )}
                  />
                  {errors.current_district_id && (
                    <div className="help-block">{errors.current_district_id.message}</div>
                  )}
                </Col>

                <Col md={6} className="mb-3">
                  <label htmlFor="current_subdistrict_id" className="">
                    แขวง/ตำบล
                  </label>
                  <Controller
                    name="current_subdistrict_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="ตำบล/แขวง"
                        isClearable
                        isDisabled={loadingProvince}
                        isLoading={loadingProvince}
                        isSearchable={true}
                        styles={selectStyles}
                        options={currentSubdistrictOptions}
                        defaultValue={currentSubDistrict}
                        placeholder="เลือกตำบล/แขวง"
                        onChange={(value) =>
                          handleSelectCurrentSubDistrictChange(value, field.name)
                        }
                      />
                    )}
                  />
                  {errors.current_subdistrict_id && (
                    <div className="help-block">{errors.current_subdistrict_id.message}</div>
                  )}
                </Col>

                <Col md={6} className="mb-3">
                  <label htmlFor="current_zipcode" className="">
                    รหัสไปรษณีย์
                  </label>
                  <input
                    placeholder="รหัสไปรษณีย์"
                    name='current_zipcode'
                    {...register('current_zipcode')}
                    value={
                      currentZipcode
                        ? currentZipcode
                        : studentData?.scholarship_student?.current_zipcode
                    }
                 
                    className="form-control"
                  />
                  {errors.current_zipcode && (
                    <div className="help-block">{errors.current_zipcode.message}</div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </div>

        <Col md={12} className={'ps-7 abroad ' + addressTypeAbroad}>
          <Row>
            <Col md={7} className=" mb-3 ">
              <label className="w-100">
                Address 1
                <input
                  type="text"
                  className="form-control font-16"
                  {...register('street_address1')}
                  placeholder="Address1"
                />
              </label>
            </Col>
            <Col md={5} className=" mb-3 " />
            <Col md={7} className=" mb-3 ">
              <label className="w-100">
                Address 2
                <input
                  type="text"
                  className="form-control font-16"
                  {...register('street_address2')}
                  placeholder="Address2"
                />
              </label>
            </Col>
            <Col md={5} className=" mb-3 " />
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Address
