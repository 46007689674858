import fontkit from "@pdf-lib/fontkit";
import { degrees, PDFDocument, rgb } from "pdf-lib";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Card, Container, Row, Col } from "react-bootstrap";
import Iframe from "react-iframe";
import useFetch from "../../utils/useFetch";
import petition_form from "../../assets/petition/form.pdf";
import TH_sarabun from "../../assets/fonts/thsarabunnew.ttf";

const Template = () => {
  const { id } = useParams();

  const { response: petitionData, loading } = useFetch(
    `/student/petition/${id}`
  );

  const [initData, setInitData] = useState({
    data: [],
    template: petition_form,
    pdfData: null,
    isLoading: true,
    isLodingInitData: false,
    petitionTypeName: "",
  });

  async function modifyPdf() {
    var option_pdf = {
      font_url: TH_sarabun,
      font_size: 10,
      font_rotage: degrees(-45), //สำหรับลายน้ำ
      font_color: rgb(0, 0, 0), //สีดำ
      //font_color: rgb(0.95, 0.1, 0.1),//สีแดง
      lineHeight: 12,
    };

    const font_url = option_pdf.font_url;
    const fontBytes = await fetch(font_url).then((res) => res.arrayBuffer());

    const url = initData.template;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const sarabunFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    //console.log('Total page: ' + pages.length)
    let page_setup = "";
    const { width, height } = pages[0].getSize(); // ขนาดกระดาษ
    //console.log("Page size (per page): " + width + " : " + height);

    for (let index = 0; index < pages.length; index++) {
      page_setup = pages[index];
      page_setup.setLineHeight(option_pdf.lineHeight);

      if (
        initData.data[index] !== undefined &&
        initData.data[index].length > 0
      ) {
        for (let i = 0; i < initData.data[index].length; i++) {
          let data = initData.data[index][i];
          if (data.value !== undefined) {
            let show_value = data.value;
            if (
              data.value === null ||
              data.value === undefined ||
              data.value === ""
            ) {
              show_value = "-";
            }
            page_setup.drawText(String(show_value), {
              x: data.x,
              y: data.y,
              leading: 1,
              size: data.size !== undefined ? data.size : option_pdf.font_size,
              font: sarabunFont,
              color: option_pdf.font_color,
            });
          }
        }
      }
    }

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    setInitData({ ...initData, pdfData: pdfDataUri });
  }

  async function loadTemplate() {
    let template_ = petition_form;
    let data_ = [];
    let template_form = "";
    let template_pdf = "";
    template_form = await import("../petition/template/petition/form");
    template_pdf = await import('../../assets/petition/form.pdf')

    template_ = template_pdf.default;
    data_ = template_form.SetDataForm(petitionData);

    let petition_type_name =petitionData.data.petition_type_name;

    setInitData({
      ...initData,
      template: template_,
      isLodingInitData: true,
      isLoading: false,
      data: data_,
      petitionTypeName: petition_type_name,
    });
  }
  useEffect(() => {
    if (petitionData) {
      if (initData.isLoading) {
        loadTemplate();
      } else {
        if (initData.isLodingInitData && initData.pdfData === null) {
          setInitData({
            ...initData,
            isLodingInitData: false,
            isLoading: false,
          });
          modifyPdf();
        }
      }
    }
  });


  return (
    <>
      <div className="contract">
        <section className="bg-light">
          <div className="container">
            {!loading ? (
              <Row>
                <Col md="12">
                  <Card className="card-plain table-plain-bg">
                    <Card.Body>
                      <div className="btn-group mb-3 float-right">
                        {/* <ButtonImport link={'/masterdata/bank/import'} /> */}
                        <Link
                          className="btn btn-primary"
                          to={`/petition-student-status/${id}`}
                        >
                          ย้อนกลับ
                        </Link>
                      </div>
                      <h4>แบบคำรองประเภท: {initData?.petitionTypeName}</h4>
                      {/* <Button onClick={(e) => modifyPdf()}>ทดสอบเติมคำลงใน pdf</Button> */}
                      <div className="p-3">
                        <Iframe
                          title="name pdf"
                          url={initData.pdfData}
                          width="100%"
                          height="800px"
                          display="initial"
                          position="relative"
                          className="p-3"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              <>
                <ReactLoading
                  type="bubbles"
                  color="#26b9d1"
                  className="mx-auto my-4"
                />
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Template;
