import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
// import ChartistGraph from "react-chartist";
// react-bootstrap components
import { Container, Row, Col, Form } from 'react-bootstrap'
import { AlertError, AlertSuccess } from '../../components/Alert'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { history } from '../../helpers/history'
import { faClipboardList, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import http from '../../utils/http'
const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`
const Upload = (props) => {
  const { id } = props
  const [files, setFiles] = useState([])
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }
  const hookFormMethods = useForm({
    //resolver: yupResolver(validateSchema),
    defaultValues: null,
  })
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    resetField,
  } = hookFormMethods

  const onError = (errors, e) => {
    alert('กรุณาระบุข้อมูลให้ครบถ้วน')
    console.log(errors)
  }
  const onSubmit = async (dataObj) => {
    // console.log(files)
    // return false;

    try {
      let dataPost = {
        files: files,
      }
      let urlActionCallAPI =  '/student/contract/document/create/' + id
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.status === 201) {
        AlertSuccess('อัปโหลดไฟล์เรียบร้อย')
        history.push('/contract/' + id)
      }else{
        AlertError('อัปโหลดไฟล์ไม่สำเร็จ')
      }
    } catch (error) {
      AlertError('อัปโหลดไฟล์ไม่สำเร็จ')
    }
  }

  function handleChange(event) {
    clearErrors('file')
    let input = event.target
    for (let index = 0; index < input.files.length; index++) {
      if (input.files[index].size > 10971520) {
        resetField('file')
        setError(
          'file',
          { type: 'validate', message: 'ขนาดไฟล์ต้องไม่เกิน 10 MB' },
          { shouldFocus: true }
        )
        event.target.value = ''
      } else {
        let fileExtension = ['PDF', 'pdf', 'jpeg', 'jpg', 'png', 'PNG']
        let name = input.files[index].name
        if (fileExtension.indexOf(name.split('.').pop().toLowerCase()) === -1) {
          resetField('file')
          setError(
            'file',
            {
              type: 'validate',
              message: 'เฉพาะไฟล์ PDF, pdf, jpeg, jpg, png ,PNG',
            },
            { shouldFocus: true }
          )
          event.target.value = ''
        }
      }
    }
  }

    const handleChangeFileDescription = (i, e) => {
      let newFileInput = [...files]
      newFileInput[i]['description'] = e?.target?.value
      setFiles(newFileInput)
    }

    const handleChangeFile = (e) => {
      let fileInput = e?.target?.files ?? null

      let newFileInput = [...files]
      let indexFile = files.length
      newFileInput[indexFile] = { file: fileInput[0], description: '' }

      setFiles(newFileInput)
    }
    const handleRemoveFile = (index, file) => {
      const newFiles = [...files]
      newFiles.splice(index, 1)
      setFiles(newFiles)
    }

    const thumbs = files?.map((item, index) => (
      <ListItem key={index + item.file.name} className={'success'}>
        <div className="upload-list-container">
          <div className="upload-list-item">
            <div className="upload-list-item-info">
              <span className="upload-span">
                <FontAwesomeIcon icon={faFileAlt} className="" />
                <span className="upload-list-item-name">
                  {item.file.name} ({item.file.size / 1024} KB)
                </span>

                <span className="upload-list-item-card-actions picture">
                  <button
                    title="Remove file"
                    type="button"
                    className="btn-item-card-actions-remove"
                    onClick={(e) => handleRemoveFile(index, e)}
                  >
                    <span role="img" aria-label="delete" className="anticon anticon-delete">
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="delete"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                      </svg>
                    </span>
                  </button>
                </span>
              </span>
            </div>
            <div className="upload-list-item-description">
              <div className="input-group">
                <span className="input-group-text">คำอธิบาย</span>
                <input
                  type="text"
                  name="description"
                  placeholder="คำอธิบาย"
                  className="form-control"
                  value={item.description || ''}
                  onChange={(e) => handleChangeFileDescription(index, e)}
                />
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    ))
  
  return (
    <>
      <div className="contract-update">
        <section className="bg-light">
          <Container fluid className="content container">
            <div className="card border-0 mb-3">
              <div className="">
                <Col className="d-md-flex pt-4 bg-white border-0">
                  <h5 className="title card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faFileInvoice} className="" />
                    </div>
                    <span className="font-prompt"> อัปโหลดเอกสารสัญญา </span>
                  </h5>
                </Col>
              </div>
              <div className="card-body">
                <h5>หลักฐานประกอบการทำสัญญาให้ทุนการศึกษาและสัญญาคํ้าประกัน</h5>
                <p className="help-block">
                  หมายเหตุ สำเนาหลักฐานที่ใช้ประกอบการทำสัญญาให้ทุน
                  และสัญญาคํ้าประกันทุกฉบับต้องรับรองสำเนาถูกต้อง พร้อมลงลายมือชื่อกำกับทุกฉบับ
                  โดยบุคคลที่เกี่ยวข้องกับหลักฐานที่นำมาใช้ (ต้องใช้เอกสารรายการละ 2 ชุด)
                </p>
                <ul class="list-group">
                  <li class="list-group-item active">1) สัญญาให้ทุนการศึกษา</li>
                  <li class="list-group-item">
                    <b>หลักฐานของผู้รับทุน</b>
                  </li>
                  <li class="list-group-item">- รูปถ่ายขนาด 2 นิ้ว ถ่ายไว้ไม่เกิน 6 เดือน</li>
                  <li class="list-group-item">- สำเนาบัตรประจำตัวประชาชน</li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้าน</li>
                  <li class="list-group-item">- สำเนาสูติบัตร</li>
                  <li class="list-group-item">
                    - สำเนาใบเปลี่ยนชื่อ สำเนาใบเปลี่ยนนามสกุล (ถ้ามี)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานสำหรับผู้รับทุนที่จดทะเบียนสมรส</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาบัตรประจำตัวประชาชน หรือสำเนาบัตรข้าราชการ หรือพนักงานของรัฐของคู่สมรส
                  </li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้านของคู่สมรส</li>
                  <li class="list-group-item">- สำเนาใบสำคัญการจดทะเบียนสมรส</li>
                  <li class="list-group-item">
                    - สำเนาใบเปลี่ยนชื่อ สำเนาใบเปลี่ยนนามสกุล ของคู่สมรส (ถ้ามี)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานอื่นๆ (เฉพาะกรณี)</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาทะเบียนรับรองบุตร (สำหรับกรณีที่ ผู้รับทุนที่อายุไม่ครบ 20 ปีบริบูรณ์
                    ที่บิดามารดาไม่ได้ จดทะเบียนสมรสกัน
                    แต่บิดาจะลงลายมือชื่อในช่องผู้ปกครอง/ผู้แทนโดยชอบธรรมในสัญญาฯ)
                  </li>
                  <li class="list-group-item">
                    - สำเนาทะเบียนรับบุตรบุญธรรม (สำหรับกรณีที่ผู้รับทุนเป็นบุตรบุญธรรม)
                  </li>
                  <li class="list-group-item">
                    - สำเนาคำสั่งศาล (สำหรับผู้รับทุนที่อายุไม่ถึง 20 ปีบริบูรณ์ และบิดา
                    มารดาเลียชีวิต หรือบิดา มารดา ถูกศาลถอดอำนาจปกครอง
                    ผู้ลงลายมือชื่อเป็นผู้ปกครองในสัญญาให้ทุน จะต้องเป็นบุคคลซึ่งศาลตั้งให้ใช้ อำนาจ
                    ปกครอง)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานของพยานฝ่ายผู้รับทุน</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาบัตรประจำตัวประชาชน หรือสำเนาบัตรข้าราชการ หรือพนักงานของรัฐ
                  </li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้าน</li>
                  <li class="list-group-item active">2) สัญญาคํ้าประกัน</li>
                  <li class="list-group-item">
                    <b>หลักฐานของผู้คํ้าประกัน</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาบัตรประจำตัวประชาชน หรือสำเนาบัตรข้าราชการ หรือพนักงานของรัฐ
                  </li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้าน</li>
                  <li class="list-group-item">
                    - สำเนาใบเปลี่ยนชื่อ สำเนาใบเปลี่ยนนามสกุล (ถ้ามี)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานเพิ่มเติมสำหรับผู้คํ้าประกันที่มีสถานะจดทะเบียนสมรส</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาบัตรประจำตัวประชาชน หรือสำเนาบัตรข้าราชการ หรือพนักงานของรัฐของคู่สมรส
                  </li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้านของคู่สมรส</li>
                  <li class="list-group-item">- สำเนาใบสำคัญการจดทะเบียนสมรส</li>
                  <li class="list-group-item">
                    - สำเนาใบเปลี่ยนชื่อ สำเนาใบเปลี่ยนนามสกุลของคู่สมรส (ถ้ามี)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานเพิ่มเติมสำหรับผู้ประกันที่มีสถานะจดทะเบียนหย่า</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาใบสำคัญการหย่า (คร.7) และสำเนาทะเบียนหย่า (คร.6)
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานเพิ่มเติมสำหรับผู้คํ้าประกันที่มีสถานะคู่สมรสเสียชีวิต</b>
                  </li>
                  <li class="list-group-item">- สำเนาใบสำคัญการจดทะเบียนสมรส</li>
                  <li class="list-group-item">- สำเนาใบมรณบัตรของคู่สมรสของผู้คํ้าประกัน</li>
                  <li class="list-group-item">
                    <b>
                      หลักฐานเพิ่มเติมสำหรับผู้คํ้าประกันทีไม่ได้เป็นบิดา หรือมารดาของผู้รับทุน
                      หรือข้าราชการ หรือพนักงานของรัฐ ตั้งแต่ระดับชำนาญการ หรือ ระดับ 7 ขึ้นไป
                    </b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาหลักทรัพย์ เช่น พันธบัตร สลากออมสิน ใบหุ้น เป็นต้น
                  </li>
                  <li class="list-group-item">
                    - และ/หรือ สำเนาโฉนดที่ดิน พร้อมหนังสือประเมินราคาที่ดิน
                  </li>
                  <li class="list-group-item">
                    <b>หลักฐานของพยานฝ่ายผู้คํ้าประกัน</b>
                  </li>
                  <li class="list-group-item">
                    - สำเนาบัตรประจำตัวประชาชน หรือสำเนาบัตรข้าราชการ หรือพนักงานของรัฐ
                  </li>
                  <li class="list-group-item">- สำเนาทะเบียนบ้าน</li>
                </ul>
              </div>
              <div className="card-body">
                <Form
                  encType="multipart/form-data"
                  onSubmit={hookFormMethods.handleSubmit(onSubmit, onError)}
                >
                  <Col md={12} className="mt-3">
                    <div className="form-group field-petitionexpense-0-files">
                      <label> ไฟล์เอกสาร </label>
                      <div className="file-caption">
                        <div className="upload-select">
                          <div className="d-flex justify-content-start">
                            <div className="btn-file-upload">
                              <span role="img" aria-label="upload" className="icon-upload">
                                <svg
                                  viewBox="64 64 896 896"
                                  focusable="false"
                                  data-icon="upload"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                </svg>
                              </span>
                              <span>Upload</span>
                              <input
                                type="file"
                                // style={{ display: "none" }}
                                //  className={"btn-input-upload"}
                                id="icon-button-file"
                                {...register('file')}
                                onChange={(e) => handleChangeFile(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <aside style={thumbsContainer} className="upload-list">
                          {thumbs}
                        </aside>
                      </div>

                      <span className="text-danger font-size-xs">*ขนาดไฟล์แนะนำไม่เกิน 10 MB</span>
                      {errors.file && <div className="help-block">{errors.file.message}</div>}
                    </div>
                  </Col>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      บันทึก
                    </button>{' '}
                  </div>
                </Form>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  )
}

export default Upload
