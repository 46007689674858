import React from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const STATUS_PENDING = 'pending'
export const STATUS_APPROVE = 'approved'
export const STATUS_REJECT = 'rejected'
export const STATUS_CANCEL = 'canceled'

export const statusHelper = {
  isPending: (status) => status === STATUS_PENDING,
  isApproved: (status) => status === STATUS_APPROVE,
  isReject: (status) => status === STATUS_REJECT,
  isCancelled: (status) => status === STATUS_CANCEL,

  getColor: (status) => {
    const statusMapper = {
      [STATUS_PENDING]: 'warning',
      [STATUS_APPROVE]: 'success',
      [STATUS_REJECT]: 'danger',
      [STATUS_CANCEL]: 'danger',
    }[status]

    return statusMapper || ''
  },

  getText: (status) => {
    const statusMapper = {
      [STATUS_PENDING]: 'รออนุมัติ',
      [STATUS_APPROVE]: 'อนุมัติ',
      [STATUS_REJECT]: 'ไม่อนุมัติ',
      [STATUS_CANCEL]: 'ยกเลิก',
    }[status]

    return statusMapper || ''
  },

  getLabel: (status, note) => {
    return (
      <>
        <div className={`text-${statusHelper.getColor(status)} align-middle`}>
          <FontAwesomeIcon icon={faCircle} className="pe-1" />
          {statusHelper.getText(status)}
          {note && <p className="text-secondary fs-14"> {note} </p>}
        </div>
      </>
    )
  },
}
