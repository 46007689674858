import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation, Route, Switch } from 'react-router-dom'

import Routes from '../config/routes-notlogged.js'
 
import HeaderLogged from "../components/layouts/auth/header/Header";
import Header from "../components/layouts/header/Header";
import FooterHome from "../components/layouts/footer/FooterHome";
import Footer from "../components/layouts/footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import  AuthContext from '../store/auth-context';

const MainNotlogged = () => {

  const authCtx = useContext(AuthContext);

  const isLogged = authCtx.isLoggedIn;
  

  const [windowsSize, setWindowSize] = useState(0)
  let location = useLocation()
  const getTitleName = () => {
    for (let i = 0; i < Routes.length; i++) {
      if (location.pathname.indexOf(Routes[i].path) !== -1) {
        return Routes[i]
      }
    }
    return []
  }

  let title = getTitleName()

  const mainPanel = React.useRef(null)

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route exact path={prop.path} render={(props) => <prop.component {...props} />} key={key} />
      )
    })
  }
  
  // useEffect(() => {
  //   let pageWrapper = document.getElementsByClassName('page-wrapper')[0]
  //   let windowHeight = window.document.documentElement.clientHeight
  //   pageWrapper.style.minHeight = windowHeight + 'px'
  //   window.onresize = function (event) {
  //     let windowHeight = window.document.documentElement.clientHeight
  //     setWindowSize(windowHeight)
  //     pageWrapper.style.minHeight = windowHeight
  //   }
  // }, [windowsSize])

  let footer = <FooterHome />;
  let breadcrumb = '';
  let bgBody = 'body';
  if(location.pathname !== "/"){

    footer = <Footer />;
    // breadcrumb = <nav aria-label="breadcrumb">
    //   <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
    //     <li className="breadcrumb-item">
    //       <Link to="/">
    //         <FontAwesomeIcon icon={faHome} />
    //       </Link>
    //     </li>
        
    //     <li className="breadcrumb-item active font-sarabun" aria-current="page">
    //       {title}
    //     </li>
    //   </ol>
    // </nav>;
    bgBody = 'bg-light';

  }

  return (
    <> 
      <div className={"d-flex flex-column h-100 " + bgBody}>
      { isLogged ? <HeaderLogged authCtx={authCtx} /> : <Header />}
        
        <main role="main" className="flex-shrink-0">

          { breadcrumb }

          <Switch>{getRoutes(Routes)}</Switch>
        </main>


        { footer }
      </div> 
    </>
  )
}

export default MainNotlogged
