import React, { useState, useRef, useEffect } from "react";
import Skeleton from "react-loading-skeleton"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faDownload,
  faEdit,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Container,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom'
import useFetch from "../../utils/useFetch";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../utils/http";
import axios from "axios";
import { history } from "../../helpers/history";

import Select from "react-select";
import styled from "styled-components";
import Loading from "../Loading";
const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`;

const BankAccountUpdate = (props) => {
  
  const { id } = useParams();
    const { response: bankAccountData, loading } = useFetch(
      `/student/bank-account/view?id=${id}`
    );
    
  const user = JSON.parse(localStorage.getItem("user"));
  // const timezone = user.timezone;//'Asia/Bangkok';
  // const yearType = user.yearType;
  // const avatar = user.avatar;
  // const fullname = user.fullname;
  //const { errors } = formState

  const [files, setFiles] = useState([]);
  const [resultValidate, setResultValidate] = useState([]);
  const bankRef = useRef(null);
  const typeRef = useRef(null);
  const urlAction = "/bank-account";
  // const urlActionCallAPI = `/student/bank-account/update?id=${id}`;
  const urlActionCallAPI = "/student/bank-account/create";
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const [isLoading, setIsLoading] = useState(false);
  const { response: responseBank } = useFetch(`/student/masterdata/bank`);
  //const { id } = useParams()

  const validationSchema = yup.object().shape({
    account_name: yup.string().required("ชื่อบัญชี ต้องไม่เป็นค่าว่าง"),
    account_number: yup.string().required("เลขที่บัญชี ต้องไม่เป็นค่าว่าง"),
    bank_id: yup.string().required("ชื่อธนาคาร ต้องไม่เป็นค่าว่าง"),
    account_type: yup.string().required("ประเภทบัญชี ต้องไม่เป็นค่าว่าง"),
    bank_branch: yup.string().required("สาขา ต้องไม่เป็นค่าว่าง"),
    //account_file: yup.string().required('แนบไฟล์ ต้องไม่เป็นค่าว่าง'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  
  //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState,
    setValue,
    getValues,
  } = useForm(formOptions);

  const { errors } = formState;

  const onSubmit = async (dataObj) => {
    setIsLoading(true);
    if (dataObj) {
      const bank = bankRef.current.getValue();
      const type = typeRef.current.getValue();

      let dataPost = { 
                      account_name: dataObj.account_name,
                      account_number: dataObj.account_number,
                      bank_branch: dataObj.bank_branch, 
                      bank_id: dataObj.bank_id, 
                      account_type: dataObj.account_type, 
                      files: files
                    };   
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost);
      
      if(dataRes.message === 'Accepted'){
        setIsLoading(false); 
        history.push(`${urlAction}`)
      }
  
    }

  }


  const optionBank = responseBank?.map((item, index) => {
    return {
      value: `${item.ID}`,
      label: `${item.name_th}`,
    };
  });

  const optionType = [
    { value: "savings", label: "ออมทรัพย์" },
    { value: "fixed_deposit", label: "ฝากประจำ" },
    { value: "current", label: "กระแสรายวัน" },
  ];

  const selectedBank = (bank) => {
    setValue("bank_id", bank.value);
  };
  const selectedBankType = (type) => {
    setValue("account_type", type.value);
  };

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files];
    newFileInput[i]["description"] = e?.target?.value;
    setFiles(newFileInput);
  };

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null;

    let newFileInput = [...files];
    let indexFile = files.length;
    newFileInput[indexFile] = { file: fileInput[0], description: "" };

    let fileSize =
      files.length != 0
        ? parseFloat(files[0].size / 1024 / 1024).toFixed(1)
        : 0; // in MB

    if (fileInput.length === 0) {
      setError("file", {
        message: "กรุณาอัปโหลดไฟล์",
      });
      return false;
    }

    if (!fileInput[0].name.match(/\.(pdf)$/)) {
      setError("file", {
        type: "manual",
        message: "ไฟล์นามสกุล .pdf เท่านั้น",
      });
      return false;
    }
    if (fileSize > 10) {
      setError("file", {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      });
      return false;
    }

    clearErrors("file");

    if (!errors.file) {
      setFiles(newFileInput);
    }
  };

  const handleRemoveFile = (index, file) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={"success"}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span
                    role="img"
                    aria-label="delete"
                    className="anticon anticon-delete"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ""}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ));

  if (!isLoading && bankAccountData && optionBank) {
    setValue("account_name", bankAccountData?.account_name);
    setValue("account_number", bankAccountData?.account_number);
    setValue("bank_id", bankAccountData?.bank_id);
    setValue("account_type", bankAccountData?.account_type);
    setValue("bank_branch", bankAccountData?.bank_branch);
   // setValue("account_name", bankAccountData?.student_id);
  }

  return (
    <div className="bank-create">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header">
              <div className="d-md-flex pt-4 bg-white border-0 ps-0 w-100 mw-800 my-0 mx-auto">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto texr-start ps-0">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>แก้ไขข้อมูลธนาคาร </span>
                  </h5>
                </div>
              </div>
            </div>
            {!loading && optionBank ? (
              <div className="card-body">
                <div className="bank-form w-auto mw-800 m-auto ">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={12} className="">
                        <div className="form-group required">
                          <label
                            className="control-label"
                            htmlFor="account_name"
                          >
                            ชื่อบัญชี
                          </label>
                          <input
                            type="text"
                            {...register("account_name")}
                            id="account_name"
                            className="form-control font-16"
                            placeholder="ชื่อบัญชี"
                            aria-required="true"
                          />
                          {errors.account_name && (
                            <div className="help-block">
                              {errors.account_name.message}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md={12} className="">
                        <div className="form-group">
                          <label
                            className="control-label"
                            htmlFor="account_number"
                          >
                            เลขที่บัญชี
                          </label>
                          <input
                            type="text"
                            {...register("account_number")}
                            id="account_number"
                            className="form-control font-16"
                            placeholder="เลขที่บัญชี"
                            aria-required="true"
                          />
                          {errors.account_number && (
                            <div className="help-block">
                              {errors.account_number.message}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <label
                          htmlFor="bank_id "
                          className="label-with-required"
                        >
                          ชื่อธนาคาร
                        </label>
                        <Select
                          {...register("bank_id")}
                          isMulti={false}
                          options={optionBank}
                          ref={bankRef}
                          onChange={selectedBank}
                          defaultValue={
                            optionBank.filter(function (option) {
                              return (
                                option.value === String(getValues("bank_id"))
                              );
                            })[0]
                          }
                        />
                        {errors.bank_id && (
                          <div className="help-block">
                            {errors.bank_id.message}
                          </div>
                        )}
                      </Col>

                      <Col md={6}>
                        <label
                          htmlFor="account_type"
                          className="label-with-required"
                        >
                          ประเภทบัญชี
                        </label>
                        <Select
                          {...register("account_type")}
                          isMulti={false}
                          options={optionType}
                          ref={typeRef}
                          onChange={selectedBankType}
                          defaultValue={
                            optionType.filter(function (option) {
                              return (
                                option.value ===
                                String(getValues("account_type"))
                              );
                            })[0]
                          }
                        />
                        {errors.account_type && (
                          <div className="help-block">
                            {errors.account_type.message}
                          </div>
                        )}
                      </Col>
                      <Col md={12} className="mt-4">
                        <div className="form-group">
                          <label
                            className="control-label required"
                            htmlFor="bank_branch"
                          >
                            สาขา
                          </label>
                          <input
                            type="text"
                            {...register("bank_branch")}
                            id="bank_branch"
                            className="form-control font-16"
                            placeholder="สาขา"
                            aria-required="true"
                          />
                          {errors.bank_branch && (
                            <div className="help-block">
                              {errors.bank_branch.message}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={12} className="mb-3">
                        <label htmlFor="file" className="label-with-required">
                          แนบไฟล์
                        </label>
                        <div className="file-caption">
                          <div className="upload-select">
                            <span className="ant-upload" role="button">
                              <input
                                type="file"
                                style={{ display: "none" }}
                                className="btn-input-upload"
                                {...register("file")}
                                onChange={(e) => handleChangeFile(e)}
                                id="icon-button-file"
                              />
                              <label
                                htmlFor="icon-button-file"
                                className="btn-upload"
                              >
                                <span
                                  role="img"
                                  aria-label="upload"
                                  className="icon-upload"
                                >
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="upload"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload</span>
                              </label>
                            </span>
                          </div>

                          <aside
                            style={thumbsContainer}
                            className="upload-list"
                          >
                            {thumbs}
                          </aside>
                        </div>
                        <span className="text-secondary  font-size-xs">
                          ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น
                        </span>
                        {errors.file && (
                          <div className="help-block">
                            {errors.file.message}
                          </div>
                        )}
                      </Col>

                      <Col md={12} className="mb-3">
                        <div className="form-group pt-3 text-end">
                          <a
                            className="btn btn-outline-primary btn-add mx-3"
                            href="/bank-account"
                          >
                            ยกเลิก
                          </a>

                          {!isLoading ? (
                            <button type="submit" className="btn btn-primary">
                              บันทึก
                            </button>
                          ) : (
                            <button type="submit" className="btn btn-primary">
                              กำลังบันทึก...
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            ) : (
              <>
                <Loading />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BankAccountUpdate;
