import React, { useState, useCallback,useEffect, useRef, useContext } from "react";
import { yupResolver } from '@hookform/resolvers/yup'
import logo from '../../assets/images/logo/static_IPST_Logo.png'; 
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { history } from '../../helpers/history' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import http from '../../utils/http'
import axios from 'axios'
import publicIp from "public-ip";
import NotFound404 from '../NotFound404'
import useFetch2 from '../../utils/useFetch2'; 
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";



const ResetPassword = (props) => {

  useEffect(() => {
    document.title = 'รีเซ็ตรหัสผ่าน';
  });

  let token = props.match.params.token; 

  const validationSchema = yup.object().shape({
    password: yup.string()
      .required('รหัสผ่านใหม่ ต้องไม่เป็นค่าว่าง')
      .min(6, 'รหัสผ่านใหม่ ต้องไม่น้อยกว่า 6 ตัว'),
    confirmPassword: yup.string()
      .required('ยืนยันรหัสผ่านใหม่ต้องไม่เป็นค่าว่าง')
      .oneOf([yup.ref('password')], 'รหัสผ่านไม่ตรงกัน')
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, reset, setError, formState } = useForm(formOptions)
  const { errors } = formState
  const [ isNotFound, setIsNotFound ] = useState(true);
  const [ isLoadingReset, setIsLoadingReset ] = useState(false);
  const [ isSubmitSendMail, setIsSubmitSendMail ] = useState(false);
  const [ isSendMail, setIsSendMail ] = useState({});
  // const apiKey = process.env.REACT_APP_API_KEY
  // const apiVersion = process.env.REACT_APP_API_VERSION
  const baseUrl = process.env.REACT_APP_API_URL
 

  const resetValidate = useCallback( async() => {
    try {
      let formData = new FormData(); //formdata object

      formData.append("token", token); //append the values with key, value pair

      const publicIpAddress = await publicIp.v4();

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          device: publicIpAddress,
        },
      };

      const response = await fetch(baseUrl + "/student/user/reset-validate", {
        method: "POST",
        body: formData,
        config,
      });

      if (response.ok) {
        const data = await response.json();  
        setIsLoadingReset(true)

        if(data.status == 404){

          setIsNotFound(true);
        }else if(data.status == 200){

          setIsNotFound(false);
        }

      }
        
    } catch (error) { 
      console.log(error);
    } 
  }, []);

  useEffect(() => {
    resetValidate()
  }, [])

  const resetPassword = useCallback(async (credentials) => {
    // setError(null);
    try {
      let formData = new FormData(); //formdata object

      formData.append("password",credentials.password); //append the values with key, value pair
      formData.append("confirm_password",credentials.confirmPassword);
      formData.append("token", credentials.token);

      const publicIpAddress = await publicIp.v4();

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          device: publicIpAddress,
        },
      };

      const response = await fetch(baseUrl + "/student/user/reset", {
        method: "POST",
        body: formData,
        config,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.status);
        if(data.status == 204){

          setIsSendMail({'status':'warning','msg': data.data});
        }else if(data.status == 200){

          setIsSendMail({'status':'success','msg':"ระบบได้รีเซ็ตรหัสผ่านของคุณเรียบร้อยแล้ว"});
        }else if(data.status == 404){

        setIsLoadingReset(true)
        }
      }
  
    } catch (error) { 
      console.log(error);
    } 
  }, []);


  const onSubmit = async (data) => {
    if(data){

      let dataPost = { 
        password: data.password,
        confirmPassword: data.confirmPassword,
        token: token,
      };  

      resetPassword(dataPost);
      
    }
  }

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  
  const [passwordInput, setPasswordInput] = useState("");

  const togglePassword =()=>{
    if(passwordType==="password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }


  const toggleConfirmPassword =()=>{
    if(confirmPasswordType==="password") {
      setConfirmPasswordType("text")
      return;
    }
    setConfirmPasswordType("password")
  }

  return (
    <>
       
    
    {
      isLoadingReset ? (
      <>
      {
        isNotFound ? (
          <NotFound404/>
        ):(
          <> 
            {
              isSendMail?.status ? (
                <section className="py-3"       
                style={{
                  backgroundColor: '#f7f7f7',
                }}>
                  <div className="container">
                    <div className={"alert alert-"+isSendMail?.status+"-soft mb-0"} role="alert">
                      <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>  
                    { isSendMail?.msg }
                    </div>
                  </div>
                </section>
              ):(
                <> </>
              )
            }
     

              <> 
              <div
                  className="account-page forget-password-page"
                  style={{
                    fontSize: '.9375rem',
                    fontWeight: 1.5,
                    color: '#1f1f1f',
                    backgroundColor: '#f7f7f7',
                    minHeight: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  <div className="main-wrapper">
                    <div className="account-content">

                      <div className="container">
                      
                        <div className="account-logo text-center">
                          <img src={logo} width={100} alt="สสวท." className="m-auto"/>
                          <h4 className="mt-3">ระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา</h4>
                        </div>
                        <div className="account-box">
                          <div className="account-wrapper">
                            <div className="account-title mb-3">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                  <h3 className="account-title">ลืมรหัสผ่าน</h3>
                                  <p className="account-subtitle " style={{ fontSize: '14px' }}>
                                    ป้อนรหัสผ่านใหม่เพื่อรีเซ็ตรหัสผ่านของคุณ
                                  </p>
                                  <Row className="text-left">
                                    <Col md={12} className="mb-3">
                                  

                                      {/* <input
                                        type="password"
                                        placeholder="รหัสผ่านใหม่"
                                        {...register('password')}
                                        className="form-control"
                                      /> */}
                                      <label htmlFor="password" className="label-with-required">
                                        รหัสผ่านใหม่
                                      </label>
                                      <div className="input-group d-flex justify-content-start">
                                   
                                          <input 
                                            type={passwordType} 
                                            {...register('password')}
                                           // value={passwordInput} 
                                            name="password" 
                                            className="form-control input-password" 
                                            placeholder="รหัสผ่านใหม่"/>

                                          <div className="input-group-btn">
                                            <button 
                                              type="button" 
                                              className="btn btn-default btn-show-password" 
                                              onClick={togglePassword} >
                                            { passwordType==="password"? <FontAwesomeIcon icon={faEye} className=""/> :<FontAwesomeIcon icon={faEye} className=""/> }
                                            </button>
                                          </div>
                                      </div>
                                      {errors.password && <div className="help-block">{errors.password.message}</div>}


                                    </Col>
                                    <Col md={12} className="mb-3">
                                      <label htmlFor="confirmPassword" className="label-with-required">
                                      ยืนยันรหัสผ่าน
                                      </label>
                                      {/* <input
                                        type="password"
                                        placeholder="ยืนยันรหัสผ่าน"
                                        {...register('confirmPassword')}
                                        className="form-control"
                                      /> */}
                                        <div className="input-group  d-flex justify-content-start">
                                   
                                          <input 
                                            type={confirmPasswordType} 
                                            {...register('confirmPassword')}
                                            //value={passwordInput} 
                                            name="confirmPassword" 
                                            className="form-control input-password" 
                                            placeholder="รหัสผ่านใหม่"/>

                                          <div className="input-group-btn">
                                            <button 
                                              type="button" 
                                              className="btn btn-default btn-show-password" 
                                              onClick={toggleConfirmPassword} >
                                            { confirmPasswordType==="password"? <FontAwesomeIcon icon={faEye} className=""/> :<FontAwesomeIcon icon={faEye} className=""/> }
                                            </button>
                                          </div>
                                      </div>
                                      {errors.confirmPassword && <div className="help-block">{errors.confirmPassword.message}</div>}
                                    </Col>

                                    <Col md={12} className="mb-3">
                                    {
                                      isSubmitSendMail ? (
                                        <button type="submit" className="btn account-btn text-white" disabled>
                                          กำลังบันทึก...
                                        </button>
                                      ):(
                                        <button type="submit" className="btn account-btn text-white">
                                          ยืนยัน
                                        </button>
                                      )
                                    }
                                      
                                    </Col>
                                    {errors.apiError && (
                                      <div className="alert alert-danger mt-3 mb-0">
                                        {errors.apiError?.message}
                                      </div>
                                    )}
                                  </Row>
                                </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

            
          
          </>
        )
      }
      </>
    ) : (
      <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
    )
  }

  </> 

   
  )
}

export default ResetPassword
