import Skeleton from 'react-loading-skeleton'

const Details = ({ data, loading }) => {
  const DetailData = ({ data }) => {
    return data?.map((item, index) => {
      return (
        <>
          <tr>
            <td>{item?.label}</td>
            <td className="w-60">{item?.value}</td>
          </tr>
        </>
      )
    })
  }

  const Loading = ({ length }) => {
    return Array(length)
      .fill(0)
      .map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        )
      })
  }

  return (
    <>
      <table className="table">
        <tbody>
          {!loading ? (
            <>
              <DetailData data={data} />
            </>
          ) : (
            <>
              <Loading length={data?.length} />
            </>
          )}
        </tbody>
      </table>
    </>
  )
}

export default Details
