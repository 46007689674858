import { faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { Card, Table } from 'react-bootstrap'
import { CardLoading } from '../../components/Skeleton'

const Education = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <>
          <Card className="border-0 mb-3">
            <Card.Body>
              <h5 className="card-title">
                <div className="bg-icon">
                  <FontAwesomeIcon icon={faUserGraduate} className="" />
                </div>
                <span>ข้อมูลการศึกษา</span>
              </h5>
              <div className="table-responsive">
                {data ? (
                  <table id="agency" className="table">
                    <tbody>
                      <tr>
                        <td>สถานศึกษา</td>
                        <td>{data?.institution_name_th}</td>
                      </tr>
                      <tr>
                        <td>ระดับการศึกษา</td>
                        <td>{data?.education_level_name_th}</td>
                      </tr>
                      <tr>
                        <td>คณะ / สาขาวิชา</td>
                        <td>
                          {data?.faculty} {data?.subject_field}
                        </td>
                      </tr>
                      { data?.class_year ? (
                        <tr>
                          <td>ชั้นปี</td>
                          <td>{data?.class_year}</td>
                        </tr>
                      ):(
                        <></>
                      )}
                      { data?.semester ? (
                        <tr>
                          <td>ภาคเรียน</td>
                          <td>{data?.semester}</td>
                        </tr>
                      ):(
                        <></>
                      )}
                      { data?.academic_year_bc ? (
                        <tr>
                          <td>ปีการศึกษา</td>
                          <td>{data?.academic_year_bc}</td>
                        </tr>
                      ):(
                        <></>
                      )}
                   
                    </tbody>
                  </table>
                ) : (
                  <Table className="table">
                    <tbody>
                      <tr>
                        <td valign="top" colSpan="100%" className="text-center">
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <CardLoading />
        </>
      )}
    </>
  )
}

export default Education
