import {
  faBriefcase,
  faBusinessTime,
  faCalendarCheck,
  faChalkboardTeacher,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Status = ({ data }) => {
  return (
    <>
      <div className="card border-0 mb-3">
        <div className="card-body">
          <h5 className="card-title text-center mb-3">สถานะการรับทุนของนักเรียนทุน</h5>
          <div id="step">
            <ul className="w-100 steps ps-0">
              <li className="step step-first">
                <a href="#" className={'bullet ' + data?.status[1]}>
                  <FontAwesomeIcon icon={faClipboardList} className="font-28" />
                </a>
                <span>ทำสัญญารับทุน</span>
              </li>
              <li className="step">
                <a href="#" className={'bullet ' + data?.status[2]}>
                  <FontAwesomeIcon icon={faChalkboardTeacher} className="font-28" />
                </a>
                <span>กำลังศึกษา</span>
              </li>
              <li className="step">
                <a href="#" className={'bullet ' + data?.status[3]}>
                  <FontAwesomeIcon icon={faBusinessTime} className="font-28" />
                </a>
                <span>รอบรรจุ</span>
              </li>
              <li className="step">
                <a href="#" className={'bullet ' + data?.status[4]}>
                  <FontAwesomeIcon icon={faBriefcase} className="font-28" />
                </a>
                <span>ปฏิบัติงานตอบแทนทุน</span>
              </li>
              <li className="step">
                <a href="#" className={'bullet ' + data?.status[5]}>
                  <FontAwesomeIcon icon={faCalendarCheck} className="font-28" />
                </a>
                <span>ปฏิบัติงานครบตามสัญญาทุน</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Status
