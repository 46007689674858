import React, { Fragment, useState, useRef,forwardRef, useCallback } from 'react'; 

//import PortfolioFormCreate from '../../components/portfolio/PortfolioCreate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { 
    faCampground,
    faBriefcase,
    faHome,
    faFileAlt
  } from "@fortawesome/free-solid-svg-icons";
import { Card, Table, Container, Form, Row, Col, Spinner,Modal } from 'react-bootstrap'; 
import { useForm } from 'react-hook-form';
import useFetch from '../../utils/useFetch'; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import http from '../../utils/http'; 
import { dateHelper } from '../../helpers/date.helper'
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton' 
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import addDays from 'date-fns/addDays'
import { DateButton, IconCalendar, DatePickerWrapperStyles } from '../../components/Filter/styles'
import _ from '../../utils/utils'

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`


const RetitutionView = (props) =>  {
    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: '#e3e3e3',
          paddingRight: '0',
          paddingLeft: '0',
          '&:hover': {
            color: '#e3e3e3',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
          color: state.isFocused || state.isSelected ? '#fff' : '#000',
          '&:active': {
            backgroundColor: '#0d3996',
            color: '#fff',
          },
        }),
        menu: (provided, state) => ({
          ...provided,
          fontWeight: 500,
          paddingTop: '0',
          paddingBottom: '0',
        }),
        control: (state) => ({
          fontWeight: 500,
          fontSize: '15px',
          display: 'flex',
          backgroundColor: '#fff',
          height: '44px',
          borderRadius: '4px',
          border: '1px solid #e3e3e3',
          boxShadow: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: '#e3e3e3',
          paddingRight: '4px',
          paddingLeft: '0',
          border: 'none',
        }),
    
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1
          const transition = 'opacity 300ms'
    
          return { ...provided, opacity, transition }
        },
    }
 
    const [isLoading, setIsLoading] = useState(false);
    //const [error, setError] = useState(null);
 
    const { response: restitutionData, loading } = useFetch('/student/restitution')
    const [calculateing, setCalculateing] = useState(false)
    const [restitutionValue, setRestitutionValue] = useState(true) 
    const [dataResponseLoading, setDataResponseLoading] = useState(false)
    const [dataResponse, setDataResponse] = useState({})

    const urlAction = "/retitution";
    const urlActionCallAPI = "/student/restitution/calculate";
    //const { id } = useParams()

    const validationSchema = yup.object().shape({
       
    restitution_date: yup.string().required('วันที่ต้องไม่ว่าง'),
    })

    const formOptions = {
        resolver: yupResolver(validationSchema),
    }

    //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
    const { register, handleSubmit, reset, clearErrors, setError, formState, setValue } = useForm(formOptions)

    const { errors } = formState

    if(!loading && restitutionValue === true){

      if(restitutionData?.summary?.years !== undefined){
        setRestitutionValue(false);
      }
  
      if ( restitutionData?.summary?.years !== undefined) {
        setValue('yearWorking', restitutionData?.summary?.years);
      }
  
      if ( restitutionData?.summary?.months !== undefined) {
        setValue('monthWorking', restitutionData?.summary?.months);
      }
  
      if ( restitutionData?.summary?.days !== undefined) {
        setValue('dayWorking', restitutionData?.summary?.days);
      }
      
      if ( restitutionData?.worked?.years !== undefined) {
        setValue('yearWorked', restitutionData?.worked?.years);
      }
  
      if ( restitutionData?.worked?.months !== undefined) {
        setValue('monthWorked', restitutionData?.worked?.months);
      }
  
      if ( restitutionData?.worked?.days !== undefined) {
        setValue('dayWorked', restitutionData?.worked?.days);
      }
  
      if ( restitutionData?.remaining?.years !== undefined) {
        setValue('yearBalanceWork', restitutionData?.remaining?.years);
      }
  
      if ( restitutionData?.remaining?.months !== undefined) {
        setValue('monthBalanceWork', restitutionData?.remaining?.months);
      }
  
      if ( restitutionData?.remaining?.days !== undefined) {
        setValue('dayBalanceWork', restitutionData?.remaining?.days);
      }
      
    }


  const DetailExpense = ({dataExpenses}) =>  {

    return (
      <>
        <div class="text-end mb-4">
        <p class="mb-1">(ประมาณการ) ระยะเวลาปฏิบัติงานตอบแทนทุนทั้งหมด:  { dataExpenses?.total?.diff_date ? dataExpenses?.total?.diff_date : ' 0 ปี 0 เดือน 0 วัน '} </p>                           
        <p class="mb-1">(ประมาณการ) ระยะเวลาที่ปฏิบัติงานแล้ว: { dataExpenses?.worked?.diff_date ?  dataExpenses?.worked?.diff_date : '0 ปี 0 เดือน 0 วัน'} </p>                     
        <p class="mb-1">(ประมาณการ) ระยะเวลาปฏิบัติงานคงเหลือ: { dataExpenses?.balance?.diff_date ? dataExpenses?.balance?.diff_date : ' 0 ปี 0 เดือน 0 วัน '} </p>
      </div>
      <Table striped bordered hover responsive>
        <tr>
          <th className="text-center w-25"> ชื่อทุน </th>
          <th className="text-center"> (ประมาณการ) ที่ต้องชดใช้ </th>
          <th className="text-center"> (ประมาณการ) จำนวนเงินใช้ไปจากวันปฏิบัติงาน </th>
          <th className="text-center"> (ประมาณการ) คงเหลือที่ต้องชดใช้ </th>
          <th className="text-center"> (ประมาณการ) เบี้ยปรับคิดเป็น </th>
        </tr>
        {
          (dataExpenses?.expenses?.length > 0) ? (
            dataExpenses?.expenses?.map((item, index) => {
              return (
                  
                <tr  key={`${index}`}>
                  <td className="text-center align-top">{item?.scholarship_name}  </td>
                  <td className="text-end">
                  {
                      item?.expense?.map((expenseItem, expenseIndex) => {
                        return (
                          <>
                            <p className='m-0 p-2'>
                              <span> {expenseItem?.amount} </span>  
                              <span> {expenseItem?.currency}</span>
                            </p>
                          </>
                        
                        )

                      })
                    }   
                  </td>
                  <td  className="text-end">  
                    {
                      item?.restitution_expense?.map((expenseItem, expenseIndex) => {
                        return (
                          <>
                            <p className='m-0 p-2'>
                              <span> {expenseItem?.amount} </span>  
                              <span> {expenseItem?.currency}</span>
                            </p>
                          </>
                        
                        )

                      })
                    }   
                  </td>    
                  <td  className="text-end">  
                    {
                      item?.remaining_expense?.map((expenseItem, expenseIndex) => {
                        return (
                          <>
                            <p className='m-0 p-2'>
                              <span> {expenseItem?.amount} </span>  
                              <span> {expenseItem?.currency}</span>
                            </p>
                          </>
                        
                        )

                      })
                    }   
                  </td>    
                  <td  className="text-end">  
                    {
                      item?.amount_expense?.map((expenseItem, expenseIndex) => {
                        return (
                          <>
                            <p className='m-0 p-2'>
                              <span> {expenseItem?.amount} </span>  
                              <span> {expenseItem?.currency}</span>
                            </p>
                          </>
                        
                        )

                      })
                    }   
                  </td>       
                </tr>  
                      
              )
            })
          ) : (
            <></>
          )
        } 
 
        <tr>       

          <td className="text-center align-top">รวมทั้งหมด  </td>
          <td className="text-end">  
          {
            (dataExpenses?.actual_cost?.length > 0) ? (
            
                dataExpenses?.actual_cost?.map((item) => {
                  return (
                    <>
                      <p className='m-0 p-2'>
                        <span> {item?.amount} </span>  
                        <span> {item?.currency}</span>
                      </p>
                    </>
                  
                  )
                  })
                
            ):(
              <></>
            )
          } 
          </td>
          <td className="text-end">
          {
            (dataExpenses?.restitution_amount?.length > 0) ? (
            
                dataExpenses?.restitution_amount?.map((item) => {
                  return (
                    <>
                      <p className='m-0 p-2'>
                        <span> {item?.amount} </span>  
                        <span> {item?.currency}</span>
                      </p>
                    </>
                  
                  )
                  })
                
            ):(
              <></>
            )
          }
          </td>
          <td className="text-end"> 
          {
            (dataExpenses?.remaining_balance?.length > 0) ? (
            
                dataExpenses?.remaining_balance?.map((item) => {
                  return (
                    <>
                      <p className='m-0 p-2'>
                        <span> {item?.amount} </span>  
                        <span> {item?.currency}</span>
                      </p>
                    </>
                  
                  )
                  })
                
            ):(
              <></>
            )
          } 
          </td>
          <td className="text-end">  
            {
              (dataExpenses?.remaining_balance2?.length > 0) ? (
              
                  dataExpenses?.remaining_balance2?.map((item) => {
                    return (
                      <>
                        <p className='m-0 p-2'>
                          <span> {item?.amount} </span>  
                          <span> {item?.currency}</span>
                        </p>
                      </>
                    
                    )
                    })
                  
              ):(
                <></>
              )
            }
          </td>
        </tr>   
      </Table> 
      <p className="text-end"> *ข้อมูล ณ วันที่ { dateHelper.getDate(dataExpenses?.date) } </p>
      </>

    )
       
  
  }   
  
    // const DetailExpense = ({dataExpenses}) =>  {
     
    //     return (
    //       <>
    //       <Table striped bordered hover responsive>
    //         <tr>
    //           <th colSpan="2" className="text-center"> ชื่อทุน </th>
    //           <th colSpan="2" className="text-center"> คงเหลือที่ต้องชดใช้ </th>
    //         </tr>
    //         {
    //           (dataExpenses?.expenses?.length > 0) ? (
    //             dataExpenses?.expenses?.map((item, index) => {
    //               return (
                      
    //                 <tr  key={`${index}`}>
    //                   <td colSpan="2" className="text-center">{item?.scholarship_name}  </td>
    //                   <td colSpan="2" className="text-end">  
    //                     {
    //                       item?.expense?.map((expenseItem, expenseIndex) => {
    //                         return (
    //                           <>
    //                             <p>
    //                               <span> {expenseItem?.amount} </span>  
    //                               <span> {expenseItem?.currency}</span>
    //                             </p>
    //                           </>
                            
    //                         )
  
    //                       })
    //                     }   
    //                   </td>       
    //                 </tr>  
                          
    //               )
    //             })
    //           ) : (
    //             <></>
    //           )
    //         } 
      
    //         <tr>
    //           <td colSpan="4" className="text-center p-3"></td>
    //         </tr>
    //         <tr>       
    //           <th className="text-center">ค่าใช้จ่ายจริง </th>
    //           <th className="text-center">จํานวนเงินใช้ไปจากวันปฏิบัติงาน </th>
    //           <th className="text-center">คงเหลือเงินที่ต้องชดใช้ </th>
    //           <th className="text-center">เบี้ยปรับคิดเป็น </th>
    //         </tr>    
    //         <tr>       
    //           <td className="text-end">  
    //           {
    //             (dataExpenses?.actual_cost?.length > 0) ? (
                
    //                 dataExpenses?.actual_cost?.map((item) => {
    //                   return (
    //                     <>
    //                       <p>
    //                         <span> {item?.amount} </span>  
    //                         <span> {item?.currency}</span>
    //                       </p>
    //                     </>
                      
    //                   )
    //                   })
                    
    //             ):(
    //               <></>
    //             )
    //           } 
    //           </td>
    //           <td className="text-end">
    //           {
    //             (dataExpenses?.restitution_amount?.length > 0) ? (
                
    //                 dataExpenses?.restitution_amount?.map((item) => {
    //                   return (
    //                     <>
    //                       <p>
    //                         <span> {item?.amount} </span>  
    //                         <span> {item?.currency}</span>
    //                       </p>
    //                     </>
                      
    //                   )
    //                   })
                    
    //             ):(
    //               <></>
    //             )
    //           }
    //           </td>
    //           <td className="text-end"> 
    //           {
    //             (dataExpenses?.remaining_balance?.length > 0) ? (
                
    //                 dataExpenses?.remaining_balance?.map((item) => {
    //                   return (
    //                     <>
    //                       <p>
    //                         <span> {item?.amount} </span>  
    //                         <span> {item?.currency}</span>
    //                       </p>
    //                     </>
                      
    //                   )
    //                   })
                    
    //             ):(
    //               <></>
    //             )
    //           } 
    //           </td>
    //           <td className="text-end">  
    //             {
    //               (dataExpenses?.remaining_balance2?.length > 0) ? (
                  
    //                   dataExpenses?.remaining_balance2?.map((item) => {
    //                     return (
    //                       <>
    //                         <p>
    //                           <span> {item?.amount} </span>  
    //                           <span> {item?.currency}</span>
    //                         </p>
    //                       </>
                        
    //                     )
    //                     })
                      
    //               ):(
    //                 <></>
    //               )
    //             }
    //           </td>
    //         </tr>   
    //       </Table> 
    //       </>
  
    //     )
           
    // }  

    const onSubmit = async (dataObj) => {

      setCalculateing(true)

      const data = dataObj; 
      if (data) { 
        //console.log(data);
        let dataRes = await http.post(`${urlActionCallAPI}`, data)

        if (dataRes.message === 'success') {

          setDataResponse(dataRes.data); 
          setDataResponseLoading(true);
          setCalculateing(false)
        
          // Swal.fire({
          //   title: 'จำนวนเงินที่ต้องชดใช้',
          //   html: dataRes.data,
          //   // html: `<div style=" border-top: 1px solid #aaa; border-bottom: 1px solid #aaa; padding: 10px 0; margin: 0; color: #ffbc34; "></div>`,
          //   confirmButtonColor: '#0d3996',
          //   confirmButtonText: 'ตกลง',
          //   footer: '',
          // })
          // setCalculateing(false)
        }
      }
    }

    const [datepickerItem, setDatepickerItem] = useState({
      restitution_date:  null, 
    })

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

    return (
        <>
        <nav aria-label="breadcrumb">
            <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
                <li className="breadcrumb-item">
                <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                </Link>
                </li> 
                <li
                    className="breadcrumb-item active font-sarabun"
                    aria-current="page"  >
                   คำนวณการตอบแทนทุน
                </li> 
            </ol>
        </nav>
        <div className="portfolio-create" >
            <section className="bg-light">
                <div className="container" >
                    <div className="card border-0 mb-3 d-flex align-items-center" > 
                        <div className="card-header col-12 col-md-8 pt-4 bg-white border-0">
                            <div className="d-md-flex">
                                <div>
                                    <h5 className="card-title me-auto ps-0">
                                        <div className="bg-icon"> 
                                            <FontAwesomeIcon icon={faBriefcase}  />
                                        </div>
                                        <span> คำนวณการตอบแทนทุน </span>
                                    </h5>
                                </div>
                            </div>
                        
                        </div>

                        <div className="card-body col-12 col-md-8">

                            <div className="portfolio-form">

                        { !loading ? (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <Row>
                                <h5>(ประมาณการ) ระยะเวลาปฏิบัติงานตอบแทนทุน</h5>
                                <Col sm={12} lg={8}>
                                  <div className="mb-3 input-group">
                                    <input
                                      placeholder="ปี"
                                      {...register('yearWorking')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">ปี</span>
                                    <input
                                      placeholder="เดือน"
                                      {...register('monthWorking')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">เดือน</span>
                                    <input
                                      placeholder="วัน"
                                      {...register('dayWorking')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">วัน</span>
                                  <span className="input-group-text border-white bg-white" style={{ width: '130px' }}> ( { restitutionData?.summary?.amount_days ?? 0 } วัน ) </span>
                                  </div>
                                </Col>
                              </Row>
                              
                              <Row>
                                <h5>(ประมาณการ) ระยะเวลาที่ปฏิบัติงานแล้ว</h5>
                                <Col sm={12} lg={8}>
                                  <div className="mb-3 input-group">
                                    <input
                                      placeholder="ปี"
                                      {...register('yearWorked')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">ปี</span>
                                    <input
                                      placeholder="เดือน"
                                      {...register('monthWorked')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">เดือน</span>
                                    <input
                                      placeholder="วัน"
                                      {...register('dayWorked')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">วัน</span>
                                    <span className="input-group-text border-white bg-white" style={{ width: '130px' }}> ( { restitutionData?.worked?.amount_days ?? 0 } วัน ) </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <h5>(ประมาณการ) ระยะเวลาปฏิบัติงานคงเหลือ</h5>
                                <Col sm={12} lg={8}>
                                  <div className="mb-3 input-group">
                                    <input
                                      placeholder="ปี"
                                      {...register('yearBalanceWork')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">ปี</span>
                                    <input
                                      placeholder="เดือน"
                                      {...register('monthBalanceWork')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">เดือน</span>
                                    <input
                                      placeholder="วัน"
                                      {...register('dayBalanceWork')}
                                      readOnly={true}
                                      className={'form-control'} 
                                    />
                                    <span className="input-group-text border-0">วัน</span>

                                  <span className="input-group-text border-white bg-white" style={{ width: '130px' }}> ( { restitutionData?.remaining?.amount_days ?? 0 } วัน ) </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border my-2 p-2 mx-0"> 
                                <h5>กรณีที่ต้องการออกจากทุน</h5>
                                  <Col md={8} className="mb-3">
                                        <label htmlFor="name">
                                        วันที่ต้องการออกจากทุน (ค.ศ.)
                                        </label>
                                      
                                  
                                        <>
                                        <div className="position-relative">
                                          <DatePicker
                                            {...register('restitution_date', {
                                              required: true,
                                            })}
                                            dateFormat="d MMM yyyy"
                                            onChange={(event) => {
                                              clearErrors('restitution_date')
                                              setValue('restitution_date', format(event, 'yyyy-MM-dd'))
                                              setDatepickerItem({ ...datepickerItem, restitution_date: event })
                                            }}
                                            selected={datepickerItem?.restitution_date}
                                            customInput={<InputDate />}
                                            className="form-control"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            preventOpenOnFocus={true}
                                            placeholder="วันที่ต้องการออกจากทุน"
                                            minDate={addDays(new Date(), 0)}
                                          />
                                          <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
                                        </div>
                                        <DatePickerWrapperStyles />
                                      </>
                                        {errors.restitution_date && <div className="help-block">{errors.restitution_date.message}</div>}
                                    </Col>
                                    <Col md={4} className="my-3 d-flex justify-content-end">      
                                        {  !calculateing ? (
                                            <button type="submit" className="btn btn-primary btn-add">คำนวณ</button>
                                            ):(
                                            <button type="submit" className="btn btn-primary btn-add" disabled>กำลังคำนวณ...</button>
                                        ) } 
                                    </Col>
                              </Row>
                                
                          </Form>
                          ):(
                            <>
                              <Skeleton /><Skeleton /><Skeleton />
                            </>
                          )}
                           

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        <Modal
          show={dataResponseLoading}
          size="lg"
          dialogClassName="modal-preview"
          centered
          onHide={() => setDataResponseLoading(false)}
          fullscreen={false}
          aria-labelledby="file-name"
        >
          <Modal.Header closeButton={true} closeLabel={'ปิด'}>
            <Modal.Title id="file-name"> จำนวนเงินที่ต้องชดใช้ </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div> 
              {
                dataResponseLoading && !_.isNull(dataResponse) ? (
                  <DetailExpense dataExpenses={dataResponse}/>
                ):(
                  <>
                    <Skeleton /><Skeleton /><Skeleton />
                  </>
                )
              }
             
            
              
            </div>
          </Modal.Body>
        </Modal>

        </div>
        </>
        )
}


export default RetitutionView