import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { dateHelper } from '../../helpers/date.helper'
import _ from '../../utils/utils'
import { Card } from 'react-bootstrap'
import { CardLoading } from '../../components/Skeleton'

const LifeRecord = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <>
          <Card className="border-0 mb-3">
            <Card.Body>
              <h5 className="card-title">
                <div className="bg-icon">
                  <FontAwesomeIcon icon={faClipboardList} className="font-28" />
                </div>
                <span>Student Life Record</span>
              </h5>
              <div className="table-responsive">
                <table id="lifeRecord-" className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: '180px', minWidth: '180px' }}
                      >
                        วันที่
                      </th>
                      {/* <th
                        scope="col"
                        className="text-center"
                        style={{ width: '220px', minWidth: '220px' }}
                      >
                        สถานะ
                      </th> */}
                      <th scope="col" className="text-center">
                        รายละเอียด
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: '220px', minWidth: '220px' }}
                      >
                        ระยะเวลา
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <Fragment key={`${index}`}>
                            <tr>
                              <td className="align-middle">{dateHelper.getDate(item.date)}</td>
                              {/* <td className="align-middle text-center">{item.student_status}</td> */}
                              <td className="align-middle">
                                <p className="mb-0">{item.description}</p>
                                <span className="font-12 text-black-50">({item.note})</span>
                              </td>
                              <td className="align-middle text-center px-1">
                                <p className="mb-0">{item.duration}</p>
                                <span className="font-12 text-black-50">
                                  ({dateHelper.getDate(item.start_date)}
                                  {!_.isNull(item.end_date)
                                    ? `- ${dateHelper.getDate(item.end_date)}`
                                    : ''}
                                  )
                                </span>
                              </td>
                            </tr>
                          </Fragment>
                        )
                      })
                    ) : (
                      <tr>
                        <td valign="top" colSpan={4} className="text-center">
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <CardLoading />
        </>
      )}
    </>
  )
}

export default LifeRecord
