import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from "react-router-dom";
import useFetch from "../../utils/useFetch";
import set_form from "../../assets/template/FN_115.pdf";
import fontkit from "@pdf-lib/fontkit";
import { degrees, PDFDocument, rgb } from "pdf-lib";
import TH_sarabun from "../../assets/template/thsarabunnew.ttf";
import Iframe from "react-iframe";
const BankAccountDownloadForm = (props) => {
  const initailUser = localStorage.getItem("user");
  const baseUrl = process.env.REACT_APP_API_URL;
  const baseUrldownload = process.env.REACT_APP_API_URL_PROD;
  // const { response: studentData, loading } = useFetch(
  //   `/api/office/tracking/tracking/student/${id}`
  // );
  const { response: studentData, loading } = useFetch(
    `/student/information/view`
  );
  const [initData, setInitData] = useState({
    data: [],
    template: set_form,
    pdfData: null,
    isLoading: true,
    isLodingInitData: false,
    giverName: "",
    contractName: "",
    contractNo: "",
  });

  async function modifyPdf() {
    var option_pdf = {
      font_url: TH_sarabun,
      font_size: 10,
      font_rotage: degrees(-45), //สำหรับลายน้ำ
      font_color: rgb(0, 0, 0), //สีดำ
      //font_color: rgb(0.95, 0.1, 0.1),//สีแดง
      lineHeight: 12,
    };

    const font_url = option_pdf.font_url;
    const fontBytes = await fetch(font_url).then((res) => res.arrayBuffer());

    const url = initData.template;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const sarabunFont = await pdfDoc.embedFont(fontBytes);
    // console.log(pdfDoc);
    const pages = pdfDoc.getPages();
    //console.log('Total page: ' + pages.length)
    let page_setup = "";
    const { width, height } = pages[0].getSize(); // ขนาดกระดาษ
    //console.log("Page size (per page): " + width + " : " + height);

    for (let index = 0; index < pages.length; index++) {
      page_setup = pages[index];
      page_setup.setLineHeight(option_pdf.lineHeight);

      if (
        initData.data[index] !== undefined &&
        initData.data[index].length > 0
      ) {
        for (let i = 0; i < initData.data[index].length; i++) {
          let data = initData.data[index][i];
          if (data.value !== undefined) {
            let show_value = data.value;
            if (
              data.value === null ||
              data.value === undefined ||
              data.value === ""
            ) {
              show_value = "-";
            }
            page_setup.drawText(String(show_value), {
              x: data.x,
              y: data.y,
              leading: 1,
              size: data.size !== undefined ? data.size : option_pdf.font_size,
              font: sarabunFont,
              color: option_pdf.font_color,
            });
          }
        }
      }
    }
    const element = document.createElement("a");
    const raw_file = await pdfDoc.saveAsBase64({ dataUri: true });
    // const file = new Blob([raw_file], {
    //   type: "application/pdf",
    // });
    // console.log(file)
    element.href = raw_file;
    element.download = "FN_115.pdf";
    document.body.appendChild(element);
    element.click();

    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    setInitData({ ...initData, pdfData: pdfDataUri });
  }

  async function loadTemplate() {

    let template_ = '';
    let data_ = [];
    let template_form = "";
    let template_pdf = "";

    template_form = await import('.//template/form_')
    template_pdf = await import("../../assets/template/FN_115.pdf");

    template_ = template_pdf.default;
    data_ = template_form.SetDataForm(studentData);

    setInitData({
      ...initData,
      template: template_,
      isLodingInitData: true,
      isLoading: false,
      data: data_,
    });
  }
  useEffect(() => {
    if (studentData) {
      if (initData.isLoading) {
        loadTemplate();
      } else {
        if (initData.isLodingInitData && initData.pdfData === null) {
          setInitData({
            ...initData,
            isLodingInitData: false,
            isLoading: false,
          });
          modifyPdf();
        }
      }
    }
  });

  const pageTitle = 'page_title';

  return (
    <div className="site-bank-account">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faDownload} className="" />
                    </div>
                    <span>
                      แบบแจ้งข้อมูลการรับเงินโอนด้วยวิธีการจ่ายตรงเข้าบัญชีเงินฝากธนาคาร
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Iframe
                title="name pdf"
                url={initData.pdfData}
                width="100%"
                height="800px"
                display="initial"
                position="relative"
                className="p-3"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BankAccountDownloadForm;
