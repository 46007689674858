import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios'
import AuthContext from '../store/auth-context';

const apiKey = process.env.REACT_APP_API_KEY
const apiVersion = process.env.REACT_APP_API_VERSION
const baseUrl = process.env.REACT_APP_API_URL


const useFetch2 = (endpoint, params = null) => {
 
  let headers = {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    'x-api-version': apiVersion, 
  }

    let options = {
      url: baseUrl + endpoint,
      // method: 'GET',
      headers: headers,
    }

    const [response, setResponse] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    
    const fetchData = useCallback(async () => { 
      await axios(options)
        .then((response) => {
          const json = response.data 
          setResponse(json.data)
          setLoading(false)
        })
        .catch((error) => {
          setError(error)
          setLoading(false)
        })


    }, []) 

    useEffect(() => {
      fetchData()
    }, [])

    return { response, error, loading }
  

}

export default useFetch2
