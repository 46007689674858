import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import Skeleton from 'react-loading-skeleton'
import Alert from 'react-bootstrap/Alert'
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import { history } from '../../helpers/history'
const List = () => {
  const pageTitle = 'ข้อความแจ้งเตือน'
  const { response: responseDataMessage, loading_approved } = useFetch(
    '/student/notification/message'
  )
  const { response: responseData, loading } = useFetch('/student/notification/list')
  console.log(responseData)
  const AlertMessage = () => {
    if (responseDataMessage !== null) {
      return responseDataMessage.map(function (value) {
        return (
          <>
            <Alert
              variant={value.alert}
              // onClose={() => true}
              // dismissible
            >
              {value.message}
            </Alert>
          </>
        )
      })
    } else {
      return (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '80px', minWidth: '80px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        let cal = 10 * currentPage
        cal = cal - (10 - 1)
        cal = cal + rowIndex
        return cal
      },
    },
    {
      dataField: 'action',
      text: 'ประเภทข้อความ',
      sort: false,
    },

    {
      dataField: 'message',
      text: 'รายละเอียดข้อความ',
      sort: false,
      align: 'left',
    },
  ]
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      แสดง {from} ถึง {to} จากทั้งหมด {size} รายการ
    </span>
  )
  const [currentPage, setCurrentPage] = useState(1)
  const paginationOptions = {
    custom: true,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: responseData?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
      history.push(`/notification-message?page=${page}`)
    },
  }
  useEffect(() => {}, [])

  return (
    <>
      <div className="contract-update">
        <section className="bg-light">
          <Container fluid className="content container">
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <h4 className="mt-4">ข้อความแจ้งเตือน</h4>
                  </Card.Header>
                  <Card.Body>
                    {!loading_approved && !loading && responseDataMessage !== null ? (
                      <div>
                        <AlertMessage />
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />
                              <BootstrapTable
                                id="tableResize"
                                // remote={{ pagination: true, filter: false, sort: false }}
                                classes="resizable"
                                bootstrap4
                                hover
                                striped={false}
                                bordered={false}
                                keyField="id"
                                data={responseData}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                wrapperClasses="table-responsive"
                                noDataIndication={'ไม่พบข้อมูล'}
                                {...paginationTableProps}
                              />
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          )}
                        </PaginationProvider>
                      </div>
                    ) : (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default List
