import logo from '../assets/images/logo/static_IPST_Logo.png'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TermsCondition = () => {
  return (
    <>
      <div className="site-news">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 my-4">
              <div className="card-header">
                <div className="pt-4 bg-white border-0">
                  <div className="account-logo">
                    <img src={logo} width={100} alt="สสวท." />
                  </div>
                  <div className="account-wrapper mb-3">
                    <h4 className="mt-3 text-center">ข้อกำหนดและเงื่อนไขการใช้งานเว็บไซต์</h4>
                  </div>
                </div>
              </div>
              <div className="card-body mb-4">
                <div className="d-flex flex-lg-row justify-content-center">
                  <div id="w0" className="w-100">
                    <Col>
                      <ol>
                        <li>
                          ข้อตกลงและเงื่อนไขนี้มีขึ้นเพื่อใช้ในการกำกับดูแลการเข้าใช้เว็บไซต์ระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา
                          ของสถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)
                          โดยการที่ผู้ใช้บริการเข้าในเว็บไซต์นี้
                          ผู้ใช้บริการตกลงและยินยอมที่จะปฏิบัติตามข้อตกลงและเงื่อนไข ตลอดจนข้อจำกัด
                          ความรับผิดในการใช้เว็บไซต์นี้ทุกประการ
                        </li>
                        <li>
                          ผู้ใช้บริการเป็นผู้รับผิดชอบ จัดหา และบำรุงรักษาสายโทรศัพท์
                          อุปกรณ์เครื่องคอมพิวเตอร์ และ/หรืออุปกรณ์อื่น ๆ
                          ที่จำเป็นรวมทั้งค่าใช้จ่ายต่าง ๆ ที่เกี่ยวข้อง เพื่อการเข้าใช้เว็บไซต์นี้
                        </li>
                        <li>
                          ผู้ใช้บริการจะต้องใช้เว็บไซต์นี้อย่างถูกต้องตามกฎหมาย
                          ห้ามผู้ใช้บริการปิดประกาศ (post) ใด ๆ
                          และ/หรือรับส่งข้อมูลผ่านเว็บไซต์นี้ในลักษณะที่ผิดกฎหมาย ผิดศีลธรรมอันดี
                          ละเมิดสิทธิของบุคคลอื่น ข่มขู่ หรือลามกอนาจาร รวมทั้งห้ามปิดประกาศ (post)
                          อัพโหลด (upload)
                          หรือทำให้มีขึ้นซึ่งสิ่งที่ถูกคุ้มครองด้วยกฎหมายทรัพย์สินทางปัญญาบนเว็บไซต์นี้
                          โดยไม่ได้รับความยินยอมจากเจ้าของงานทรัพย์สินทางปัญญานั้น ๆ ก่อน
                          ภาระหน้าที่ในการตัดสินใจว่าสิ่งใด
                          เป็นสิ่งที่ถูกคุ้มครองภายใต้กฎหมายทรัพย์สินทางปัญญาอยู่ที่ผู้ใช้บริการ
                          โดยผู้ใช้บริการจะต้องรับผิดชอบแต่เพียงผู้เดียวในความเสียหายใด ๆ
                          ที่เกิดขึ้นจากการละเมิดลิขสิทธิ์ทรัพย์สินทางปัญญา
                        </li>
                        <li>
                          ห้ามผู้ใช้บริการประกาศ โฆษณา ประชาสัมพันธ์ สินค้าหรือบริการใด ๆ
                          บนเว็บไซต์นี้
                          รวมทั้งการโฆษณาชวนเชื่อให้ผู้ใช้บริการเว็บไซต์นี้สมัครเป็นสมาชิกของเว็บไซต์อื่น
                          ๆ เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรอย่างชัดแจ้งจาก สสวท. ก่อน
                        </li>
                        <li>
                          ผู้ใช้บริการตกลง ยินยอม
                          และเข้าใจโดยชัดแจ้งแล้วว่าผู้ใช้บริการเข้าใช้บริการเว็บไซต์
                          โดยความเสี่ยงของผู้ใช้บริการเองทั้งสิ้น สสวท. หรือพนักงานของ สสวท.
                          หรือตัวแทนของ สสวท. ไม่รับประกันว่าเว็บไซต์
                          จะปราศจากข้อผิดพลาดหรือข้อขัดข้อง
                          รวมทั้งไม่รับประกันถึงความถูกต้องและความน่าเชื่อถือของข้อมูลหรือ บริการใด
                          ๆ บนเว็บไซต์
                        </li>
                        <li>
                          ผู้ใช้บริการยินยอมให้ สสวท. หรือผู้ใดก็ตามที่ สสวท. เห็นสมควร
                          ทำการตรวจสอบและควบคุมเนื้อหาของข้อมูลบนเว็บไซต์ทั้งหมด รวมทั้งทำการลบ
                          และ/หรือ แก้ไขสิ่งใด ๆ
                          ที่ผู้ใช้บริการได้ทำให้มีขึ้นบนเว็บไซต์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                        </li>
                        <li>
                          การให้บริการของเว็บไซต์นี้ เป็นการให้บริการตามสภาพ และตามสถานะที่เป็นอยู่
                          โดยไม่มีการรับประกันใด ๆ ทั้งสิ้น ไม่ว่าทางตรงหรือทางอ้อม
                          ข้อจำกัดความรับผิดนี้ ใช้กับความเสียหายใด ๆ ที่เกิดขึ้นจากการให้บริการ
                          ข้อผิดพลาด การละเว้น การขัดข้อง ข้อบกพร่อง การล่าช้าในการให้บริการ
                          ส่งข้อมูลและคอมพิวเตอร์ไวรัส
                          รวมทั้งการเข้าถึงโดยไม่มีอำนาจเพื่อเข้าใช้หรือเปลี่ยนแปลงข้อมูล
                          ผู้ใช้บริการรับทราบว่า สสวท.
                          ไม่ต้องรับผิดชอบในกรณีหมิ่นประมาทหรือการกระทำอันเป็นการผิดกฎหมายของผู้ใช้บริการรายอื่น
                          หรือของบุคคลอื่น และความเสี่ยงในการรับผิดในความเสียหายที่อาจเกิดใด ๆ
                          ทั้งหมดตกอยู่กับผู้ใช้บริการเท่านั้น
                        </li>
                        <li>
                          เว็บไซต์นี้ มีสิ่งที่ถูกคุ้มครองด้วยกฎหมายทรัพย์สินทางปัญญา เช่น ข้อความ
                          (text) ซอฟต์แวร์ รูปภาพ วีดีโอ กราฟิก (graphic) เพลง และเสียง
                          ห้ามมิให้ผู้ใช้บริการแก้ไข ดัดแปลง
                          ทำซ้ำหรือมีส่วนร่วมในการกระทำดังกล่าวในทุกกรณี
                          เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรอย่างชัดแจ้งและถูกต้องจาก สสวท.
                          หรือเจ้าของงานทรัพย์สินทางปัญญานั้น ๆ ก่อน แล้วแต่กรณี
                          และผู้ใช้บริการสามารถดาวน์โหลด (download)
                          และใช้สิ่งที่ถูกคุ้มครองด้วยทรัพย์สินทางปัญญาเพื่อใช้ประโยชน์ของตนเองเท่านั้น
                        </li>
                        <li>
                          สสวท. สงวนสิทธิ์ที่จะแก้ไขเปลี่ยนแปลง
                          หรือหยุดให้บริการเนื้อหาส่วนใดส่วนหนึ่ง หรือทั้งหมดเมื่อใดก็ได้
                          รวมทั้งสงวนสิทธิ์ที่จะแก้ไข เปลี่ยนแปลง เพิ่มเติม
                          ข้อตกลงและเงื่อนไขส่วนหนึ่งส่วนใด หรือทั้งหมดได้
                          โดยไม่ต้องแจ้งให้ทราบล่วงหน้า และให้ถือว่าการแก้ไข เปลี่ยนแปลง
                          เพิ่มเติมนั้นมีผลบังคับใช้ทันที
                          และการที่ผู้ใช้บริการเข้าใช้เว็บไซต์นี้ภายหลังจากการแก้ไข เปลี่ยนแปลง
                          เพิ่มเติมดังกล่าว
                          ถือว่าผู้ใช้บริการได้ตกลงยินยอมตามข้อตกลงและเงื่อนไขใหม่นั้นแล้ว
                        </li>
                        <li>
                          สสวท. หรือผู้ใด หรือหน่วยงานใดที่เกี่ยวข้องในการสร้าง การดำเนินการ
                          การบำรุงรักษา การเผยแพร่เว็บไซต์ รวมทั้งผู้ร่วมให้บริการด้านข้อมูล
                          (content partners) จะไม่ต้องรับผิดสำหรับความเสียหายไม่ว่ากรณีใด ๆ
                          ที่เกิดขึ้นจากการใช้
                          หรือการไม่สามารถใช้บริการเว็บไซต์ส่วนหนึ่งส่วนใดหรือทั้งหมด ทั้งนี้ สสวท.
                          จะให้ความช่วยเหลือในการกู้คืนข้อมูลอย่างสุดความสามารถ
                        </li>
                        <li>สสวท. ขอสงวนสิทธิ์ระงับการให้บริการแก่ผู้ใช้งาน ในกรณีดังต่อไปนี้</li>
                      </ol>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.1
                        มีการใช้งานที่ไม่ตรงกับวัตถุประสงค์ของระบบ
                        หรือกระทำการที่ก่อให้เกิดปัญหาทางด้านความปลอดภัยต่อระบบ เช่น เจาะระบบ
                        ปล่อยไวรัสคอมพิวเตอร์ เป็นต้น
                      </p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.2
                        มีการกระทำที่เป็นการฝ่าฝืนพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์
                        พ.ศ. 2550
                      </p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.3
                        มีเหตุสุดวิสัยเกิดขึ้นแก่ สสวท. หรือเครื่องคอมพิวเตอร์แม่ข่าย (Server)
                        ของระบบ
                      </p>
                      <ol start="12">
                        <li>
                          การเข้าใช้งานผ่านระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา สสวท.
                          โดยไม่ใช่ตนเอง ไม่ว่าจะเป็นการให้ผู้อื่นใช้ “ชื่อผู้ใช้ (Username)
                          และรหัสผ่าน (Password) ” ของตัวเอง หรือใช้ “ชื่อผู้ใช้ (Username)
                          และรหัสผ่าน (Password) ” ของผู้อื่น เพื่อเข้าระบบ (Login)
                          ถือว่าเป็นการทุจริตและอาจถูกดำเนินการอื่นใดตามกฎหมายต่อไป
                        </li> 
                      </ol>
                    </Col>
                    <Col className="text-center mt-4">
                      <Link
                        to={'/'}
                        className="mb-0"
                        style={{ fontSize: '16px', color: '#1f1f1f' }}
                      >
                        ⟵ กลับไปที่หน้าเข้าสู่ระบบ
                      </Link>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default TermsCondition
