import { faCircle, faClipboardList, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { history } from '../../helpers/history'
import useFetch from '../../utils/useFetch'
import { ButtonViewDetail } from '../../components/Button'
import { dateHelper } from '../../helpers/date.helper'
import { petitionStatusHelper } from '../../helpers/petition.helper'
import Breadcrumb from '../../components/BreadcrumbBS'

const actionButton = (cell, row, rowIndex, formatExtraData) => {
  return (
    <>
      <ButtonViewDetail link={row.petition_group} />
    </>
  )
}

const List = (props) => {
  const sizePerPage = 10

  const { response, loading } = useFetch(`/student/petition?type=expense`)

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      แสดง {from} ถึง {to} จากทั้งหมด {size} รายการ
    </span>
  )

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onPageChange: (page, sizePerPage) => {
      history.push(`/petition-expense?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: 'เลขที่คำร้อง',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
    },
    {
      dataField: 'petition_type_name',
      text: 'ประเภทคำร้อง',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'created_at',
      text: 'วันที่ยื่นคำร้อง',
      sort: true,
      headerStyle: () => {
        return { width: '250px', minWidth: '250px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.created_at)
      },
    },
    {
      dataField: 'status',
      text: 'สถานะ',
      sort: true,
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return petitionStatusHelper.getLabel(row?.status, row?.note)
      },
    },
    {
      dataField: 'action',
      text: 'รายละเอียด',
      align: 'center',
      headerStyle: () => {
        return { width: '135px', minWidth: '135px', textAlign: 'center' }
      },
      events: {
        onClick: (e) => {
          e.stopPropagation()
        },
      },
      formatter: actionButton,
    },
  ]

  const pageTitle = 'คำร้องค่าใช้จ่าย'

  const routes = [
    {
      path: '',
      breadcrumb: pageTitle,
    },
  ]

  return (
    <div className="petition-index">
      <Breadcrumb isLoading={false} routes={routes} />
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span> คำร้องค่าใช้จ่าย </span>
                  </h5>
                </div>
                <div className="ms-auto my-auto mt-lg-0 mt-4">
                  <div className="ms-auto my-auto">
                    <Link className="btn btn-outline-primary" to="/petition-expense/create">
                      <FontAwesomeIcon icon={faClipboardList} className="" /> ยื่นคำร้องใหม่
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div>
                  <div>
                    {!loading ? (
                      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => (
                          <div>
                            <PaginationTotalStandalone {...paginationProps} />
                            <BootstrapTable
                              id="tableResize"
                              classes="resizable"
                              bootstrap4
                              hover
                              striped={false}
                              bordered={false}
                              keyField="id"
                              data={response}
                              columns={columns}
                              filter={filterFactory()}
                              filterPosition="top"
                              wrapperClasses="table-responsive"
                              noDataIndication={'ไม่พบข้อมูล'}
                              {...paginationTableProps}
                            />
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        )}
                      </PaginationProvider>
                    ) : (
                      <table id="petition" className="table">
                        <thead>
                          <tr>
                            <th>รหัสคำร้อง</th>
                            <th>ประเภทคำร้อง</th>
                            <th style={{ width: 220, minWidth: 220 }}>วันที่ยื่นคำร้อง</th>
                            <th style={{ width: 180, minWidth: 180 }}>สถานะ</th>
                            <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                              รายละเอียด
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default List
