import React  from 'react';
import './Status.css';
import { Link } from 'react-router-dom';

// get our fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faChalkboardTeacher, faBusinessTime, faBriefcase, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

import useFetch from '../../utils/useFetch2';
import Skeleton from 'react-loading-skeleton';

const Status = (props) => {

    const { response: statusInformation, loading: loading } = useFetch( '/student/dashboard/status-information' )
 
    const isLogged = props.isLogged;
    // let classAuth = "col-xl-8 order-xl-1 order-2";
    // if(isLogged){
    //     classAuth = "col-xl-12 order-xl-1 order-2";
    // }  

    return ( 
        <>
        { 
        isLogged ? (
            <div className="col-xl-12 order-xl-1 order-2">
                <div className="card bg-cyan border-0 mb-3">
                    <div className="py-3 font-prompt px-5"> 
                        <h5 className="text-center mb-3 title">สถานะการรับทุนของนักเรียนทุน</h5>
                        <div className="block-step-description">

                            <ul className="w-full steps ps-0">
                                <li className="step info step-first">
                                    <div className="bullet bg-info text-white">
                                        {/* <i className="fas fa-clipboard-list font-28"></i>  */}
                                        <FontAwesomeIcon icon={faClipboardList} className="font-28"/>
                                    </div>
                                    <span className="lh-1">ทำสัญญารับทุน</span>
                                </li>
                                <li className="step info">
                                    <div className="bullet bg-info text-white">
                                        {/* <i className="fas fa-chalkboard-teacher font-28"></i> */}
                                        <FontAwesomeIcon icon={faChalkboardTeacher} className="font-28"/>
                                    </div>
                                    <span className="lh-1">กำลังศึกษา</span>
                                </li>
                                <li className="step info">
                                    <div className="bullet bg-info text-white">
                                        {/* <i className="fas fa-business-time font-28"></i> */}
                                        <FontAwesomeIcon icon={faBusinessTime} className="font-28"/>
                                    </div>
                                    <span className="lh-1">รอบรรจุ</span>
                                </li>
                                <li className="step info">
                                    <div className="bullet bg-info text-white">
                                        {/* <i className="fas fa-briefcase font-28"></i> */}
                                        <FontAwesomeIcon icon={faBriefcase} className="font-28"/>
                                    </div>
                                    <span className="lh-1">ปฏิบัติงานตอบแทนทุน</span>
                                </li>
                                <li className="step info">
                                    <div className="bullet bg-info text-white">
                                        {/* <i className="fas fa-calendar-check font-28"></i> */}
                                        <FontAwesomeIcon icon={faCalendarCheck} className="font-28"/>
                                    </div>
                                    <span className="lh-1">ปฏิบัติงานครบตามสัญญาทุน</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="font-prompt">
                        <h4 className="title mb-3">คำอธิบาย</h4>
                        <div>
                            <p className="text-blue-soft mb-0 fs-5 title fst-italic weight-500">ทำสัญญารับทุน</p>
                        
                        {
                            !loading ? (
                                <p className="font-sarabun"> { statusInformation?.sign_contract} </p>
                            ):(
                                <p className="font-sarabun">   <Skeleton />  </p>
                            )
                        }
                            
                        </div>
                        <div>
                            <p className="text-blue-soft mb-0 fs-5 title fst-italic weight-500">กำลังศึกษา</p>
                                    
                        {
                            !loading ? (
                                <p className="font-sarabun">{ statusInformation?.studying} </p>
                            ):(
                                <p className="font-sarabun">   <Skeleton />  </p>
                            )
                        }
                            
                        </div>
                        <div>
                            <p className="text-blue-soft mb-0 fs-5 title fst-italic weight-500">รอใช้ทุน</p>
                        {
                            !loading ? (
                                <p className="font-sarabun">{ statusInformation?.waiting_restitution} </p>
                            ):(
                                <p className="font-sarabun">   <Skeleton />  </p>
                            )
                        }
                        </div>
                        <div>
                            <p className="text-blue-soft mb-0 fs-5 title fst-italic weight-500">ปฏิบัติงานตอบแทนทุน </p>
                        {
                            !loading ? (
                                <p className="font-sarabun">{ statusInformation?.working} </p>
                            ):(
                                <p className="font-sarabun">   <Skeleton />  </p>
                            )
                        }
                        </div>
                        <div className="">
                            <p className="text-blue-soft mb-0 fs-5 title fst-italic weight-500">ปฏิบัติงานครบตามสัญญาทุน </p>
                        {
                            !loading ? (
                                <p className="font-sarabun">{ statusInformation?.working_complete} </p>
                            ):(
                                <p className="font-sarabun">   <Skeleton />  </p>
                            )
                        }
                        </div>

                    </div>
                </div> */}
            </div> 
        ):(
            <div className="col-xl-8 order-xl-1 order-2">
                <div className="container">
                    <h4 className="mb-4 title font-prompt fst-italic">ข่าวประชาสัมพันธ์</h4>
                    <div className="row scroll-element js-scroll fade-in-bottom scrolled">

                        { props.newsBlock }
                    </div>
                    <div className="text-center scroll-element js-scroll fade-in-bottom scrolled pt-3">

                        <Link to="/news" className="btn btn-info text-white font-prompt"> ดูทั้งหมด </Link>
                    </div>
                </div>
            </div>
        )

        }
        </>
        
           
    )
}

export default Status


                
           