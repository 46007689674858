import Swal from 'sweetalert2'

export const AlertError = (title, text) => {
  Swal.fire({
    icon: 'error',
    title: title,
    html: text,
    confirmButtonColor: '#0d3996',
    confirmButtonText: 'ตกลง',
    footer: '',
  })
}

export const AlertSuccess = (title, text) => {
  Swal.fire({
    icon: 'success',
    title: title,
    text: text,
    confirmButtonColor: '#0d3996',
    confirmButtonText: 'ตกลง',
    footer: '',
  })
}

export const AlertLoading = (title, text) => {
  Swal.fire({
    title: title,
    html: '<i class="fas fa-spinner fa-pulse"></i>',
    footer: '',
    showConfirmButton: false,
  })
}
