import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPlus, faSearch, faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useFetch from '../../../utils/useFetch'
import Skeleton from 'react-loading-skeleton'
import { dateHelper } from '../../../helpers/date.helper'
import { ButtonAction } from '../../../components/Button'

const PortfolioContainer = (props) => {
  const { response: portfolioData, loading } = useFetch(`/student/personal/portfolio`)

  return (
    <div className="card border-0 mb-3">
      <div className="card-body">
        <h5 className="card-title d-flex align-items-center">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faStar} className="" />
          </div>
          <span>ผลงาน</span>
          {props.student ? (
            <Link to={'/portfolio/create'} className="btn btn-outline-primary btn-add ms-auto">
              <FontAwesomeIcon icon={faPlus} className="me-1" /> เพิ่มรายการ
            </Link>
          ) : (
            <></>
          )}
        </h5>
        <div className="table-responsive">
          <div id="w0" className="grid-view">
            <table className="table">
              <thead>
                <tr>
                  <th>อัปเดตล่าสุด</th>
                  <th>รายการ</th>
                  <th>ประเภทผลงาน</th>
                  <th>สถานะ</th>
                  <th className="action-column">รายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      {' '}
                      <Skeleton />
                    </td>
                    <td>
                      {' '}
                      <Skeleton />
                    </td>
                    <td>
                      {' '}
                      <Skeleton />
                    </td>
                  </tr>
                ) : portfolioData?.length > 0 ? (
                  portfolioData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {' '}
                          {item?.updated_at
                            ? dateHelper.getDateTime(item.updated_at)
                            : dateHelper.getDateTime(item.created_at)}{' '}
                        </td>
                        <td className="text-start">{item.name}</td>
                        <td>{item.portfolio_type}</td>
                        <td className={item.classStatus + ' align-middle'}>
                          <FontAwesomeIcon icon={faCircle} className="pe-1" />
                          {item.status}
                          <p className="text-secondary fs-14">{item.note} </p>
                        </td>
                        <td>
                          <ButtonAction
                            link={'/portfolio/' + item.ID}
                            label="ดูรายละเอียด"
                            variant="outline-primary"
                            icon={faSearch}
                            width="160px"
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr className="odd">
                    <td valign="top" colSpan={5} className="text-center">
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioContainer
