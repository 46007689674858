import React, { useState } from 'react'
import useFetch from '../../utils/useFetch'
import ReactLoading from 'react-loading'
import FormField from './FormField'

const Update = () => {
  const { response, loading } = useFetch(`/student/personal`)

  const data = response

  const props = {
    data,
  }

  return (
    <div className="site-personal-information">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3 d-flex align-items-left">
            <div className="card-body pt-4">
              {!loading ? (
                <>
                  <FormField {...props} />
                </>
              ) : (
                <>
                  <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Update
