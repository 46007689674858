import { faBusinessTime, faDownload, faEye, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { dateHelper } from '../../helpers/date.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'
import { Card, Container, Modal } from 'react-bootstrap'
import PDFViewer from '../../components/Pdf'
import _ from '../../utils/utils'
import { statusHelper } from '../../helpers/status.helper'

const OperationalView = (props) => {
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const id = props.match.params.id
  // const { response: operationalData, loading } = useFetch(`/student/operational`);
  const { response: operationalData, loading } = useFetch('/student/operational/' + id)

  const auth = JSON.parse(localStorage.getItem('user'))

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/operational/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/operational/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </>
      )
    })
  }

  let parentOrg = !_.isNu(operationalData?.root_organization_th)
    ? `(${
        operationalData?.sub1_organization_th !== null &&
        operationalData?.sub2_organization_th === operationalData?.name_th
          ? operationalData?.sub1_organization_th + ' - '
          : ''
      } ${operationalData?.root_organization_th})`
    : ''

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/operational"> ข้อมูลการปฏิบัติงาน </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            <span>รายละเอียดข้อมูลการปฏิบัติงาน </span>
          </li>
        </ol>
      </nav>
      <div className="operational-index">
        <section className="bg-light">
          <Container>
            <Card className="border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faBusinessTime} className="" />
                      </div>
                      <span>รายละเอียดข้อมูลการปฏิบัติงาน </span>
                    </h5>
                  </div>
                </div>
              </div>
              <Card.Body>
                <div className="table-responsive">
                  <h6 className="text-primary fw-bolder ps-5 mt-5">ข้อมูลทั่วไป</h6>
                  <div id="operational_work" className="table-responsive">
                    {loading ? (
                      <table className="table">
                        <tbody>
                          <tr>
                            <td className="align-middle">หน่วยงานต้นสังกัด</td>
                            <td className="organization_name align-middle">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle">หน่วยงาน</td>
                            <td className="organization_name align-middle">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td className="align-middle">หน่วยงานย่อย</td>
                            <td className="organization_name align-middle">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td> ตำแหน่งงาน </td>
                            <td className="work_detail">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td> กลุ่มตำแหน่งงาน </td>
                            <td className="work_group_detail">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>สถานะปฏิบัติงานตามสัญญาทุน</td>
                            <td className="operational_status">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>วันที่บรรจุ</td>
                            <td className="start_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>วันที่สิ้นสุดในการปฏิบัติงาน</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>จำนวนระยะเวลาที่ต้องปฏิบัติงานตอบแทนทุน</td>
                            <td className="duration_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>วันที่ปฏิบัติงานไปแล้ว </td>
                            <td className="duration_worked">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>จำนวนวันปฏิบัติงานคงเหลือ </td>
                            <td className="duration_work_balance">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>ไฟล์แนบ</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>สถานะ</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>เหตุผล</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>วันที่สร้าง</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>วันที่อัปเดต</td>
                            <td className="end_work">
                              <Skeleton />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <>
                        {operationalData ? (
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="align-middle">หน่วยงานต้นสังกัด</td>
                                <td className="organization_name align-middle">
                                  {operationalData?.root_organization_th}
                                </td>
                              </tr>
                              <tr>
                                <td className="align-middle">หน่วยงาน</td>
                                <td className="organization_name align-middle">
                                  {operationalData?.sub1_organization_th}
                                </td>
                              </tr>
                              <tr>
                                <td className="align-middle">หน่วยงานย่อย</td>
                                <td className="organization_name align-middle">
                                  {operationalData?.name_th} 
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="align-middle">หน่วยงาน</td>
                                <td className="organization_name align-middle">
                                  {operationalData?.name_th}{' '}
                                  {operationalData?.name_th !==
                                  operationalData?.root_organization_th
                                    ? parentOrg
                                    : ''}
                                </td>
                              </tr> */}
                              <tr>
                                <td> ตำแหน่ง </td>
                                <td className="work_detail">{operationalData?.position}</td>
                              </tr>
                              <tr>
                                <td> กลุ่มตำแหน่งงาน </td>
                                <td className="work_group_detail">
                                  {operationalData?.position_group}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานะปฏิบัติงานตามสัญญาทุน</td>
                                <td className="operational_status">
                                  {operationalData?.operational_status}
                                </td>
                              </tr>
                              <tr>
                                <td>วันที่บรรจุ</td>
                                <td className="start_work">
                                  {dateHelper.getDate(operationalData?.start_work)}
                                </td>
                              </tr>
                              <tr>
                                <td>วันที่สิ้นสุดในการปฏิบัติงาน</td>
                                <td className="end_work">
                                  {dateHelper.getDate(operationalData?.end_work)}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>ระยะเวลาปฏิบัติงานทั้งหมด</td>
                                <td className="duration_work"> {operationalData?.total?.diff_date}</td>
                              </tr> */}
                              <tr>
                                <td>ระยะเวลาปฏิบัติงาน (เฉพาะหน่วยงานนี้)</td>
                                <td className="duration_work"> {operationalData?.duration?.diff_date ?? ' - '}</td>
                              </tr>
                              <tr>
                                <td>ระยะเวลาปฏิบัติงานแล้ว (เฉพาะหน่วยงานนี้)</td>
                                <td className="duration_worked">{operationalData?.worked?.diff_date ?? ' - '}</td>
                              </tr>
                              <tr>
                                <td>ระยะเวลาปฏิบัติงานคงเหลือ (เฉพาะหน่วยงานนี้)</td>
                                <td className="duration_work_balance">
                                  {operationalData?.balance?.diff_date ?? ' - '}
                                </td>
                              </tr>
                              <tr>
                                <td>ไฟล์แนบ</td>
                                <td>
                                  {operationalData?.files.length > 0
                                    ? renderFileDocument(operationalData?.files)
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>สถานะ</td>
                                <td>{statusHelper.getLabel(operationalData?.status)}</td>
                              </tr>

                              <tr>
                                <td>เหตุผล</td>
                                <td className="w-60">{operationalData?.note}</td>
                              </tr>
                              <tr>
                                <td>วันที่สร้าง</td>
                                <td className="w-60">
                                  {dateHelper.getDateTime(operationalData?.created_at)}
                                </td>
                              </tr>
                              <tr>
                                <td>วันที่อัปเดต</td>
                                <td className=" w-60">
                                  {dateHelper.getDateTime(operationalData?.updated_at)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center p-4 my-2 bg-light">ไม่พบข้อมูล</div>
                        )}
                      </>
                    )}
                  </div>
                  {!loading ? (
                    <>
                      <Modal
                        show={show}
                        size="lg"
                        dialogClassName="modal-preview"
                        centered
                        onHide={() => setShow(false)}
                        aria-labelledby="file-name"
                      >
                        <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                          <Modal.Title id="file-name">{fileName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <PDFViewer pdf={filePreview} />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </section>
      </div>
    </>
  )
}

export default OperationalView
