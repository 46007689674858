import * as Pages from '../../pages/petition-other'

export const PetitionOther = [
  {
    path: '/petition-other/create',
    name: 'ยื่นคำร้องใหม่',
    component: Pages.Create,
    layout: 'petition-other',
    breadcrumb: { parent: 'คำร้องอื่น ๆ', child: 'ยื่นคำร้องใหม่' },
  },
  {
    path: '/petition-other/history/detail/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.HistoryDetail,
    layout: 'petition-other',
    breadcrumb: {
      parent: 'คำร้องอื่น ๆ',
      child: 'ประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-other/history/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.History,
    layout: 'petition-other',
    breadcrumb: {
      parent: 'คำร้องอื่น ๆ',
      child: 'ประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-other/:id',
    name: 'คำร้องอื่น ๆ',
    component: Pages.View,
    layout: 'petition-other',
    breadcrumb: { parent: 'คำร้องอื่น ๆ', child: 'รายละเอียดคำร้อง' },
  },

  {
    path: '/petition-other/print/:id',
    name: 'คำร้องอื่น ๆ',
    component: Pages.Template,
    layout: 'petition-other',
    breadcrumb: { parent: 'คำร้องอื่น ๆ', child: 'รายละเอียดคำร้อง' },
  },

  {
    path: '/petition-other',
    name: 'คำร้องอื่น ๆ',
    component: Pages.List,
    layout: 'petition-other',
    breadcrumb: { parent: '', child: 'คำร้องอื่น ๆ' },
  },
]
