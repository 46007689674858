import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'

const Header = ({ title, icon, isLoading }) => {
  return (
    <>
      <div className="card-header ">
        <div className="d-md-flex pt-4 bg-white border-0">
          <div>
            <h5 className="card-title d-flex align-items-center me-auto">
              <div className="bg-icon">
                <FontAwesomeIcon icon={icon} />
              </div>
              {isLoading ? (
                <>
                  <span>{title}</span>
                </>
              ) : (
                <>
                  <Skeleton width="250px" />
                </>
              )}
            </h5>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
