import {
  faCircle,
  faClipboardList,
  faExclamationCircle,
  faHome,
  faSearch,
  faSignal,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { CardLoading } from '../../components/Skeleton'
import { dateHelper } from '../../helpers/date.helper'
import useFetch from '../../utils/useFetch'
import Agency from './Agency'
import Education from './Education'
import LifeRecord from './LifeRecord'
import Status from './Status'
import Contract from './Contract'

const Dashboard = () => {
  const { response: studentData, loading } = useFetch(`/student/dashboard`)

  const { response: bankAccountData, loading: loadingBankAccount } =
    useFetch(`/student/bank-account`)

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            Dashboard
          </li>
        </ol>
      </nav>
      <div className="site-dashboard">
        {!loadingBankAccount && bankAccountData?.data?.length === 0 ? (
          <>
            <section className="bg-white py-3 mb-3">
              <div className="container">
                <div className="alert alert-warning-soft mb-3" role="alert">
                  <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                  กรุณาเพิ่มข้อมูลบัญชีธนาคาร
                </div>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
        <section className="bg-light">
          <div className="container">
            <Status data={studentData} />
            {!loading ? (
              <>
                <div className="card border-0 mb-3">
                  {studentData?.status['student_status_group_id'] > 1 ? (
                    <div className="card-body">
                      <h5 className="card-title me-auto">
                        <div className="bg-icon">
                          <FontAwesomeIcon icon={faSignal} className="" />
                        </div>
                        <span> สถานะคำร้องล่าสุด </span>
                      </h5>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">รหัสคำร้อง</th>
                              <th scope="col">หมวดหมู่</th>
                              <th scope="col">ประเภท</th>
                              <th scope="col">วันที่ยื่นคำร้อง</th>
                              <th scope="col">อัปเดตล่าสุด</th>
                              <th scope="col">สถานะ</th>
                              <th scope="col">รายละเอียด</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentData?.petition.length > 0 ? (
                              studentData?.petition.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="align-middle"> {item.id} </td>
                                    <td className="align-middle">{item.petition_category_name}</td>
                                    <td className="align-middle"> {item.petition_type_name} </td>
                                    <td className="align-middle">
                                      {dateHelper.getDateTime(item.created_at)}
                                    </td>
                                    <td className="align-middle">
                                      {dateHelper.getDateTime(item.updated_at)}
                                    </td>
                                    <td className={item.classStatus + ' align-middle'}>
                                      <FontAwesomeIcon icon={faCircle} className="pe-1" />
                                      {item.status}
                                    </td>
                                    <td className="align-middle text-center px-1">
                                      <Link
                                        className="btn btn-outline-primary px-2"
                                        to={item.petition_group}
                                      >
                                        <FontAwesomeIcon icon={faSearch} className="" />
                                        ดูรายละเอียด
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td valign="top" colSpan={7} className="text-center">
                                  ไม่พบข้อมูล
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {studentData?.status['student_status_group_id'] == 1 ? (
                    <div className="card-body">
                      <h5 className="card-title">
                        <div className="bg-icon">
                          <FontAwesomeIcon icon={faClipboardList} className="" />
                        </div>
                        <span>ทำสัญญารับทุน</span>
                      </h5>
                      <div className="table-responsive">
                        <table id="contract" className="table">
                          <thead>
                            <tr>
                              <th scope="col">รายการทุน</th>
                              <th scope="col">วันที่ลงนาม</th>
                              <th scope="col">สถานะ</th>
                              <th scope="col" className="text-center">
                                รายละเอียด
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentData?.contract.length > 0 ? (
                              studentData?.contract.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="align-middle"> {item.name} </td>
                                    <td className="align-middle">
                                      {dateHelper.getDateTime(item.contract_date)}
                                    </td>
                                    <td className={item.classStatus + ' align-middle'}>
                                      <FontAwesomeIcon icon={faCircle} className="" />
                                      {item.status}
                                    </td>
                                    <td className="align-middle text-center px-1">
                                      <Link
                                        className="btn btn-outline-primary px-2"
                                        to={'/contract/' + item.id}
                                      >
                                        <FontAwesomeIcon icon={faSearch} className="" />
                                        ดูรายละเอียด
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td valign="top" colSpan={4} className="text-center">
                                  ไม่พบข้อมูล
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>

                <Contract
                  data={studentData?.contract}
                  isShow={studentData?.status['student_status_group_id'] == 1}
                />
              </>
            ) : (
              <CardLoading />
            )}

            <Education data={studentData?.education} isLoading={!loading} />
            <Agency data={studentData?.agency} isLoading={!loading} />
            <LifeRecord data={studentData?.lifeRecord} isLoading={!loading} />

            {/* <div className="card border-0 mb-3">
                  <div className="card-body px-2 d-flex">
                    <Col md={4} className="px-2">
                      <div style={{background: '#ebedf5', borderRadius: 10, padding: '1rem'}}>

                        <div style={{width: 70, height: 70, margin: '0 auto', background: '#fff', borderRadius: '50%', padding: '1.25rem', textAlign: 'center'}}>

                          <FontAwesomeIcon icon={faFileAlt} style={{fontSize: '2rem'}}/>
                          </div>
                      
                        <h5 className="text-center my-3">ยื่นคำร้อง</h5>
                        <Link className="btn btn-primary d-block" to="/petition-student-status/create"
                         style={{ width: '200px',
                         textAlign: 'center',
                         margin: 'auto',
                         marginTop: '40px'}}>
                          <FontAwesomeIcon icon={faPenAlt} /> ยื่นคำร้อง
                        </Link>
                      </div>
                    </Col>
                    <Col md={4} className="px-2">
                      <div style={{background: '#ebedf5', borderRadius: 10, padding: '1rem'}}>

                        <div style={{width: 70, height: 70, margin: '0 auto', background: '#fff', borderRadius: '50%', padding: '1.25rem', textAlign: 'center'}}>

                          <FontAwesomeIcon icon={faUserGraduate} style={{fontSize: '2rem'}}/>
                        </div>

                        <h5 className="text-center my-3">รายงานความก้าวหน้าทางการศึกษา</h5>
                        <Link className="btn btn-primary d-block" to="/educational-progress/create" 
                        style={{ width: '200px',
                            textAlign: 'center',
                            margin: 'auto',
                            marginTop: '40px'}}>
                          <FontAwesomeIcon icon={faList} /> อัปเดตรายการ
                        </Link>
                      </div>
                    </Col>
                    <Col md={4} className="px-2">
                    <div style={{background: '#ebedf5', borderRadius: 10, padding: '1rem'}}>

                      <div style={{width: 70, height: 70, margin: '0 auto', background: '#fff', borderRadius: '50%', padding: '1.25rem', textAlign: 'center'}}>

                        <FontAwesomeIcon icon={faBriefcase} style={{fontSize: '2rem'}}/>
                      </div>

                      <h5 className="text-center my-3">คำนวณการตอบแทนทุน</h5>
                      <Link className="btn btn-primary d-block" to="/"
                       style={{ width: '200px',
                       textAlign: 'center',
                       margin: 'auto',
                       marginTop: '40px'}}>
                        <FontAwesomeIcon icon={faCalculator} /> คำนวณผล
                      </Link>
                      </div>
                    </Col>
                  </div>
                </div> */}
          </div>
        </section>
      </div>
    </>
  )
}

export default Dashboard
