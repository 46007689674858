import PersonalInformationUpdate from '../../pages/personal-information/Update';
import PersonalInformationView from '../../pages/personal-information/View'

export const PersonalInformation = [
    //ข้อมูลของฉัน
    {
        path: '/personal-information/update',
        name: 'แก้ไขข้อมูลส่วนบุคคล', 
        component: PersonalInformationUpdate,
        layout: 'personal-information',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'แก้ไขข้อมูลส่วนบุคคล' },
    },
    {
        path: '/personal-information',
        name: 'ข้อมูลของฉัน', 
        component: PersonalInformationView,
        layout: 'personal-information',
        breadcrumb: {'parent': '','child': 'ข้อมูลของฉัน' },
    },
  
]