import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState, forwardRef } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import * as yup from 'yup'
import { AlertError, AlertSuccess } from '../../components/Alert'
import { yearHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import http from '../../utils/http'
import _ from '../../utils/utils'
import DatePicker from 'react-datepicker'
import { DateButton, IconCalendar, DatePickerWrapperStyles } from '../../components/Filter/styles'
import { format } from 'date-fns'
import { useSemesterOptions } from '../../hooks/options/useOptions'

const FormField = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)

  const academicYearStart = parseInt(data?.academic_year) - 10
  const academicYearEnd = parseInt(data?.academic_year) + 10

  const academicYearOptions = []
  for (let index = academicYearStart; index <= academicYearEnd; index++) {
    academicYearOptions.push({
      value: index,
      label: yearHelper.getYearBE(index),
    })
  }

  const { options: semesterOptions, loading: semesterLoading } = useSemesterOptions()

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

  const urlAction = '/move-classes'
  const urlActionCallAPI = '/student/move-classes/create'

  const validationSchema = yup.object().shape({
    academic_year: yup
      .object()
      .shape({
        label: yup.string().required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),
        value: yup
          .string()
          .required('ปีการศึกษาต้องไม่เป็นค่าว่าง')
          .nullable()
          .required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),
      })
      .nullable()
      .required('ปีการศึกษาต้องไม่เป็นค่าว่าง'),

    class_year: yup.string().required('ชั้นปีต้องไม่ว่าง'),

    semester_id: yup
      .object()
      .shape({
        label: yup.string().required('ภาคเรียนต้องไม่เป็นค่าว่าง'),
        value: yup
          .string()
          .required('ภาคเรียนต้องไม่เป็นค่าว่าง')
          .nullable()
          .required('ภาคเรียนต้องไม่เป็นค่าว่าง'),
      })
      .nullable()
      .required('ภาคเรียนต้องไม่เป็นค่าว่าง'),

    start_date: yup.string().required('วันที่เปิดภาคเรียนต้องไม่ว่าง'),
    end_date: yup.string().required('วันที่ปิดภาคเรียนต้องไม่ว่าง'),
  })

  const academicYear = {
    value: data?.academic_year,
    label: yearHelper.getYearBE(data?.academic_year),
  }

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      publish: '1',
      semester_id: null,
    },
  }

  formOptions.defaultValues = {
    academic_year: academicYear,
  }

  const { handleSubmit, register, control, clearErrors, formState, setValue, getValues } =
    useForm(formOptions)

  const { errors } = formState

  const createData = async (data) => {
    let dataRes = await http.post(urlActionCallAPI, data)
    if (dataRes.status === 201) {
      setIsLoading(false)
      AlertSuccess('สร้างคำขอเรียบร้อย', '')
      history.push(urlAction)
    } else {
      setIsLoading(false)
      AlertError('ไม่สามารถส่งคำขอได้', dataRes.data)
    }
  }

  const onSubmit = async (dataObj) => {
    setIsLoading(true)

    let dataPost = {
      class_year: dataObj.class_year,
      semester_current: data?.semester,
      class_year_current: data?.class_year,
      semester_id: dataObj.semester_id?.value,
      academic_year: dataObj.academic_year?.value,
      education_level_id: data.education_level_id,
      start_date: dataObj.start_date,
      end_date: dataObj.end_date,
      institution_id: data?.institution_id,
    }

    return createData(dataPost)
  }

  const [datepickerItem, setDatepickerItem] = useState({
    start_date: getValues('start_date') ? new Date(getValues('start_date')) : null,
    end_date: getValues('end_date') ? new Date(getValues('end_date')) : null,
  })

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  return (
    <div className="form-field">
      <Row>
        <Col md="5">ระดับการศึกษา</Col>
        <Col md="7">{data?.education_level_name_th}</Col>
      </Row>
      <Row>
        <Col md="5">ปีการศึกษาปัจจุบัน</Col>
        <Col md="7">{data?.academic_year_bc}</Col>
      </Row>
      <Row>
        <Col md="5">ชั้นปีปัจจุบัน</Col>
        <Col md="7">{data?.class_year}</Col>
      </Row>
      <Row>
        <Col md="5">ภาคเรียนปัจจุบัน</Col>
        <Col md="7">{data?.semester}</Col>
      </Row>
      <Row>
        <Col md="5" className="mb-4">
          สถานศึกษาปัจจุบัน
        </Col>
        <Col md="7" className="mb-4">
          {data?.institution_name_th}
        </Col>
      </Row> 
      <div className="mt-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6" className="mb-3">
              <label htmlFor="academic_year" className="label-with-required">
                ปีการศึกษาถัดไป
              </label>
              <Controller
                name="academic_year"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'ปีการศึกษาถัดไป'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={academicYearOptions}
                    placeholder="เลือกปีการศึกษาถัดไป"
                    defaultValue={academicYear}
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => {
                      handleSelectChange(value, 'academic_year')
                    }}
                  />
                )}
              />
              {errors?.academic_year && (
                <div className="help-block">{errors.academic_year?.message}</div>
              )}
            </Col>
            <Col md="3" className="mb-3">
              <label htmlFor="class_year" className="label-with-required">
                ชั้นปีถัดไป
              </label>
              <input
                type="text"
                id="class_year"
                className="form-control font-16"
                {...register('class_year')}
                placeholder="ชั้นปีถัดไป"
                aria-required="true"
              />
              {errors.class_year && <div className="help-block">{errors.class_year.message}</div>}
            </Col>
            <Col md="3" className="mb-3">
              <label htmlFor="semester_id" className="label-with-required">
                ภาคเรียน
              </label>
              <Controller
                name="semester_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="ภาคเรียน"
                    isClearable
                    isDisabled={semesterLoading}
                    isLoading={semesterLoading}
                    isSearchable={true}
                    styles={selectStyles}
                    options={semesterOptions}
                    placeholder="เลือกภาคเรียน"
                    onChange={(value) => handleSelectChange(value, 'semester_id')}
                  />
                )}
              />
              {errors.semester_id && <div className="help-block"> ภาคเรียนต้องไม่เป็นค่าว่าง </div>}
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="form-group  required">
                <label className="control-label label-with-required" htmlFor="start_date">
                  วันที่เปิดภาคเรียน
                </label>
                <>
                  <div className="position-relative">
                    <DatePicker
                      {...register('start_date', {
                        required: true,
                      })}
                      dateFormat="d MMM yyyy"
                      onChange={(event) => {
                        clearErrors('start_date')
                        setValue('start_date', format(event, 'yyyy-MM-dd'))
                        setDatepickerItem({ ...datepickerItem, start_date: event })
                      }}
                      selected={datepickerItem?.start_date}
                      customInput={<InputDate />}
                      className="form-control"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      preventOpenOnFocus={true}
                      placeholder="วันที่เริ่มทำงาน"
                    />
                    <IconCalendar className="fas fa-calendar-alt position-absolute" />
                  </div>
                  <DatePickerWrapperStyles />
                </>
                {errors.start_date && <div className="help-block">{errors.start_date.message}</div>}
              </div>
            </Col>

            <Col md="6">
              <div className="form-group ">
                <label className="control-label label-with-required" htmlFor="end_work">
                  วันที่ปิดภาคเรียน
                </label>
                <>
                  <div className="position-relative">
                    <DatePicker
                      {...register('end_date', {
                        required: true,
                      })}
                      dateFormat="d MMM yyyy"
                      onChange={(event) => {
                        clearErrors('end_date')
                        setValue('end_date', format(event, 'yyyy-MM-dd'))
                        setDatepickerItem({ ...datepickerItem, end_date: event })
                      }}
                      selected={datepickerItem?.end_date}
                      customInput={<InputDate />}
                      className="form-control"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      preventOpenOnFocus={true}
                      placeholder="วันที่สิ้นสุด"
                    />
                    <IconCalendar className="fas fa-calendar-alt position-absolute" />
                  </div>
                  <DatePickerWrapperStyles />
                </>
                {errors.end_date && <div className="help-block">{errors.end_date.message}</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-group text-end mt-4">
                {!isLoading ? (
                  <button type="submit" className="btn btn-primary">
                    บันทึก
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary" disabled>
                    กำลังบันทึก...
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default FormField
