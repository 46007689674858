import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import useFetch from '../../utils/useFetch';
import Update from '../../components/personal-information/Update';
 
const PersonalInformationUpdate = (props) => {
     
    const [error, setError] = useState(null);
    let content = <Update />;

    if (error) {
        content = <p>{error}</p>;
    }

    return (
    <>
     <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
            <li className="breadcrumb-item">
                <Link to="/">
                <FontAwesomeIcon icon={faHome} />
                </Link>
            </li>

            <li className="breadcrumb-item">
                <Link to="/personal-information"> ข้อมูลของฉัน </Link>
            </li>
            <li
                className="breadcrumb-item active font-sarabun"
                aria-current="page"  >
                แก้ไขข้อมูลของฉัน
            </li> 
        
        </ol>
    </nav>
    { content }
    </>
    )
}

export default PersonalInformationUpdate;
