import SettingBankAccount from '../../pages/setting/BankAccount';
import BankAccountDownloadForm from "../../pages/setting/BankAccountDownloadForm";
import SettingBankAccountCreate from "../../pages/setting/BankAccountCreate";
import SettingBankAccountUpdate from "../../pages/setting/BankAccountUpdate";
import SettingProfile from '../../pages/setting/Profile'

export const Setting = [
  //ผลงาน
  {
    path: "/profile/edit",
    name: "ตั้งค่าการใช้งาน",
    component: SettingProfile,
    layout: "setting",
    breadcrumb: { parent: "", child: "ตั้งค่าการใช้งาน" },
  },

  {
    path: "/bank-account/download",
    name: "ดาวน์โหลดฟอร์ม",
    component: BankAccountDownloadForm,
    layout: "setting",
    breadcrumb: { parent: "ตั้งค่าบัญชีธนาคาร", child: "ดาวน์โหลดฟอร์ม" },
  },

  {
    path: "/bank-account/create",
    name: "เพิ่มข้อมูลธนาคาร",
    component: SettingBankAccountCreate,
    layout: "setting",
    breadcrumb: { parent: "ตั้งค่าบัญชีธนาคาร", child: "เพิ่มข้อมูลธนาคาร" },
  },
  {
    path: "/bank-account/update/:id",
    name: "แก้ไขข้อมูลธนาคาร",
    component: SettingBankAccountUpdate,
    layout: "setting",
    breadcrumb: { parent: "ตั้งค่าบัญชีธนาคาร", child: "แก้ไขข้อมูลธนาคาร" },
  },
  {
    path: "/bank-account",
    name: "ตั้งค่าบัญชีธนาคาร",
    component: SettingBankAccount,
    layout: "setting",
    breadcrumb: { parent: "", child: "ตั้งค่าบัญชีธนาคาร" },
  },
];