import React, { Fragment, useState, useRef, useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHome, faSearch, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { Card, Table, Container, Form, Row, Col, Spinner } from 'react-bootstrap'

import { useForm, Controller } from 'react-hook-form'
import useFetch from '../../utils/useFetch'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Select from 'react-select'

import http from '../../utils/http'
import { history } from '../../helpers/history'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`

const PortfolioCreate = (props) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }
  const [bankAccount, setBankAccount] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  //const [error, setError] = useState(null);

  const title = 'เพิ่มผลงาน'
  const breadcrumb = { link: '/personal-information', name: 'ข้อมูลของฉัน' }
  const pathname = props.match.url

  const urlAction = '/personal-information'
  const urlActionCallAPI = '/student/portfolio/create'

  //const { id } = useParams()
  const [files, setFiles] = useState([])
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumbs = files?.map((item, index) => (
    <ListItem key={index + item.file.name} className={'success'}>
      <div className="upload-list-container">
        <div className="upload-list-item">
          <div className="upload-list-item-info">
            <span className="upload-span">
              <FontAwesomeIcon icon={faFileAlt} className="" />
              <span className="upload-list-item-name">
                {item.file.name} ({item.file.size / 1024} KB)
              </span>

              <span className="upload-list-item-card-actions picture">
                <button
                  title="Remove file"
                  type="button"
                  className="btn-item-card-actions-remove"
                  onClick={(e) => handleRemoveFile(index, e)}
                >
                  <span role="img" aria-label="delete" className="anticon anticon-delete">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="delete"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
            </span>
          </div>
          <div className="upload-list-item-description">
            <div className="input-group">
              <span className="input-group-text">คำอธิบาย</span>
              <input
                type="text"
                name="description"
                placeholder="คำอธิบาย"
                className="form-control"
                value={item.description || ''}
                onChange={(e) => handleChangeFileDescription(index, e)}
              />
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  ))

  const validationSchema = yup.object().shape({
    name: yup.string().required('ชื่อผลงาน ต้องไม่เป็นค่าว่าง'),
    portfolio_type_id: yup.string().required('ประเภทผลงาน ต้องไม่เป็นค่าว่าง'),
    education_level_id: yup.string().required('ระดับการศึกษา ต้องไม่เป็นค่าว่าง'),
    country_id: yup.string().required('ประเทศ ต้องไม่เป็นค่าว่าง'),
    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
    //account_file: yup.string().required('แนบไฟล์ ต้องไม่เป็นค่าว่าง'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
  }

  //const { register, handleSubmit, formState, setValue } = useForm(formOptions)
  const { register, handleSubmit, reset, clearErrors, setError, formState, setValue } =
    useForm(formOptions)

  const { errors } = formState

  const onSubmit = async (dataObj) => {
    setIsLoading(true)
    if (dataObj) {
      let dataPost = {
        name: dataObj.name,
        portfolio_type_id: dataObj.portfolio_type_id,
        country_id: dataObj.country_id,
        education_level_id: dataObj.education_level_id,
        type: dataObj.type,
        year: dataObj.year,
        files: files,
      }
      let dataRes = await http.post(`${urlActionCallAPI}`, dataPost)

      if (dataRes.message === 'Accepted') {
        setIsLoading(false)
        history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
      }
    }
  }

  const { response: responsePortfolioType, loading: loadingPortfolioType } = useFetch(
    '/student/masterdata/portfolio-type'
  )

  const portfolioType = responsePortfolioType?.map((item, index) => {
    return {
      value: `${item.name}`,
      label: `${item.name}`,
    }
  })

  const { response: responseEducationLevel, loading: loadingEducationLevel } = useFetch(
    '/student/masterdata/education-level'
  )

  const educationLevel = responseEducationLevel?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name_th}`,
    }
  })

  const { response: responseCountry, loading: loadingCountry } = useFetch(
    '/student/masterdata/country'
  )

  const countryOption = responseCountry?.map((item, index) => {
    return {
      value: `${item.id}`,
      label: `${item.name_th} ${item.name_en ? `(${item.name_en})` : ''}`,
    }
  })

  const handleChange = (name, e) => {
    setValue(name, e.value)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB

    if (fileInput.length === 0) {
      setError('file', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }

    if (!fileInput[0].name.match(/\.(pdf)$/)) {
      setError('file', {
        type: 'manual',
        message: 'ไฟล์นามสกุล .pdf เท่านั้น',
      })
      return false
    }
    if (fileSize > 10) {
      setError('file', {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      })
      return false
    }

    clearErrors('file')

    if (!errors.file) {
      setFiles(newFileInput)
    }
  }

  const handleRemoveFile = (e) => {
    setFiles([])
    clearErrors('file')
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/personal-information"> ข้อมูลของฉัน</Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            เพิ่มผลงาน
          </li>
        </ol>
      </nav>
      <div className="portfolio-create">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex align-items-center">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <span> เพิ่มผลงานใหม่ </span>
                    </h5>
                  </div>
                </div>
              </div>

              <div className="card-body col-12 col-md-8">
                <div className="portfolio-form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="name" className="label-with-required">
                          ชื่อผลงาน
                        </label>
                        <input
                          type="text"
                          {...register('name')}
                          id="name"
                          className="form-control font-16"
                          placeholder="ชื่อผลงาน"
                          aria-required="true"
                        />

                        {errors.name && <div className="help-block">{errors.name.message}</div>}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="portfolio_type_id" className="label-with-required">
                          ประเภทผลงาน
                        </label>
                        <Select
                          isMulti={false}
                          options={portfolioType}
                          styles={customStyles}
                          onChange={(e) => handleChange('portfolio_type_id', e)}
                          placeholder="เลือกประเภทผลงาน"
                        />
                        {errors.portfolio_type_id && (
                          <div className="help-block">{errors.portfolio_type_id.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="account_type" className="label-with-required">
                          ระดับการศึกษา
                        </label>
                        <Select
                          isMulti={false}
                          options={educationLevel}
                          styles={customStyles}
                          onChange={(e) => handleChange('education_level_id', e)}
                          placeholder="เลือกระดับการศึกษา"
                        />
                        {errors.education_level_id && (
                          <div className="help-block">{errors.education_level_id.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="country_id" className="label-with-required">
                          ประเทศ
                        </label>
                        <Select
                          options={countryOption}
                          loading={loadingCountry}
                          isDisabled={loadingCountry}
                          styles={customStyles}
                          onChange={(e) => handleChange('country_id', e)}
                          placeholder="เลือกประเทศ"
                        />
                        {errors.country_id && (
                          <div className="help-block">{errors.country_id.message}</div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3">
                        <label htmlFor="file" className="label-with-required">
                          แนบไฟล์
                        </label>
                        <div className="file-caption">
                          <div className="upload-select">
                            <div className="d-flex justify-content-start">
                              <div className="btn-file-upload">
                                <span role="img" aria-label="upload" className="icon-upload">
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="upload"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload</span>
                                <input
                                  type="file"
                                  // style={{ display: "none" }}
                                  //  className={"btn-input-upload"}
                                  id="icon-button-file"
                                  {...register('file')}
                                  onChange={(e) => handleChangeFile(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <aside style={thumbsContainer} className="upload-list">
                            {thumbs}
                          </aside>
                        </div>
                        <span className="text-secondary  font-size-xs">
                          ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น
                        </span>
                        {errors.file && <div className="help-block">{errors.file.message}</div>}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="my-3 d-flex justify-content-end">
                        <Link
                          className="btn btn-outline-primary btn-add mx-3"
                          to="/personal-information"
                        >
                          ยกเลิก
                        </Link>
                        {!isLoading ? (
                          <button type="submit" className="btn btn-primary btn-add">
                            บันทึก
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary btn-add" disabled>
                            กำลังบันทึก...
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PortfolioCreate
