import React, { useState, useCallback,useEffect, useRef, useContext } from "react";
import { yupResolver } from '@hookform/resolvers/yup'
import logo from '../../assets/images/logo/static_IPST_Logo.png'; 
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { history } from '../../helpers/history' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import http from '../../utils/http'
import axios from 'axios'
import publicIp from "public-ip";



const ForgotPassword = () => {

  useEffect(() => {
    document.title = 'ลืมรหัสผ่าน';
  });

  const validationSchema = yup.object().shape({
    email: yup.string().required('อีเมลต้องไม่เป็นค่าว่าง'),
  })
  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, reset, setError, formState } = useForm(formOptions)
  const { errors } = formState
  const [isSendMail, setIsSendMail] = useState({});
  const [isSubmitSendMail, setIsSubmitSendMail] = useState(false);
  

  const apiKey = process.env.REACT_APP_API_KEY
  const apiVersion = process.env.REACT_APP_API_VERSION
  const baseUrl = process.env.REACT_APP_API_URL


  const forgotPassword = useCallback(async (credentials) => {
    // setError(null);
    try {
      let formData = new FormData(); //formdata object

      formData.append("email",credentials.email); //append the values with key, value pair

      const publicIpAddress = await publicIp.v4();

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          device: publicIpAddress,
        },
      };

      const response = await fetch(baseUrl + "/student/user/forgotpassword", {
        method: "POST",
        body: formData,
        config,
      });

      if (response.ok) {
        const data = await response.json();

        setIsSubmitSendMail(false);
        if(data.status == 204){

          setIsSendMail({'status':'warning','msg':"ไม่พบอีเมลนี้ในระบบ"});
        }else if(data.status == 200){

          setIsSendMail({'status':'success','msg':"ระบบได้ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านให้ท่านทางอีเมล กรุณาตรวจสอบอีเมล"});
        }
      }
  
    } catch (error) { 
      console.log(error);
    } 
  }, []);


  const onSubmit = async (data) => {
    setIsSubmitSendMail(true);
    if(data.email){
      let dataPost = { 
        email: data.email,
      };  

      forgotPassword(dataPost);
      //setIsSendMail(true);      
    }
  }

  
  return (
    <>

    {
      isSendMail?.status ? (
        <section className="py-3"       
        style={{
          backgroundColor: '#f7f7f7',
        }}>
          <div className="container">
            <div className={"alert alert-"+isSendMail?.status+"-soft mb-0"} role="alert">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>  
             { isSendMail?.msg }
            </div>
          </div>
        </section>
      ):(
        <> </>
      )
    }
    <div
      className="account-page forget-password-page"
      style={{
        fontSize: '.9375rem',
        fontWeight: 1.5,
        color: '#1f1f1f',
        backgroundColor: '#f7f7f7',
        minHeight: '100%',
        overflowX: 'hidden',
      }}
    >
      <div className="main-wrapper">
        <div className="account-content">

          <div className="container">
          
            <div className="account-logo text-center">
              <img src={logo} width={100} alt="สสวท." className="m-auto"/>
              <h4 className="mt-3">ระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา</h4>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <div className="account-title mb-3">
                <Form onSubmit={handleSubmit(onSubmit)}>
                      <h3 className="account-title">ลืมรหัสผ่าน</h3>
                      <p className="account-subtitle " style={{ fontSize: '14px' }}>
                        ป้อนที่อยู่อีเมลแล้วระบบจะส่งลิงก์เพื่อรีเซ็ตรหัสผ่านของคุณทางอีเมล
                      </p>
                      <Row className="text-left">
                        <Col md={12} className="mb-3">
                          <label htmlFor="username" className="label-with-required">
                            อีเมล
                          </label>

                          <input
                            type="text"
                            placeholder="อีเมล"
                            {...register('email')}
                            className="form-control"
                          />
                          {errors.email && <div className="help-block">{errors.email.message}</div>}
                        </Col>

                        <Col md={12} className="mb-3">
                          {
                             isSubmitSendMail ? (
                              <button type="submit" className="btn account-btn text-white" disabled>
                                กำลังส่งอีเมล...
                              </button>
                             ):(
                              <button type="submit" className="btn account-btn text-white">
                                รีเซ็ตรหัสผ่าน
                              </button>
                             )
                          }
                          
                        
                        </Col>
                        {errors.apiError && (
                          <div className="alert alert-danger mt-3 mb-0">
                            {errors.apiError?.message}
                          </div>
                        )}
                      </Row>
                    </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   
  </>
  )
}

export default ForgotPassword
