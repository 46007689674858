// import PetitionCreate from '../../pages/petition/Create'
// import PetitionView from '../../pages/petition/View'
// import PetitionHistory from '../../pages/petition/History'
// import PetitionList from '../../pages/petition/List'
// import PetitionTemplate from '../../pages/petition/Template'

import * as Pages from '../../pages/petition-expense'

export const PetitionExpense = [
  {
    path: '/petition-expense/create',
    name: 'ยื่นคำร้องใหม่',
    component: Pages.Create,
    layout: 'petition-other',
    breadcrumb: { parent: 'คำร้องค่าใช้จ่าย', child: 'เพิ่มคำร้อง' },
  },
  {
    path: '/petition-expense/history/detail/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.HistoryDetail,
    layout: 'petition-expense',
    breadcrumb: {
      parent: 'คำร้องค่าใช้จ่าย',
      child: 'รายละเอียดประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-expense/history/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.History,
    layout: 'petition-expense',
    breadcrumb: {
      parent: 'คำร้องค่าใช้จ่าย',
      child: 'ประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-expense',
    name: 'คำร้องค่าใช้จ่าย',
    component: Pages.List,
    layout: 'petition-expense',
    breadcrumb: { parent: '', child: 'คำร้องค่าใช้จ่าย' },
  },
  {
    path: '/petition-expense/:id',
    name: 'คำร้องค่าใช้จ่าย',
    component: Pages.View,
    layout: 'petition-expense',
    breadcrumb: { parent: '', child: 'รายละเอียดคำร้อง' },
  },
  {
    path: '/petition-expense/print/:id',
    name: 'คำร้องค่าใช้จ่าย',
    component: Pages.Template,
    layout: 'petition-expense',
    breadcrumb: { parent: '', child: 'รายละเอียดคำร้อง' },
  },
]
