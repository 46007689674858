import React, { useState } from 'react'

import { faCircle, faDownload, faEye, faHome, faMedal } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'

const StudentAwardView = (props) => {
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  let id = props.match.params.id
  const { response: responseData, loading } = useFetch('/student/student-award/' + id)

  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/student-award/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/student-award/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </>
      )
    })
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/personal-information"> ข้อมูลของฉัน </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            {responseData?.name}
          </li>
        </ol>
      </nav>
      <div className="portfolio-view">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div className="w-100">
                    {!loading ? (
                      <h5 className="card-title me-auto">
                        <div className="bg-icon">
                          <FontAwesomeIcon icon={faMedal} />
                        </div>
                        <span> {responseData?.name} </span>
                      </h5>
                    ) : (
                      <h5 className="card-title me-auto">
                        <div className="bg-icon">
                          <FontAwesomeIcon icon={faMedal} />
                        </div>
                        <Skeleton width={300} />
                      </h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  // <ReactLoading type="bubbles" color="#26b9d1" className="m-auto"/>
                  <table id="w0" className="table">
                    <tbody>
                      <tr>
                        <th>ชื่อรางวัล</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      {/* <tr>
                                    <th>คำอธิบาย</th>
                                    <td className="w-60"> <Skeleton/> </td>
                                </tr> */}

                      <tr>
                        <th>กลุ่มรางวัล</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ประเทศ</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ระดับการศึกษา</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>หน่วยงาน / องค์กร</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ปีที่ได้รับ (ค.ศ.)</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>ไฟล์แนบ</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>สถานะ</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>

                      <tr>
                        <th>เหตุผล</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>

                      <tr>
                        <th>วันที่สร้าง</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่อัปเดต</th>
                        <td className="w-60">
                          <Skeleton />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <>
                    <table id="w0" className="table">
                      <tbody>
                        <tr>
                          <th>ชื่อรางวัล</th>
                          <td className="w-60"> {responseData?.name}</td>
                        </tr>
                        {/* <tr>
                                    <th>คำอธิบาย</th>
                                    <td className="w-60">  { responseData?.description} </td>
                                </tr> */}
                        <tr>
                          <th>กลุ่มรางวัล</th>
                          <td className="w-60"> {responseData?.award_group} </td>
                        </tr>
                        <tr>
                          <th>ประเทศ</th>
                          <td className="w-60"> {responseData?.country_name} </td>
                        </tr>
                        <tr>
                          <th>ระดับการศึกษา</th>
                          <td className="w-60"> {responseData?.education_level} </td>
                        </tr>
                        <tr>
                          <th>หน่วยงาน / องค์กร</th>
                          <td className="w-60"> {responseData?.organization_name} </td>
                        </tr>
                        <tr>
                          <th>ปีที่ได้รับ (ค.ศ.)</th>
                          <td className="w-60"> {responseData?.year} </td>
                        </tr>
                        <tr>
                          <th>ไฟล์แนบ</th>
                          <td>
                            {responseData?.files.length > 0
                              ? renderFileDocument(responseData?.files)
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <th>สถานะ</th>
                          <td className={responseData?.classStatus + ' align-middle w-60'}>
                            <FontAwesomeIcon icon={faCircle} className="pe-1" />
                            {responseData?.status}
                          </td>
                        </tr>
                        <tr>
                          <th>เหตุผล</th>
                          <td>{responseData?.note}</td>
                        </tr>

                        <tr>
                          <th>วันที่สร้าง</th>
                          <td className=" w-60">
                            {dateHelper.getDateTime(responseData?.created_at)}
                          </td>
                        </tr>
                        <tr>
                          <th>วันที่อัปเดต</th>
                          <td className=" w-60">
                            {dateHelper.getDateTime(responseData?.updated_at)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
            {!loading ? (
              <>
                <Modal
                  show={show}
                  size="lg"
                  dialogClassName="modal-preview"
                  centered
                  onHide={() => setShow(false)}
                  //fullscreen={true}
                  aria-labelledby="file-name"
                >
                  <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                    <Modal.Title id="file-name">{fileName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <PDFViewer pdf={filePreview} />
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
                <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
              </>
            )}
          </div>
        </section>
      </div>
    </>
  )
}

export default StudentAwardView
