import { format } from 'date-fns'
import addDays from 'date-fns/addDays'
import React, { forwardRef, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import {
  useCurrencyOptions,
  usePrefixOptions,
  useNationalityOptions,
} from '../../../hooks/options/useOptions'
import _ from '../../../utils/utils'
import { DateButton, DatePickerWrapperStyles, IconCalendar } from '../../Filter/styles'
import { selectStyle } from '../styled'

const Guarantor = (props) => {
  const {
    register,
    errors,
    setValue,
    getValues,
    relationshipOption,
    occupationOptions,
    provinceOptions,
    contractData,
    clearErrors,
    guarantorData,
    guarantorOptions,
    trigger,
    control,
    currentAddress,
    studentAddress,
  } = props

  const { options: prefixOptions, loading: prefixLoading } = usePrefixOptions()
  const { options: currencyOptions, loading: currencyLoading } = useCurrencyOptions()
  const { options: nationalityOptions, loading: nationalityLoading } = useNationalityOptions()

  const newRelationshipOption =
    relationshipOption !== null
      ? relationshipOption.map(function (option) {
          return {
            value: option.name,
            label: option.name,
          }
        })
      : []

  const newOccupationOptions =
    occupationOptions !== null
      ? occupationOptions.map(function (option) {
          return {
            value: option.name,
            label: option.name,
          }
        })
      : []

  const newProvinceOptions =
    provinceOptions !== null
      ? provinceOptions.map(function (option) {
          return {
            value: option.id,
            label: option.name_th,
          }
        })
      : []
  const [guarantor, setGuarantor] = useState({
    name_title_id:
      contractData?.guarantor_name_title_th !== null
        ? {
            label: contractData?.guarantor_name_title_th,
            value: contractData?.guarantor_name_title_id,
          }
        : null,
    relationship: newRelationshipOption
      ? newRelationshipOption.filter(function (option) {
          return option.value === String(getValues('guarantor_relationship'))
        })[0]
      : null,
  })

  const [provinceItem, setprovinceItem] = useState({
    guarantor_province_id: getValues('guarantor_province_id')
      ? getValues('guarantor_province_id')
      : '',
    land_1_province_id: getValues('land_1_province_id') ? getValues('land_1_province_id') : '',
    land_2_province_id: getValues('land_2_province_id') ? getValues('land_2_province_id') : '',
    spouse_province_id: getValues('spouse_province_id') ? getValues('spouse_province_id') : '',

    district_guarantor_id: getValues('district_guarantor_id')
      ? getValues('district_guarantor_id')
      : '',
    district_land_1_id: getValues('district_land_1_id') ? getValues('district_land_1_id') : '',
    district_land_2_id: getValues('district_land_2_id') ? getValues('district_land_2_id') : '',
    district_spouse_id: getValues('district_spouse_id') ? getValues('district_spouse_id') : '',

    subdistrict_guarantor_id: getValues('subdistrict_guarantor_id')
      ? getValues('subdistrict_guarantor_id')
      : '',
    subdistrict_land_1_id: getValues('subdistrict_land_1_id')
      ? getValues('subdistrict_land_1_id')
      : '',
    subdistrict_land_2_id: getValues('subdistrict_land_2_id')
      ? getValues('subdistrict_land_2_id')
      : '',
    subdistrict_spouse_id: getValues('subdistrict_spouse_id')
      ? getValues('subdistrict_spouse_id')
      : '',

    zipcode_guarantor_id: getValues('zipcode_guarantor_id')
      ? getValues('zipcode_guarantor_id')
      : '',
    zipcode_land_1_id: getValues('zipcode_land_1_id') ? getValues('zipcode_land_1_id') : '',
    zipcode_land_2_id: getValues('zipcode_land_2_id') ? getValues('zipcode_land_2_id') : '',
    zipcode_spouse_id: getValues('zipcode_spouse_id') ? getValues('zipcode_spouse_id') : '',

    district_guarantor: contractData !== undefined ? contractData.get_district_guarantor : [],
    district_land_1:
      contractData !== undefined && contractData.get_land_1_district !== undefined
        ? contractData.get_land_1_district
        : [],
    district_land_2:
      contractData !== undefined && contractData.get_land_2_district !== undefined
        ? contractData.get_land_2_district
        : [],
    district_spouse: contractData !== undefined ? contractData.get_district_spouse : [],

    subdistrict_guarantor: contractData !== undefined ? contractData.get_subdistrict_guarantor : [],
    subdistrict_land_1:
      contractData !== undefined && contractData.get_land_1_subdistrict !== undefined
        ? contractData.get_land_1_subdistrict
        : [],
    subdistrict_land_2:
      contractData !== undefined && contractData.get_land_2_subdistrict !== undefined
        ? contractData.get_land_2_subdistrict
        : [],
    subdistrict_spouse: contractData !== undefined ? contractData.get_subdistrict_spouse : [],
    is_initData: false,
    guarator_options: [],
  })
  const onActionUpdate = () => {
    if (contractData && provinceItem.is_initData === false) {
      let set_land_1_province_id = ''
      let set_land_1_district_id = ''
      let set_land_1_subdistrict_id = ''

      let set_land_2_province_id = ''
      let set_land_2_district_id = ''
      let set_land_2_subdistrict_id = ''

      if (contractData.guaratee_detail.length > 0) {
        let index_land = 1
        for (let index = 0; index < contractData.guaratee_detail.length; index++) {
          const element = contractData.guaratee_detail[index]
          if (element.type === 'land') {
            if (index_land === 1) {
              set_land_1_province_id = element?.land_province_id
              set_land_1_district_id = element?.land_district_id
              set_land_1_subdistrict_id = element?.land_subdistrict_id
            } else if (index_land === 2) {
              set_land_2_province_id = element?.land_province_id
              set_land_2_district_id = element?.land_district_id
              set_land_2_subdistrict_id = element?.land_subdistrict_id
            }
            index_land++
          }
        }
      }

      setprovinceItem({
        ...provinceItem,
        is_initData: true,
        district_guarantor: contractData?.get_district_guarantor,
        district_land_1:
          contractData !== undefined && contractData.get_land_1_district !== undefined
            ? contractData.get_land_1_district
            : [],
        district_land_2:
          contractData !== undefined && contractData.get_land_2_district !== undefined
            ? contractData.get_land_2_district
            : [],
        district_spouse:
          contractData !== undefined && contractData.get_district_spouse !== undefined
            ? contractData.get_district_spouse
            : [],

        subdistrict_guarantor: contractData?.get_subdistrict_guarantor,
        subdistrict_land_1:
          contractData !== undefined && contractData.get_land_1_subdistrict !== undefined
            ? contractData.get_land_1_subdistrict
            : [],
        subdistrict_land_2:
          contractData !== undefined && contractData.get_land_2_subdistrict !== undefined
            ? contractData.get_land_2_subdistrict
            : [],
        subdistrict_spouse:
          contractData !== undefined && contractData.get_subdistrict_spouse !== undefined
            ? contractData.get_subdistrict_spouse
            : [],

        guarantor_province_id: contractData?.guarantor_province_id,
        land_1_province_id: set_land_1_province_id,
        land_2_province_id: set_land_2_province_id,
        spouse_province_id: contractData?.spouse_province_id,

        district_guarantor_id: contractData?.guarantor_district_id,
        district_land_1_id: set_land_1_district_id,
        district_land_2_id: set_land_2_district_id,
        district_spouse_id: contractData?.spouse_district_id,

        subdistrict_guarantor_id: contractData?.guarantor_subdistrict_id,
        subdistrict_land_1_id: set_land_1_subdistrict_id,
        subdistrict_land_2_id: set_land_2_subdistrict_id,
        subdistrict_spouse_id: contractData?.spouse_subdistrict_id,

        zipcode_guarantor_id: contractData?.guarantor_zipcode,
        zipcode_spouse_id: contractData?.spouse_zipcode,
      })
    }
  }

  const onChangeProvince = async (provinceId, set_form, ele_name) => {
    let set_form_subdistrict_item = 'subdistrict_' + set_form
    let set_form_subdistrict = 'subdistrict_' + set_form + '_id'
    let set_form_zipcode = 'zipcode_' + set_form + '_id'
    const baseUrl = process.env.REACT_APP_API_URL
    const res = await fetch(baseUrl + '/district?province_id=' + provinceId)
    // const res  = await http.get('/api/district?province_id=' + provinceId)
    const json = await res.json()
    if (json.data) {
      let items = json.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })

      setprovinceItem({
        ...provinceItem,
        ['district_' + set_form]: items,
        [ele_name]: provinceId,
        [set_form_subdistrict_item]: [],
        [set_form_subdistrict]: '',
        [set_form_zipcode]: '',
      })
    } else {
      setprovinceItem({
        ...provinceItem,
        ['district_' + set_form]: [],
        [ele_name]: provinceId,
        [set_form_subdistrict_item]: [],
        [set_form_subdistrict]: '',
        [set_form_zipcode]: '',
      })
    }
  }
  const onChangeDistrict = async (districtId, set_form, ele_name) => {
    const baseUrl = process.env.REACT_APP_API_URL
    let set_form_zipcode = 'zipcode_' + set_form + '_id'
    const res = await fetch(baseUrl + '/sub-district?district_id=' + districtId)
    const json = await res.json()
    if (json.data) {
      let items = json.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setprovinceItem({
        ...provinceItem,
        ['subdistrict_' + set_form]: items,
        [ele_name]: districtId,
        [set_form_zipcode]: '',
      })
    } else {
      setprovinceItem({
        ...provinceItem,
        ['subdistrict_' + set_form]: [],
        [ele_name]: districtId,
        [set_form_zipcode]: '',
      })
    }
  }
  const onChangeSubDistrict = async (subdistrictId, set_form, ele_name) => {
    set_form = 'zipcode_' + set_form + '_id'
    const baseUrl = process.env.REACT_APP_API_URL
    const res = await fetch(baseUrl + '/zipcode?sub_district_id=' + subdistrictId)
    const json = await res.json()
    if (json.data) {
      let items = json.data.map((item, index) => {
        return item.zipcode
      })
      setprovinceItem({
        ...provinceItem,
        [set_form]: items,
        [ele_name]: subdistrictId,
      })
    } else {
      setprovinceItem({
        ...provinceItem,
        [set_form]: '',
        [ele_name]: subdistrictId,
      })
    }
  }

  const selectGuarantor = async (value) => {
    if (value !== null && value !== undefined) {
      let guarantor = guarantorData[value.value]?.data
      let nameTitleID =
        guarantor?.length !== 0 && guarantor?.name_title_th
          ? {
              label: guarantor?.name_title_th,
              value: guarantor?.name_title_id,
            }
          : null
      let option_relationship =
        guarantor?.length !== 0 && guarantor?.relationship
          ? {
              label: guarantor?.relationship,
              value: guarantor?.relationship,
            }
          : null
      setGuarantor({
        ...guarantor,
        name_title_id: nameTitleID,
        name_title_th: guarantor?.name_title_th,
        relationship: option_relationship,
      })

      // console.log(array_data)
      setValue('guarantor_name_title_id', nameTitleID)
      setValue('guarantor_firstname_th', guarantor.firstname_th)
      setValue('guarantor_lastname_th', guarantor.lastname_th)
      setValue('guarantor_firstname_en', guarantor.firstname_en)
      setValue('guarantor_lastname_en', guarantor.lastname_en)
      setValue('guarantor_citizen_id_number', guarantor.citizen_id_number)
      setValue('guarantor_phone_number', guarantor.phone_number)
      setValue('guarantor_email', guarantor.email)
      setValue('guarantor_relationship', option_relationship)
      setValue('guarantor_nationality', guarantor.nationality)
      setValue('guarantor_occupation', guarantor.occupation)
      setValue('guarantor_organization', guarantor.organization)
      // setValue('fieldArray', [
      //   { guarantor_name_title_id: array_data?.name_title_id },
      //   { guarantor_firstname_th: array_data?.firstname_th },
      //   { guarantor_lastname_th: array_data?.lastname_th },
      // ])
      // console.log(getValues('guarantor_firstname_th'))
    }
  }

  const delay = 3
  useEffect(() => {
    if (provinceItem.is_initData === false) {
      let timer1 = setTimeout(() => onActionUpdate(), delay * 1000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [])

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <DateButton onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))
  const [datepickerItem, setDatepickerItem] = useState({
    guarantor_birth_date: getValues('guarantor_birth_date')
      ? new Date(getValues('guarantor_birth_date'))
      : null,
  })
  const setCurrentAddressStudent = (type) => {
    console.log(currentAddress)
    console.log(studentAddress)
    if (currentAddress && type === 'current_address') {
      setValue('guarantor_address_no', currentAddress?.current_address_no)
      setValue('guarantor_village_no', currentAddress?.current_village_no)
      setValue('guarantor_alley', currentAddress?.current_alley)
      setValue('guarantor_road', currentAddress?.current_road)
      setValue('guarantor_province_id', parseInt(currentAddress?.current_province_id))
      setValue('guarantor_district_id', parseInt(currentAddress?.current_district_id))
      setValue('guarantor_subdistrict_id', parseInt(currentAddress?.current_subdistrict_id))
      setValue('guarantor_zipcode', currentAddress?.current_zipcode)
      if (currentAddress?.guarantor_district) {
        let element = currentAddress?.guarantor_district
        currentAddress.get_district_guarantor = element.map((item, index) => {
          return {
            value: item.id,
            label: item.name_th,
          }
        })
      }
      if (currentAddress?.guarantor_subdistrict) {
        let element = currentAddress?.guarantor_subdistrict
        currentAddress.get_subdistrict_guarantor = element.map((item, index) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      }
      setprovinceItem({
        ...provinceItem,
        district_guarantor: currentAddress?.guarantor_district
          ? studentAddress.guarantor_district
          : [],
        subdistrict_guarantor: currentAddress?.guarantor_subdistrict
          ? studentAddress.guarantor_subdistrict
          : [],
        guarantor_province_id: currentAddress?.current_province_id,
        district_guarantor_id: currentAddress?.current_district_id,
        subdistrict_guarantor_id: currentAddress?.current_subdistrict_id,
        zipcode_guarantor_id: currentAddress?.current_zipcode,
      })
    } else if (studentAddress && type === 'address') {
      setValue('guarantor_address_no', studentAddress?.address_no)
      setValue('guarantor_village_no', studentAddress?.village_no)
      setValue('guarantor_alley', studentAddress?.alley)
      setValue('guarantor_road', studentAddress?.road)
      setValue('guarantor_province_id', parseInt(studentAddress?.province_id))
      setValue('guarantor_district_id', parseInt(studentAddress?.district_id))
      setValue('guarantor_subdistrict_id', parseInt(studentAddress?.subdistrict_id))
      setValue('guarantor_zipcode', studentAddress?.zipcode)

      setprovinceItem({
        ...provinceItem,
        district_guarantor: studentAddress?.guarantor_district
          ? studentAddress.guarantor_district
          : [],
        subdistrict_guarantor: studentAddress?.guarantor_subdistrict
          ? studentAddress.guarantor_subdistrict
          : [],
        guarantor_province_id: studentAddress?.province_id,
        district_guarantor_id: studentAddress?.district_id,
        subdistrict_guarantor_id: studentAddress?.subdistrict_id,
        zipcode_guarantor_id: studentAddress?.zipcode,
      })
    }
  }
  return (
    <>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_id">ผู้ค้ำประกัน</label>
          <Select
            {...register('guarantor_id')}
            onChange={(event) => {
              selectGuarantor(event)
            }}
            options={guarantorOptions?.map((option) => {
              return option
            })}
            placeholder="เลือกผู้ค้ำประกัน"
            styles={selectStyle}
          />
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="guarantor_name_title_id" className="label-with-required">
            คำนำหน้า
          </label>
          <Controller
            name="guarantor_name_title_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                onChange={(value) => handleSelectChange(value, 'guarantor_name_title_id')}
                options={prefixOptions}
                isLoading={prefixLoading}
                placeholder="เลือกคำนำหน้า"
                styles={selectStyle}
                defaultValue={guarantor?.name_title_id}
              />
            )}
          />
          {errors?.guarantor_name_title_id?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_firstname_th " className="label-with-required">
            ชื่อ (ภาษาไทย)
          </label>
          <input
            type="text"
            placeholder="ชื่อ (ภาษาไทย)"
            {...register('guarantor_firstname_th')}
            className="form-control"
          />
          {errors?.guarantor_firstname_th?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_lastname_th " className="label-with-required">
            นามสกุล (ภาษาไทย)
          </label>
          <input
            type="text"
            placeholder="นามสกุล (ภาษาไทย)"
            {...register('guarantor_lastname_th')}
            className="form-control"
          />
          {errors?.guarantor_lastname_th?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_firstname_en " className="label-with-required">
            ชื่อ (English)
          </label>
          <input
            type="text"
            placeholder="ชื่อ (English)"
            {...register('guarantor_firstname_en')}
            className="form-control"
          />
          {errors?.guarantor_firstname_en?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_lastname_en " className="label-with-required">
            นามสกุล (English)
          </label>
          <input
            type="text"
            placeholder="นามสกุล (English)"
            {...register('guarantor_lastname_en')}
            className="form-control"
          />
          {errors?.guarantor_lastname_en?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_birth_date " className="">
            วันเดือนปีเกิด
          </label>
          <>
            <div className="position-relative">
              <DatePicker
                {...register('guarantor_birth_date')}
                dateFormat="d MMM yyyy"
                onChange={(event) => {
                  setValue('guarantor_birth_date', format(event, 'yyyy-MM-dd'))
                  setDatepickerItem({
                    ...datepickerItem,
                    guarantor_birth_date: event,
                  })
                }}
                selected={datepickerItem.guarantor_birth_date}
                customInput={<InputDate />}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={addDays(new Date(), 0)}
                preventOpenOnFocus={true}
                placeholder="วันเดือนปีเกิด"
              />
              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
            </div>
            <DatePickerWrapperStyles />
          </>
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_citizen_id_number " className="">
            เลขประจำตัวประชาชน
          </label>
          <input
            type="text"
            placeholder="เลขประจำตัวประชาชน"
            {...register('guarantor_citizen_id_number')}
            className="form-control"
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_phone_number " className="">
            หมายเลขโทรศัพท์
          </label>
          <input
            type="text"
            placeholder="หมายเลขโทรศัพท์"
            {...register('guarantor_phone_number')}
            className="form-control"
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_email" className="">
            อีเมล
          </label>
          <input
            type="text"
            placeholder="อีเมล"
            {...register('guarantor_email')}
            className="form-control"
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_relationship " className="label-with-required">
            ความสัมพันธ์
          </label>
          <Controller
            name="guarantor_relationship"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                onChange={(value) => handleSelectChange(value, 'guarantor_relationship')}
                options={newRelationshipOption?.map((option) => {
                  return option
                })}
                placeholder="เลือกความสัมพันธ์"
                styles={selectStyle}
                defaultValue={guarantor?.relationship}
              />
            )}
          />
          {errors?.guarantor_relationship?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_nationality" className="label-with-required">
            สัญชาติ
          </label>
          <Select
            {...register('guarantor_nationality', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'guarantor_nationality')}
            defaultValue={
              contractData?.guarantor_nationality !== null
                ? {
                    label: contractData?.guarantor_nationality,
                    value: contractData?.guarantor_nationality,
                  }
                : null
            }
            options={nationalityOptions}
            isLoading={nationalityLoading}
            placeholder={'เลือกสัญชาติ'}
            styles={selectStyle}
          />
          {errors?.guarantor_nationality?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_occupation">อาชีพ</label>
          <Select
            {...register('guarantor_occupation', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'guarantor_occupation')}
            defaultValue={
              contractData?.guarantor_occupation !== null
                ? {
                    label: contractData?.guarantor_occupation,
                    value: contractData?.guarantor_occupation,
                  }
                : null
            }
            options={newOccupationOptions?.map((option) => {
              return option
            })}
            placeholder="เลือกอาชีพ"
            styles={selectStyle}
          />
          {errors?.guarantor_occupation?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_position">ตำแหน่ง</label>
          <input
            type="text"
            placeholder="ตำแหน่ง"
            {...register('guarantor_position')}
            className="form-control"
          />
          {errors?.guarantor_position?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_organization">สังกัดหน่วยงาน</label>
          <input
            type="text"
            placeholder="สังกัดหน่วยงาน"
            {...register('guarantor_organization')}
            className="form-control"
          />
          {errors?.guarantor_organization?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="marital_status">สถานภาพการสมรส</label>
          <div className="mb-3 form-check-group">
            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="marital_status_1"
                value="1"
                {...register('marital_status', { required: false })}
                defaultChecked={getValues('marital_status')}
                // onChange={(event) => {
                //   setValue('marital_status', 1)
                // }}
                type="radio"
              />
              <Form.Check.Label htmlFor="marital_status_1">โสด</Form.Check.Label>
            </Form.Check>

            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                {...register('marital_status', { required: false })}
                id="marital_status_2"
                value="2"
                defaultChecked={getValues('marital_status')}
                type="radio"
              />
              <Form.Check.Label htmlFor="marital_status_2">จดทะเบียนสมรส</Form.Check.Label>
            </Form.Check>

            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                {...register('marital_status', { required: false })}
                id="marital_status_3"
                value="3"
                defaultChecked={getValues('marital_status')}
                type="radio"
              />
              <Form.Check.Label htmlFor="marital_status_3">หย่า</Form.Check.Label>
            </Form.Check>

            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                {...register('marital_status', { required: false })}
                id="marital_status_4"
                value="4"
                defaultChecked={getValues('marital_status')}
                type="radio"
              />
              <Form.Check.Label htmlFor="marital_status_4">คู่สมรสเสียชีวิต</Form.Check.Label>
            </Form.Check>
          </div>
          {errors?.marital_status?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mb-3">
          <label htmlFor="address_type">ประเภทที่อยู่</label>
          <div className="mb-3 form-check-group">
            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="address_type-1"
                value="1"
                defaultChecked={'1'}
                {...register('guarantor_address_type', { required: false })}
                type="radio"
                onChange={(event) => {
                  setCurrentAddressStudent('address')
                }}
              />
              <Form.Check.Label htmlFor="address_type-1">ที่อยู่ตามทะเบียนบ้าน</Form.Check.Label>
            </Form.Check>
            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="address_type-2"
                value="2"
                defaultChecked={getValues('guarantor_address_type')}
                {...register('guarantor_address_type', { required: false })}
                type="radio"
                onChange={(event) => {
                  setCurrentAddressStudent('current_address')
                }}
              />
              <Form.Check.Label htmlFor="address_type-2">ที่อยู่ปัจจุบัน</Form.Check.Label>
            </Form.Check>
            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="address_type-3"
                value="3"
                defaultChecked={getValues('guarantor_address_type')}
                {...register('guarantor_address_type', { required: false })}
                type="radio"
              />
              <Form.Check.Label htmlFor="address_type-3">ที่อยู่ใหม่</Form.Check.Label>
            </Form.Check>
          </div>
          {errors?.address_type?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={3} className="mb-3">
          <label htmlFor="guarantor_address_no">เลขที่</label>
          <input type="text" {...register('guarantor_address_no')} className="form-control" />
        </Col>
        <Col md={3} className="mb-3">
          <label htmlFor="guarantor_village_no">หมู่ที่</label>
          <input type="text" {...register('guarantor_village_no')} className="form-control" />
        </Col>
        <Col md={3} className="mb-3">
          <label htmlFor="guarantor_alley">ตรอก/ซอย</label>
          <input type="text" {...register('guarantor_alley')} className="form-control" />
        </Col>
        <Col md={3} className="mb-3">
          <label htmlFor="guarantor_road ">ถนน</label>
          <input type="text" {...register('guarantor_road')} className="form-control" />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_province_id">จังหวัด</label>
          <Select
            {...register('guarantor_province_id', { required: false })}
            onChange={(event) => {
              if (event?.value) {
                setValue('guarantor_province_id', event.value)
                setValue('guarantor_district_id', '')
                setValue('guarantor_subdistrict_id', '')
                setValue('guarantor_zipcode', '')
                onChangeProvince(event.value, 'guarantor', 'guarantor_province_id')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              } else {
                setValue('guarantor_province_id', '')
                setValue('guarantor_district_id', '')
                setValue('guarantor_subdistrict_id', '')
                setValue('guarantor_zipcode', '')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              }
            }}
            value={
              String(getValues('guarantor_province_id')) !== ''
                ? newProvinceOptions.filter(function (option) {
                    //return option.value === String(getValues('guarantor_province_id'))
                    return option.value === String(getValues('guarantor_province_id'))
                  })[0]
                : { label: '', value: '' }
            }
            placeholder="เลือกจังหวัด"
            options={newProvinceOptions?.map((option) => {
              return option
            })}
            styles={selectStyle}
            isClearable
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_district_id">อำเภอ/เขต</label>
          <Select
            {...register('guarantor_district_id', { required: false })}
            isClearable
            onChange={(event) => {
              if (event?.value) {
                setValue('guarantor_subdistrict_id', '')
                setValue('guarantor_zipcode', '')
                setValue('guarantor_district_id', event.value)
                onChangeDistrict(event.value, 'guarantor', 'guarantor_district_id')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              } else {
                setValue('guarantor_district_id', '')
                setValue('guarantor_subdistrict_id', '')
                setValue('guarantor_zipcode', '')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              }
            }}
            value={
              String(getValues('guarantor_district_id')) !== ''
                ? provinceItem.district_guarantor.filter(function (option) {
                    return option.value === String(getValues('guarantor_district_id'))
                  })[0]
                : { label: '', value: '' }
            }
            placeholder="เลือกอำเภอ/เขต"
            defaultValue={
              provinceItem.district_guarantor.filter(function (option) {
                return option.value === String(getValues('guarantor_district_id'))
              })[0]
            }
            options={provinceItem.district_guarantor?.map((option) => {
              return option
            })}
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_subdistrict_id">ตำบล/แขวง</label>
          <Select
            {...register('guarantor_subdistrict_id', { required: false })}
            isClearable
            onChange={(event) => {
              if (event?.value) {
                setValue('guarantor_zipcode', '')
                setValue('guarantor_subdistrict_id', event.value)
                onChangeSubDistrict(event.value, 'guarantor', 'guarantor_subdistrict_id')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              } else {
                setValue('guarantor_subdistrict_id', '')
                setValue('guarantor_zipcode', '')
                setprovinceItem({ ...provinceItem, zipcode_guarantor_id: '' })
              }
            }}
            value={
              String(getValues('guarantor_subdistrict_id')) !== ''
                ? provinceItem.subdistrict_guarantor.filter(function (option) {
                    return option.value === String(getValues('guarantor_subdistrict_id'))
                  })[0]
                : { label: '', value: '' }
            }
            placeholder="เลือกตำบล/แขวง"
            defaultValue={
              provinceItem.subdistrict_guarantor.filter(function (option) {
                return option.value === String(getValues('guarantor_subdistrict_id'))
              })[0]
            }
            options={provinceItem.subdistrict_guarantor?.map((option) => {
              return option
            })}
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="guarantor_zipcode">รหัสไปรษณีย์</label>
          <input
            type="text"
            {...register('guarantor_zipcode')}
            className="form-control"
            value={provinceItem.zipcode_guarantor_id}
          />
        </Col>
      </Row>
      <h4>หลักฐานการค้ำประกัน</h4>
      <h5>ข้อมูลค้ำประกัน</h5>
      <Row>
        <Col md={5}>
          <label htmlFor="guarantor_amount_baht">เงินค้ำประกัน (บาท)</label>
          <Controller
            control={control}
            name="guarantor_amount_baht"
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                name={name}
                value={value}
                onChange={onChange}
                thousandSeparator
                className="form-control"
                placeholder="เงินค้ำประกัน (บาท)"
                disabled={true}
              />
            )}
          />
        </Col>
        <Col md={5}>
          <label htmlFor="guarantor_amount">เงินค้ำประกัน</label>
          <Controller
            control={control}
            name="guarantor_amount"
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                name={name}
                value={value}
                onChange={onChange}
                thousandSeparator
                className="form-control"
                placeholder="เงินค้ำประกัน"
                disabled={true}
              />
            )}
          />
        </Col>
        <Col md={2}>
          <label htmlFor="guarantor_currency_id">สกุลเงิน</label>
          <Select
            {...register('guarantor_currency_id', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'guarantor_currency_id')}
            defaultValue={
              contractData?.guarantor_currency_id !== null
                ? {
                    label: contractData?.guarantor_currency_name,
                    value: contractData?.guarantor_currency_id,
                  }
                : null
            }
            options={currencyOptions}
            isLoading={currencyLoading}
            placeholder="เลือกสกุลเงิน"
            styles={selectStyle}
            isDisabled={true}
          />

          {errors?.guarantor_currency_id && (
            <div className="help-block">{errors.guarantor_currency_id?.message}</div>
          )}
        </Col>
      </Row>
      <h4 className="mt-4">ที่ดินที่1</h4>
      <div className="col-md-12 mb-2  p-3">
        <Row className="border mb-2 py-3">
          <Col md="6">
            <label htmlFor="title_deed_number">เลขที่โฉนด</label>
            <input
              type="text"
              placeholder="เลขที่โฉนด"
              {...register('title_deed_number_1')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="percel_no">เลขที่ดิน</label>
            <input
              type="text"
              placeholder="เลขที่ดิน"
              {...register('percel_no_1')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="dealing_file_no">หน้าสำรวจ</label>
            <input
              type="text"
              placeholder="หน้าสำรวจ"
              {...register('dealing_file_no_1')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="dealing_file_no">ระวาง</label>
            <input
              type="text"
              placeholder="ระวาง"
              {...register('sheet_number_1')}
              className="form-control"
            />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="rai">ไร่</label>
            <input type="text" placeholder="ไร่" {...register('rai_1')} className="form-control" />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="rai">งาน</label>
            <input type="text" placeholder="งาน" {...register('ngan_1')} className="form-control" />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="wha">ตารางวา</label>
            <input
              type="text"
              placeholder="ตารางวา"
              {...register('wha_1')}
              className="form-control"
            />
          </Col>
          <Col md="6">
            <label htmlFor="land_1_province_id">จังหวัด</label>
            <Select
              {...register('land_1_province_id', { required: false })}
              onChange={(event) => {
                setValue('land_1_province_id', event.value)
                onChangeProvince(event.value, 'land_1', 'land_1_province_id')
              }}
              defaultValue={
                newProvinceOptions.filter(function (option) {
                  return option.value === String(getValues('land_1_province_id'))
                })[0]
              }
              options={newProvinceOptions?.map((option) => {
                return option
              })}
              placeholder="เลือกจังหวัด"
              styles={selectStyle}
            />
          </Col>
          <Col md="6">
            <label htmlFor="district_land_1_id">อำเภอ/เขต</label>
            <Select
              {...register('district_land_1_id', { required: false })}
              onChange={(event) => {
                setValue('district_land_1_id', event.value)
                onChangeDistrict(event.value, 'land_1', 'district_land_1_id')
              }}
              defaultValue={
                provinceItem.district_land_1.filter(function (option) {
                  return option.value === String(getValues('district_land_1_id'))
                })[0]
              }
              options={provinceItem.district_land_1?.map((option) => {
                return option
              })}
              styles={selectStyle}
            />
          </Col>
          <Col md="6">
            <label htmlFor="subdistrict_land_1_id">ตำบล/แขวง</label>
            <Select
              {...register('subdistrict_land_1_id', { required: false })}
              onChange={(event) => {
                setValue('subdistrict_land_1_id', event.value)
                onChangeSubDistrict(event.value, 'land_1', 'subdistrict_land_1_id')
              }}
              defaultValue={
                provinceItem.subdistrict_land_1.filter(function (option) {
                  return option.value === String(getValues('subdistrict_land_1_id'))
                })[0]
              }
              options={provinceItem.subdistrict_land_1?.map((option) => {
                return option
              })}
              placeholder="เลือกตำบล/แขวง"
              styles={selectStyle}
            />
          </Col>
        </Row>
      </div>
      <h4 className="mt-4">ที่ดินที่2</h4>
      <div className="col-md-12 mb-2  p-3">
        <Row className="border mb-2 py-3">
          <Col md="6">
            <label htmlFor="title_deed_number">เลขที่โฉนด</label>
            <input
              type="text"
              placeholder="เลขที่โฉนด"
              {...register('title_deed_number_2')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="percel_no">เลขที่ดิน</label>
            <input
              type="text"
              placeholder="เลขที่ดิน"
              {...register('percel_no_2')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="dealing_file_no">หน้าสำรวจ</label>
            <input
              type="text"
              placeholder="หน้าสำรวจ"
              {...register('dealing_file_no_2')}
              className="form-control"
            />
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="dealing_file_no">ระวาง</label>
            <input
              type="text"
              placeholder="ระวาง"
              {...register('sheet_number_2')}
              className="form-control"
            />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="rai">ไร่</label>
            <input type="text" placeholder="ไร่" {...register('rai_2')} className="form-control" />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="rai">งาน</label>
            <input type="text" placeholder="งาน" {...register('ngan_2')} className="form-control" />
          </Col>
          <Col md={2} className="mb-3">
            <label htmlFor="wha">ตารางวา</label>
            <input
              type="text"
              placeholder="ตารางวา"
              {...register('wha_2')}
              className="form-control"
            />
          </Col>
          <Col md="6">
            <label htmlFor="land_2_province_id">จังหวัด</label>
            <Select
              {...register('land_2_province_id', { required: false })}
              onChange={(event) => {
                setValue('land_2_province_id', event.value)
                onChangeProvince(event.value, 'land_2', 'land_2_province_id')
              }}
              defaultValue={
                newProvinceOptions.filter(function (option) {
                  return option.value === String(getValues('land_2_province_id'))
                })[0]
              }
              options={newProvinceOptions?.map((option) => {
                return option
              })}
              styles={selectStyle}
            />
          </Col>
          <Col md="6">
            <label htmlFor="district_land_2_id">อำเภอ/เขต</label>
            <Select
              {...register('district_land_2_id', { required: false })}
              onChange={(event) => {
                setValue('district_land_2_id', event.value)
                onChangeDistrict(event.value, 'land_2', 'district_land_2_id')
              }}
              defaultValue={
                provinceItem.district_land_2.filter(function (option) {
                  return option.value === String(getValues('district_land_2_id'))
                })[0]
              }
              options={provinceItem.district_land_2?.map((option) => {
                return option
              })}
              placeholder="เลือกอำเภอ/เขต"
              styles={selectStyle}
            />
          </Col>
          <Col md="6">
            <label htmlFor="subdistrict_land_2_id">ตำบล/แขวง</label>
            <Select
              {...register('subdistrict_land_2_id', { required: false })}
              onChange={(event) => {
                setValue('subdistrict_land_2_id', event.value)
                onChangeSubDistrict(event.value, 'land_2', 'subdistrict_land_2_id')
              }}
              defaultValue={
                provinceItem.subdistrict_land_2.filter(function (option) {
                  return option.value === String(getValues('subdistrict_land_2_id'))
                })[0]
              }
              options={provinceItem.subdistrict_land_2?.map((option) => {
                return option
              })}
              styles={selectStyle}
            />
          </Col>
        </Row>
      </div>

      <h4>หลักทรัพย์อื่นๆ</h4>
      <Col md="12" className="mb-2 p-3">
        <Row className="border mb-2 py-3">
          <Col md="6">
            <label htmlFor="title_deed_number">ชื่อหลักทรัพย์ที่ 1</label>
            <input type="text" {...register('asset_name_1')} className="form-control" disabled={true}/>
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="percel_no">ราคาประมาณ</label>
            <Controller
              control={control}
              name="asset_value_1"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  thousandSeparator
                  className="form-control"
                  disabled={true}
                />
              )}
            />
          </Col>
          <Col md="6">
            <label htmlFor="title_deed_number">ชื่อหลักทรัพย์ที่ 2</label>
            <input type="text" {...register('asset_name_2')} className="form-control" disabled={true}/>
          </Col>
          <Col md="6" className="mb-3">
            <label htmlFor="percel_no">ราคาประมาณ</label>
            <Controller
              control={control}
              name="asset_value_2"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  thousandSeparator
                  className="form-control"
                  disabled={true}
                />
              )}
            />
          </Col>
        </Row>
      </Col>

      <h3>คำยินยอมของคู่สมรส</h3>
      <Row>
        <Col md={2} className="mb-3">
          <label htmlFor="spouse_name_title_id">คำนำหน้า</label>
          <Select
            {...register('spouse_name_title_id', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'spouse_name_title_id')}
            defaultValue={{
              label: contractData?.spouse_name_title_th,
              value: contractData?.spouse_name_title_id,
            }}
            placeholder="เลือกคำนำหน้า"
            options={prefixOptions}
            styles={selectStyle}
          />
        </Col>
        <Col md={5} className="mb-3">
          <label htmlFor="spouse_firstname ">ชื่อคู่สมรส</label>
          <input
            type="text"
            placeholder="ชื่อคู่สมรส"
            {...register('spouse_firstname')}
            className="form-control"
          />
        </Col>
        <Col md={5} className="mb-3">
          <label htmlFor="spouse_lastname ">นามสกุลคู่สมรส</label>
          <input
            type="text"
            placeholder="นามสกุลคู่สมรส"
            {...register('spouse_lastname')}
            className="form-control"
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" className="mb-3">
          <label htmlFor="spouse_address_no">เลขที่</label>
          <input
            type="text"
            placeholder="เลขที่"
            {...register('spouse_address_no')}
            className="form-control"
          />
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="spouse_village_no">หมู่ที่</label>
          <input
            type="text"
            placeholder="หมู่ที่"
            {...register('spouse_village_no')}
            className="form-control"
          />
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="spouse_alley">ตรอก/ซอย</label>
          <input
            type="text"
            placeholder="ตรอก/ซอย"
            {...register('spouse_alley')}
            className="form-control"
          />
        </Col>
        <Col md="3" className="mb-3">
          <label htmlFor="spouse_road ">ถนน</label>
          <input
            type="text"
            placeholder="ถนน"
            {...register('spouse_road')}
            className="form-control"
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="spouse_province_id">จังหวัด</label>
          <Select
            {...register('spouse_province_id', { required: false })}
            onChange={(event) => {
              setValue('spouse_province_id', event.value)
              onChangeProvince(event.value, 'spouse', 'spouse_province_id')
            }}
            defaultValue={
              newProvinceOptions.filter(function (option) {
                return option.value === String(getValues('spouse_province_id'))
              })[0]
            }
            options={newProvinceOptions?.map((option) => {
              return option
            })}
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="spouse_district_id">อำเภอ/เขต</label>
          <Select
            {...register('spouse_district_id', { required: false })}
            onChange={(event) => {
              setValue('spouse_district_id', event.value)
              onChangeDistrict(event.value, 'spouse', 'spouse_district_id')
            }}
            defaultValue={
              provinceItem.district_spouse.filter(function (option) {
                return option.value === String(getValues('spouse_district_id'))
              })[0]
            }
            options={provinceItem.district_spouse?.map((option) => {
              return option
            })}
            placeholder="เลือกอำเภอ/เขต"
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="spouse_subdistrict_id">ตำบล/แขวง</label>
          <Select
            {...register('spouse_subdistrict_id', { required: false })}
            onChange={(event) => {
              setValue('spouse_subdistrict_id', event.value)
              onChangeSubDistrict(event.value, 'spouse', 'spouse_subdistrict_id')
            }}
            defaultValue={
              provinceItem.subdistrict_spouse.filter(function (option) {
                return option.value === String(getValues('spouse_subdistrict_id'))
              })[0]
            }
            options={provinceItem.subdistrict_spouse?.map((option) => {
              return option
            })}
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="land_district_id">รหัสไปรษณีย์</label>
          <input
            type="text"
            placeholder="รหัสไปรษณีย์"
            {...register('spouse_zipcode')}
            className="form-control"
            value={provinceItem.zipcode_spouse_id}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="spouse_nationality">สัญชาติ</label>
          <Select
            {...register('spouse_nationality')}
            isClearable
            onChange={(value) => handleSelectChange(value, 'spouse_nationality')}
            defaultValue={{
              label: contractData?.spouse_nationality,
              value: contractData?.spouse_nationality,
            }}
            options={nationalityOptions}
            placeholder={'เลือกสัญชาติ'}
            styles={selectStyle}
          />
        </Col>
        <Col md="6" className="mb-3">
          <label htmlFor="spouse_citizen_id_number " className="">
            เลขประจำตัวประชาชน
          </label>
          <input
            type="text"
            {...register('spouse_citizen_id_number')}
            className="form-control"
          />
        </Col>
      </Row>
    </>
  )
}

export default Guarantor
