import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPlus, faSearch, faChartArea, faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useFetch from '../../../utils/useFetch'
import Skeleton from 'react-loading-skeleton'
import { dateHelper } from '../../../helpers/date.helper'
import { Table } from 'react-bootstrap'
import { ButtonViewDetail } from '../../../components/Button'
import { ButtonAction } from '../../../components/Button'

const ResearchContainer = (props) => {
  const { response: researchData, loading } = useFetch(`/student/personal/research`)

  return (
    <div className="card border-0 mb-3">
      <div className="card-body">
        <h5 className="card-title d-flex align-items-center">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faChartArea} className="" />
          </div>
          <span>การทำวิจัย</span>

          {props.student ? (
            <Link to={'/research/create'} className="btn btn-outline-primary btn-add ms-auto">
              <FontAwesomeIcon icon={faPlus} className="me-1" /> เพิ่มรายการ
            </Link>
          ) : (
            <></>
          )}
        </h5>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ minWidth: '280px', width: '280px' }}>อัปเดตล่าสุด</th>
              <th>รายการ</th>
              <th style={{ minWidth: '150px', width: '150px' }}>ประเทศ</th>
              <th style={{ minWidth: '150px', width: '150px' }}>สถานะ</th>
              <th className="action-column">รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ) : researchData?.length > 0 ? (
              researchData?.map((item, index) => {
                return (
                  <React.Fragment key={`${index}`}>
                    <tr>
                      <td>
                        {item?.updated_at
                          ? dateHelper.getDateTime(item.updated_at)
                          : dateHelper.getDateTime(item.created_at)}
                      </td>
                      <td className="text-start">{item.title}</td>
                      <td>{item.country_name}</td>
                      <td className={item.classStatus + ' align-middle'}>
                        <FontAwesomeIcon icon={faCircle} className="pe-1" />
                        {item.status}
                        <p className="text-secondary fs-14">{item.note} </p>
                      </td>
                      <td>
                        <ButtonAction
                          link={'/research/' + item.ID}
                          label="ดูรายละเอียด"
                          variant="outline-primary"
                          icon={faSearch}
                          width="160px"
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })
            ) : (
              <tr className="odd">
                <td valign="top" colSpan={5} className="text-center">
                  ไม่พบข้อมูล
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ResearchContainer
