import { faClipboardList, faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/BreadcrumbBS'
import { ButtonAction, ButtonHistory } from '../../components/Button'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import { petitionStatusHelper } from '../../helpers/petition.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'
import { getName } from '../../helpers/helper'
import _ from '../../utils/utils'
import Details from '../../components/Details'
const View = () => {
  const { id } = useParams()
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const { response:petitionData, loading } = useFetch(`/student/petition-status/${id}`)

  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/petition/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const pageName = 'คำร้องสถานภาพ'
  const pageTitle = 'รายละเอียด'

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/petition/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </>
      )
    })
  }

  const routes = [
    { path: '/petition-student-status', breadcrumb: pageName },
    {
      path: '',
      breadcrumb: pageTitle,
    },
  ]

  return (
    <>
      <div className="site-petition">
        <Breadcrumb isLoading={loading} routes={routes} />
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header ">
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div>
                    <h5 className=" d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span>รายละเอียดคำร้อง</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
                ) : (
                  <table id="w0" className="table">
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="w-60" style={{ textAlign: 'right' }}>
                          <ButtonHistory
                            link={`/petition-student-status/history/${id}`}
                            amount={petitionData?.data?.amount_history}
                          />
                          <ButtonAction
                            link={`/petition-student-status/print/${id}`}
                            label="พิมพ์"
                            variant="outline-primary"
                            icon={faPrint}
                            width="120px"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>ชื่อ - นามสกุลนักเรียน</th>
                        <td className="w-60"> {petitionData?.data?.fullname} </td>
                      </tr>
                      <tr>
                        <th>ประเภทคำร้อง</th>
                        <td>{petitionData?.data?.petition_type_name}</td>
                      </tr>
                      {!_.isNu(petitionData?.data?.study_higher_level) && (
                        <>
                            <tr>
                              <th>สถาบันที่เข้าศึกษาต่อ</th>
                              <td>{getName(petitionData?.data?.study_higher_level?.institution_name_th,petitionData?.data?.study_higher_level?.institution_name_en)}</td>
                            </tr>
                           <tr>
                              <th>สาขาที่เข้าศึกษาต่อ</th>
                              <td>{petitionData?.data?.study_higher_level?.subject_area}</td>
                            </tr>
                            <tr>
                              <th>ระดับการศึกษา</th>
                              <td>{getName(petitionData?.data?.study_higher_level?.education_level_name_th,petitionData?.data?.study_higher_level?.education_level_name_en)}</td>
                            </tr>
                            <tr>
                              <th>ปีการศึกษาที่เริ่มเรียน</th>
                              <td>{petitionData?.data?.study_higher_level?.semester_start+'/'+petitionData?.data?.study_higher_level?.academic_year_start_bc + " (" +petitionData?.data?.study_higher_level?.semester_start_name +")"}</td>
                            </tr>
                            <tr>
                              <th>ปีการศึกษาที่สิ้นสุด</th>
                              <td>{petitionData?.data?.study_higher_level?.semester_end+'/'+petitionData?.data?.study_higher_level?.academic_year_end_bc + " (" +petitionData?.data?.study_higher_level?.semester_end_name +")"}</td>
                            </tr>
                            <tr>
                              <th>วันที่เริ่มต้น</th>
                              <td>{dateHelper.getDate(petitionData?.data?.study_higher_level?.start_date)}</td>
                            </tr>
                            <tr>
                              <th>วันที่สิ้นสุด</th>
                              <td>{dateHelper.getDate(petitionData?.data?.study_higher_level?.end_date)}</td>
                            </tr>
                            <tr>
                              <th>รับทุนอื่น</th>
                              <td>{petitionData?.data?.study_higher_level?.is_scholarship_other === '1' ? 'ใช่' : 'ไม่ใช่'}</td>
                            </tr>
                            <tr>
                              <th>ทุนที่ไปรับ</th>
                              <td>{petitionData?.data?.study_higher_level?.scholarship_name}</td>
                            </tr>
                        </>
                      )}

                
                      <tr>
                        <th>ความประสงค์</th>
                        <td>{petitionData?.data?.description}</td>
                      </tr>
                      <tr>
                        <th>เหตุผลและความจำเป็น</th>
                        <td>{petitionData?.data?.reason}</td>
                      </tr>
                      {!_.isNu(petitionData?.data?.type==='finish') && (
                        <>
                     
                      <tr>
                        <th>มีหน่วยงานปฏิบัติตอบแทนทุนแล้วหรือไม่</th>
                        <td>{petitionData?.data?.have_work=='0'?'ไม่มี':'มีแล้ว'}</td>
                      </tr>
                      {!_.isNu(petitionData?.data?.have_work=='1') && (
                        <>
                          <tr>
                          <th>ชื่อหน่วยงานที่ปฏิบัติงานอยู่</th>
                          <td>{petitionData?.data?.organization_name}</td>
                        </tr>
                        </>
                      )}
                      {!_.isNu(petitionData?.data?.have_work=='0') && (
                        <>
                          <tr>
                          <th>มีความประสงค์ขอเข้าปฏิบัติงานในหน่วยงานดังนี้</th>
                          <td>{petitionData?.data?.organization_name}</td>
                        </tr>
                        {!_.isNu(petitionData?.data?.organization_name2) && (
                          <tr>
                            <th></th>
                            <td>{petitionData?.data?.organization_name2}</td>
                          </tr>
                        )}
                          {!_.isNu(petitionData?.data?.organization_name3) && (
                          <tr>
                            <th></th>
                            <td>{petitionData?.data?.organization_name2}</td>
                          </tr>
                        )}
                       
                        </>
                      )}
                      <tr>
                        <th>ชื่อวิทยานิพนธ์</th>
                        <td>{petitionData?.data?.thesis_name}</td>
                      </tr>
                      <tr>
                        <th>ทักษะหรือความเชี่ยวชาญ</th>
                        <td>{petitionData?.data?.skill}</td>
                      </tr>
                    
                      </>
                    )}

                      {petitionData != null &&
                      petitionData !== undefined &&
                      petitionData?.data?.petition_contract_id !== undefined &&
                      petitionData?.data?.petition_contract_id !== '' ? (
                        <>
                          <tr>
                            <th>เลขที่สัญญา</th>
                            <td>{petitionData?.data?.petition_contract_no}</td>
                          </tr>
                          <tr>
                            <th>วันที่เริ่มต้น</th>
                            <td>{dateHelper.getDate(petitionData?.data?.petition_start_date)}</td>
                          </tr>
                          <tr>
                            <th>วันที่สิ้นสุด</th>
                            <td>{dateHelper.getDate(petitionData?.data?.petition_end_date)}</td>
                          </tr>
                          <tr>
                            <th>นับเวลาชดใช้ทุน</th>
                            <td>{petitionData?.data?.is_recoup ? 'นับ' : 'ไม่นับ'}</td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                      <tr>
                        <th>ไฟล์แนบ</th>
                        <td>
                          {petitionData?.document.length > 0
                            ? renderFileDocument(petitionData?.document)
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th>สถานะคำร้อง</th>
                        <td className="align-middle">
                          {petitionStatusHelper?.getLabel(petitionData?.data?.status)}
                        </td>
                      </tr>

                      <tr>
                        <th>เหตุผล</th>
                        <td>{petitionData?.data?.note}</td>
                      </tr>

                      <tr>
                        <th>วันที่สร้าง</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(petitionData?.data?.created_at)}
                        </td>
                      </tr>
                      <tr>
                        <th>วันที่อัปเดต</th>
                        <td className=" w-60">
                          {dateHelper.getDateTime(petitionData?.data?.updated_at)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                {!loading ? (
                  <>
                    <Modal
                      show={show}
                      size="lg"
                      dialogClassName="modal-preview"
                      centered
                      onHide={() => setShow(false)}
                      aria-labelledby="file-name"
                    >
                      <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                        <Modal.Title id="file-name">{fileName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <PDFViewer pdf={filePreview} />
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default View
