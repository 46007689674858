import { faEye, faTrash, faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import _ from '../../utils/utils'
import { Alert } from '../../actions/alert.action'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

export const ButtonDownloadTemplate = ({ fileName, data }) => {
  const [isLoading, setLoading] = useState(false)
  var unixTime = Math.round(+new Date() / 1000)
  const newFileName = `${fileName}_${unixTime}.csv`
  const simulateNetworkRequest = () => {
    return new Promise((resolve) => setTimeout(resolve, 500))
  }
  // {url}/file/download/bank-account/download-document
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false)
      })
    }
  }, [isLoading])

  const handleClick = () => {
    setLoading(true)
  }

  return (
    <>
      <Button
        filename={newFileName}
        data={data}
        className="btn btn-outline-primary btn-download me-2"
        disabled={isLoading}
        onClick={!isLoading ? handleClick : null}
      >
        {isLoading ? (
          <>
            <i className="fas fa-spinner fa-pulse"></i> กำลังดาวน์โหลด...
          </>
        ) : (
          <>
            <i className="fa fa-download"></i> ดาวน์โหลดเทมเพลต
          </>
        )}
      </Button>
    </>
  )
}

export const ButtonViewDetail = ({ link }) => {
  return (
    <>
      <Link className="btn btn-outline-primary px-2" to={link} style={{ width: '140px' }}>
        <FontAwesomeIcon icon={faEye} className="me-1" />
        ดูรายละเอียด
      </Link>
    </>
  )
}

export const ButtonAction = ({ link, label, variant, width, icon }) => {
  return (
    <>
      <Button
        as={Link}
        variant={variant}
        className="px-2"
        to={link}
        style={{ width: width || '140px', minWidth: width || '140px' }}
      >
        <FontAwesomeIcon icon={icon} className="me-1" />
        {label}
      </Button>
    </>
  )
}

export const ButtonDeleteConfirm = ({
  data,
  value,
  link,
  redirectUrl,
  isIcon,
  className,
  isShow,
  isAuth = false,
  isLoading = false,
}) => {
  const deleteData = (data, value, link, redirectUrl) => {
    Alert.confirm({ data, value, link, redirectUrl })
  }

  return (
    <>
      {isShow ? (
        <>
          {!isLoading ? (
            <>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={<Tooltip>ลบ{data}</Tooltip>}
              >
                <Button
                  variant="danger"
                  className={className}
                  // size={isIcon ? 'sm' : 'md'}
                  onClick={() => deleteData(data, value, link, redirectUrl)}
                >
                  {isIcon ? (
                    <>
                      <FontAwesomeIcon icon={faTrash} />
                    </>
                  ) : (
                    <>ลบ</>
                  )}
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <>
              <Skeleton height="40px" width="80px" />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export const ButtonHistory = ({ label = 'ประวัติการแก้ไข', link, amount = 0 }) => {
  return (
    <>
      <Button as={Link} size={'md'} variant="secondary" to={link} className="me-2">
        <FontAwesomeIcon icon={faHistory} />
        <span className="ms-1">{label}</span>{' '}
        <Badge pill bg="light" text="secondary">
          {amount}
        </Badge>
      </Button>
    </>
  )
}

PropTypes.ButtonHistory = {
  label: PropTypes.string,
  link: PropTypes.string,
  amount: PropTypes.number,
}
