import React from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const PETITION_OTHER = 3
export const PETITION_EXPENSE = 2
export const PETITION_STUDENT_STATUS = 1

export const PETITION_STATUS_PENDING = 'pending'
export const PETITION_STATUS_APPROVE = 'approved'
export const PETITION_STATUS_REJECT = 'rejected'
export const PETITION_STATUS_APPROVE_BY_AGENCY = 'approved_by_agency'
export const PETITION_STATUS_APPROVE_BY_IPST = 'approved_by_ipst'
export const PETITION_STATUS_COMPLETED = 'completed'

export const STATUS_APPROVED_AND_PENDING_DISBURSE = 'approved_and_pending_disburse'
export const STATUS_APPROVED_AND_DISBURSE = 'approved_and_disburse'
export const STATUS_APPROVED_AND_DISBURSE_COMPLETED = 'approved_and_disburse_completed'

export const petitionStatusHelper = {
  isPending: (status) => status === PETITION_STATUS_PENDING,
  isApprove: (status) => status === PETITION_STATUS_APPROVE,
  isReject: (status) => status === PETITION_STATUS_REJECT,
  isAgencyApprove: (status) => status === PETITION_STATUS_APPROVE_BY_AGENCY,
  isIpstApprove: (status) => status === PETITION_STATUS_APPROVE_BY_IPST,

  getPending: () => PETITION_STATUS_PENDING,
  getApproved: () => PETITION_STATUS_APPROVE,
  getRejected: () => PETITION_STATUS_REJECT,
  getAgencyApprove: () => PETITION_STATUS_APPROVE_BY_AGENCY,

  getColor: (statusID) => {
    const status = {
      [PETITION_STATUS_PENDING]: 'warning',
      [PETITION_STATUS_APPROVE]: 'info',
      [PETITION_STATUS_REJECT]: 'danger',
      [PETITION_STATUS_APPROVE_BY_AGENCY]: 'success',
      [PETITION_STATUS_APPROVE_BY_IPST]: 'info',
      [STATUS_APPROVED_AND_PENDING_DISBURSE]: 'warning',
      [STATUS_APPROVED_AND_DISBURSE]: 'success',
      [STATUS_APPROVED_AND_DISBURSE_COMPLETED]: 'success',
    }[statusID]

    return status || ''
  },

  getTextColor: (status) => {
    const statusMapper = {
      [PETITION_STATUS_PENDING]: 'dark',
      [PETITION_STATUS_APPROVE]: 'dark',
      [PETITION_STATUS_REJECT]: 'white',
      [PETITION_STATUS_APPROVE_BY_AGENCY]: 'white',
      [PETITION_STATUS_APPROVE_BY_IPST]: 'white',
      [STATUS_APPROVED_AND_PENDING_DISBURSE]: 'dark',
      [STATUS_APPROVED_AND_DISBURSE]: 'dark',
      [STATUS_APPROVED_AND_DISBURSE_COMPLETED]: 'dark',
    }[status]
    return statusMapper || ''
  },

  getText: (statusID) => {
    const status = {
      [PETITION_STATUS_PENDING]: 'รออนุมัติ',
      [PETITION_STATUS_APPROVE]: 'อนุมัติ',
      [PETITION_STATUS_REJECT]: 'ไม่อนุมัติ',
      [PETITION_STATUS_APPROVE_BY_AGENCY]: 'ศูนย์ผู้ดูแลอนุมัติ',
      [PETITION_STATUS_APPROVE_BY_IPST]: 'อนุมัติโดยสสวท.',
      [STATUS_APPROVED_AND_PENDING_DISBURSE]: 'อนุมัติและรอส่งเบิกจ่าย',
      [STATUS_APPROVED_AND_DISBURSE]: 'อนุมัติและส่งคำขอเบิกจ่ายแล้ว',
      [STATUS_APPROVED_AND_DISBURSE_COMPLETED]: 'อนุมัติและเบิกจ่ายแล้ว',
    }[statusID]
    return status || ''
  },

  getLabel: (status, note) => {
    return (
      <>
        <div className={`text-${petitionStatusHelper.getColor(status)} align-middle`}>
          <FontAwesomeIcon icon={faCircle} className="pe-1" />
          {petitionStatusHelper.getText(status)}
          {note && <p className="text-secondary fs-14"> {note} </p>}
        </div>
      </>
    )
  },

  getOptions: () => {
    return [
      {
        value: PETITION_STATUS_PENDING,
        label: petitionStatusHelper.getText(PETITION_STATUS_PENDING),
      },
      {
        value: PETITION_STATUS_APPROVE,
        label: petitionStatusHelper.getText(PETITION_STATUS_APPROVE),
      },
      {
        value: PETITION_STATUS_REJECT,
        label: petitionStatusHelper.getText(PETITION_STATUS_REJECT),
      },
      {
        value: PETITION_STATUS_APPROVE_BY_AGENCY,
        label: petitionStatusHelper.getText(PETITION_STATUS_APPROVE_BY_AGENCY),
      },
      {
        value: PETITION_STATUS_APPROVE_BY_IPST,
        label: petitionStatusHelper.getText(PETITION_STATUS_APPROVE_BY_IPST),
      },
    ]
  },
}
