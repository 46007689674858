import _ from '../utils/utils'


export const onHoverSidebar = () => {
    let body = document.body

    if (body.classList.contains('mini-sidebar')) {
        body.classList.add('expand-menu')
    }
}

export const onLeaveSidebar = () => {
    let body = document.body

    if (body.classList.contains('mini-sidebar')) {
        body.classList.remove('expand-menu')
    }
}

export const mobileHandleToggle = (e) => {
    e.preventDefault()
    let html = document.getElementsByTagName('html')[0]
    let mainWrapper = document.querySelectorAll('.main-wrapper')[0]
    mainWrapper.classList.toggle('slide-nav')
    html.classList.add('menu-opened')
}

export const getStatusPublish = (status) => {
    return status == 1 ? 'เปิดใช้งาน' : 'ซ่อน'
}

export const getDateFormat = (date) => {
    // return date ? moment(new Date(date)).add(543, 'year').format('D/M/Y HH:mm') : ''
    return date
}

export const resizeColumn = () => {
    const tables = document.querySelectorAll('.resizable')

    const resizableGrid = (table) => {
        let tableHeight = table.offsetHeight

        let row = table.getElementsByTagName('tr')[0],
            cols = row ? row.children : undefined
        if (!cols) return

        table.style.overflow = 'hidden'

        const createDiv = (height) => {
            let div = document.createElement('div')
            div.style.top = 0
            div.style.right = 0
            div.style.width = '5px'
            div.style.position = 'absolute'
            div.style.cursor = 'col-resize'
            div.style.userSelect = 'none'
            div.style.height = height + 'px'
            return div
        }

        const setListeners = (div) => {
            var pageX, curCol, nxtCol, curColWidth, nxtColWidth, tableWidth

            div.addEventListener('mousedown', function (e) {
                tableWidth = document.querySelector('#tableCustom').offsetWidth
                curCol = e.target.parentElement
                nxtCol = curCol.nextElementSibling
                pageX = e.pageX

                let padding = paddingDiff(curCol)
                curColWidth = curCol.offsetWidth - padding
            })

            div.addEventListener('mouseover', function (e) {
                e.target.style.borderRight = '1px solid #c1c1c1'
            })

            div.addEventListener('mouseout', function (e) {
                e.target.style.borderRight = ''
            })

            document.addEventListener('mousemove', function (e) {
                if (curCol) {
                    let diffX = e.pageX - pageX

                    curCol.style.width = curColWidth + diffX + 'px'
                    document.querySelector('#tableCustom').style.width = tableWidth + diffX + 'px'
                }
            })

            document.addEventListener('mouseup', function (e) {
                curCol = undefined
                nxtCol = undefined
                pageX = undefined
                nxtColWidth = undefined
                curColWidth = undefined
            })
        }

        for (var i = 0; i < cols.length; i++) {
            var div = createDiv(tableHeight)
            cols[i].appendChild(div)
            cols[i].style.position = 'relative'
            setListeners(div)
        }
    }

    for (let index = 0; index < tables.length; index++) {
        resizableGrid(tables[index])
    }

    const getStyleVal = (elm, css) => {
        return window.getComputedStyle(elm, null).getPropertyValue(css)
    }

    const paddingDiff = (col) => {
        if (getStyleVal(col, 'box-sizing') == 'border-box') return 0

        let padLeft = getStyleVal(col, 'padding-left')
        let padRight = getStyleVal(col, 'padding-right')
        return parseInt(padLeft) + parseInt(padRight)
    }
}

export const getName = (name_th, name_en) => {
  if (!_.isNu(name_th) || !_.isNu(name_en)) {
    return `${name_th} ${!_.isEmpty(name_en) && !_.isNu(name_en) ? `(${name_en})` : ''}`
  }

  return '-'
}
// export const handleChangeFile = (e) => {
//     const [files, setFiles] = useState([])
//     setFiles([])
//     let files = e?.target?.files ?? null
//     let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB

//     if (files.length == 0) {
//         setResultValidate([])
//         setError('file', {
//             message: 'กรุณาอัปโหลดไฟล์',
//         })
//         return false
//     }

//     if (!files[0].name.match(/\.(csv)$/)) {
//         setError('file', {
//             type: 'manual',
//             message: 'อัปโหลดไฟล์นามสกุล csv เท่านั้น',
//         })
//         return false
//     }

//     if (fileSize > 10) {
//         setError('file', {
//             message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
//         })
//         return false
//     }

//     clearErrors('file')

//     if (files.length > 0) {
//         setFiles(files)
//     }
// }