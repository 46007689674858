import styled from 'styled-components'

export const List = styled.div`
  border: 1px dashed #e3e3e3;
  padding: 0.75rem 1.25rem;
  position: relative;
  margin-bottom: 1rem;
`

export const Icon = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`

export const Remark = styled.div`
  font-size: 0.75rem;
  color: #999;
`

export const ListItem = styled.div`
  margin-top: 0.5rem;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
`
