import { faClipboardList, faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal, ToastHeader } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import { ButtonAction, ButtonHistory } from '../../components/Button'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import { petitionStatusHelper } from '../../helpers/petition.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'
import { Link, useParams } from 'react-router-dom'
import Breadcrumb from '../../components/BreadcrumbBS'

const HistoryDetail = () => {
  const { id } = useParams()
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')
  const { response: petitionData, loading } = useFetch(
    `/student/petition-status/history-detail/${id}`
  )
  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/petition/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/petition/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </>
      )
    })
  }

  const pageName = 'คำร้องอื่น ๆ'
  const pageTitle = petitionData?.data?.petition_type_name

  const routes = [
    { path: '/petition-other', breadcrumb: pageName },
    { path: `/petition-other/${id}`, breadcrumb: 'รายละเอียดคำร้อง' },
    { path: `/petition-other/history/${id}`, breadcrumb: 'ประวัติการแก้ไข' },
    {
      path: '',
      breadcrumb: pageTitle,
    },
  ]

  return (
    <div className="site-petition">
      <Breadcrumb isLoading={loading} routes={routes} />
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className=" d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>รายละเอียดคำร้อง</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
              ) : (
                <table className="table">
                  <tbody>
                    <tr>
                      <th>ชื่อ - นามสกุลนักเรียน</th>
                      <td className="w-60"> {petitionData?.data?.fullname} </td>
                    </tr>
                    <tr>
                      <th>ประเภทคำร้อง</th>
                      <td>{petitionData?.data?.petition_type_name}</td>
                    </tr>
                    <tr>
                      <th>ความประสงค์</th>
                      <td>{petitionData?.data?.description}</td>
                    </tr>
                    <tr>
                      <th>เหตุผลและความจำเป็น</th>
                      <td>{petitionData?.data?.reason}</td>
                    </tr>
                    {petitionData != null &&
                    petitionData !== undefined &&
                    petitionData?.data?.petition_contract_id !== undefined &&
                    petitionData?.data?.petition_contract_id !== '' ? (
                      <>
                        <tr>
                          <th>เลขที่สัญญา</th>
                          <td>{petitionData?.data?.petition_contract_no}</td>
                        </tr>
                        <tr>
                          <th>วันที่เริ่มต้น</th>
                          <td>{dateHelper.getDate(petitionData?.data?.petition_start_date)}</td>
                        </tr>
                        <tr>
                          <th>วันที่สิ้นสุด</th>
                          <td>{dateHelper.getDate(petitionData?.data?.petition_end_date)}</td>
                        </tr>
                        <tr>
                          <th>นับเวลาชดใช้ทุน</th>
                          <td>{petitionData?.data?.is_recoup ? 'นับ' : 'ไม่นับ'}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <th>ไฟล์แนบ</th>
                      <td>
                        {petitionData?.document.length > 0
                          ? renderFileDocument(petitionData?.document)
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>สถานะคำร้อง</th>
                      <td className="align-middle">
                        {petitionStatusHelper?.getLabel(petitionData?.data?.status)}
                      </td>
                    </tr>

                    <tr>
                      <th>เหตุผล</th>
                      <td>{petitionData?.data?.note}</td>
                    </tr>

                    <tr>
                      <th>วันที่สร้าง</th>
                      <td className=" w-60">
                        {dateHelper.getDateTime(petitionData?.data?.created_at)}
                      </td>
                    </tr>
                    <tr>
                      <th>วันที่อัปเดต</th>
                      <td className=" w-60">
                        {dateHelper.getDateTime(petitionData?.data?.updated_at)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {!loading ? (
                <>
                  <Modal
                    show={show}
                    size="lg"
                    dialogClassName="modal-preview"
                    centered
                    onHide={() => setShow(false)}
                    aria-labelledby="file-name"
                  >
                    <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                      <Modal.Title id="file-name">{fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <PDFViewer pdf={filePreview} />
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HistoryDetail
