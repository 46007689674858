import { useEffect, useState } from 'react'
import { faHistory, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { convertCurrency, currencyHelper } from '../../helpers/currency.helper'
import { dateHelper } from '../../helpers/date.helper'
import { useExpenseYearOptions } from '../../hooks/options/useOptions'
import { customStyles } from './styles'

const ExpenseList = (props) => {
  const [filteredYear, setFilteredYear] = useState('')
  const [petition, setPetition] = useState(null)
  const [loading, setLoading] = useState(true)

  const authUser = JSON.parse(localStorage.getItem('user'))
  const accessToken = authUser.token
  const apiKey = process.env.REACT_APP_API_KEY
  const apiVersion = process.env.REACT_APP_API_VERSION
  const baseUrl = process.env.REACT_APP_API_URL

  const filterChangeHandler = (value) => {
    setFilteredYear(value.value)
    setLoading(true)
  }

  const { options: expenseYearOptions, loading: expenseYearLoading } = useExpenseYearOptions()

  let headers = {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    'x-api-version': apiVersion,
    'access-token': accessToken,
  }

  let options = {
    url: baseUrl + `/student/petition/expense?year=${filteredYear}`,
    headers: headers,
  }

  const fetchPetitionData = async () => {
    await axios(options)
      .then((response) => {
        const json = response.data

        setPetition(json.data)
        // let year = {
        //   value: json?.data?.current_year?.max_year,
        //   label: json?.data?.current_year?.max_year_bc,
        // }
        // setFilteredYear(year)
        setLoading(false)
      })
      .catch((error) => {
        //setError(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchPetitionData()
  }, [filteredYear])

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ประวัติค่าใช้จ่าย
          </li>
        </ol>
      </nav>
      <div className="expense-index">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faHistory} className="" />
                      </div>
                      <span>ประวัติค่าใช้จ่าย </span>
                    </h5>
                  </div>
                </div>
                <div className="px-4">
                  <p>ปีที่ต้องการดูย้อนหลัง</p>
                </div>
                <div className="d-md-flex justify-content-between">
                  <div className="px-4">
                    {!expenseYearLoading ? (
                      <>
                        <Select
                          isMulti={false}
                          options={expenseYearOptions}
                          styles={customStyles}
                          isSearchable={false}
                          isLoading={expenseYearLoading}
                          onChange={(value) => filterChangeHandler(value)}
                          // onChange={(e) => handleChange('country_id', e)}
                          placeholder={petition?.current_year?.max_year_bc}
                        />
                      </>
                    ) : (
                      <div className="">
                        <Skeleton width="80px" />
                        <Skeleton height="44px" />
                      </div>
                    )}
                  </div>
                  <div className="text-end">
                    <p className="text-end mb-1">ค่าใช้จ่ายรวม</p>
                    {petition?.summary?.length > 0 ? (
                      <>
                        {petition?.summary?.map((item) => {
                          return (
                            <>
                              <p className="mb-1">
                                {convertCurrency(item.amount_baht || item.amount)}{' '}
                                {item.currency_name}
                              </p>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <>0.00</>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {loading ? (
                    <table id="expense" className="table">
                      <thead>
                        <tr>
                          <td>ลำดับ</td>
                          <td>ประเภทค่าใช้จ่าย</td>
                          <td>รายละเอียด</td>
                          <td>จำนวนเงิน</td>
                          <td>สกุล</td>
                          <td>วันเวลา</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table id="expense" className="table">
                      <thead>
                        <tr>
                          <td className="text-center">ลำดับ</td>
                          <td className="text-center">ประเภทค่าใช้จ่าย</td>
                          <td className="text-center">จำนวนเงิน</td>
                          <td className="text-center">สกุล</td>
                          <td className="text-center">วันเวลา</td>
                        </tr>
                      </thead>
                      <tbody>
                        {petition?.expense?.length > 0 ? (
                          petition?.expense?.map((item, key) => {
                            let amount = currencyHelper.isBaht(item?.currency)
                              ? item?.amount_baht
                              : item?.amount
                            return (
                              <tr key={key}>
                                <td className="align-middle text-center"> {key + 1} </td>
                                <td className="align-middle"> {item?.expense_type_name} </td>
                                <td style={{ textAlign: 'right' }}>
                                  {convertCurrency(amount === 0 ? item?.amount : amount)}
                                </td>
                                <td className="align-middle text-center"> {item?.currency} </td>
                                <td className="align-middle text-center">
                                  {dateHelper.getDateTime(item.created_at)}
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr className="odd">
                            <td valign="top" colSpan={6} className="text-center">
                              ไม่พบข้อมูล
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ExpenseList
