import React, { useState } from 'react'
import { faCircle, faClipboardList, faEye, faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import useFetch from '../../utils/useFetch'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import { history } from '../../helpers/history'
import { PaginationHelper } from '../../helpers/pagination.helper'
import { dateHelper } from '../../helpers/date.helper'
import { statusHelper } from '../../helpers/status.helper'

const actionButton = (cell, row, rowIndex, formatExtraData) => {
  return (
    <>
      <Link
        className="btn btn-outline-primary btn-view-detail mb-2"
        to={'/academic-result/' + row.id}
      >
        <FontAwesomeIcon icon={faEye} className="" /> ดูรายละเอียด{' '}
      </Link>
    </>
  )
}

const AcademicResultList = (props) => {
  const { response, loading } = useFetch(`/student/academic-result`)

  const [currentPage, setCurrentPage] = useState(1)

  const sizePerPage = 10

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: PaginationHelper.getTotalRenderer,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
      history.push(`/academic-result?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '80px', minWidth: '80px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        let cal = sizePerPage * currentPage
        cal = cal - (sizePerPage - 1)
        cal = cal + rowIndex
        return cal
      },
    },
    {
      dataField: 'academic_year',
      text: 'ปีการศึกษา',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'semester',
      text: 'ภาคเรียน',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.semester || '-'
      },
    },
    {
      dataField: 'class_year',
      text: 'ชั้นปี',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.class_year || '-'
      },
    },
    {
      dataField: 'gpax',
      text: 'ผลการศึกษา',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.gpax || '-'
      },
    },
    {
      dataField: 'status',
      text: 'สถานะ',
      sort: true,
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return statusHelper.getLabel(row?.status, row?.note)
      },
    },
    {
      dataField: 'created_at',
      text: 'วันที่ส่งผลการเรียน',
      sort: true,
      headerStyle: () => {
        return { width: '280px', minWidth: '280px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row.created_at)
      },
    },
    {
      dataField: 'action',
      text: 'รายละเอียด',
      align: 'center',
      headerStyle: () => {
        return { width: '135px', minWidth: '135px', textAlign: 'center' }
      },
      events: {
        onClick: (e) => {
          e.stopPropagation()
        },
      },
      formatter: actionButton,
    },
  ]

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ผลการศึกษา
          </li>
        </ol>
      </nav>
      <div className="academic-result-index">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header ">
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div>
                    <h5 className="card-title d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span>ผลการศึกษา </span>
                    </h5>
                  </div>
                  <div className="ms-auto my-auto mt-lg-0 mt-4">
                    <div className="ms-auto my-auto">
                      <Link to="/academic-result/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} className="" /> ยื่นผลการศึกษา
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div id="p0" data-pjax-container data-pjax-push-state data-pjax-timeout={1000}>
                    <div id="w0" className="grid-view table-responsive">
                      {!loading ? (
                        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                          {({ paginationProps, paginationTableProps }) => (
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />

                              <BootstrapTable
                                id="tableResize"
                                classes="resizable"
                                bootstrap4
                                hover
                                striped={false}
                                bordered={false}
                                keyField="id"
                                data={response}
                                columns={columns}
                                filter={filterFactory()}
                                filterPosition="top"
                                wrapperClasses="table-responsive"
                                noDataIndication={'ไม่พบข้อมูล'}
                                {...paginationTableProps}
                              />
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          )}
                        </PaginationProvider>
                      ) : (
                        <table id="petition" className="table">
                          <thead>
                            <tr>
                              <th>
                                <Skeleton />
                              </th>
                              <th>
                                <Skeleton />
                              </th>
                              <th>
                                <Skeleton />
                              </th>
                              <th>
                                <Skeleton />
                              </th>
                              <th style={{ width: 220, minWidth: 220 }}>
                                <Skeleton />
                              </th>
                              <th style={{ width: 180, minWidth: 180 }}>
                                <Skeleton />
                              </th>
                              <th style={{ width: 180, minWidth: 180 }}>
                                <Skeleton />
                              </th>
                              <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                                <Skeleton />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default AcademicResultList
