import './loading.css'
// import { Spinner } from 'react-bootstrap'

const Loading = (props) => {
  return (
    <>
      <div className="loader-ellips">
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
        <span className="loader-ellips__dot"></span>
      </div>
    </>
  )
}

export default Loading
