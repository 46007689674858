import { faClipboardList, faDownload, faEye, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Card, Container, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Details from '../../components/Details'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import { statusHelper } from '../../helpers/status.helper'
import useFetch from '../../utils/useFetch'
import { Icon, List } from './Styled'

const EducationalProgressView = (props) => {
  const baseUrl = process.env.REACT_APP_API_URL_FILE
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')

  const id = props.match.params.id
  const { response: progressData, loading } = useFetch(`/student/education/progressdetail/${id}`)

  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/education/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <React.Fragment key={`${item.file_name}_${index}`}>
          <List>
            <Icon>
              <button
                className="btn btn-info btn-sm me-2 text-white"
                onClick={() => showFile(item?.file_name, item?.file_code)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <a
                download
                href={`${baseUrl}/student/education/download/${item?.file_code}?token=${auth?.token}`}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </Icon>
            <i className="fas fa-paperclip"></i> {item.file_name}
            <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
              {item.description}
            </p>
          </List>
        </React.Fragment>
      )
    })
  }

  const data = [
    {
      label: 'รายละเอียด',
      value: progressData?.description,
    },
    {
      label: 'ไฟล์แนบ',
      value: progressData?.files.length > 0 ? renderFileDocument(progressData?.files) : '-',
    },
    {
      label: 'สถานะ',
      value: statusHelper.getLabel(progressData?.status),
    },
    {
      label: 'เหตุผล',
      value: progressData?.note,
    },
    {
      label: 'วันที่ยื่นความก้าวหน้า',
      value: dateHelper.getDateTime(progressData?.created_at),
    },
    {
      label: 'วันที่อัปเดตล่าสุด',
      value: dateHelper.getDateTime(progressData?.updated_at),
    },
  ]

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/educational-progress'}> ความก้าวหน้าทางการศึกษา </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            รายละเอียดความก้าวหน้าทางการศึกษา
          </li>
        </ol>
      </nav>
      <div className="site-petition">
        <section className="bg-light">
          <Container>
            <Card className="border-0 mb-3">
              <Card.Header>
                <div className="d-md-flex pt-4 bg-white border-0">
                  <div>
                    <h5 className=" d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span>รายละเอียดความก้าวหน้าทางการศึกษา</span>
                    </h5>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <Details data={data} loading={loading} />
                {!loading ? (
                  <>
                    <Modal
                      show={show}
                      size="lg"
                      dialogClassName="modal-preview"
                      centered
                      onHide={() => setShow(false)}
                      aria-labelledby="file-name"
                    >
                      <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                        <Modal.Title id="file-name">{fileName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <PDFViewer pdf={filePreview} />
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>
          </Container>
        </section>
      </div>
    </>
  )
}

export default EducationalProgressView
