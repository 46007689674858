import React, { Fragment, useContext } from 'react'
import logo from '../../../../assets/images/logo/IPST_Logo.png'
import './Header.css'
import {
  faBell,
  faChevronDown,
  faCog,
  faHome,
  faMoneyCheck,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import AuthContext from '../../../../store/auth-context'
import { Link } from 'react-router-dom'

const HeaderLogged = (props) => {
  const userAuth = props.authCtx.user
  const auth = JSON.parse(userAuth)
  const authCtx = useContext(AuthContext)

  const onSignOutHandler = (event) => {
    authCtx.logout(null)
  }

  return (
    <Fragment>
      <header>
        <Navbar className="navbar-dark bg-primary" expand="xl">
          <Container fluid className="px-0">
            <Navbar.Brand href="/" className="ps-3 pe-2 mx-auto navbar-brand">
              <img src={logo} alt=" " />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="nav-dropdown" />
            <Navbar.Collapse id="nav-dropdown">
              <Nav>
                <Nav.Link as={Link} to="/dashboard">
                  <FontAwesomeIcon icon={faHome} />
                </Nav.Link>
                <Nav.Link as={Link} to="/personal-information">
                  ข้อมูลของฉัน
                </Nav.Link>
                <NavDropdown
                  id="contract"
                  title={
                    <div>
                      ทุนและสัญญา <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  }
                  menuVariant="light"
                >
                  <NavDropdown.Item as={Link} to="/contract">
                    ทุนและสัญญา
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/expense">
                    ประวัติค่าใช้จ่าย
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/retitution">
                    คำนวณการตอบแทนทุน
                  </NavDropdown.Item>
                  
                </NavDropdown>
                <NavDropdown
                  id="education"
                  title={
                    <div>
                      ประวัติการศึกษา <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  }
                  menuVariant="light"
                >
                  <NavDropdown.Item as={Link} to="/education-history">
                    ประวัติการศึกษาของฉัน
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/educational-progress">
                    ความก้าวหน้าทางการศึกษา
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/academic-result">
                    ผลการศึกษา
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/move-classes">
                    การเลื่อนชั้น
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/operational">
                  การปฏิบัติงาน
                </Nav.Link>
                <NavDropdown
                  id="petition"
                  title={
                    <div>
                      คำร้อง <FontAwesomeIcon icon={faChevronDown} />{' '}
                    </div>
                  }
                  menuVariant="light"
                >
                  <NavDropdown.Item as={Link} to="/petition-student-status">
                    คำร้องสถานภาพนักเรียนทุน
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/petition-expense">
                    คำร้องค่าใช้จ่าย
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/petition-other">
                    คำร้องอื่น ๆ
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse id="nav-dropdown-user">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/notification-message" className="nav-link px-4">
                  <FontAwesomeIcon icon={faBell} />
                </Nav.Link>
                <NavDropdown
                  title={
                    <div className="pull-left">
                      <img
                        className="img-thumbnail me-3 rounded-circle profile-thumbnail"
                        src={auth.avatar}
                        alt="user pic"
                      />
                      <span>{auth.fullname}</span>
                      <FontAwesomeIcon icon={faChevronDown} className="mx-1" />
                    </div>
                  }
                  id="basic-nav-dropdown"
                  className="bg-info"
                >
                  <NavDropdown.Item as={Link} to="/bank-account">
                    <FontAwesomeIcon icon={faMoneyCheck} className="me-1" />
                    ตั้งค่าบัญชีธนาคาร
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/profile/edit">
                    <FontAwesomeIcon icon={faCog} className="me-1" />
                    ตั้งค่าการใช้งาน
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onSignOutHandler}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-1" />
                    ออกจากระบบ
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </Fragment>
  )
}

export default HeaderLogged
