import { faCamera, faCog, faExclamationCircle, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import Header from '../../components/Header'
import Resetpassword from '../../components/setting/Resetpassword'
import http from '../../utils/http'

const SettingProfile = (props) => {
  const Upload = styled.button`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    color: #fff;
    background-color: #888;
    border-color: #888;
    position: absolute;
    padding: 0;
  `

  const user = JSON.parse(localStorage.getItem('user'))
  const timezone = user.timezone //'Asia/Bangkok';
  const yearType = user?.years_type
  const avatar = user.avatar
  const fullname = user.fullname

  const urlActionCallAPI = '/student/setting/update'

  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedYearType, setSelectedYearType] = useState(yearType)
  const [selectedTimezone, setSelectedTimezone] = useState({ value: timezone })
  const [setting, setSetting] = useState([])
  const [error, setError] = useState(null)
  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const confirmPasswordRef = useRef(null)

  const validationSchema = yup.object().shape({
    year: yup.string().required('การแสดงผล ต้องไม่เป็นค่าว่าง'),
    //timezone: yup.string().required('ปีงบประมาณ ต้องไม่เป็นค่าว่าง'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      year: selectedYearType,
      timezone: selectedTimezone,
    },
  }

  const { register, handleSubmit, formState, setValue, clearErrors } = useForm(formOptions)

  const { errors } = formState

  const onSubmit = async (data) => {
    setIsLoading(true)
    if (data) {
      setValue('timezone', selectedTimezone.value)
      let dataObj = { year: data.year, timezone: selectedTimezone.value, picture: files }

      let dataRes = await http.post(`${urlActionCallAPI}`, dataObj)
      if (dataRes.message === 'Accepted') {
        let userResponse = dataRes?.data
        const auth = localStorage.getItem('user')
        const updateUser = JSON.parse(auth)
        updateUser.avatar = userResponse.picture
        updateUser.timezone = userResponse.timezone
        updateUser.years_type = userResponse.years_type

        const userJson = JSON.stringify(updateUser)
        localStorage.setItem('user', userJson)

        setIsLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'แก้ไขข้อมูลเรียบร้อย',
          text: '',
          confirmButtonColor: '#0d3996',
          confirmButtonText: 'ตกลง',
          footer: '',
        }).then(() => {
          window.location.reload()
        })
        // history.push(`${urlAction}`)
      } else {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (selectedYearType) {
      setValue('year', selectedYearType)
      setValue('timezone', selectedTimezone)
    }
  }, [selectedYearType])

  const uploadedImage = useRef(null)
  const imageUploader = useRef(null)

  const handleImageUpload = (e) => {
    setFiles([])
    let [file] = e?.target?.files ?? null
    let fileSize = parseFloat(file?.size / 1024).toFixed(1) // in KB
    let fileType = file.type

    if (file.length === 0) {
      setError('picture', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }

    if (fileSize > 800) {
      setError('picture', {
        message: `ขนาดไฟล์ห้ามเกิน 800 KB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} KB`,
      })
      return false
    }

    if (file) {
      const reader = new FileReader()
      const { current } = uploadedImage
      current.file = file
      reader.onload = (e) => {
        current.src = e.target.result
      }

      if (
        fileType !== 'image/jpeg' &&
        fileType !== 'image/jpg' &&
        fileType !== 'image/bmp' &&
        fileType !== 'image/png'
      ) {
        setError('picture', {
          message: `กรุณาอัปโหลดไฟล์รูปภาพนามสกุล JPG, PNG, BMP เท่านั้น`,
        })
        return false
      } else {
        setFiles(file)
        reader.readAsDataURL(file)
      }
    }

    clearErrors('picture')
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ตั้งค่าการใช้งาน
          </li>
        </ol>
      </nav>
      <div className="site-dashboard">
        <section className="bg-white py-3 mb-3 font-sarabun">
          <div className="container">
            <div className="alert alert-warning-soft mb-3" role="alert">
              <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
              หากต้องการเปลี่ยนชื่อ-นามสกุล ให้ทำคำร้องสถานภาพนักเรียนทุน
            </div>
          </div>
        </section>
        <section className="bg-light">
          <div className="container">
            <Card className="border-0 mb-3">
              <Header title={'ตั้งค่าการใช้งาน'} icon={faCog} isLoading={true} />
              <Card.Body>
                <div className="profile-form w-auto">
                  <div className="d-flex">
                    <div
                      className="profile-picture position-relative"
                      style={{ width: 250, height: 180 }}
                    >
                      <div className="d-flex justify-content-center">
                        <div className="mb-3 position-relative">
                          <input
                            {...register('picture')}
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                              display: 'none',
                            }}
                          />
                          <img
                            src={avatar}
                            ref={uploadedImage}
                            style={{
                              width: '200px',
                              height: '200px',
                              borderRadius: '50%',
                              borderWidth: '6px',
                              borderColor: '#ebedf5',
                              borderStyle: 'solid',
                              objectFit: 'cover',
                            }}
                            alt="รูปภาพประจำตัว"
                          />
                          <Upload
                            type="button"
                            className="btn btn-upload-profile d-flex justify-content-center align-items-center"
                            onClick={() => {
                              imageUploader.current.click()
                            }}
                          >
                            <FontAwesomeIcon icon={faCamera} className="" />
                          </Upload>
                        </div>
                      </div>
                      <div className="text-center text-secondary mb-3 font-14">
                        <span>นามสกุล JPG, PNG, BMP เท่านั้น ขนาดไม่เกิน 800KB</span>
                      </div>
                      {errors?.picture && (
                        <div className="help-block text-center">{errors?.picture?.message}</div>
                      )}
                    </div>
                    <div className="w-100">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="form-group field-profile-firstname_th required">
                            <label className="control-label" htmlFor="profile-firstname_th">
                              ชื่อ (ภาษาไทย)
                            </label>
                            <input
                              type="text"
                              id="profile-firstname_th"
                              className="form-control font-16"
                              name="Profile[firstname_th]"
                              defaultValue={user?.firstname_th}
                              readOnly="readonly"
                              placeholder="ชื่อจริง (ภาษาไทย)"
                              aria-required="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group field-profile-lastname_th required">
                            <label className="control-label" htmlFor="profile-lastname_th">
                              นามสกุล (ภาษาไทย)
                            </label>
                            <input
                              type="text"
                              id="profile-lastname_th"
                              className="form-control font-16"
                              name="Profile[lastname_th]"
                              defaultValue={user?.lastname_th}
                              readOnly="readonly"
                              placeholder="นามสกุล (ภาษาไทย)"
                              aria-required="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="form-group field-profile-firstname_en">
                            <label className="control-label" htmlFor="profile-firstname_en">
                              ชื่อ (ภาษาอังกฤษ)
                            </label>
                            <input
                              type="text"
                              id="profile-firstname_en"
                              className="form-control font-16"
                              name="Profile[firstname_en]"
                              defaultValue={user?.firstname_en}
                              readOnly="readonly"
                              placeholder="ชื่อจริง (English)"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group field-profile-lastname_en">
                            <label className="control-label" htmlFor="profile-lastname_en">
                              นามสกุล (ภาษาอังกฤษ)
                            </label>
                            <input
                              type="text"
                              id="profile-lastname_en"
                              className="form-control font-16"
                              name="Profile[lastname_en]"
                              defaultValue={user?.lastname_en}
                              readOnly="readonly"
                              placeholder="นามสกุล (English)"
                            />
                          </div>
                        </div>
                      </div>

                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={6} className="mb-3">
                            <div className="form-group field-profile-timezone">
                              <label className="control-label" htmlFor="profile-timezone">
                                เวลาสำหรับการแสดงผล
                              </label>
                              <div className="select-wrapper">
                                <TimezoneSelect
                                  //name="timezone"
                                  {...register('timezone')}
                                  value={selectedTimezone}
                                  onChange={setSelectedTimezone}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="mb-3">
                            <label htmlFor="year" className="label-with-required">
                              การแสดงผล
                            </label>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  {...register('year')}
                                  defaultChecked="BE"
                                  value="BE"
                                />
                                <label className="form-check-label" htmlFor="year">
                                  พุทธศักราช (พ.ศ.)
                                </label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  {...register('year')}
                                  id="year"
                                  value="BC"
                                />
                                <label className="form-check-label" htmlFor="year">
                                  คริสต์ศักราช (ค.ศ.)
                                </label>
                              </div>
                            </div>
                            {errors.year && <div className="help-block">{errors.year.message}</div>}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="form-group pt-3 text-end">
                            {!isLoading ? (
                              <button type="submit" className="btn btn-primary">
                                บันทึก
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary" disabled>
                                กำลังบันทึก...
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Resetpassword />
          </div>
        </section>
      </div>
    </>
  )
}

export default SettingProfile
