import * as dayjs from 'dayjs'
import 'dayjs/locale/th'

export const buddhistEra = require('dayjs/plugin/buddhistEra')

export const getAgoDateFormat = (date) => {
  const now = dayjs()
  const minutes = now.diff(date, 'minutes')
  const hours = now.diff(date, 'hours')
  const days = now.diff(date, 'days')
  const month = now.diff(date, 'month')
  const formatDate = dateHelper.getDateTime(date)

  if (minutes < 1) {
    return 'ไม่กี่วินาทีที่ผ่านมา'
  } else if (minutes < 60) {
    return `${minutes} นาทีที่แล้ว (${formatDate})`
  } else if (hours < 24) {
    return `${hours} ชั่วโมงที่แล้ว (${formatDate})`
  } else if (days < 30) {
    return `${days} วันที่แล้ว (${formatDate})`
  } else if (month < 12) {
    return `${month} เดือนที่แล้ว (${formatDate})`
  } else {
    return formatDate
  }
}

export const dateHelper = {
  getDateTime: (date) => {
    if (date === null) return '-'
    const auth = JSON.parse(localStorage.getItem('user'))
    const yearType = auth?.years_type
    dayjs.extend(buddhistEra)
    let yearFormat = yearType === 'BE' ? 'BBBB' : 'YYYY'
    let dateTimeFormat = dayjs(date).locale('th').format(`D MMM ${yearFormat} HH:mm`)
    return `${dateTimeFormat} (${dateHelper.getTimeZone(date)})`
  },

  getDate: (date) => {
    if (date === null) return '-'
    const auth = JSON.parse(localStorage.getItem('user'))
    const yearType = auth?.years_type
    dayjs.extend(buddhistEra)
    let yearFormat = yearType === 'BE' ? 'BBBB' : 'YYYY'
    let dateFormat = dayjs(date).locale('th').format(`D MMM ${yearFormat}`)
    return dateFormat !== 'Invalid Date' ? `${dateFormat}` : ''
  },

  getTimeZone: (date) => {
    if (date === null) return '-'
    let timeZone = dayjs(date).format('Z')
    return timeZone
  },

  getYear: (date) => {
    if (date === null) return '-'
    const auth = JSON.parse(localStorage.getItem('user'))
    const yearType = auth?.years_type
    dayjs.extend(buddhistEra)
    let yearFormat = yearType === 'BE' ? 'BBBB' : 'YYYY'
    let year = dayjs(date).locale('th').format(`${yearFormat}`)
    return year
  },
}

export const yearHelper = {
  getYearBE: (year) => {
    return year < 2100 ? Number(year) + 543 : year
  },
}
