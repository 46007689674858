import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch, faMedal, faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useFetch from '../../../utils/useFetch'
import Skeleton from 'react-loading-skeleton'
import { dateHelper } from '../../../helpers/date.helper'
import { ButtonAction } from '../../../components/Button'

const StudentAwardContainer = (props) => {
  const { response: awardData, loading } = useFetch(`/student/personal/award`)

  return (
    <div className="card border-0 mb-3">
      <div className="card-body">
        <h5 className="card-title d-flex align-items-center">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faMedal} className="" />
          </div>
          <span>รางวัล</span>
          {props.student ? (
            <Link to={'/student-award/create'} className="btn btn-outline-primary btn-add ms-auto">
              <FontAwesomeIcon icon={faPlus} className="me-1" /> เพิ่มรายการ
            </Link>
          ) : (
            <></>
          )}
        </h5>
        <div className="table-responsive">
          <div id="w0" className="grid-view">
            {/* <div className="summary">
                แสดง <b>1 ถึง 1</b> จาก <b>1</b> ผลลัพธ์
            </div> */}
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '280px', minWidth: '280px' }}>อัปเดตล่าสุด</th>
                  <th style={{ width: '140px', minWidth: '140px' }}>ปีที่ได้รับ</th>
                  <th>รายการ</th>
                  <th>ประเทศ</th>
                  <th style={{ width: '140px', minWidth: '140px' }}>สถานะ</th>
                  <th className="action-column">รายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ) : awardData?.length > 0 ? (
                  awardData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {item?.updated_at
                            ? dateHelper.getDateTime(item.updated_at)
                            : dateHelper.getDateTime(item.created_at)}{' '}
                        </td>

                        <td>{item.year}</td>
                        <td className="text-start">{item.name}</td>
                        <td>{item.country_name}</td>
                        <td className={item.classStatus + ' align-middle'}>
                          <FontAwesomeIcon icon={faCircle} className="pe-1" />
                          {item.status}
                          <p className="text-secondary fs-14">{item.note} </p>
                        </td>
                        <td>
                          <ButtonAction
                            link={'/student-award/' + item.ID}
                            label="ดูรายละเอียด"
                            variant="outline-primary"
                            icon={faSearch}
                            width="160px"
                          />
                          {/* <Link
                            to={'/student-award/' + item.ID}
                            target="_blank"
                            className="btn btn-outline-primary ms-auto"
                          >
                            <FontAwesomeIcon icon={faSearch} className="" /> ดูรายละเอียด
                          </Link> */}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr className="odd">
                    <td valign="top" colSpan={6} className="text-center">
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentAwardContainer
