import { faClipboardList, faFileInvoice, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap'
import { th } from 'date-fns/locale'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormProvider, useForm } from 'react-hook-form'
import ReactLoading from 'react-loading'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import http from '../../utils/http'
import useFetch from '../../utils/useFetch'
import useFetchGET from '../../utils/useFetchGET'
import Contract from './form/Contract'
import Guarantor from './form/Guarantor'
import { contractHelper } from '../../helpers/contract.helper'

registerLocale('th', th)
setDefaultLocale('th')

const Update = () => {
  const { id } = useParams()
  var history = useHistory()
  const { response: contractData } = useFetch(`/student/contract/${id}`)

  // const { response: prefixOptions } = useFetch('/student/masterdata/prefix?publish=1')
  const { response: nationalityOptions } = useFetch(
    '/student/masterdata/nationality/list?publish=1'
  )
  const { response: currencyOptions } = useFetch('/student/masterdata/currency')

  const { response: optionStudent } = useFetch('/student/masterdata/student')
  const { response: optionScholarship } = useFetch('/student/masterdata/scholarship')
  const { response: optionScholarshipType } = useFetch('/student/masterdata/scholarship-type')
  const { response: optionInstitution } = useFetch('/student/masterdata/organization?institution=1')
  const { response: optionSubjectField } = useFetch('/student/masterdata/subject-field')

  const { response: optionAcademicYear } = useFetch('/student/masterdata/academic-year?publish=1')

  const { response: nameTitle } = useFetch('/student/masterdata/subject-field')

  const { response: relationshipOption } = useFetch('/student/masterdata/relationship?publish=1')
  const { response: occupationOptions } = useFetch('/student/masterdata/occupation')
  const { response: optionContractNo } = useFetch('/student/contract/list')
  const { response: provinceOptions } = useFetchGET('/province')
  const { response: educationlevelOptions } = useFetch('/student/masterdata/education-level')
  const [initData, setInitData] = useState({
    step: 1,
    tab_key: 'contract',
    is_initData: false,
    gurantorData: [],
    guarantorOptions: [],
    is_default_gurantor: false,
    to_submit: false,
    studentCurrentAddress: null,
    studentAddress: null,
  })

  const hookFormMethods = useForm({
    //resolver: yupResolver(validateSchema),
    // defaultValues: {
    //   contract_type: 1,
    // },
  })

  let canEditData =
    contractHelper.isStatusPendingUpdate(contractData?.status) ||
    contractHelper.isStatusWaitingInformation(contractData?.status)

  const getApiStudent = async (studentID) => {
    const res = await http.get('/student/student/' + studentID)

    return res
  }

  const getGuarantor = async (student_id, init = false) => {
    const json = await getApiStudent(student_id)
    //resetField('guarantor_id')
    if (init === false) {
      setValue('guarantor_name_title_id', null)
      setValue('guarantor_firstname_th', '')
      setValue('guarantor_lastname_th', '')
      setValue('guarantor_firstname_en', '')
      setValue('guarantor_lastname_en', '')
      setValue('guarantor_citizen_id_number', '')
      setValue('guarantor_phone_number', '')
      setValue('guarantor_email', '')
      setValue('guarantor_relationship', '')
      setValue('guarantor_nationality_id', '')
      setValue('guarantor_occupation', '')
      setValue('guarantor_organization', '')
    }
    let currentAddress = null
    let studentAddress = null
    if (json.data) {
        let current_address = json.data
        currentAddress = {
          current_address_no: current_address?.current_address_no,
          current_village_no: current_address?.current_village_no,
          current_alley: current_address?.current_alley,
          current_road: current_address?.current_road,
          current_province_id: current_address?.current_province_id,
          current_district_id: current_address?.current_district_id,
          current_subdistrict_id: current_address?.current_subdistrict_id,
          current_zipcode: current_address?.current_zipcode,
          guarantor_district: current_address?.guarantor_district,
          guarantor_subdistrict: current_address?.guarantor_subdistrict,
        }
        let student_address = json.data.address
        studentAddress = {
          address_no: student_address?.address_no,
          village_no: student_address?.village_no,
          alley: student_address?.alley,
          road: student_address?.road,
          province_id: student_address?.province?.id,
          district_id: student_address?.district?.id,
          subdistrict_id: student_address?.sub_district?.id,
          zipcode: student_address?.zipcode,
          guarantor_district: student_address?.district_options,
          guarantor_subdistrict: student_address?.sub_district_options,
        }

      let items = json.data.parents

      let data = []
      let guarantorOptions = []
      data[0] = {
        type: 'ไม่เลือกข้อมูล',
        data: [],
      }
      guarantorOptions[0] = {
        value: 0,
        label: 'ไม่เลือกข้อมูล',
      }
      if (items?.father !== undefined && items?.father?.firstname_th !== undefined) {
        data[data?.length] = {
          type: items?.father?.relationship,
          data: items?.father,
        }
        guarantorOptions[guarantorOptions?.length] = {
          value: data?.length - 1,
          label: 'บิดา',
        }
      }
      if (items?.mother !== undefined && items?.mother?.firstname_th !== undefined) {
        data[data?.length] = {
          type: items?.mother?.relationship,
          data: items?.mother,
        }
        guarantorOptions[guarantorOptions.length] = {
          value: data.length - 1,
          label: 'มารดา',
        }
      }
      if (items?.other !== undefined && items?.other?.firstname_th !== undefined) {
        data[data?.length] = {
          type: items?.other?.relationship,
          data: items?.other,
        }
        guarantorOptions[guarantorOptions.length] = {
          value: data.length - 1,
          label: `ผู้ปกครอง (${items.other.relationship})`,
        }
      }

      if (data.length > 0) {
        setInitData({
          ...initData,
          gurantorData: data,
          guarantorOptions: guarantorOptions,
          is_default_gurantor: true,
          studentCurrentAddress: currentAddress,
          studentAddress: studentAddress,
        })
      } else {
        setInitData({
          ...initData,
          gurantorData: [],
          guarantorOptions: [],
          is_default_gurantor: true,
          studentCurrentAddress: currentAddress,
          studentAddress: studentAddress,
        })
      }
    }
  }
  const {
    register,
    setValue,
    setError,
    watch,
    trigger,
    clearErrors,
    formState: { errors },
    getValues,
    reset,
    control,
  } = hookFormMethods

  let isLoaded =
    contractData &&
    nationalityOptions &&
    currencyOptions &&
    optionStudent &&
    optionScholarship &&
    optionScholarshipType &&
    optionInstitution &&
    optionSubjectField &&
    optionAcademicYear &&
    relationshipOption &&
    occupationOptions &&
    provinceOptions &&
    optionContractNo &&
    educationlevelOptions &&
    initData.is_initData === false

  if (isLoaded) {
    let scholarshipTypeID =
      contractData?.scholarship_type !== null && contractData?.scholarship_type !== ''
        ? {
            label: contractData?.scholarship_type_name,
            value: contractData?.scholarship_type_id,
          }
        : null

    let faculty =
      contractData?.faculty !== null
        ? {
            label: contractData?.faculty,
            value: contractData?.faculty,
          }
        : null
    let guarantorNameTitleID =
      contractData?.guarantor_name_title_id !== null && contractData?.guarantor_name_title_id !== ''
        ? {
            label: contractData?.guarantor_name_title_th,
            value: contractData?.guarantor_name_title_id,
          }
        : null

    let guarantorNationality =
      contractData?.guarantor_nationality !== null && contractData?.guarantor_nationality !== ''
        ? {
            label: contractData?.guarantor_nationality,
            value: contractData?.guarantor_nationality,
          }
        : null
    let witnessFirstnameFirst =
      contractData?.witness_name_title_first_id !== null &&
      contractData?.witness_name_title_first_id !== ''
        ? {
            label: contractData?.witness_name_title_first_th,
            value: contractData?.witness_name_title_first_id,
          }
        : null
    let witnessFirstnameSecond =
      contractData?.witness_name_title_second_id !== null &&
      contractData?.witness_name_title_second_id !== ''
        ? {
            label: contractData?.witness_name_title_second_th,
            value: contractData?.witness_name_title_second_id,
          }
        : null
    let spouseNationality =
      contractData?.spouse_nationality !== null && contractData?.spouse_nationality !== ''
        ? {
            label: contractData?.spouse_nationality,
            value: contractData?.spouse_nationality,
          }
        : null

    let guarantorCurrencyID =
      contractData?.guarantor_currency_id !== null && contractData?.guarantor_currency_id !== ''
        ? {
            label: contractData?.guarantor_currency_name,
            value: contractData?.guarantor_currency_id,
          }
        : null
    let guarantorRelationship =
      contractData?.guarantor_relationship !== null && contractData?.guarantor_relationship !== ''
        ? {
            label: contractData?.guarantor_relationship,
            value: contractData?.guarantor_relationship,
          }
        : null

    setValue('agency_id', String(contractData?.agency_id))
    setValue('student_status_id', String(contractData?.student_status_id))
    setValue('project_type_id', String(contractData?.project_type_id))
    setValue('subject_area', String(contractData?.subject_area))

    setValue('scholarship_id', contractData?.scholarship_id)
    setValue('academic_year', contractData?.academic_year)
    setValue('contract_type', String(contractData?.contract_type))
    //setValue('scholarship_type', scholarshipTypeID)
    setValue('scholarship_type', String(contractData?.scholarship_type_id))
    if (contractData?.contract_type_id) {
      setValue('contract_type_id', {
        label: contractData?.contract_type_name,
        value: contractData?.contract_type_id,
      })
    } else {
      setValue('contract_type_id', contractData?.contract_type_id)
    }
    setValue('contract_date', contractData?.contract_date)
    setValue('start_date', contractData?.start_date)
    setValue('institution_id', contractData?.institution_id)
    setValue('faculty', faculty)
    setValue('subject_field', contractData?.subject_field)
    setValue('before_high_school_academic_year', contractData?.before_high_school_academic_year)
    setValue('before_high_school_contract_no', contractData?.before_high_school_contract_no)
    setValue('before_high_school_contract_date', contractData?.before_high_school_contract_date)
    setValue('before_university_academic_year', contractData?.before_university_academic_year)
    setValue('before_university_contract_no', contractData?.before_university_contract_no)
    setValue('before_university_contract_date', contractData?.before_university_contract_date)
    setValue('witness_name_title_first_id', witnessFirstnameFirst)
    setValue('witness_firstname_first', contractData?.witness_firstname_first)
    setValue('witness_lastname_first', contractData?.witness_lastname_first)
    setValue('witness_name_title_second_id', witnessFirstnameSecond)
    setValue('witness_firstname_second', contractData?.witness_firstname_second)
    setValue('witness_lastname_second', contractData?.witness_lastname_second)
    setValue('show_witness', contractData?.show_witness)
    setValue('guarantor_name_title_id', guarantorNameTitleID)
    setValue('guarantor_firstname_th', contractData?.guarantor_firstname_th)
    setValue('guarantor_lastname_th', contractData?.guarantor_lastname_th)
    setValue('guarantor_firstname_en', contractData?.guarantor_firstname_en)
    setValue('guarantor_lastname_en', contractData?.guarantor_lastname_en)
    setValue('guarantor_birth_date', contractData?.guarantor_birth_date)
    setValue('guarantor_citizen_id_number', contractData?.guarantor_citizen_id_number)
    setValue('guarantor_phone_number', contractData?.guarantor_phone_number)
    setValue('guarantor_email', contractData?.guarantor_email)
    setValue('guarantor_relationship', guarantorRelationship)
    setValue('guarantor_nationality', guarantorNationality)
    setValue('guarantor_occupation', contractData?.guarantor_occupation)
    setValue('guarantor_position', contractData?.guarantor_position)
    setValue('guarantor_organization', contractData?.guarantor_organization)
    setValue('marital_status', String(contractData?.marital_status))

    setValue('guarantor_address_type', String(contractData?.guarantor_address_type))
    setValue('guarantor_address_no', contractData?.guarantor_address_no)
    setValue('guarantor_village_no', contractData?.guarantor_village_no)
    setValue('guarantor_alley', contractData?.guarantor_alley)
    setValue('guarantor_road', contractData?.guarantor_road)
    setValue('guarantor_province_id', parseInt(contractData?.guarantor_province_id))
    setValue('guarantor_district_id', parseInt(contractData?.guarantor_district_id))
    setValue('guarantor_subdistrict_id', parseInt(contractData?.guarantor_subdistrict_id))
    setValue('guarantor_zipcode', contractData?.guarantor_zipcode)

    setValue('guarantor_amount_baht', contractData?.guarantor_amount_baht)
    setValue('guarantor_amount', contractData?.guarantor_amount)
    setValue('guarantor_currency_id', guarantorCurrencyID)

    setValue('spouse_name_title_id', contractData?.spouse_name_title_id)
    setValue('spouse_firstname', contractData?.spouse_firstname)
    setValue('spouse_lastname', contractData?.spouse_lastname)
    setValue('spouse_address_no', contractData?.spouse_address_no)
    setValue('spouse_village_no', contractData?.spouse_village_no)
    setValue('spouse_citizen_id_number', contractData?.spouse_citizen_id_number)
    setValue('spouse_road', contractData?.spouse_road)
    setValue('spouse_alley', contractData?.spouse_alley)
    setValue('spouse_province_id', parseInt(contractData?.spouse_province_id))
    setValue('spouse_district_id', parseInt(contractData?.spouse_district_id))
    setValue('spouse_subdistrict_id', parseInt(contractData?.spouse_subdistrict_id))
    setValue('spouse_zipcode', contractData?.spouse_zipcode)
    setValue('spouse_nationality', spouseNationality)

    setValue('education_level_id', String(contractData?.education_level_id))
    setValue('expand_type', parseInt(contractData?.expand_type))
    setValue('end_date', contractData?.end_date)
    setValue('contract_ref_id', String(contractData?.contract_ref_id))

    if (contractData.guaratee_detail.length > 0) {
      let index_land = 1
      for (let index = 0; index < contractData.guaratee_detail.length; index++) {
        const element = contractData.guaratee_detail[index]
        if (element.type === 'land') {
          setValue('title_deed_number_' + index_land, element?.title_deed_number)
          setValue('percel_no_' + index_land, element?.percel_no)
          setValue('dealing_file_no_' + index_land, element?.dealing_file_no)
          setValue('sheet_number_' + index_land, element?.sheet_number)
          setValue('ngan_' + index_land, element?.ngan)
          setValue('rai_' + index_land, element?.rai)
          setValue('wha_' + index_land, element?.wha)
          setValue('land_' + index_land + '_province_id', element?.land_province_id)
          setValue('district_land_' + index_land + '_id', element?.land_district_id)
          setValue('subdistrict_land_' + index_land + '_id', element?.land_subdistrict_id)
          index_land++
        }
      }

      let index_asset = 1
      for (let index = 0; index < contractData.guaratee_detail.length; index++) {
        const element = contractData.guaratee_detail[index]
        if (element.type === 'asset') {
          setValue('asset_name_' + index_asset, element?.asset_name)
          setValue('asset_value_' + index_asset, element?.asset_value)
          index_asset++
        }
      }
    }

    if (contractData.guaratee_detail.length > 0) {
      let index_land = 1
      for (let index = 0; index < contractData.guaratee_detail.length; index++) {
        const element = contractData.guaratee_detail[index]
        if (element.type === 'land') {
          setValue('title_deed_number_' + index_land, element?.title_deed_number)
          setValue('percel_no_' + index_land, element?.percel_no)
          setValue('dealing_file_no_' + index_land, element?.dealing_file_no)
          setValue('sheet_number_' + index_land, element?.sheet_number)
          setValue('ngan_' + index_land, element?.ngan)
          setValue('rai_' + index_land, element?.rai)
          setValue('wha_' + index_land, element?.wha)
          setValue('land_' + index_land + '_province_id', element?.land_province_id)
          setValue('district_land_' + index_land + '_id', element?.land_district_id)
          setValue('subdistrict_land_' + index_land + '_id', element?.land_subdistrict_id)
          index_land++
        }
      }

      let index_asset = 1
      for (let index = 0; index < contractData.guaratee_detail.length; index++) {
        const element = contractData.guaratee_detail[index]
        if (element.type === 'asset') {
          setValue('asset_name_' + index_asset, element?.asset_name)
          setValue('asset_value_' + index_asset, element?.asset_value)
          index_asset++
        }
      }
    }

    if (contractData?.guarantor_district) {
      let element = contractData?.guarantor_district
      contractData.get_district_guarantor = element.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
    }

    if (contractData?.guarantor_subdistrict) {
      let element = contractData?.guarantor_subdistrict
      contractData.get_subdistrict_guarantor = element.map((item, index) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    }

    if (contractData?.spouse_district) {
      let element = contractData?.spouse_district
      contractData.get_district_spouse = element.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
    }

    if (contractData?.spouse_subdistrict) {
      let element = contractData?.spouse_subdistrict
      contractData.get_subdistrict_spouse = element.map((item, index) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    }

    if (contractData.guaratee_detail.length > 0) {
      let index_land = 1
      for (let index = 0; index < contractData.guaratee_detail.length; index++) {
        const element = contractData.guaratee_detail[index]
        if (element.type === 'land') {
          if (index_land === 1) {
            contractData.get_land_1_district = element.district.map((item, index) => {
              return {
                value: item.id,
                label: item.name_th,
              }
            })
            contractData.get_land_1_subdistrict = element.subdistrict.map((item, index) => {
              return {
                value: item.id,
                label: item.name,
              }
            })
          } else if (index_land === 2) {
            contractData.get_land_2_district = element.district.map((item, index) => {
              return {
                value: item.id,
                label: item.name_th,
              }
            })
            contractData.get_land_2_subdistrict = element.subdistrict.map((item, index) => {
              return {
                value: item.id,
                label: item.name,
              }
            })
          }
          index_land++
        }
      }
    }

    setInitData({ ...initData, is_initData: true })

    if (initData.is_default_gurantor === false) {
      getGuarantor(contractData.student_id, true)
    }
  }

  const onSubmit = async (dataObj) => {
    // console.log(dataObj)
    // return false
    let status_ = 0
    if (dataObj?.guarantor_name_title_id === '') {
      setError(
        'guarantor_name_title_id',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_firstname_th === '') {
      setError(
        'guarantor_firstname_th',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_firstname_en === '') {
      setError(
        'guarantor_firstname_en',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_lastname_th === '') {
      setError(
        'guarantor_lastname_th',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_lastname_en === '') {
      setError(
        'guarantor_lastname_en',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_relationship === '') {
      setError(
        'guarantor_relationship',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj?.guarantor_nationality_id === '') {
      setError(
        'guarantor_nationality_id',
        { type: 'required', message: 'requried' },
        { shouldFocus: true }
      )
      status_ = 1
    }
    if (dataObj.guarantor_amount === '') {
      if (isNaN(+dataObj.guarantor_amount)) {
        setError(
          'guarantor_amount',
          { type: 'number', message: 'ต้องเป็นตัวเลข' },
          { shouldFocus: true }
        )
        status_ = 1
      }
    }

    if (dataObj.guarantor_amount_baht === '') {
      if (isNaN(+dataObj.guarantor_amount_baht)) {
        setError(
          'guarantor_amount_baht',
          { type: 'number', message: 'ต้องเป็นตัวเลข' },
          { shouldFocus: true }
        )

        status_ = 1
      }
    }
    if (status_ === 1) {
      Swal.fire({
        icon: 'error',
        title: 'ต้องระบุข้อมูลที่บังคับ',
        text: 'ระบุข้อมูลผู้ค้ำประกัน',
        confirmButtonColor: '#0d3996',
        confirmButtonText: 'ตกลง',
      })
      //alert("กรณีมีข้อมูลผู้ค้ำประกัน ต้องระบุข้อมูลที่บังคับ");
      return false
    }
    // }
    setInitData({ ...initData, to_submit: true })
    try {
      let formData = new FormData() //formdata object
      formData.append('id', id)
      if (typeof dataObj?.agency_id === 'object' ) {
        formData.append('agency_id', dataObj?.agency_id?.value)
      } else {
        formData.append('agency_id', dataObj?.agency_id)
      }
      if (typeof dataObj?.student_status_id === 'object' ) {
        formData.append('student_status_id', dataObj?.student_status_id?.value)
      } else {
        formData.append('student_status_id', dataObj?.student_status_id)
      }
      if (typeof dataObj?.project_type_id === 'object' ) {
        formData.append('project_type_id', dataObj?.project_type_id?.value)
      } else {
        formData.append('project_type_id', dataObj?.project_type_id)
      }
      if (typeof dataObj?.subject_area === 'object' ) {
        formData.append('subject_area', dataObj?.subject_area?.value)
      } else {
        formData.append('subject_area', dataObj?.subject_area)
      }
      formData.append('guarantor_address_no', dataObj?.guarantor_address_no)
      formData.append('guarantor_address_type', dataObj?.guarantor_address_type)
      formData.append('guarantor_alley', dataObj?.guarantor_alley)
      if (typeof dataObj?.academic_year === 'object' ) {
        formData.append('academic_year', dataObj?.academic_year?.value)
      } else {
        formData.append('academic_year', dataObj?.academic_year)
      }
      if (typeof dataObj?.before_high_school_academic_year === 'object' ) {
        formData.append(
          'before_high_school_academic_year',
          dataObj?.before_high_school_academic_year?.value
        )
      } else {
        formData.append(
          'before_high_school_academic_year',
          dataObj?.before_high_school_academic_year
        )
      }
      formData.append('before_high_school_contract_date', dataObj?.before_high_school_contract_date)
      formData.append('before_high_school_contract_no', dataObj?.before_high_school_contract_no)
      if (typeof dataObj?.before_university_academic_year === 'object' ) {
        formData.append(
          'before_university_academic_year',
          dataObj?.before_university_academic_year?.value
        )
      } else {
        formData.append('before_university_academic_year', dataObj?.before_university_academic_year)
      }
      formData.append('before_university_contract_date', dataObj?.before_university_contract_date)
      formData.append('before_university_contract_no', dataObj?.before_university_contract_no)
      formData.append('contract_date', dataObj?.contract_date)
      formData.append('start_date', dataObj?.start_date)
      formData.append('contract_type', dataObj?.contract_type)
      if (typeof dataObj?.contract_type_id === 'object' ) {
        formData.append('contract_type_id', dataObj?.contract_type_id?.value)
      } else {
        formData.append('contract_type_id', dataObj?.contract_type_id)
      }
      formData.append('dealing_file_no', dataObj?.dealing_file_no)
      if (typeof dataObj?.faculty === 'object' ) {
        formData.append('faculty', dataObj?.faculty?.value)
      } else {
        formData.append('faculty', dataObj?.faculty)
      }
      formData.append('guarantor_amount', dataObj?.guarantor_amount)
      formData.append('guarantor_amount_baht', dataObj?.guarantor_amount_baht)
      formData.append('guarantor_birth_date', dataObj?.guarantor_birth_date)
      formData.append('guarantor_citizen_id_number', dataObj?.guarantor_citizen_id_number)
      formData.append('guarantor_email', dataObj?.guarantor_email)
      
      if (typeof dataObj?.guarantor_name_title_id === 'object' ) {
        formData.append('guarantor_name_title_id', dataObj?.guarantor_name_title_id?.value)
      } else {
        formData.append('guarantor_name_title_id', dataObj?.guarantor_name_title_id)
      }
      formData.append('guarantor_firstname_th', dataObj?.guarantor_firstname_th)
      formData.append('guarantor_firstname_en', dataObj?.guarantor_firstname_en)
      formData.append('guarantor_lastname_en', dataObj?.guarantor_lastname_en)
      formData.append('guarantor_lastname_th', dataObj?.guarantor_lastname_th)
      if (typeof dataObj?.guarantor_nationality === 'object' ) {
        formData.append('guarantor_nationality', dataObj?.guarantor_nationality?.value)
      } else {
        formData.append('guarantor_nationality', dataObj?.guarantor_nationality)
      }
      if (typeof dataObj?.guarantor_occupation === 'object' ) {
        formData.append('guarantor_occupation', dataObj?.guarantor_occupation?.value)
      } else {
        formData.append('guarantor_occupation', dataObj?.guarantor_occupation)
      }
      // if (typeof dataObj?.guarantor_organization === 'object' ) {
      //   formData.append('guarantor_organization', dataObj?.guarantor_organization?.value)
      // } else {
      //   formData.append('guarantor_organization', dataObj?.guarantor_organization)
      // }
      formData.append('guarantor_organization', dataObj?.guarantor_organization)
      formData.append('guarantor_phone_number', dataObj?.guarantor_phone_number)
      // if (typeof dataObj?.guarantor_position === 'object' ) {
      //   formData.append('guarantor_position', dataObj?.guarantor_position?.value)
      // } else {
      //   formData.append('guarantor_position', dataObj?.guarantor_position)
      // }
      formData.append('guarantor_position', dataObj?.guarantor_position)
      if (typeof dataObj?.guarantor_relationship === 'object' ) {
        formData.append('guarantor_relationship', dataObj?.guarantor_relationship?.value)
      } else {
        formData.append('guarantor_relationship', dataObj?.guarantor_relationship)
      }
      formData.append('guarantor_province_id', dataObj?.guarantor_province_id)
      formData.append('guarantor_district_id', dataObj?.guarantor_district_id)
      formData.append('guarantor_subdistrict_id', dataObj?.guarantor_subdistrict_id)
      formData.append('guarantor_zipcode', dataObj?.guarantor_zipcode)
      if (typeof dataObj?.institution_id === 'object' ) {
        formData.append('institution_id', dataObj?.institution_id?.value)
      } else {
        formData.append('institution_id', dataObj?.institution_id)
      }
      formData.append('marital_status', dataObj?.marital_status)
      formData.append('guarantor_road', dataObj?.guarantor_road)
      if (typeof dataObj?.scholarship_id === 'object' ) {
        formData.append('scholarship_id', dataObj?.scholarship_id?.value)
      } else {
        formData.append('scholarship_id', dataObj?.scholarship_id)
      }
      if (typeof dataObj?.scholarship_type === 'object' ) {
        formData.append('scholarship_type', dataObj?.scholarship_type?.value)
      } else {
        formData.append('scholarship_type', dataObj?.scholarship_type)
      }
      formData.append('sheet_number', dataObj?.sheet_number)
      formData.append('show_witness', dataObj?.show_witness === true || dataObj?.show_witness === 1 ? 1 : 0)
      formData.append('spouse_address_no', dataObj?.spouse_address_no)
      formData.append('spouse_alley', dataObj?.spouse_alley)
      formData.append('spouse_district_id', dataObj?.spouse_district_id)
      formData.append('spouse_firstname', dataObj?.spouse_firstname)
      formData.append('spouse_lastname', dataObj?.spouse_lastname)
      if (typeof dataObj?.spouse_name_title_id === 'object' ) {
        formData.append('spouse_name_title_id', dataObj?.spouse_name_title_id?.value)
      } else {
        formData.append('spouse_name_title_id', dataObj?.spouse_name_title_id)
      }
      if (typeof dataObj?.spouse_nationality === 'object' ) {
        formData.append('spouse_nationality', dataObj?.spouse_nationality?.value)
      } else {
        formData.append('spouse_nationality', dataObj?.spouse_nationality)
      }
      formData.append('spouse_province_id', dataObj?.spouse_province_id)
      formData.append('spouse_road', dataObj?.spouse_road)
      formData.append('spouse_subdistrict_id', dataObj?.spouse_subdistrict_id)
      formData.append('spouse_village_no', dataObj?.spouse_village_no)
      formData.append('spouse_citizen_id_number', dataObj?.spouse_citizen_id_number)
      formData.append('spouse_zipcode', dataObj?.spouse_zipcode)
      formData.append('student_id', dataObj?.student_id)
      if (typeof dataObj?.subject_field === 'object' ) {
        formData.append('subject_field', dataObj?.subject_field?.value)
      } else {
        formData.append('subject_field', dataObj?.subject_field)
      }
      formData.append('guarantor_village_no', dataObj?.guarantor_village_no)
      formData.append('witness_firstname_first', dataObj?.witness_firstname_first)
      formData.append('witness_lastname_first', dataObj?.witness_lastname_first)
      if (typeof dataObj?.witness_name_title_first_id === 'object' ) {
        formData.append('witness_name_title_first_id', dataObj?.witness_name_title_first_id?.value)
      } else {
        formData.append('witness_name_title_first_id', dataObj?.witness_name_title_first_id)
      }
      formData.append('witness_firstname_second', dataObj?.witness_firstname_second)
      formData.append('witness_lastname_second', dataObj?.witness_lastname_second)
      if (typeof dataObj?.witness_name_title_second_id === 'object' ) {
        formData.append('witness_name_title_second_id', dataObj?.witness_name_title_second_id?.value)
      } else {
        formData.append('witness_name_title_second_id', dataObj?.witness_name_title_second_id)
      }
      if (typeof dataObj?.guarantor_currency_id === 'object' ) {
        formData.append('guarantor_currency_id', dataObj?.guarantor_currency_id?.value)
      } else {
        formData.append('guarantor_currency_id', dataObj?.guarantor_currency_id)
      }
      formData.append('status', dataObj?.status)
      if (typeof dataObj?.contract_ref_id === 'object' ) {
        formData.append('contract_ref_id', dataObj?.contract_ref_id?.value)
      } else {
        formData.append('contract_ref_id', dataObj?.contract_ref_id)
      }
      if (dataObj?.is_recoup !== '1') {
        formData.append('is_recoup', 0)
      } else {
        formData.append('is_recoup', 1)
      }
      if (typeof dataObj?.education_level_id === 'object' ) {
        formData.append('education_level_id', dataObj?.education_level_id?.value)
      } else {
        formData.append('education_level_id', dataObj?.education_level_id)
      }
      formData.append('expand_type', dataObj?.expand_type)
      formData.append('end_date', dataObj?.end_date)
       formData.append('commitment', dataObj?.commitment)
       formData.append('note', dataObj?.note)
      if (dataObj?.contract_no_type) {
        //สัญญาหลักเท่านั้น
        if (typeof dataObj?.contract_no_ref === 'object' ) {
          formData.append('contract_no_ref', dataObj?.contract_no_ref?.value)
        } else {
          formData.append('contract_no_ref', dataObj?.contract_no_ref)
        }
        formData.append('contract_type', '1')
        formData.append('contract_ref_id', '')
        formData.append('commitment', '')
      }

      let guaratee_detail = []
      let index_detail = 0
      if (dataObj?.title_deed_number_1 !== '' && dataObj?.percel_no_1 !== '') {
        guaratee_detail[index_detail] = {
          type: 'land',
          title_deed_number: dataObj?.title_deed_number_1,
          percel_no: dataObj?.percel_no_1,
          dealing_file_no: dataObj?.dealing_file_no_1,
          sheet_number: dataObj?.sheet_number_1,
          rai: dataObj?.rai_1,
          ngan: dataObj?.ngan_1,
          wha: dataObj?.wha_1,
          land_province_id: dataObj?.land_1_province_id,
          land_district_id: dataObj?.district_land_1_id,
          land_subdistrict_id: dataObj?.subdistrict_land_1_id,
          land_value: '',
        }
        index_detail++
      }
      if (dataObj?.title_deed_number_2 !== '' && dataObj?.percel_no_2 !== '') {
        guaratee_detail[index_detail] = {
          type: 'land',
          title_deed_number: dataObj?.title_deed_number_2,
          percel_no: dataObj?.percel_no_2,
          dealing_file_no: dataObj?.dealing_file_no_2,
          sheet_number: dataObj?.sheet_number_2,
          rai: dataObj?.rai_2,
          ngan: dataObj?.ngan_2,
          wha: dataObj?.wha_2,
          land_province_id: dataObj?.land_2_province_id,
          land_district_id: dataObj?.district_land_2_id,
          land_subdistrict_id: dataObj?.subdistrict_land_2_id,
          land_value: '',
        }
        index_detail++
      }

      if (dataObj?.asset_name_1 !== '' && dataObj?.asset_value_1 !== '') {
        guaratee_detail[index_detail] = {
          type: 'asset',
          asset_name: dataObj?.asset_name_1,
          asset_value: dataObj?.asset_value_1,
        }
        index_detail++
      }

      if (dataObj?.asset_name_2 !== '' && dataObj?.asset_value_2 !== '') {
        guaratee_detail[index_detail] = {
          type: 'asset',
          asset_name: dataObj?.asset_name_2,
          asset_value: dataObj?.asset_value_2,
        }
        index_detail++
      }

      formData.append('guaratee_detail', JSON.stringify(guaratee_detail))
      formData.append('status', dataObj?.submit)
      const apiKey = process.env.REACT_APP_API_KEY
      const apiVersion = process.env.REACT_APP_API_VERSION
      //const token = 'niVRizsa9eRC6BHLhvcy24iPCK90BWjk'
      //  const baseUrl = "https://student.tester.studio";
      const baseUrl = process.env.REACT_APP_API_URL
      const auth = JSON.parse(localStorage.getItem('user'))
      const token = auth.token
      const response = await fetch(baseUrl + `/student/contract/update/${id}`, {
        method: 'PATCH',
        body: formData,
        headers: {
          'x-api-key': apiKey,
          'x-api-version': apiVersion,
          'access-token': token,
        },
      })

      if (!response.ok) {
        setInitData({ ...initData, to_submit: false })
        throw new Error('Something went wrong!')
      }

      if (response.ok) {
        const data = await response.json()
        //console.log(data)
        if (
          data.status === '201' ||
          data.status === 201 ||
          data.status === 202 ||
          data.status === '202'
        ) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonColor: '#0d3996',
            confirmButtonText: 'ตกลง',
            footer: '',
          }).then(() => {
            history.push('/contract/' + data.data.root_id)
          })
        } else {
          setInitData({ ...initData, to_submit: false })
          throw new Error(data.data)
        }
        //setResultValidate(data.data)
      }
    } catch (error) {
      //  alert(error)
      setInitData({ ...initData, to_submit: false })
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        text: error,
        confirmButtonColor: '#0d3996',
        confirmButtonText: 'ตกลง',
      })
      throw new Error('Something went wrong!')
      //setError(error.message)
    }
  }

  const onError = (errors, e) => {}

  const onRequried = () => {
    return true
  }

  const methods = {
    nameTitle,
    // prefixOptions,
    nationalityOptions,
    currencyOptions,
    register,
    setValue,
    errors,
    clearErrors,
    watch,
    optionStudent,
    optionScholarship,
    optionScholarshipType,
    optionInstitution,
    optionSubjectField,
    optionAcademicYear,
    getValues,
    reset,
    trigger,
    relationshipOption,
    occupationOptions,
    provinceOptions,
    contractData,
    onRequried,
    optionContractNo,
    getGuarantor,
    control,
    educationlevelOptions,
  }

  const nextForm = async () => {
    clearErrors()
    const result = await trigger([
      'scholarship_id',
      'contract_type',
      'scholarship_type',
      'contract_type_id',
      'academic_year',
      'contract_date',
      'institution_id',
      'status',
      'contract_no_ref',
    ])
    //console.log(result)
    if (result === true) {
      setInitData({ ...initData, step: 2, tab_key: 'guarantor' })
    }
  }

  const prevForm = () => {
    setInitData({ ...initData, step: 1, tab_key: 'contract' })
  }

  const setKey = (key) => {
    let step = 1
    if (key === 'guarantor') {
      step = 2
    }
    setInitData({ ...initData, tab_key: key, step: step })
  }

  return (
    <>
      <div className="contract-update">
        <section className="bg-light">
          <Container fluid className="content container">
            <div className="card border-0 mb-3">
              <div className="card-header">
                <Row>
                  <Col className="d-md-flex pt-4 bg-white border-0">
                    <h5 className="title card-title d-flex align-items-center me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faClipboardList} className="" />
                      </div>
                      <span className="font-prompt"> แก้ไขสัญญา </span>
                    </h5>
                  </Col>
                </Row>
              </div>

              <div className="card-body">
                {canEditData ? (
                  <>
                    {!initData.is_initData || initData.is_submit === true ? (
                      <>
                        <ReactLoading type="bubbles" color="#26b9d1" className="mx-auto my-4" />
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col md="12">
                            <div className="scholarship-student-create">
                              <div className="scholarship-student-form">
                                <FormProvider {...hookFormMethods}>
                                  <Form onSubmit={hookFormMethods.handleSubmit(onSubmit, onError)}>
                                    <Tab.Container
                                      id="formContract"
                                      defaultActiveKey="contract"
                                      activeKey={initData.tab_key}
                                      onSelect={(k) => setKey(k)}
                                    >
                                      <Row>
                                        <Col sm={12}>
                                          <Nav
                                            variant="pills"
                                            className="nav nav-pills w-100 steps ps-0"
                                          >
                                            <Nav.Item className="step step-first">
                                              <Nav.Link eventKey="contract" className="bullet">
                                                <FontAwesomeIcon
                                                  icon={faFileInvoice}
                                                  className="font-28"
                                                />
                                              </Nav.Link>
                                              <span>ข้อมูลประกอบสัญญา</span>
                                            </Nav.Item>

                                            <Nav.Item className="step">
                                              <Nav.Link eventKey="guarantor" className="bullet">
                                                <FontAwesomeIcon
                                                  icon={faUsers}
                                                  className="font-28"
                                                />
                                              </Nav.Link>
                                              <span>ผู้ค้ำประกัน</span>
                                            </Nav.Item>
                                          </Nav>
                                        </Col>
                                        <Col sm={12}>
                                          <Tab.Content>
                                            <Tab.Pane eventKey="guarantor">
                                              <Guarantor
                                                {...methods}
                                                guarantorData={initData.gurantorData}
                                                guarantorOptions={initData.guarantorOptions}
                                                currentAddress={initData.studentCurrentAddress}
                                                studentAddress={initData.studentAddress}
                                              />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="contract">
                                              <Contract {...methods} />
                                            </Tab.Pane>
                                          </Tab.Content>
                                        </Col>
                                        {initData.step === 1 ? (
                                          <>
                                            <Col sm={12} className="mt-3 text-end">
                                              <Button
                                                type="button"
                                                variant="info"
                                                className="text-white"
                                                onClick={(e) => nextForm()}
                                              >
                                                ถัดไป
                                              </Button>
                                            </Col>
                                          </>
                                        ) : (
                                          <>
                                            <Col sm={6} className="mt-3 text-start">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => prevForm()}
                                              >
                                                ย้อนกลับ
                                              </button>
                                            </Col>
                                            <Col sm={6} className="mt-3 text-end">
                                              <div className="form-group d-flex justify-content-end">
                                                <div>
                                                  {!contractHelper.isStatusApproved(
                                                    contractData?.status
                                                  ) && (
                                                    <>
                                                      <button
                                                        type="submit"
                                                        {...register('submit', {
                                                          required: true,
                                                        })}
                                                        className="btn btn-primary me-2"
                                                        value="waiting_information"
                                                        onClick={(event) => {
                                                          setValue('submit', 'waiting_information')
                                                        }}
                                                        disabled={initData.to_submit}
                                                      >
                                                        บันทึก
                                                      </button>
                                                    </>
                                                  )}

                                                  <button
                                                    type="submit"
                                                    {...register('submit', {
                                                      required: true,
                                                    })}
                                                    className="btn btn-primary"
                                                    value="waiting_verification"
                                                    onClick={(event) => {
                                                      setValue('submit', 'waiting_verification')
                                                    }}
                                                    disabled={initData.to_submit}
                                                  >
                                                    {' '}
                                                    บันทึก และ ส่งข้อมูลให้ สสวท.
                                                  </button>
                                                </div>
                                              </div>
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </Tab.Container>
                                  </Form>
                                </FormProvider>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="text-center">ไม่อยู่ในช่วงแก้ไขสัญญา</p>
                  </>
                )}
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  )
}

export default Update
