import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import * as yup from 'yup'
import { AlertError, AlertSuccess } from '../../components/Alert'
import { academicResultHelper } from '../../helpers/academic-result.helper'
import { yearHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import { useStudyPlanAcademicYearOptions } from '../../hooks/options/useOptions'
import http from '../../utils/http'
import _ from '../../utils/utils'
import { ListItem } from './Styled'

const FormField = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { options: academicYearOptions, loading: academicYearLoading } =
    useStudyPlanAcademicYearOptions()

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const [files, setFiles] = useState([])

  const [type, setType] = useState('')

  const [class_year, setClassYear] = useState('')
  

  const urlAction = '/academic-result'
  const urlActionCallAPI = '/student/academic-result/create'

  const validationSchema = yup.object().shape({
    gpa: yup.string().when('type', {
      is: (value) => academicResultHelper?.isTypeNumber(value?.value),
      then: yup.string().required('ผลการเรียนเฉลี่ย (GPA) ต้องไม่ว่าง'),
    }),

    gpax: yup.string().when('type', {
      is: (value) => academicResultHelper?.isTypeNumber(value?.value),
      then: yup.string().required('ผลการเรียนต้องไม่ว่าง'),
    }),

    semester: yup.string().required('ภาคการศึกษาต้องไม่ว่าง'),

    description: yup.string().when('type', {
      is: (value) => academicResultHelper?.isTypeResearch(value?.value),
      then: yup.string().required('รายละเอียดต้องไม่ว่าง'),
    }),
    


    class_year: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกชั้นปี'),
        value: yup
          .string()
          .required('กรุณาเลือกชั้นปี')
          .nullable()
          .required('กรุณาเลือกชั้นปี'),
      })
      .nullable()
      .required('กรุณาเลือกชั้นปี'),


    type: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกรูปแบบผลการศึกษา'),
        value: yup
          .string()
          .required('กรุณาเลือกรูปแบบผลการศึกษา')
          .nullable()
          .required('กรุณาเลือกรูปแบบผลการศึกษา'),
      })
      .nullable()
      .required('กรุณาเลือกรูปแบบผลการศึกษา'),

    academic_year: yup
      .object()
      .shape({
        label: yup.string().required('กรุณาเลือกปีการศึกษา'),
        value: yup
          .string()
          .required('กรุณาเลือกปีการศึกษา')
          .nullable()
          .required('กรุณาเลือกปีการศึกษา'),
      })
      .nullable()
      .required('กรุณาเลือกปีการศึกษา'),

    file: yup.mixed().test('fileSize', 'กรุณาแนบไฟล์', (value) => {
      return value[0]
    }),
  })

  let academicYear = {
    value: data?.academic_year,
    label: yearHelper.getYearBE(data?.academic_year),
  }

  let classYear = {
    value: data?.class_year,
    label: data?.class_year,
  }

  const formOptions = {
    resolver: yupResolver(validationSchema),
  }

  formOptions.defaultValues = {
    academic_year: academicYear,
    semester: data?.semester,
    type: null,
    class_year: classYear,
  }

  const { register, handleSubmit, clearErrors, control, setError, formState, setValue } =
    useForm(formOptions)
  const { errors } = formState

  const handleAcademicYearChange = (value) => {
    if (value) {
      let options = {
        value: value.value,
        label: value.label,
      }
      setValue('academic_year', options)
    } else {
      setValue('academic_year', [])
    }
  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '0',
      paddingLeft: '0',
      '&:hover': {
        color: '#e3e3e3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused || state.isSearchable ? '#0d3996' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      '&:active': {
        backgroundColor: '#0d3996',
        color: '#fff',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      paddingTop: '0',
      paddingBottom: '0',
    }),
    control: (state) => ({
      fontWeight: 500,
      fontSize: '15px',
      display: 'flex',
      backgroundColor: '#fff',
      height: '44px',
      borderRadius: '4px',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#e3e3e3',
      paddingRight: '4px',
      paddingLeft: '0',
      border: 'none',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  const createData = async (data) => {
    let dataRes = await http.post(urlActionCallAPI, data)
    if (dataRes.status === 201) {
      setIsLoading(false)
      AlertSuccess('ยื่นผลการศึกษาเรียบร้อย', '')
      history.push(urlAction)
    } else {
      setIsLoading(false)
      AlertError('เกิดข้อผิดพลาด', '')
    }
  }

  const onSubmit = async (dataObj) => {

    setIsLoading(true)
    let dataPost = {
      type: dataObj?.type?.value,
      gpax: dataObj?.gpax,
      gpa: dataObj?.gpa,
      academic_year: dataObj?.academic_year?.value,
      class_year: dataObj?.class_year.value,
      semester: dataObj?.semester,
      description: dataObj?.description,
      files: files,
      education_level_id: data?.education_level_id,
      semester_id: data?.semester_id,
      student_status: data?.student_status,
    }

    return createData(dataPost)
  }

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleChangeFileDescription = (i, e) => {
    let newFileInput = [...files]
    newFileInput[i]['description'] = e?.target?.value
    setFiles(newFileInput)
  }

  const handleChangeFile = (e) => {
    let fileInput = e?.target?.files ?? null

    let newFileInput = [...files]
    let indexFile = files.length
    newFileInput[indexFile] = { file: fileInput[0], description: '' }

    let fileSize = files.length != 0 ? parseFloat(files[0].size / 1024 / 1024).toFixed(1) : 0 // in MB

    if (fileInput.length === 0) {
      setError('file', {
        message: 'กรุณาอัปโหลดไฟล์',
      })
      return false
    }

    if (!fileInput[0].name.match(/\.(pdf)$/)) {
      setError('file', {
        type: 'manual',
        message: 'ไฟล์นามสกุล .pdf เท่านั้น',
      })
      return false
    }
    if (fileSize > 10) {
      setError('file', {
        message: `ขนาดไฟล์ห้ามเกิน 10 MB ขนาดของไฟล์ที่อัปโหลดคือ ${fileSize} MB`,
      })
      return false
    }

    clearErrors('file')

    if (!errors.file) {
      setFiles(newFileInput)
    }
  }

  const handleRemoveFile = (i, e) => {
    let newFileInput = [...files]
    newFileInput.splice(i, 1)
    setFiles(newFileInput)
  }

  const handleChangeAcademicresultType = (value) => {
    let options = ''
    if (!_.isNull(value)) {
      setType(value?.value)

      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors('type')
    }
    setValue('type', options)
  }

  const handleChangeAcademicresultClassYear = (value) => {
    let options = ''
    if (!_.isNull(value)) {
      setClassYear(value?.value)

      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors('class_year')
    }
    setValue('class_year', options)
  }

  const thumbs = files?.map((item, index) => (
    <>
      <ListItem className={'success'}>
        <div className="upload-list-container">
          <div className="upload-list-item">
            <div className="upload-list-item-info">
              <span className="upload-span">
                <FontAwesomeIcon icon={faFileAlt} className="" />
                <span className="upload-list-item-name">
                  {item.file.name} ({item.file.size / 1024} KB)
                </span>

                <span className="upload-list-item-card-actions picture">
                  <button
                    title="Remove file"
                    type="button"
                    className="btn-item-card-actions-remove"
                    onClick={(e) => handleRemoveFile(index, e)}
                  >
                    <span role="img" aria-label="delete" className="anticon anticon-delete">
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="delete"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                      </svg>
                    </span>
                  </button>
                </span>
              </span>
            </div>
            <div className="upload-list-item-description">
              <div className="input-group">
                <span className="input-group-text">คำอธิบาย</span>
                <input
                  type="text"
                  name="description"
                  placeholder="คำอธิบาย"
                  className="form-control"
                  value={item.description || ''}
                  onChange={(e) => handleChangeFileDescription(index, e)}
                />
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    </>
  ))

  return (
    <div className="form-field">
      <div className="mt-3">
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>เลขที่สัญญา:</span>
          </div>
          <div className="col-md-9">{data?.contract_no}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>ประเภททุน:</span>
          </div>
          <div className="col-md-9">{data?.scholarship_type_name || '-'}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>สถานศึกษา:</span>
          </div>
          <div className="col-md-9">{data?.institution_name_th}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>คณะ:</span>
          </div>
          <div className="col-md-9">{data?.faculty || '-'}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>สาขาวิชา:</span>
          </div>
          <div className="col-md-9">{data?.subject_field || '-'}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>ระดับการศึกษา:</span>
          </div>
          <div className="col-md-9">{data?.education_level_name_th}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>ปีการศึกษา</span>
          </div>
          <div className="col-md-9">{yearHelper.getYearBE(data?.academic_year)}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>ชั้นปี</span>
          </div>
          <div className="col-md-9">{data?.class_year}</div>
        </div>
        <div className="row">
          <div className="col-md-3 fw-bold">
            <span>ภาคการศึกษา</span>
          </div>
          <div className="col-md-9">{data?.semester}</div>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <Col md={6} className="mb-3">
              <label htmlFor="academic_year" className="label-with-required">
                เลือกปีการศึกษา
              </label>
              <Controller
                name="academic_year"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={'ปีการศึกษา'}
                    isClearable
                    isDisabled={academicYearLoading}
                    isLoading={academicYearLoading}
                    isSearchable={true}
                    styles={selectStyles}
                    options={academicYearOptions}
                    placeholder="เลือกปีการศึกษา"
                    defaultValue={academicYear}
                    onChange={handleAcademicYearChange}
                  />
                )}
              />
              {errors.academic_year && (
                <div className="help-block">ภาคการศึกษาต้องไม่เป็นค่าว่าง</div>
              )}
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="semester" className="label-with-required">
                ภาคการศึกษา
              </label>
              <input type="text" className="form-control font-16" {...register('semester')} />

              {errors.semester && <div className="help-block">{errors.semester.message}</div>}
            </Col>
          
          </div>
          <div className="row mt-3">
          <Col md={6} className="mb-3">
          <label className="control-label" htmlFor="class_year">
             ชั้นปี
                </label>
          <Controller
                name="class_year"
                control={control}
                render={({ field }) => (
  
                <Select
                    {...field}
                      label={'ชั้นปี'}
                      isClearable
                      isSearchable={true}
                      styles={selectStyles}
                      options={academicResultHelper?.getClassYearOptions()}
                      placeholder="เลือกชั้นปี"
                      onChange={handleChangeAcademicresultClassYear}
                    />
                    )}
                    />

              {errors.class_year && <div className="help-block">{errors.class_year.message}</div>}
            </Col>
            <div className="col-md-6">
              <div className="form-group field-academicresult-type required">
                <label className="control-label" htmlFor="academicresult-type">
                  รูปแบบผลการศึกษา
                </label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'รูปแบบผลการศึกษา'}
                      isClearable
                      isSearchable={true}
                      styles={selectStyles}
                      options={academicResultHelper?.getTypeOptions()}
                      placeholder="เลือกรูปแบบผลการศึกษา"
                      onChange={handleChangeAcademicresultType}
                    />
                  )}
                />
                {errors.type && <div className="help-block">{errors.type.message}</div>}
              </div>
            </div>

            {academicResultHelper?.isTypeNumber(type) && (
              <>
                <div className="col-md-6">
                  <div className="form-group required">
                    <label className="control-label" htmlFor="gpa">
                      ผลการเรียนเฉลี่ย (GPA)
                    </label>
                    <input
                      type="text"
                      className="form-control font-16"
                      {...register('gpa')}
                      placeholder="ผลการเรียนเฉลี่ย (GPA)"
                      aria-required="true"
                    />
                    {errors.gpa && <div className="help-block">{errors.gpa.message}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group required">
                    <label className="control-label" htmlFor="gpax">
                      ผลการเรียนเฉลี่ยสะสม (GPAX)
                    </label>
                    <input
                      type="text"
                      id="gpax"
                      className="form-control font-16"
                      {...register('gpax')}
                      placeholder="ผลการเรียนเฉลี่ยสะสม (GPAX)"
                      aria-required="true"
                    />
                    {errors.gpax && (
                      <div className="help-block"> ผลการเรียนเฉลี่ยสะสม (GPAX) ต้องไม่ว่าง</div>
                    )}
                  </div>
                </div>
              </>
            )}

            {academicResultHelper?.isTypeText(type) && (
              <>
                <div className="col-md-6">
                  <div className="form-group required">
                    <label className="control-label" htmlFor="gpax">
                      ผลการเรียน
                    </label>
                    <input
                      type="text"
                      id="gpax"
                      className="form-control font-16"
                      {...register('gpax')}
                      placeholder="ผลการเรียน"
                      aria-required="true"
                    />
                    {errors.gpax && <div className="help-block">{errors.gpax.message}</div>}
                  </div>
                </div>
              </>
            )}

            {academicResultHelper?.isTypeResearch(type) && (
              <>
                <div className="col-md-6">
                  <div className="form-group field-academicresult-description required">
                    <label className="control-label" htmlFor="academicresult-description">
                      รายละเอียด
                    </label>
                    <textarea
                      id="academicresult-description"
                      className="form-control font-16"
                      {...register('description')}
                      rows={3}
                      placeholder="รายละเอียด"
                    />
                    {errors.description && (
                      <div className="help-block">{errors.description.message}</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <Col md={12} className="mb-3">
            <label htmlFor="file" className="label-with-required">
              แนบไฟล์
            </label>
            <div className="file-caption">
              <div className="upload-select">
                <div className="d-flex justify-content-start">
                  <div className="btn-file-upload">
                    <span role="img" aria-label="upload" className="icon-upload">
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="upload"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                      </svg>
                    </span>
                    <span>Upload</span>
                    <input
                      type="file"
                      id="icon-button-file"
                      {...register('file')}
                      onChange={(e) => handleChangeFile(e)}
                    />
                  </div>
                </div>
              </div>

              <aside style={thumbsContainer} className="upload-list">
                {thumbs}
              </aside>
            </div>
            <span className="text-secondary  font-size-xs">
              ไฟล์ต้องนามสกุล .pdf และ ไม่เกิน 10 MB เท่านั้น
            </span>
            {errors.file && <div className="help-block">{errors.file.message}</div>}
          </Col>

          <div className="form-group text-end">
            {!isLoading ? (
              <button type="submit" className="btn btn-primary">
                บันทึก
              </button>
            ) : (
              <button type="submit" className="btn btn-primary" disabled>
                กำลังบันทึก...
              </button>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default FormField
