import React, { Fragment, useState, useEffect, useCallback } from 'react'; 

import Update from '../../components/contract/Update';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {  
    faHome,  
} from "@fortawesome/free-solid-svg-icons"; 
import { Link } from "react-router-dom"; 

const ContractUpdate = (props) => {
    const [error, setError] = useState(null);
    
    let content = <Update id={props.match.params.id}/>;

    if (error) {
        content = <p>{error}</p>;
    }
    return (
        <>
        <nav aria-label="breadcrumb">
            <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
                <li className="breadcrumb-item">
                    <Link to="/">
                    <FontAwesomeIcon icon={faHome} />
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/contract"> สัญญา </Link>
                </li>
                <li
                    className="breadcrumb-item active font-sarabun"
                    aria-current="page"  >
                แก้ไขสัญญา
                </li>
        
            </ol>
        </nav> 
        {content}
        </> 
    );
}


export default ContractUpdate
 