import React, { useState, useCallback, useRef, useContext } from 'react'
import './Login.css'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import AuthContext from '../../store/auth-context'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ReactLoading from 'react-loading'
import publicIp from 'public-ip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'

const Login = (props) => {
  //const [isLogin, setIsLogin] = useState(true);
  // const [error, setError] = useState(null);
  const [error, setError] = useState(null)
  const [errorCaptcha, setErrorCaptcha] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const reCaptchaRef = useRef(null)
  const username = useRef(null)

  const [passwordType, setPasswordType] = useState('password')

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const host = process.env.REACT_APP_API_URL
  //const host = "https://ipst.debug.support";
  const authCtx = useContext(AuthContext)

  const loginUser = useCallback(async (credentials) => {
    // setError(null);
    try {
      let formData = new FormData() //formdata object

      formData.append('username', credentials.username) //append the values with key, value pair
      formData.append('password', credentials.password)
      formData.append('reCaptcha', credentials.reCaptcha)

      const publicIpAddress = await publicIp.v4()

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          device: publicIpAddress,
        },
      }

      const response = await fetch(host + '/student/user/login', {
        method: 'POST',
        body: formData,
        config,
      })

      if (!response.ok) {
        throw new Error('ชื่อผู้ใช้/อีเมลหรือรหัสผ่านไม่ถูกต้อง')
      }

      if (response.ok) {
        const data = await response.json()
        if (data.status != 200) {
          throw new Error('ชื่อผู้ใช้/อีเมลหรือรหัสผ่านไม่ถูกต้อง')
        }

        let expiresIn = data.data?.expired
        const expirationTime = new Date(expiresIn) //new Date(new Date().getTime() + (+expiresIn * 1000));

        authCtx.login(data.data, expirationTime.toISOString())
      }

      setErrorCaptcha('')
      reCaptchaRef.current.reset()
    } catch (error) {
      setError(error.message)
    }
    setIsLoading(false)
  }, [])

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('อีเมลต้องไม่ว่าง'),
    password: Yup.string().required('รหัสผ่านต้องไม่ว่าง'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data) => {
    const getToken = reCaptchaRef.current.getValue() //"6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"; //reCaptchaRef.current.getValue();

    if (getToken != '') {
      setIsLoading(true)
      // could add validation here...
      const user = {
        username: data.username,
        password: data.password,
        reCaptcha: getToken,
      }

      loginUser(user)
    } else {
      setErrorCaptcha('reCaptcha ต้องไม่ว่าง')
    }
  }
  const handleClear = () => {
    Swal.fire({
      icon: 'question',
      title: 'เข้าสู่ระบบไม่ได้ใช่ไหม',
      html: 'ระบบจะทำการ Reset ข้อมูลการท่องเว็บไซต์ใน Browser ของคุณหลังจากกดตกลง กรุณาล็อกอินเข้าสู่ระบบใหม่',
      confirmButtonColor: '#0d3996',
      confirmButtonText: 'ตกลง',
      showDenyButton: true,
      denyButtonText: 'ยกเลิก',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.clear();

        // scrollToTop();
        Swal.fire('สำเร็จ', 'กรุณากรอกข้อมูลอีเมลและรหัสผ่านสำหรับเข้าสู่ระบบอีกครั้ง', 'success')
   
      } else if (result.isDenied) {
     
      }})
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  
  return (
    <div className="col-xl-4 order-xl-2 font-prompt">
      <div className="card bg-primary border-0 mb-3">
        <div className="card-body p-4 font-kanit">
          <h4 className="text-center text-white">เข้าสู่ระบบ</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!isLoading ? (
              <></>
            ) : (
              <ReactLoading type="bubbles" color="#26b9d1" className="m-auto" />
            )}

            <div className="field">
              {!!error ? (
                <p className="alert alert-danger text-white">{error}</p>
              ) : (
                <p className=""> </p>
              )}
            </div>
            <div className="field mb-3">
              <label className="form-label text-white">อีเมล</label>
              <div className="control">
                {/* <input className="form-control rounded-7 px-4" type="text" name="username" ref={usernameRef}  placeholder="อีเมล" onChange={onChangeUsername}/> */}

                <input
                  name="username"
                  type="text"
                  {...register('username')}
                  className={`form-control rounded-7 px-4 ${errors.username ? 'is-invalid' : ''}`}
                  placeholder="อีเมล"
  
                />
                <div className="invalid-feedback">{errors.username?.message}</div>
              </div>
            </div>
            <div className="field mb-3">
              <label className="form-label text-white">รหัสผ่าน</label>
              <div className="control">
                <div className="input-group d-flex justify-content-start">
                  <input
                    type={passwordType}
                    {...register('password')}
                    // value={passwordInput}
                    name="password"
                    className={`form-control input-password rounded-7 px-4 ${
                      errors.password ? 'is-invalid' : ''
                    }`}
                    placeholder="รหัสผ่าน"
                  />
                  {passwordType === 'password' ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="togglePasswordType"
                      onClick={togglePassword}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      className="togglePasswordType"
                      onClick={togglePassword}
                    />
                  )}
                  {/* <input
                        name="password"
                        type="password"
                        {...register("password")}
                        className={`form-control rounded-7 px-4 ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="รหัสผ่าน"
                      /> */}
                </div>

                <div className="invalid-feedback">{errors.password?.message}</div>
                {/* <input className="form-control rounded-7 px-4" type="password" name="password" ref={passwordRef} placeholder="รหัสผ่าน" onChange={onChangePassword}/> */}
              </div>
            </div>
            <div className="field my-3">
              <div className="d-flex justify-content-center">
                <div className="field-loginform-recaptcha">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    // sitekey="6LdeQ3UdAAAAADu3KjYCTwPvgiSaJEq4iZVJelQe"
                    sitekey="6LdoMgImAAAAAIKqm-o8xGupTVVzTSvB-dGo7ffO"
                  />
                </div>
              </div>
              <div className="text-danger">{errorCaptcha}</div>
            </div>
            <label className="container-checkmark">
              จดจำรหัสของฉัน
              <input type="checkbox" defaultChecked={1} />
              <span className="checkmark"></span>
            </label>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-info account-btn text-white w-100"
                name="login-button"
              >
                เข้าสู่ระบบ
              </button>
            </div>
            <p className="text-white mt-3 text-center">
              ลืมรหัสผ่าน{' '}
              <a href="/forgot-password" className="text-info">
                คลิกที่นี่
              </a>
            </p>
          </form>
        </div>
      </div>
      <div className="card bg-cyan border-0 mb-3 font-prompt">
        <div className=" p-4 text-center">
          <p className="mb-2">
            สำหรับนักเรียนทุนที่ยังไม่มีบัญชีเข้าใช้งาน สามารถแจ้งอีเมลได้ที่นี่
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfzipLICcpBA8hkW1-0IxJ1X5IssarS2PKQps3bss0lyO6Evg/viewform" target="_blank" rel="noopener noreferrer">
          <Button variant="primary">
          คลิกที่นี่
          </Button>
        </a>
        </div>
      </div>
      <div className="card bg-cyan border-0 mb-3 font-prompt">
        <div className=" p-4 text-center">
          <p className="mb-2">
          หากเข้าสู่ระบบด้วย Browser ที่ใช้งานปัจจุบันไม่ได้ สามารถเคลียร์แคชได้ที่นี่  
          </p>
          <Button  onClick={handleClear}>
          คลิกที่นี่
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
