import {
  faBusinessTime,
  faExclamationCircle,
  faHome,
  faPlus,
  faSearch,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { ButtonAction } from '../../components/Button'
import { dateHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import { statusHelper } from '../../helpers/status.helper'
import useFetch from '../../utils/useFetch'

const actionButton = (cell, row, rowIndex, formatExtraData) => {
  return (
    <>
      <ButtonAction
        link={`/operational/${row?.ID}`}
        label="ดูรายละเอียด"
        variant="outline-primary"
        icon={faSearch}
        width="150px"
      />
      {
         row?.status == 'approved' && row?.pending_modification == '' ? (
          <ButtonAction
            link={`/operational/update/${row?.ID}`}
            label="กำหนดวันที่สิ้นสุด"
            variant="outline-warning ms-2"
            icon={faEdit}
            width="130px"
          />
         ):(
          <></>
         )
      }
     
      
    </>
  )
}

const OperationalList = (props) => {
  const title = 'ข้อมูลการปฏิบัติงาน'

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const sizePerPage = 10

  const { response, loading } = useFetch(`/student/operational`)
  //console.log(response);
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      แสดง {from} ถึง {to} จากทั้งหมด {size} รายการ
    </span>
  )

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: response?.operational?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
      history.push(`/operational?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ลำดับ',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '80px', minWidth: '80px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        let cal = sizePerPage * currentPage
        cal = cal - (sizePerPage - 1)
        cal = cal + rowIndex
        return cal
      },
    },
    {
      dataField: 'root_organization_id',
      text: 'หน่วยงานต้นสังกัด',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.root_organization_th
      },
    },
    {
      dataField: 'sub1_organization_th',
      text: 'หน่วยงาน',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.sub1_organization_th
      },
    },
    {
      dataField: 'sub2_organization_th',
      text: 'หน่วยงานย่อย',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.sub2_organization_th
      },
    },
    {
      dataField: 'position',
      text: 'ตำแหน่งงาน',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'position_group',
      text: 'กลุ่มตำแหน่งงาน',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'start_work',
      text: 'วันที่บรรจุ',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDate(row.start_work)
      },
    },
    {
      dataField: 'end_work',
      text: 'วันที่สิ้นสุด',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDate(row.end_work)
      },
    },
    {
      dataField: 'duration',
      text: 'ระยะเวลาปฏิบัติงาน',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.duration ?? '-'
      },
    },
    {
      dataField: 'status',
      text: 'สถานะ',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '150px', minWidth: '150px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
     
        return statusHelper.getLabel(row?.status, row?.note) 
        
      },
    },
    {
      dataField: 'action',
      text: 'รายละเอียด',
      align: 'center',
      headerStyle: () => {
        return { width: '360px', minWidth: '360px', textAlign: 'center' }
      },
      events: {
        onClick: (e) => {
          e.stopPropagation()
        },
      },
      formatter: actionButton,
    },
  ]
  
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ข้อมูลการปฏิบัติงาน
          </li>
        </ol>
      </nav>
      <div className="operational-index">
        
        {response?.pending_model?.id ? (
          <section className="bg-white py-3 mb-3">
            <div className="container">
              <div className="alert alert-warning-soft mb-0" role="alert">
                <FontAwesomeIcon icon={faExclamationCircle} className="pe-1" />
                ข้อมูลการปฏิบัติงานอยู่ในระหว่างดำเนินการอนุมัติ แก้ไขเมื่อ{' '}
                {dateHelper.getDateTime(response?.pending_model?.created_at)}
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faBusinessTime} className="" />
                      </div>
                      <span>ข้อมูลการปฏิบัติงาน </span>
                    </h5>
                  </div>
                  { response?.allowCreate ? (
                    <div className="ms-auto my-auto mt-lg-0 mt-4">
                      <div className="ms-auto my-auto">
                        <ButtonAction
                          link="/operational/create"
                          label="เพิ่มข้อมูลการปฏิบัติงาน"
                          variant="outline-primary"
                          icon={faPlus}
                          width="220px"
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div id="operational_work" className="table-responsive">
                    {loading ? (
                      <div class="text-end"><p class="text-end mb-1"><Skeleton /></p><p class="mb-1"><Skeleton /></p></div>
                    
                    ) : (
                      <>
                        {response?.operational ? (
                          <div class="text-end">
                            <p class="mb-1">(ประมาณการ) ระยะเวลาปฏิบัติงานตอบแทนทุนทั้งหมด:  { response?.total?.diff_date ?? ' 0 ปี 0 เดือน 0 วัน '} </p>                           
                            <p class="mb-1">(ประมาณการ) ระยะเวลาที่ปฏิบัติงานแล้ว: { response?.worked?.diff_date ?? ' 0 ปี 0 เดือน 0 วัน '} </p>                     
                            <p class="mb-1">(ประมาณการ) ระยะเวลาปฏิบัติงานคงเหลือ: { response?.balance?.diff_date ?? ' 0 ปี 0 เดือน 0 วัน '} </p>
                          </div>
                    
                          // <table className="table">
                          //   <tbody>
                            
                          //     <tr>
                          //       <td>ระยะเวลาปฏิบัติงานตอบแทนทุนทั้งหมด</td> 
                          //       <td className="duration_work"> {response?.operational?.total?.diff_date ?? ' 0 ปี 0 เดือน 0 วัน '}</td>
                          //     </tr>
                          //     <tr>
                          //       <td>ระยะเวลาที่ปฏิบัติงานแล้ว </td>
                          //       <td className="duration_worked">{response?.operational?.worked}</td>
                          //     </tr>
                          //     <tr>
                          //       <td>ระยะเวลาปฏิบัติงานคงเหลือ</td>
                          //       <td className="duration_work_balance">
                          //         {response?.operational?.balance?.diff_date ?? ' 0 ปี 0 เดือน 0 วัน '}
                          //       </td>
                          //     </tr>
                              
                          //   </tbody>
                          // </table>
                        ) : (
                          <div className="text-center p-4 my-2 bg-light">ไม่พบข้อมูล</div>
                        )}
                      </>
                    )}
                </div>
                <div className="table-responsive">
                  {!loading ? (
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <div>
                          <PaginationTotalStandalone {...paginationProps} />

                          <BootstrapTable
                            id="tableResize"
                            classes="resizable"
                            bootstrap4
                            hover
                            striped={false}
                            bordered={false}
                            keyField="uuid"
                            data={response?.operational}
                            columns={columns}
                            filter={filterFactory()}
                            filterPosition="top"
                            wrapperClasses="table-responsive"
                            noDataIndication={'ไม่พบข้อมูล'}
                            {...paginationTableProps}
                          />
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      )}
                    </PaginationProvider>
                  ) : (
                    <table id="petition" className="table">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th style={{ width: 220, minWidth: 220 }}>หน่วยงานต้นสังกัด</th>
                          <th style={{ width: 220, minWidth: 220 }}>หน่วยงาน</th>
                          <th style={{ width: 220, minWidth: 220 }}>หน่วยงานย่อย</th>
                          <th style={{ width: 220, minWidth: 220 }}>ตำแหน่งงาน</th>
                          <th style={{ width: 220, minWidth: 220 }}>กลุ่มตำแหน่งงาน</th>
                          <th style={{ width: 220, minWidth: 220 }}>วันที่บรรจุ</th>
                          <th style={{ width: 220, minWidth: 220 }}>วันที่สิ้นสุด</th>
                          <th style={{ width: 180, minWidth: 180 }}>สถานะ</th>
                          <th className="text-center" style={{ width: 220, minWidth: 220 }}>
                            รายละเอียด
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default OperationalList
