import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../store/auth-context'

const apiKey = process.env.REACT_APP_API_KEY
const apiVersion = process.env.REACT_APP_API_VERSION
const baseUrl = process.env.REACT_APP_API_URL

const useFetch = (endpoint, params = null) => {
  const authCtx = useContext(AuthContext)
  const isLogged = authCtx.isLoggedIn

  if (!isLogged) {
    authCtx.logout(null)
  }

  const authUser = JSON.parse(authCtx.user)

  const accessToken = authUser?.token

  let headers = {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
    'x-api-version': apiVersion,
    'access-token': accessToken,
    // 'token': authUser.token
  }

  let options = {
    url: baseUrl + endpoint,
    // method: 'GET',
    headers: headers,
    params: params,
  }

  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    await axios(options)
      .then((response) => {
        const json = response.data
        if (json.status == '401') {
          authCtx.logout(null)
          //  history.push('/');
        }

        setResponse(json.data)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  return { response, error, loading }
}

export default useFetch
