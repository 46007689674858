import { faCampground, faCircle, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { dateHelper } from '../../../helpers/date.helper'
import useFetch from '../../../utils/useFetch'
import { Card, Table } from 'react-bootstrap'
import { ButtonAction } from '../../../components/Button'

const StudentActivityContainer = (props) => {
  const { response: activityData, loading } = useFetch(`/student/personal/activity`)

  return (
    <Card className="border-0 mb-3">
      <Card.Body>
        <h5 className="card-title d-flex align-items-center">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faCampground} className="" />
          </div>
          <span>การเข้าค่าย/กิจกรรม</span>
          {props.student && (
            <Link
              to={'/student-activity/create'}
              className="btn btn-outline-primary btn-add ms-auto"
            >
              <FontAwesomeIcon icon={faPlus} className="me-1" /> เพิ่มรายการ
            </Link>
          )}
        </h5>
        <Table responsive>
          <thead>
            <tr>
              <th>อัปเดตล่าสุด</th>
              <th>รายการ</th>
              <th>ประเทศ</th>
              <th>สถานะ</th>
              <th className="action-column">รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            ) : activityData?.length > 0 ? (
              activityData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {' '}
                      {item?.updated_at
                        ? dateHelper.getDateTime(item.updated_at)
                        : dateHelper.getDateTime(item.created_at)}{' '}
                    </td>

                    <td>{item.name}</td>
                    <td>{item.country_name}</td>
                    <td className={item.classStatus + ' align-middle'}>
                      <FontAwesomeIcon icon={faCircle} className="pe-1" />
                      {item.status}
                      <p className="text-secondary fs-14">{item.note} </p>
                    </td>
                    <td>
                      <ButtonAction
                        link={'/student-activity/' + item.ID}
                        label="ดูรายละเอียด"
                        variant="outline-primary"
                        icon={faSearch}
                        width="160px"
                      />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr className="odd">
                <td valign="top" colSpan={5} className="text-center">
                  ไม่พบข้อมูล
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default StudentActivityContainer
