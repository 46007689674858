import React, { Fragment, useState } from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

import './Slide.css';

import Banner from '../../assets/images/banner/IPST_Banner.png'; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight,faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Slide = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })
  // const bannerService = useService(base_url +'/api/banner')

  return (
    <Fragment>

      <div className="body-content mb-5">

        <div id="banner"  className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">

            <div className="keen-slider__slide">
              <img src={Banner} className={"w-100"} alt="Scholarship Self-service"/>
            </div>
            <div className="keen-slider__slide"><img src={Banner} className={"w-100"} alt="Scholarship Self-service"/></div>
            <div className="keen-slider__slide"><img src={Banner} className={"w-100"} alt="Scholarship Self-service"/></div>
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              )
            })}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Slide

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <FontAwesomeIcon icon={faChevronLeft} className="font-32"/>
      )}
      {!props.left && (
        <FontAwesomeIcon icon={faChevronRight} className="font-32"/>
      )}
    </svg>
  )
}