import React from 'react';
import './News.css';
import NewsItem from './NewsItem';

const NewsList = (props) => {
  
    return ( 
    <React.Fragment>
      
      { props.news.map((item) => (
        <NewsItem
          key={item.id}
          id={item.id}
          title={item.title}
          publish_date={item.publish_date}
          description={item.description}
          views={item.views}
          thumbnail= {item.thumbnail}
        />
      )) } 

    </React.Fragment>

    )
}

export default NewsList


                
           