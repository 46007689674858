// import PetitionCreate from '../../pages/petition/Create'
// import PetitionView from '../../pages/petition/View'
// import History from '../../pages/petition/History'
// import PetitionList from '../../pages/petition/List'
// import PetitionTemplate from '../../pages/petition/Template'

import * as Pages from '../../pages/petition-student-status'

export const PetitionStudentStatus = [
  {
    path: '/petition-student-status/create',
    name: 'ยื่นคำร้องใหม่',
    component: Pages.Create,
    layout: 'petition-student-status',
    breadcrumb: { parent: 'คำร้องสถานภาพนักเรียนทุน', child: 'ยื่นคำร้องใหม่' },
  },
  {
    path: '/petition-student-status/history/detail/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.HistoryDetail,
    layout: 'petition-student-status',
    breadcrumb: {
      parent: 'คำร้องสถานภาพนักเรียนทุน',
      child: 'ประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-student-status/history/:id',
    name: 'ประวัติการแก้ไข',
    component: Pages.History,
    layout: 'petition-student-status',
    breadcrumb: {
      parent: 'คำร้องสถานภาพนักเรียนทุน',
      child: 'ประวัติการแก้ไข',
    },
  },
  {
    path: '/petition-student-status/print/:id',
    name: 'คำร้องสถานภาพนักเรียนทุน',
    component: Pages.Template,
    layout: 'petition-student-status',
    breadcrumb: { parent: 'คำร้องสถานภาพนักเรียนทุน', child: 'รายละเอียดคำร้อง' },
  },
  {
    path: '/petition-student-status/:id',
    name: 'คำร้องสถานภาพนักเรียนทุน',
    component: Pages.View,
    layout: 'petition-student-status',
    breadcrumb: { parent: 'คำร้องสถานภาพนักเรียนทุน', child: 'รายละเอียดคำร้อง' },
  },
  {
    path: '/petition-student-status',
    name: 'คำร้องสถานภาพนักเรียนทุน',
    component: Pages.List,
    layout: 'petition-student-status',
    breadcrumb: { parent: '', child: 'คำร้องสถานภาพนักเรียนทุน' },
  },
]
