import StudentAwardCreate from '../../pages/student-award/Create';
import StudentAwardView from '../../pages/student-award/View'

export const StudentAward = [
  //รางวัล

    {
        path: '/student-award/create',
        name: 'เพิ่มรางวัล', 
        component: StudentAwardCreate,
        layout: 'student-award',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'เพิ่มรางวัล' },
    },
    {
        path: '/student-award/:id',
        name: 'รางวัล', 
        component: StudentAwardView,
        layout: 'student-award',
        breadcrumb: {'parent': 'ข้อมูลของฉัน','child': 'รายละเอียดรางวัล' },
    },
]