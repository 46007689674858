export default {
  /**
   *
   *
   *
   * ===== เช็คค่า =====
   *
   *
   *
   */
  isNull(value) {
    return typeof value === null || value === null || value == 'null' || value == '' ? true : false
  },

  isUndefined(value) {
    return typeof value === undefined || value === undefined || value == 'undefined' ? true : false
  },

  isNan(value) {
    return typeof value === NaN || value === NaN || value == 'NaN' ? true : false
  },

  isNu(value) {
    return this.isNull(value) || this.isUndefined(value) ? true : false
  },

  isNun(value) {
    return this.isNull(value) || this.isUndefined(value) || this.isNan(value) ? true : false
  },

  isStr(value) {
    return typeof value === 'string' ? true : false
  },

  isInt(value) {
    return Number.isInteger(value) ? true : false
  },

  isEmpty(value) {
    return value == '' ? true : false
  },

  isEmptyArray(value) {
    return Array.isArray(value) && value.length === 0 ? true : false
  },

  isSet(value) {
    return this.isEmpty(value) || this.isNun(value) ? false : true
  },

  isObject(value) {
    return typeof value === 'object' ? true : false
  },

  isFunction(value) {
    return typeof value === 'function' ? true : false
  },

  isArray(value) {
    return Array.isArray(value) ? true : false
  },

  isElement(elem) {
    return elem instanceof Element ? true : false
  },

  /**
   *
   *
   *
   * ===== จัดการ element =====
   *
   *
   *
   */

  e(selector) {
    return document.querySelector(selector)
  },

  getCssValue(elem, property) {
    if (this.isElement(elem)) {
      return window.getComputedStyle(elem, null).getPropertyValue(property)
    } else {
      elem = this.e(elem)
      return elem ? window.getComputedStyle(elem, null).getPropertyValue(property) : null
    }
  },

  css(selector, value) {
    if (typeof selector === 'object') {
      selector.setAttribute('style', value)
    } else {
      document.querySelector(selector).setAttribute('style', value)
    }
  },

  addClass(selector, className) {
    if (typeof selector === 'object') {
      if (this.isArray(className)) {
        document.querySelector(selector).classList.add(...className)
      } else {
        document.querySelector(selector).classList.add(className)
      }
    } else {
      if (this.isArray(className)) {
        selector.classList.add(...className)
      } else {
        selector.classList.add(className)
      }
    }
  },

  removeClass(selector, className) {
    if (typeof selector === 'object') {
      if (this.isArray(className)) {
        document.querySelector(selector).classList.remove(...className)
      } else {
        document.querySelector(selector).classList.remove(className)
      }
    } else {
      if (this.isArray(className)) {
        selector.classList.remove(...className)
      } else {
        selector.classList.remove(className)
      }
    }
  },

  toggleClass(selector, className) {
    if (typeof selector === 'object') {
      if (this.isArray(className)) {
        document.querySelector(selector).classList.toggle(...className)
      } else {
        document.querySelector(selector).classList.toggle(className)
      }
    } else {
      if (this.isArray(className)) {
        selector.classList.toggle(...className)
      } else {
        selector.classList.toggle(className)
      }
    }
  },

  /**
   *
   *
   *
   * ===== แปลงค่า =====
   *
   *
   *
   */
  toStr(value) {
    return value.toString()
  },

  toInt(value) {
    return parseInt(value)
  },

  toFloat(value) {
    return parseFloat(value)
  },

  toJsonObject(jsonString) {
    return JSON.parse(jsonString)
  },

  toJsonString(jsonObject) {
    return JSON.stringify(jsonObject)
  },

  allObjectToNull(object) {
    for (const [key, item] of Object.entries(object)) {
      if (this.isObject(object[key]) && !this.isNull(object[key])) {
        // _.log(object[key])
        this.allObjectToNull(object[key])
      } else {
        // _.log([key, item])
        object[key] = null
      }
    }

    return object
  },

  formatFileSize(
    size,
    units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    delimiter = ' '
  ) {
    const threshold = 1024
    size = size * threshold
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
    return (size / Math.pow(threshold, i)).toFixed(2) * 1 + delimiter + units[i]
  },

  /**
   *
   *
   *
   * ===== จัดการไฟล์ =====
   *
   *
   *
   */
  getFile(event) {
    return event.target.files[0] ? event.target.files[0] : null
  },

  getFiles(event) {
    return event?.target?.files ?? null
  },

  getFileName(event) {
    return event.target.files[0].name ? event.target.files[0].name : null
  },

  getBase64Image(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  },

  /**
   *
   *
   *
   * ===== เปลี่ยนสี % =====
   *
   *
   *
   */
  toLighter(color, percent) {
    return this.changeShade(percent / 100, color)
  },

  toDarker(color, percent) {
    return this.changeShade(-(percent / 100), color)
  },
}
