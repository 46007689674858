import logo from '../assets/images/logo/static_IPST_Logo.png'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  const baseUrl = process.env.REACT_APP_API_URL_PROD

  return (
    <>
      <div className="site-news">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 my-4">
              <div className="card-header">
                <div className="pt-4 bg-white border-0">
                  <div className="account-logo">
                    <img src={logo} width={100} alt="สสวท." />
                  </div>
                  <div className="account-wrapper mb-3">
                    <h4 className="mt-3 text-center">
                      นโยบายการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-body mb-4">
                <div className="d-flex flex-lg-row justify-content-center">
                  <div id="w0" className="w-100">
                    <Col>
                      <p className="text-center">
                        ตามประกาศเรื่อง&nbsp;
                        <a download href={`${baseUrl}privacy/pdpa2565`}>
                          นโยบายคุ้มครองข้อมูลส่วนบุคคลของ สสวท. พ.ศ. 2565
                        </a>
                      </p>
                      <p>
                        สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี หรือ สสวท. (IPST)
                        ได้จัดทํานโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น
                        เพื่อคุ้มครองข้อมูลส่วนบุคคล ของผู้ใช้บริการทุกท่าน (Personal Information)
                        โดยมีข้อกำหนดและเงื่อนไข ดังต่อไปนี้
                      </p>
                      <p>
                        <strong>ข้อกำหนดและเงื่อนไข</strong>&nbsp;<strong></strong>
                      </p>
                      <p>
                        <strong>&nbsp;1. ประเภทข้อมูลส่วนบุคคล</strong>
                      </p>
                      <ul>
                        <li>
                          ข้อมูลทั่วไป&nbsp; หมายความว่า
                          ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                          แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
                        </li>
                        <li>
                          ข้อมูลของผู้ใช้บริการ หมายความว่า
                          <ul>
                            <li>
                              ข้อมูลบัญชีผู้ใช้งาน (User Account)
                              ซึ่งเป็นข้อมูลสำหรับใช้ในการล็อกอินเข้าใช้ระบบให้บริการสารสนเทศของ
                              สสวท. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </li>
                            <li>
                              ข้อมูลที่ผู้ใช้บริการให้ไว้เมื่อลงทะเบียนขอใช้บริการระบบให้บริการสารสนเทศของ
                              สสวท. ซึ่งจะเป็นประโยชน์ต่อการติดต่อประสานงาน
                              ตลอดจนการให้บริการด้านข้อมูลในระบบสารสนเทศของ สสวท. แก่ผู้ใช้บริการ
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p>
                        <strong>
                          &nbsp;2. วัตถุประสงค์การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
                        </strong>
                      </p>
                      <ul>
                        <li>
                          เพื่อใช้ในการล็อกอินเข้าใช้ระบบให้บริการสารสนเทศของ สสวท.
                          (ผู้ใช้บริการต้องไม่ให้บัญชีผู้ใช้งานของตนเองแก่ผู้อื่นเพื่อใช้ในการล็อกอินเข้าระบบในนามของตนเอง)
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </li>
                        <li>เพื่อใช้ในการติดต่อประสานงานกับผู้ใช้บริการ</li>
                        <li>เพื่อเอื้ออำนวยให้ผู้ใช้บริการ</li>
                        <li>เพื่อใช้ในการปรับปรุงการให้บริการระบบสารสนเทศของ สสวท.</li>
                        <li>
                          เพื่อให้เป็นไปตาม พรบ. ว่าด้วยการเก็บข้อมูลจราจรคอมพิวเตอร์
                          ซึ่งกำหนดให้มีการบันทึกข้อมูลล็อกที่เกี่ยวข้องกับการใช้งานของผู้ใช้บริการ
                        </li>
                      </ul>
                      <p>
                        <strong>&nbsp;3. แหล่งที่มาของข้อมูลส่วนบุคคล</strong>
                      </p>
                      <ul>
                        <li>
                          ข้อมูลจากเจ้าของข้อมูลโดยตรง
                          <ul>
                            <li>
                              ข้อมูลของผู้ใช้บริการ
                              โดยผู้ใช้บริการได้ให้ข้อมูลไว้ในช่วงที่ลงทะเบียนเพื่อขอใช้บริการระบบให้บริการสารสนเทศของ
                              สสวท.&nbsp;
                            </li>
                          </ul>
                        </li>
                        <li>
                          ข้อมูลจากแหล่งอื่น
                          <ul>
                            <li>
                              สสวท. ไม่ได้มีการรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการจากแหล่งอื่น ๆ
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p>
                        <strong>&nbsp;4. ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
                      </p>
                      <p>
                        สสวท.
                        จะเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการตราบเท่าที่วัตถุประสงค์ของการนำข้อมูลดังกล่าวไปใช้งานยังคงมีอยู่
                        เว้นแต่กรณีจำเป็นต้องเก็บรักษาข้อมูลต่อไปตามที่กฎหมายกำหนด
                        หรือเป็นการใช้สิทธิ์การเพิกถอนความยินยอมในการจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการซึ่งจะกระทำเมื่อใดก็ได้
                      </p>
                      <p>
                        <strong>5. การใช้คุ้กกี้ (Cookies)</strong>
                      </p>
                      <p>
                        “คุกกี้” คือ ข้อมูลที่ สสวท. ส่งไปยังโปรแกรมค้นผ่านเว็บไซต์ (Web browser)
                        ของผู้ใช้บริการ และเมื่อมีการติดตั้งข้อมูลดังกล่าวไว้ในระบบของท่านแล้ว
                        หากมีการใช้ “คุกกี้” ก็จะทำให้เว็บไซต์ สสวท.
                        สามารถบันทึกหรือจดจำข้อมูลของผู้ใช้บริการไว้&nbsp;
                        จนกว่าผู้ใช้บริการจะออกจากโปรแกรมค้นผ่านเว็บไซต์
                        หรือจนกว่าผู้ใช้บริการจะทำการลบ “คุกกี้” นั้นเสีย หรือไม่อนุญาตให้ “คุกกี้”
                        นั้นทำงานอีกต่อไป
                      </p>
                      <p>
                        หากท่านเลือกใช้ “คุกกี้” แล้ว
                        ท่านจะได้รับความสะดวกสบายในการท่องเว็บไซต์มากขึ้น เพราะ “คุกกี้”
                        จะช่วยจดจำเว็บไซต์ ที่ท่านแวะหรือเยี่ยมชม ทั้งนี้ สสวท. จะนำข้อมูล ที่
                        “คุกกี้” ได้บันทึกหรือเก็บรวบรวมไว้ ไปใช้ในการวิเคราะห์เชิงสถิติ
                        หรือในกิจกรรมอื่นของ สสวท. เพื่อปรับปรุงคุณภาพการให้บริการของ สสวท. ต่อไป
                      </p>
                      <p>
                        <strong>6. การเปิดเผยข้อมูลส่วนบุคคล</strong>
                      </p>
                      <p>
                        ข้อมูลส่วนบุคคลจะถูกเก็บรักษาไว้เป็นความลับตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
                        การใช้งานและการเปิดเผยจะเป็นไปตามวัตถุประสงค์ที่กำหนดไว้ในข้างต้น รวมทั้ง
                        สสวท. อาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลนี้ให้แก่หน่วยงานต้นสังกัดของ สสวท.
                        เนื่องจากเป็นหน่วยงานที่มีอำนาจในการกำกับดูแลการปฏิบัติงานตามภารกิจและวัตถุประสงค์ของ
                        สสวท. นอกจากนี้ เพื่อประโยชน์ของผู้ใช้บริการ สสวท.
                        อาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลให้แก่หน่วยงานอื่นที่เกี่ยวข้องกับผู้ใช้บริการ
                        อาทิเช่น หน่วยงานต้นสังกัด หน่วยงานที่ดำเนินการเกี่ยวกับการประเมินวิทยฐานะ
                        เป็นต้น &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </p>
                      <p>
                        <strong>7. การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</strong>
                      </p>
                      <p>
                        สสวท.
                        ไม่มีวัตถุประสงค์ที่เกี่ยวข้องกับการส่งหรือโอนย้ายข้อมูลส่วนบุคคลไปยังต่างประเทศ
                      </p>
                      <p>
                        <strong>&nbsp;8. สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong>
                      </p>
                      <p>ผู้ใช้บริการมีสิทธิดังนี้</p>
                      <ul>
                        <li>
                          เข้าถึงข้อมูล ดูข้อมูล เปลี่ยนแปลงหรือแก้ไขข้อมูล
                          และสำเนาข้อมูลส่วนบุคคลของตนเองในระบบให้บริการได้ด้วยตัวเองโดยใช้การล็อกอินเข้าระบบและดำเนินการได้ตามที่ต้องการ
                          ผู้ใช้บริการไม่มีสิทธิ์ให้ข้อมูลการล็อกอินของตนเองแก่ผู้อื่น
                        </li>
                        <li>
                          ขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนเองไม่ได้ให้ความยินยอมไว้
                        </li>
                        <li>
                          แจ้งให้ผู้ควบคุมข้อมูลส่วนบุคคลต้องดำเนินการทำให้ข้อมูลส่วนบุคคลนั้นถูกต้องเป็นปัจจุบัน
                          สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                        </li>
                        <li>
                          ในการทำสำเนาหรือขอข้อมูลส่วนบุคคลของตนเองจากระบบให้บริการ
                          ผู้ใช้สามารถดำเนินการได้ด้วยตนเอง จึงไม่มีความจำเป็นต้องร้องขอเพื่อให้
                          สสวท. ดำเนินการให้ แต่หากต้องการให้ สสวท. ดำเนินการให้
                          ให้แจ้งความประสงค์มาที่อีเมล ทุน พสวท. scholarship@ipst.ac.th, ทุน สควค.
                          psmt@ipst.ac.th และ ทุน โอลิมปิก olympiac@ipst.ac.th
                          จะดำเนินการสำเนาข้อมูลออกจากระบบและจัดส่งให้ทางอีเมลของผู้ใช้บริการภายในระยะเวลา
                          10 วันทำการ
                        </li>
                        <li>
                          คัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตน
                          ที่กฎหมายอนุญาตให้เก็บได้โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูล
                        </li>
                        <li>
                          ขอให้ผู้ควบคุมข้อมูลส่วนบุคคลดำเนินการลบหรือทำลาย
                          หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้
                          ในกรณีตามที่กฎหมายกำหนด
                        </li>
                        <li>
                          ขอให้ผู้ควบคุมข้อมูลส่วนบุคคลระงับการใช้ข้อมูลส่วนบุคคลได้
                          ในกรณีตามที่กฎหมายกำหนด
                        </li>
                        <li>
                          เพิกถอนความยินยอมในการจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการเมื่อใดก็ได้ตามที่ต้องการ
                        </li>
                        <li>
                          ร้องเรียนในกรณีที่ผู้ควบคุมข้อมูลส่วนบุคคลหรือผู้ประมวลผลข้อมูลส่วนบุคคล
                          รวมทั้งลูกจ้างหรือผู้รับจ้างของผู้ควบคุมข้อมูลส่วนบุคคลหรือผู้ประมวลผลข้อมูลส่วนบุคคล
                          ฝ่าฝืนหรือ ไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                        </li>
                      </ul>
                      <p>
                        <strong>
                          9. <strong>การปรับปรุงนโยบายการคุ้มครองข้อมูลส่วนบุคคล</strong>
                        </strong>
                      </p>
                      <p>
                        สสวท. อาจทำการปรับปรุงหรือแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                        โดยมิได้แจ้งให้ท่านทราบล่วงหน้า ทั้งนี้
                        เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ ดังนั้น สสวท.&nbsp;
                        จึงขอแนะนําให้ผู้ใช้บริการอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                        ทุกครั้งที่เยี่ยมชม หรือมีการใช้บริการจากเว็บไซต์ของ สสวท.
                      </p>
                      <p>
                        <strong>
                          10. <strong>การขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล สสวท</strong>.
                        </strong>
                      </p>
                      <p>
                        เจ้าของข้อมูลสามารถยื่น
                        <a download href={`${baseUrl}privacy/request_personal_doc`}>
                          แบบคำร้องขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล
                        </a>
                        สำหรับการขอใช้สิทธิดำเนินการต่อข้อมูลส่วนบุคคลของตนซึ่งอยู่ในความดูแลของสถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี
                        (สสวท.) ในฐานะผู้ควบคุมข้อมูลส่วนบุคคล ทั้งนี้
                        สามารถศึกษารายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของ สสวท.
                        ได้ที่ประกาศ เรื่อง
                        <a download href={`${baseUrl}privacy/pdpa2565`}>
                          นโยบายคุ้มครองข้อมูลส่วนบุคคลของ สสวท. พ.ศ. 2565
                        </a>
                      </p>
                      <p className="text-left">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เอกสารแบบคำร้องขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลในรูปแบบ
                        &nbsp;&nbsp;&nbsp;
                        <a download href={`${baseUrl}privacy/request_personal_pdf`}>
                          [ PDF ]
                        </a>
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <a download href={`${baseUrl}privacy/request_personal_doc`}>
                          [ DOC ]
                        </a>
                      </p>
                      <p>
                        <strong>11. ช่องทางติดต่อ</strong>
                      </p>
                      <p>
                        ผู้ใช้บริการสามารถติดต่อ ได้ตามช่องทาง ดังนี้
                        <br />
                        ระบบสารสนเทศเพื่อบริหารจัดการทุนการศึกษา
                        <br />
                        สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)
                        <br />
                        ที่อยู่สำนักงานชั่วคราว (ตั้งแต่วันที่ 1 กุมภาพันธ์ 2566 เป็นต้นไป)
                        <br />
                        475 อาคารสิริภิญโญ ชั้น 9 ถนนศรีอยุธยา แขวงถนนพญาไท เขตราชเทวี กรุงเทพฯ
                        10400
                        <br />
                        ติดต่อ
                        <br />
                        ทุน พสวท. scholarship@ipst.ac.th
                        <br />
                        ทุน สควค. psmt@ipst.ac.th
                        <br />
                        ทุน โอลิมปิก olympiac@ipst.ac.th
                      </p>
                    </Col>
                    <Col className="text-center mt-4">
                      <Link
                        to={'/'}
                        className="mb-0"
                        style={{ fontSize: '16px', color: '#1f1f1f' }}
                      >
                        ⟵ กลับไปที่หน้าเข้าสู่ระบบ
                      </Link>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PrivacyPolicy
