import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

const FooterHome = () => {
  return (
    <footer className="footer mt-auto text-muted ">
      <div className="footer-content "></div>

      <div className="footer-contrainer pb-4">
        <div className="container position-relative">
          <div className="button-to-top" role="button" aria-label="To Top">
            <FontAwesomeIcon icon={faAngleUp} className="" />
          </div>
        </div>
        <div className="container pt-5">
          <div className="row mb-3">
            <div className="col-6 text-end">
              <Link to="/terms-and-condition" className="text-info">
                ข้อกำหนดและเงื่อนไขการใช้งานเว็บไซต์
              </Link>
            </div>
            <div className="col-6 text-start">
              <Link to="/privacy-policy" className="text-info">
                นโยบายการคุ้มครองข้อมูลส่วนบุคคล
              </Link>
            </div>
          </div>
          <p className="text-center mb-0 text-white font-weight-500">
            COPYRIGHT © 2021 The Institute for the Promotion of Teaching Science and Technology ALL
            RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default FooterHome
