import Skeleton from 'react-loading-skeleton'
import { Card, Table,Col } from 'react-bootstrap'

export const CardLoading = () => {
  return (
    <>
      <Card className="border-0 mb-3">
        <Card.Body>
          <Card.Title className="mt-2">
            <div className="bg-icon"> </div>
            <Skeleton width="250px" />
          </Card.Title>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <Skeleton />
                  </th>
                  <th scope="col">
                    <Skeleton />
                  </th>
                  <th scope="col">
                    <Skeleton />
                  </th>
                  <th scope="col">
                    <Skeleton />
                  </th>
                  <th scope="col">
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                  <td className="align-middle">
                    <Skeleton />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export const TableLoading = ({ row, col }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            {Array(col)
              .fill(0)
              .map((_, index) => {
                return (
                  <th key={index}>
                    <Skeleton height={30} />
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {Array(row)
            .fill(0)
            .map((_, index) => {
              return (
                <>
                  <tr>
                    {Array(col)
                      .fill(0)
                      .map((_, index) => {
                        return (
                          <>
                            <td key={index}>
                              <Skeleton />
                            </td>
                          </>
                        )
                      })}
                  </tr>
                </>
              )
            })}
        </tbody>
      </Table>
    </>
  )
}

export const FormLoading = () => {
  return (
    <>
      <Card className="border-0 mb-3">
        <Card.Body>
          <Card.Title className="mt-2">
            <div className="bg-icon"> </div>
            <Skeleton width="250px" />
          </Card.Title>
          <div className="row m-3">
            <Col md={9}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
