import {
  faCircle,
  faClipboardList,
  faDownload,
  faEdit,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import PDFViewer from '../../components/Pdf'
import { dateHelper } from '../../helpers/date.helper'
import useFetch from '../../utils/useFetch'
import { List } from './Styled'

const BankAccount = (props) => {
  const { response: bankAccountData, loading } = useFetch(`/student/bank-account`)

  const baseUrl = process.env.REACT_APP_API_URL
  const [show, setShow] = useState(false)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState('')

  const baseUrldownload = process.env.REACT_APP_API_URL_PROD

  const auth = JSON.parse(localStorage.getItem('user'))
  // ?token=${auth?.token}

  const saveFile = () => {
    saveAs(baseUrldownload + '/file/download/bank-account/download-document', 'FN-115.pdf')
  }

  const showFile = (fileName, fileCode) => {
    setFileName(fileName)
    setFilePreview(`${baseUrl}/student/bank-account/preview/${fileCode}?token=${auth?.token}`)
    setShow(true)
  }

  const renderFileDocument = (documents) => {
    return documents.map((item, index) => {
      return (
        <>
          <List key={`${item.file_name}_${index}`}>
            <div>
              <i className="fas fa-paperclip"></i> {item.file_name}
              <p className="mb-0" style={{ color: '#6c757d', fontSize: '14px' }}>
                {item.description}
              </p>
            </div>
            <button
              className="btn btn-info btn-sm me-2 text-white"
              onClick={() => showFile(item?.file_name, item?.file_code)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </List>
        </>
      )
    })
  }

  return (
    <div className="site-bank-account">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className="card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span>ตั้งค่าบัญชีธนาคาร </span>
                  </h5>
                </div>

                <div className="ms-auto my-auto mt-lg-0 mt-4">
                  <div className="ms-auto my-auto">
                    {/* <Button
                      className="btn btn-outline-primary btn-download me-2"
                      onClick={(e) => saveFile()}
                    >
                      <FontAwesomeIcon icon={faDownload} className="" />
                      ดาวน์โหลดเอกสาร
                    </Button> */}
                    <Link
                      to="/bank-account/download"
                      className="btn btn-primary btn-download me-2"
                      title="download"
                      aria-label="download"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faDownload} className="" />
                      ดาวน์โหลดเอกสาร
                    </Link>
                    <Link
                      to={
                        bankAccountData?.length > 0
                          ? '/bank-account/update/' + bankAccountData[0].id
                          : '/bank-account/create'
                      }
                      className="btn btn-outline-primary px-2"
                      title="Create"
                      aria-label="Create"
                    >
                      <FontAwesomeIcon icon={faEdit} className="" />
                      {bankAccountData?.length > 0 ? (
                        <> แก้ไขข้อมูลธนาคาร</>
                      ) : (
                        <> เพิ่มข้อมูลธนาคาร</>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="p0" data-pjax-container data-pjax-push-state data-pjax-timeout={1000}>
                  <div id="w0" className="grid-view">
                    <div className="summary">
                      {bankAccountData?.length > 0 ? (
                        <>
                          แสดง <b>1 ถึง {bankAccountData?.length}</b> จาก{' '}
                          <b>{bankAccountData?.length}</b> ผลลัพธ์
                        </>
                      ) : (
                        <> </>
                      )}
                    </div>
                    {loading ? (
                      <table id="petition" className="table">
                        <thead>
                          <tr>
                            <th>ชื่อบัญชี</th>
                            <th>เลขที่บัญชี</th>
                            <th>ชื่อธนาคาร</th>
                            {/* <th>ประเภทบัญชี</th> */}
                            <th>ไฟล์</th>
                            <th style={{ width: 180, minWidth: 180 }}>สถานะ</th>
                            <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                              วันที่สร้าง
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            {/* <td>
                              <Skeleton />
                            </td> */}
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table id="petition" className="table">
                        <thead>
                          <tr>
                            <th style={{ width: 180, minWidth: 180 }}>ชื่อบัญชี</th>
                            <th style={{ width: 180, minWidth: 180 }}>เลขที่บัญชี</th>
                            <th style={{ width: 180, minWidth: 180 }}>ชื่อธนาคาร</th>
                            {/* <th>ประเภทบัญชี</th> */}
                            <th>ไฟล์</th>
                            <th style={{ width: 180, minWidth: 180 }}>สถานะ</th>
                            <th className="text-center" style={{ width: 320, minWidth: 320 }}>
                              วันที่สร้าง
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bankAccountData?.length > 0 ? (
                            bankAccountData?.map((item) => {
                              return (
                                <tr>
                                  <td className="align-middle"> {item.account_name} </td>
                                  <td className="align-middle"> {item.account_number} </td>
                                  <td className="align-middle"> {item.bank_name_th} </td>
                                  {/* <td className="align-middle">
                                    {" "}
                                    {item.account_type_name}{" "}
                                  </td> */}
                                  <td className="align-middle">
                                    {item?.document?.length > 0
                                      ? renderFileDocument(item?.document)
                                      : '-'}
                                  </td>

                                  <td className={item.classStatus + ' align-middle'}>
                                    <FontAwesomeIcon icon={faCircle} className={item.classStatus} />
                                    <span> {item.status} </span>
                                  </td>
                                  <td className="align-middle text-center px-1">
                                    {dateHelper.getDateTime(item.created_at)}
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              {' '}
                              <td valign="top" colSpan={7} className="text-center">
                                {' '}
                                ไม่พบข้อมูล กรุณาเพิ่มข้อมูลบัญชีธนาคาร{' '}
                              </td>{' '}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!loading ? (
              <>
                <Modal
                  show={show}
                  size="lg"
                  dialogClassName="modal-preview"
                  centered
                  onHide={() => setShow(false)}
                  //  fullscreen={true}
                  aria-labelledby="file-name"
                >
                  <Modal.Header closeButton={true} closeLabel={'ปิด'}>
                    <Modal.Title id="file-name">{fileName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <PDFViewer pdf={filePreview} />
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default BankAccount
