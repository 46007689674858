import React from 'react'
import './News.css'
import { Link } from 'react-router-dom'
import { dateHelper } from '../../helpers/date.helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const NewsItem = (props) => {
  const date = new Date(props.publish_date)

  return (
    <div className="col-xl-3 col-md-6 col-xs-12">
      <Link to={'/news/' + props.id} className="item-news text-decoration-none text-dark">
        <img className="w-100" src={props.thumbnail} alt={props.title} />
        <h5 className="title mt-3 font-prompt">{props.title}</h5>
        <p className="desc">{props.description}</p>
        <p>
          <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
          {dateHelper.getDateTime(date)}
        </p>
      </Link>
    </div>
  )
}

export default NewsItem
