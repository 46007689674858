import React, { useState, forwardRef } from 'react'
import Select from 'react-select'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { Row, Col, Form } from 'react-bootstrap'
import { selectStyle } from '../styled'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import addDays from 'date-fns/addDays'
import addYears from 'date-fns/addYears'
import { DateButton, IconCalendar, DatePickerWrapperStyles } from '../../Filter/styles'
import {
  useContractTypeOptions,
  usePrefixOptions,
  useCurrencyOptions,
  useFaculty,
} from '../../../hooks/options/useOptions'
import _ from '../../../utils/utils'

import http from '../../../utils/http'
import { Controller } from 'react-hook-form'
const Contract = (props) => {
  const {
    // prefixOptions,
    register,
    setValue,
    errors,
    optionStudent,
    optionScholarship,
    optionScholarshipType,
    optionInstitution,
    // optionFaculty,
    optionSubjectField,
    optionAcademicYear,
    optionContractNo,
    getValues,
    clearErrors,
    onRequried,
    getGuarantor,
    contractData,
    educationlevelOptions,
    control,
  } = props
  // registerLocale('th', th)
  const { options: contractTypeOptions, loading: contractTypeLoading } = useContractTypeOptions()
  const { options: prefixOptions, loading: prefixLoading } = usePrefixOptions()
  const { options: facultyOptions, loading: facultyLoading } = useFaculty()

  const newEducationlevelOptions =
    educationlevelOptions !== null
      ? educationlevelOptions.map(function (option) {
          return {
            value: option.id,
            label: option.name_th,
          }
        })
      : []
  const newOptionSturent =
    optionStudent !== null
      ? optionStudent.map(function (option) {
          return {
            value: option.id,
            label: `${option?.student_code}-${option?.firstname_th} ${option?.lastname_th}`,
          }
        })
      : []
  const newOptionScholarship =
    optionScholarship !== null
      ? optionScholarship.map(function (option) {
          return {
            value: option.id,
            label: option.name,
          }
        })
      : []
  const newOptionScholarshipType =
    optionScholarshipType !== null
      ? optionScholarshipType.map(function (option) {
          return {
            value: option.id,
            label: option.name,
          }
        })
      : []

  const newOptionInstitution =
    optionInstitution !== null
      ? optionInstitution.map(function (option) {
          return {
            value: option.id,
            label: option?.name_th + ' ' + (option?.name_en ? `(${option?.name_en})` : ''),
          }
        })
      : []

  const newOptionSubjectField =
    optionSubjectField !== null
      ? optionSubjectField.map(function (option) {
          return {
            value: option.id,
            label: option.name,
          }
        })
      : []
  const newOptionAcademicYear =
    optionAcademicYear !== null
      ? optionAcademicYear.map(function (option) {
          return {
            value: String(option.year),
            label: String(option.year),
          }
        })
      : []

  const newOptionContractNo =
    optionContractNo !== null
      ? optionContractNo.map(function (option) {
          return {
            value: option.id,
            label: option.contract_no,
          }
        })
      : []

  const InputDate = forwardRef(({ value, onClick, disabled }, ref) => (
    <DateButton disabled={disabled} onClick={onClick} ref={ref}>
      {value ? (
        <>{value}</>
      ) : (
        <>
          <span style={{ color: '#808080' }}>เลือกวันที่</span>
        </>
      )}
    </DateButton>
  ))

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const [datepickerItem, setDatepickerItem] = useState({
    contract_date: getValues('contract_date') ? new Date(getValues('contract_date')) : null,
    before_high_school_contract_date: getValues('before_high_school_contract_date')
      ? new Date(getValues('before_high_school_contract_date'))
      : null,
    before_university_contract_date: getValues('before_university_contract_date')
      ? new Date(getValues('before_university_contract_date'))
      : null,
    start_date: getValues('start_date') ? new Date(getValues('start_date')) : null,
    end_date: getValues('end_date') ? new Date(getValues('end_date')) : null,
  })

  const [initData, setInitData] = useState({
    isContractAmendment:
      getValues('contract_type') && getValues('contract_type') === '2' ? true : false,
    isStatusPendingUpdate: false,
    isShowWitness: contractData?.show_witness || false,
    newOptionContractNo:
      contractData && contractData?.student_contract_ref ? contractData.student_contract_ref : [],
    newOptionContractType:
      contractData && contractData?.contract_type_option ? contractData.contract_type_option : [],
    expand_type_date:
      getValues('expand_type') &&
      (getValues('expand_type') === '1' || getValues('expand_type') === '2')
        ? true
        : false,
    dataContractType: [],
    studyDuration: 0,
    contract_no_type: false,
  })
  const isContractAmendment = (is, e) => {
    setValue('contract_type', e.currentTarget.value)
    if (e.currentTarget.value === '1' || e.currentTarget.value === 1) {
      setValue('contract_ref_id', '')
      setValue('commitment', '')
    } else if (e.currentTarget.value === '2' || e.currentTarget.value === 2) {
      const option_value = contractTypeOptions.filter(function (option) {
        return option.value === '4'
      })[0]
      setValue('contract_type_id', option_value)
    }
    setInitData({ ...initData, isContractAmendment: is })
  }

  const isStatusPendingUpdate = (value) => {
    if (value === 'pending_update') {
      setInitData({ ...initData, isStatusPendingUpdate: true })
    } else {
      setInitData({ ...initData, isStatusPendingUpdate: false })
    }
  }

  const isShowWitness = (e) => {
    if (e.target.checked === true) {
      setInitData({ ...initData, isShowWitness: true })
    } else {
      setInitData({ ...initData, isShowWitness: false })
    }
  }

  const getContractTypeOption = async (scholarship_id) => {
    const res = await http.get('/student/masterdata/contract-type?scholarship_id=' + scholarship_id)
    setValue('contract_type_id', '')
    const json = res
    if (json.data) {
      let data = json.data
      let new_options = []
      new_options[0] = {
        value: 0,
        label: 'เลือกประเภทสัญญา',
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        new_options[new_options.length] = {
          value: element.id,
          label: element.name,
        }
      }
      if (new_options.length > 0) {
        setInitData({
          ...initData,
          newOptionContractType: new_options,
        })
      } else {
        setInitData({
          ...initData,
          newOptionContractType: [],
        })
      }
    }
  }
  const setEndDateDefault = () => {
    let data = initData.dataContractType
    let contract_type = getValues('contract_type_id')
    let start_date = getValues('start_date')
    if (data.length > 0 && contract_type !== undefined && contract_type !== '') {
      for (let index = 0; index < data.length; index++) {
        if (data[index].id === contract_type) {
          if (!isNaN(data[index].study_duration)) {
            if (start_date !== undefined && start_date !== '') {
              let duration_year = parseInt(data[index].study_duration)

              let end_date = addYears(new Date(start_date), duration_year)
              setValue('end_date', format(end_date, 'yyyy-MM-dd'))
              setDatepickerItem({ ...datepickerItem, end_date: end_date })
            }
          }
        }
      }
    }
  }

  const filterOptions = async (option, string) => {
    if (string === '') {
      return true
    } else {
      const { label, value } = option

      const otherKey = newOptionSturent.filter(
        (opt) => opt.label === label && opt.label.includes(string)
      )
      return value.includes(string) || otherKey.length > 0
    }
  }
  if (!getValues('contract_type')) {
    setValue('contract_type', '1')
  }

  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <label htmlFor="scholarship_id " className="label-with-required">
            ชื่อทุนที่ได้รับ
          </label>
          <Select
            {...register('scholarship_id', { required: onRequried })}
            // isClearable
            onChange={(event) => {
              clearErrors('scholarship_id')
              setValue('scholarship_id', event.value)
              getContractTypeOption(event.value)
            }}
            defaultValue={
              newOptionScholarship.filter(function (option) {
                return option.value === String(getValues('scholarship_id'))
              })[0]
            }
            options={newOptionScholarship?.map((option) => {
              return option
            })}
            placeholder="เลือกชื่อทุนที่ได้รับ"
            styles={selectStyle}
            isDisabled
          />
          {errors?.scholarship_id?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <label className="label-with-required">แบบสัญญา</label>
          <div className="mb-3 form-check-group">
            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="contract_type_1"
                value="1"
                {...register('contract_type', { required: false })}
                type="radio"
                checked={
                  getValues('contract_type') === 1 ||
                  getValues('contract_type') === '1' ||
                  getValues('contract_type') === null ||
                  getValues('contract_type') === 'null' ||
                  getValues('contract_type') === ''
                }
                onChange={(e) => isContractAmendment(false, e)}
                disabled={true}
              />
              <Form.Check.Label htmlFor="contract_type_1">สัญญาหลัก</Form.Check.Label>
            </Form.Check>

            <Form.Check className="cursor-pointer">
              <Form.Check.Input
                id="contract_type_2"
                value="2"
                {...register('contract_type', { required: false })}
                type="radio"
                onChange={(e) => isContractAmendment(true, e)}
                disabled={true}
              />
              <Form.Check.Label htmlFor="contract_type_2">สัญญาแก้ไขเพิ่มเติม</Form.Check.Label>
            </Form.Check>
          </div>
          {errors?.contract_type?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        {initData.isContractAmendment === true && initData.contract_no_type === false && (
          <Col md={6} className="mb-3 input-disabled">
            <label htmlFor="contract_ref_id " className="label-with-required">
              เลขสัญญาก่อนหน้า
            </label>
            <Select
              {...register('contract_ref_id', { required: onRequried })}
              onChange={(event) => {
                clearErrors('contract_ref_id')
                setValue('contract_ref_id', event.value)
              }}
              defaultValue={
                newOptionContractNo.filter(function (option) {
                  return option.value === String(getValues('contract_ref_id'))
                })[0]
              }
              options={newOptionContractNo?.map((option) => {
                return option
              })}
              placeholder="เลือกเลขสัญญาก่อนหน้า"
              styles={selectStyle}
              isDisabled
            />
            {errors?.contract_ref_id?.type === 'required' && (
              <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
            )}
          </Col>
        )}
        <Col></Col>
      </Row>
      <Row>
        <Col md="12">
          <Form.Check
            type="switch"
            id="commitment"
            {...register('commitment')}
            label="นับเวลาชดใช้ทุน"
            value="1"
            defaultChecked={getValues('commitment') === '1'}
            disabled={true}
          />
        </Col>
      </Row>
      {initData.isContractAmendment === false && (
        <Row>
          <Col md={6} className="mb-3">
            <label htmlFor="agency_id " className="label-with-required">
              ศูนย์ผู้ดูแล
            </label>
            <Select
              {...register('agency_id', {
                required: onRequried,
              })}
              isClearable
              onChange={(value) => handleSelectChange(value, 'agency_id')}
              defaultValue={
                !_.isNu(contractData?.agency_id)
                  ? {
                      label: contractData?.agency_name,
                      value: contractData?.agency_id,
                    }
                  : null
              }
              placeholder="เลือกศูนย์ผู้ดูแล"
              styles={selectStyle}
              isDisabled={true}
            />
            {errors?.agency_id?.type === 'required' && (
              <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="project_type_id " className="label-with-required">
              ประเภทการเข้าโครงการ
            </label>
            <Select
              {...register('project_type_id', {
                required: onRequried,
              })}
              isClearable
              onChange={(value) => handleSelectChange(value, 'project_type_id')}
              defaultValue={
                !_.isNu(contractData?.project_type_id)
                  ? {
                      label: contractData?.project_type_name,
                      value: contractData?.project_type_id,
                    }
                  : null
              }
              placeholder="เลือกประเภทการเข้าโครงการ"
              styles={selectStyle}
              isDisabled={true}
            />
            {errors?.project_type_id?.type === 'required' && (
              <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="subject_area " className="label-with-required">
              สาขาวิชาที่ได้รับทุน
            </label>
            <Select
              {...register('subject_area', {
                required: false,
              })}
              isClearable
              onChange={(value) => handleSelectChange(value, 'subject_area')}
              defaultValue={
                !_.isNu(contractData?.subject_area)
                  ? {
                      label: contractData?.subject_area,
                      value: contractData?.subject_area,
                    }
                  : null
              }
              placeholder="เลือกสาขาวิชาที่ได้รับทุน"
              styles={selectStyle}
              isDisabled={true}
            />
            {errors?.subject_area?.type === 'required' && (
              <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
            )}
          </Col>
          <Col md={6} className="mb-3">
            <label htmlFor="student_status_id " className="label-with-required">
              สถานภาพนักเรียนทุน
            </label>
            <Select
              {...register('student_status_id', {
                required: onRequried,
              })}
              isClearable
              onChange={(value) => handleSelectChange(value, 'student_status_id')}
              defaultValue={
                !_.isNu(contractData?.student_status_id)
                  ? {
                      label: contractData?.student_status_name,
                      value: contractData?.student_status_id,
                    }
                  : null
              }
              placeholder="เลือกสถานภาพนักเรียนทุน"
              styles={selectStyle}
              isDisabled={true}
            />
            {errors?.student_status_id?.type === 'required' && (
              <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6} className="mb-3">
          <label htmlFor="scholarship_type " className="label-with-required">
            ประเภทการให้ทุน
          </label>
          <Select
            {...register('scholarship_type', {
              required: onRequried,
            })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'scholarship_type')}
            defaultValue={
              newOptionScholarshipType.filter(function (option) {
                return option.value === String(getValues('scholarship_type'))
              })[0]
            }
            options={newOptionScholarshipType?.map((option) => {
              return option
            })}
            placeholder="เลือกประเภทการให้ทุน"
            styles={selectStyle}
            isDisabled
          />
          {errors?.scholarship_type?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="contract_type_id " className="label-with-required">
            ประเภทสัญญา
          </label>
          <Controller
            name="contract_type_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                onChange={(value) => handleSelectChange(value, 'contract_type_id')}
                options={contractTypeOptions}
                placeholder="เลือกประเภทสัญญา"
                styles={selectStyle}
                isDisabled={true}
              />
            )}
          />
          {errors?.contract_type_id?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="education_level_id " className="label-with-required">
            ระดับการศึกษา
          </label>
          <Select
            {...register('education_level_id', { required: onRequried })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'education_level_id')}
            defaultValue={
              newEducationlevelOptions.filter(function (option) {
                return option.value === String(getValues('education_level_id'))
              })[0]
            }
            options={newEducationlevelOptions?.map((option) => {
              return option
            })}
            placeholder="เลือกระดับการศึกษา"
            styles={selectStyle}
            isDisabled
          />
          {errors?.education_level_id?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="academic_year " className="label-with-required">
            ปีการศึกษา
          </label>
          <input
            type="text"
            placeholder="ปีการศึกษา"
            {...register('academic_year', { required: onRequried })}
            className="form-control"
            onChange={(event) => {
              clearErrors('academic_year')
            }}
            disabled={true}
          />
          {errors.academic_year && <div className="help-block">ต้องไม่เป็นค่าว่าง</div>}
        </Col>
        <Col md={6} className="mb-3 input-disabled">
          <label htmlFor="contract_date">วันที่ทำสัญญา</label>
          <div className="position-relative">
            <DatePicker
              {...register('contract_date', {
                required: false,
              })}
              dateFormat="d MMM yyyy"
              onChange={(event) => {
                clearErrors('contract_date')
                setValue('contract_date', format(event, 'yyyy-MM-dd'))
                setDatepickerItem({
                  ...datepickerItem,
                  contract_date: event,
                })
              }}
              selected={datepickerItem.contract_date}
              customInput={<InputDate />}
              className="form-control input-disabled"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={addDays(new Date(), 0)}
              preventOpenOnFocus={true}
              placeholder="วันที่ทำสัญญา"
              disabled={true}
            />
            <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
          </div>
          <DatePickerWrapperStyles />

          {/* <input
            type="date"
            placeholder="วันที่สัญญา"
            {...register('contract_date')}
            className="form-control"
          /> */}
          {errors.contract_date && <div className="help-block">ต้องไม่เป็นค่าว่าง</div>}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="institution_id" className="label-with-required">
            สถาบันการศึกษา
          </label>
          <Select
            {...register('institution_id', { required: onRequried })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'institution_id')}
            defaultValue={
              newOptionInstitution.filter(function (option) {
                return option.value === String(getValues('institution_id'))
              })[0]
            }
            options={newOptionInstitution?.map((option) => {
              return option
            })}
            placeholder="เลือกสถาบันการศึกษา"
            styles={selectStyle}
            isDisabled
          />
          {errors.institution_id && <div className="help-block">ต้องไม่เป็นค่าว่าง</div>}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="faculty">คณะ</label>
          <Select
            {...register('faculty', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'faculty')}
            defaultValue={
              !_.isNu(contractData?.faculty) && !_.isEmpty(contractData?.faculty)
                ? {
                    label: contractData?.faculty,
                    value: contractData?.faculty,
                  }
                : null
            }
            options={facultyOptions}
            styles={selectStyle}
            placeholder="เลือกคณะ"
            isDisabled
          />
          {errors?.faculty?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="subject_field">สาขา</label>
          <Select
            {...register('subject_field', { required: false })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'subject_field')}
            defaultValue={
              newOptionSubjectField.filter(function (option) {
                return option.value === String(getValues('subject_field'))
              })[0]
            }
            options={newOptionSubjectField?.map((option) => {
              return option
            })}
            placeholder="เลือกสาขา"
            styles={selectStyle}
            isDisabled
          />
          {errors.subject_field && <div className="help-block">ต้องไม่เป็นค่าว่าง</div>}
        </Col>
        <Col md={6} className="mb-3 input-disabled">
          <label htmlFor="start_date ">วันที่เริ่มรับทุน</label>
          <>
            <div className="position-relative">
              <DatePicker
                {...register('start_date', {
                  required: false,
                })}
                dateFormat="d MMM yyyy"
                onChange={(event) => {
                  clearErrors('start_date')
                  setValue('start_date', format(event, 'yyyy-MM-dd'))
                  setDatepickerItem({ ...datepickerItem, start_date: event })
                }}
                selected={datepickerItem.start_date}
                customInput={<InputDate />}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                // maxDate={addDays(new Date(), 0)}
                preventOpenOnFocus={true}
                placeholder="วันที่เริ่มรับทุน"
                disabled={true}
              />
              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
            </div>
            <DatePickerWrapperStyles />
          </>
        </Col>
        {/* <Col md={6} className="mb-3">
          <label htmlFor="end_date " className="">
            วันที่ครบสัญญา{' '}
            <button
              type="button"
              className="btn btn-warning btn-cal-duration"
              onClick={(e) => setEndDateDefault()}
            >
              คำนวณจากประเภทสัญญา
            </button>
          </label>
          <>
            <div className="position-relative">
              <DatePicker
                {...register('end_date', {
                  required: false,
                })}
                dateFormat="d MMM yyyy"
                onChange={(event) => {
                  clearErrors('end_date')
                  setValue('end_date', format(event, 'yyyy-MM-dd'))
                  setDatepickerItem({ ...datepickerItem, end_date: event })
                }}
                selected={datepickerItem.end_date}
                customInput={<InputDate />}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                // maxDate={addDays(new Date(), 0)}
                preventOpenOnFocus={true}
                placeholder="วันที่ครบสัญญา"
              />
              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
            </div>
            <DatePickerWrapperStyles />
          </>
        </Col> */}
      </Row>
      <h5>สัญญารับทุนก่อนหน้า (ระดับมัธยมศึกษาตอนปลาย)</h5>
      <Row>
        <Col md={6}>
          <label htmlFor="before_high_school_academic_year ">ปีการศึกษา</label>
          <Select
            {...register('before_high_school_academic_year', {
              required: false,
            })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'before_high_school_academic_year')}
            defaultValue={
              newOptionAcademicYear.filter(function (option) {
                return option.value === String(getValues('before_high_school_academic_year'))
              })[0]
            }
            options={newOptionAcademicYear?.map((option) => {
              return option
            })}
            placeholder="เลือกปีการศึกษา"
            styles={selectStyle}
            isDisabled={true}
          />
          {errors?.before_high_school_academic_year?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="before_high_school_contract_no ">เลขที่สัญญา</label>
          <input
            type="text"
            placeholder="เลขที่สัญญา"
            {...register('before_high_school_contract_no')}
            className="form-control"
            disabled={true}
          />
          {errors?.before_high_school_contract_no?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="before_high_school_contract_date ">วันที่ทำสัญญา</label>
          <>
            <div className="position-relative">
              <DatePicker
                {...register('before_high_school_contract_date')}
                dateFormat="d MMM yyyy"
                onChange={(event) => {
                  setValue('before_high_school_contract_date', format(event, 'yyyy-MM-dd'))
                  setDatepickerItem({
                    ...datepickerItem,
                    before_high_school_contract_date: event,
                  })
                }}
                selected={datepickerItem.before_high_school_contract_date}
                customInput={<InputDate />}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={addDays(new Date(), 0)}
                preventOpenOnFocus={true}
                placeholder="วันที่สัญญา"
                disabled={true}
              />
              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
            </div>
            <DatePickerWrapperStyles />
          </>

          {errors?.before_high_school_contract_date?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>
      <h5>สัญญารับทุนก่อนหน้า (ระดับอุดมศึกษา)</h5>
      <Row>
        <Col md={6}>
          <label htmlFor="before_university_academic_year ">ปีการศึกษา</label>
          <Select
            {...register('before_university_academic_year', {
              required: false,
            })}
            isClearable
            onChange={(value) => handleSelectChange(value, 'before_university_academic_year')}
            defaultValue={
              newOptionAcademicYear.filter(function (option) {
                return option.value === String(getValues('before_university_academic_year'))
              })[0]
            }
            options={newOptionAcademicYear?.map((option) => {
              return option
            })}
            placeholder="เลือกปีการศึกษา"
            styles={selectStyle}
            isDisabled={true}
          />
          {errors?.before_university_academic_year?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="before_university_contract_no ">เลขที่สัญญา</label>
          <input
            type="text"
            placeholder="เลขที่สัญญา"
            {...register('before_university_contract_no')}
            className="form-control"
            disabled={true}
          />
          {errors?.before_university_contract_no?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <label htmlFor="before_university_contract_date ">วันที่ทำสัญญา</label>
          <>
            <div className="position-relative">
              <DatePicker
                {...register('before_university_contract_date')}
                dateFormat="d MMM yyyy"
                onChange={(event) => {
                  setValue('before_university_contract_date', format(event, 'yyyy-MM-dd'))
                  setDatepickerItem({
                    ...datepickerItem,
                    before_university_contract_date: event,
                  })
                }}
                // isClearable
                selected={datepickerItem.before_university_contract_date}
                customInput={<InputDate />}
                className="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={addDays(new Date(), 0)}
                preventOpenOnFocus={true}
                placeholder="วันที่ทำสัญญา"
                disabled={true}
              />
              <IconCalendar className="fas fa-calendar-alt position-absolute"></IconCalendar>
            </div>
            <DatePickerWrapperStyles />
          </>
          {errors?.before_university_contract_date?.type === 'required' && (
            <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
          )}
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Form.Check
            type="switch"
            id="show_witness"
            {...register('show_witness')}
            label="แสดงชื่อพยานที่สัญญา"
            onChange={(event) => {
              isShowWitness(event)
            }}
            defaultChecked={getValues('show_witness')}
          />
        </Col>
      </Row>

      {initData.isShowWitness && (
        <>
          <Row>
            <Col md="12">
              <label htmlFor="witness_firstname_first">ชื่อพยาน (คนที่ 1)</label>
            </Col>
            <Col md="2" className="mb-3">
              <Select
                {...register('witness_name_title_first_id', { required: false })}
                onChange={(value) => handleSelectChange(value, 'witness_name_title_first_id')}
                isClearable
                defaultValue={
                  contractData?.witness_name_title_first_id !== null
                    ? {
                        label: contractData?.witness_name_title_first_th,
                        value: contractData?.witness_name_title_first_id,
                      }
                    : null
                }
                options={prefixOptions}
                isLoading={prefixLoading}
                placeholder="เลือกคำนำหน้า"
                styles={selectStyle}
              />
              {errors?.witness_name_title_first_id?.type === 'required' && (
                <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
              )}
            </Col>
            <Col md="5">
              <input
                type="text"
                {...register('witness_firstname_first')}
                className="form-control"
              />
            </Col>
            <Col md="5">
              <input type="text" {...register('witness_lastname_first')} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <label htmlFor="witness_firstname_second">ชื่อพยาน (คนที่ 2)</label>
            </Col>
            <Col md={2} className="mb-3">
              <Select
                {...register('witness_name_title_second_id', { required: false })}
                onChange={(value) => handleSelectChange(value, 'witness_name_title_second_id')}
                isClearable
                defaultValue={
                  contractData?.witness_name_title_second_id !== null
                    ? {
                        label: contractData?.witness_name_title_second_th,
                        value: contractData?.witness_name_title_second_id,
                      }
                    : null
                }
                options={prefixOptions}
                isLoading={prefixLoading}
                placeholder="เลือกคำนำหน้า"
                styles={selectStyle}
              />
              {errors?.witness_name_title_second_id?.type === 'required' && (
                <div className="help-block">ต้องไม่เป็นค่าว่าง</div>
              )}
            </Col>
            <Col md={5}>
              <input
                type="text"
                {...register('witness_firstname_second')}
                className="form-control"
              />
            </Col>
            <Col md={5}>
              <input
                type="text"
                {...register('witness_lastname_second')}
                className="form-control"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default Contract
