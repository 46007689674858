import { faArrowUp, faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { ButtonAction } from '../../components/Button'
import { dateHelper } from '../../helpers/date.helper'
import { history } from '../../helpers/history'
import { PaginationHelper } from '../../helpers/pagination.helper'
import { statusHelper } from '../../helpers/status.helper'
import useFetch from '../../utils/useFetch'
import { Card } from 'react-bootstrap'

const List = (props) => {
  const [currentPage, setCurrentPage] = useState(1)

  const sizePerPage = 10

  const { response: responseData, loading } = useFetch(`/student/move-classes`)

  const paginationOptions = {
    custom: true,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    totalSize: responseData?.length,
    firstPageTitle: 'first',
    lastPageTitle: 'last',
    prePageTitle: 'pre',
    nextPageTitle: 'next',
    firstPageText: '«',
    lastPageText: '»',
    prePageText: '‹',
    nextPageText: '›',
    disablePageTitle: false,
    showTotal: true,
    paginationTotalRenderer: PaginationHelper.getTotalRenderer,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
      history.push(`/move-classes?page=${page}`)
    },
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ลำดับ',
      align: 'center',
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '80px', minWidth: '80px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        let cal = sizePerPage * currentPage
        cal = cal - (sizePerPage - 1)
        cal = cal + rowIndex
        return cal
      },
    },
    {
      dataField: 'education_level_name_th',
      text: 'ระดับการศึกษา',
      align: 'left',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'academic_year',
      text: 'ปีการศึกษา',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'semester_name',
      text: 'ภาคการศึกษา',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'class_year',
      text: 'ชั้นปี',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px' }
      },
    },
    {
      dataField: 'start_date',
      text: 'วันที่เปิดภาคเรียน',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDate(row?.start_date)
      },
    },
    {
      dataField: 'end_date',
      text: 'วันที่เปิดภาคเรียน',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '220px', minWidth: '220px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDate(row?.end_date)
      },
    },
    {
      dataField: 'status',
      text: 'สถานะ',
      align: 'center',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: () => {
        return { width: '150px', minWidth: '150px' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return statusHelper.getLabel(row?.status, row?.note)
      },
    },
    {
      dataField: 'approved_date',
      text: 'วันที่อนุมัติ',
      sort: true,
      headerStyle: () => {
        return { width: '300px', minWidth: '300px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row?.approved_date)
      },
    },
    {
      dataField: 'created_at',
      text: 'วันที่ทำรายการ',
      sort: true,
      headerStyle: () => {
        return { width: '300px', minWidth: '300px', textAlign: 'center' }
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return dateHelper.getDateTime(row?.created_at)
      },
    },
  ]

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            การเลื่อนชั้น
          </li>
        </ol>
      </nav>
      <div className="operational-index">
        <section className="bg-light">
          <div className="container">
            <Card className="border-0 mb-3">
              <div className="card-header pt-4 bg-white border-0">
                <div className="d-md-flex">
                  <div>
                    <h5 className="card-title me-auto">
                      <div className="bg-icon">
                        <FontAwesomeIcon icon={faArrowUp} />
                        {/* <FontAwesomeIcon icon={faBusinessTime} className="" /> */}
                      </div>
                      <span>การเลื่อนชั้น</span>
                    </h5>
                  </div>
                  <div className="ms-auto my-auto mt-lg-0 mt-4">
                    <div className="ms-auto my-auto">
                      <ButtonAction
                        link="/move-classes/create"
                        label="สร้างคำขอเลื่อนชั้น"
                        variant="outline-primary"
                        icon={faPlus}
                        width="190px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Card.Body>
                <div className="table-responsive">
                  {!loading ? (
                    <>
                      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => (
                          <div>
                            <PaginationTotalStandalone {...paginationProps} />
                            <BootstrapTable
                              id="tableResize"
                              classes="resizable"
                              bootstrap4
                              hover
                              striped={false}
                              bordered={false}
                              keyField="id"
                              data={responseData}
                              columns={columns}
                              filter={filterFactory()}
                              filterPosition="top"
                              wrapperClasses="table-responsive"
                              noDataIndication={'ไม่พบข้อมูล'}
                              {...paginationTableProps}
                            />
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        )}
                      </PaginationProvider>
                    </>
                  ) : (
                    <table id="petition" className="table">
                      <thead>
                        <tr>
                          <th style={{ width: 180, minWidth: 220 }}>ลำดับ</th>
                          <th style={{ width: 180, minWidth: 220 }}>ระดับการศึกษา</th>
                          <th style={{ width: 180, minWidth: 220 }}>ปีการศึกษา</th>
                          <th style={{ width: 180, minWidth: 220 }}>ภาคการศึกษา</th>
                          <th style={{ width: 180, minWidth: 220 }}>ชั้นปี</th>
                          <th style={{ width: 180, minWidth: 220 }}>สถานะ</th>
                          <th style={{ width: 180, minWidth: 180 }}>วันที่อนุมัติ </th>
                          <th className="text-center" style={{ width: 180, minWidth: 180 }}>
                            วันที่ทำรายการ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>

                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </section>
      </div>
    </>
  )
}

export default List
