import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

const NewsContainer = (props) => {
  return (
    <div className="site-news">
      <section className="bg-light">
        <div className="container">
          <div className="card border-0 mb-3">
            <div className="card-header ">
              <div className="d-md-flex pt-4 bg-white border-0">
                <div>
                  <h5 className="title card-title d-flex align-items-center me-auto">
                    <div className="bg-icon">
                      <FontAwesomeIcon icon={faClipboardList} className="" />
                    </div>
                    <span className="font-prompt"> {props.title} </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body mb-4">
              <div className="d-flex flex-lg-row justify-content-center">
                <div id="w0" className="row w-100">
                  {props.newsBlock}
                  {/* <ul className="pagination">
                    <li className="prev disabled">
                      <span>«</span>
                    </li>

                    <li className="active">
                      <a href="/news/index?page=1" data-page={0}>
                        1
                      </a>
                    </li>

                    <li>
                      <a href="/news/index?page=2" data-page={1}>
                        2
                      </a>
                    </li>

                    <li className="next">
                      <a href="/news/index?page=2" data-page={1}>
                        »
                      </a>
                    </li>
                    
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsContainer;
