import React, { useContext, useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import HeaderLogged from '../components/layouts/auth/header/Header'
import Footer from '../components/layouts/footer/Footer'
import FooterHome from '../components/layouts/footer/FooterHome'
import Header from '../components/layouts/header/Header'
import routes from '../config/routes'
import ResetPassword from '../pages/reset-password/ResetPassword'
import AuthContext from '../store/auth-context'

const Main = () => {
  const authCtx = useContext(AuthContext)

  const isLogged = authCtx.isLoggedIn
  const firstLoggedIn = localStorage.getItem('firstLoggedIn')

  const [windowsSize, setWindowSize] = useState(0)
  const [counter, setCounter] = useState(0)
  let location = useLocation()

  const getTitleName = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i]
      }
    }
    return []
  }

  let title = getTitleName()

  const mainPanel = React.useRef(null)
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route exact path={prop.path} render={(props) => <prop.component {...props} />} key={key} />
      )
    })
  }

  let history = useHistory()

  useEffect(() => {
    document.title = title.name
  }, [title])

  let footer = <FooterHome />
  let breadcrumb = ''
  let bgBody = 'body'
  if (location.pathname !== '/') {
    footer = <Footer />
    bgBody = 'bg-light'
  }

  return (
    <>
      {firstLoggedIn === true || firstLoggedIn == 'true' ? (
        <ResetPassword />
      ) : (
        <div className={'d-flex flex-column h-100 ' + bgBody}>
          {isLogged ? <HeaderLogged authCtx={authCtx} /> : <Header />}

          <main role="main" className="flex-shrink-0">
            {/* <Breadcrumb title={title} /> */}

            <Switch>{getRoutes(routes)}</Switch>
          </main>

          {footer}

          <CookieConsent
            location="bottom"
            buttonText="ยอมรับ"
            cookieName="myAwesomeCookieName2"
            style={{
              backgroundColor: '#08338cbf',
              padding: ' 10px 5%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            buttonStyle={{
              color: '#FFF',
              width: '120px',
              backgroundColor: '#26B9D1',
              border: '1px solid #26B9D1',
              borderRadius: '1.25rem',
              padding: '10px 15px',
            }}
            expires={150}
          >
            <div className="">
              เว็บไซต์นี้มีการใช้คุกกี้ (Cookies) เพื่อเพิ่มประสบการณ์การใช้งาน
              และมีการเก็บข้อมูลตาม ตามนโยบาย พรบ.การคุ้มครองข้อมูลส่วนบุคคล
            </div>
          </CookieConsent>
        </div>
      )}
      {/* <div className="main-wrapper">
        <Helmet>
          
        </Helmet>
        <Navbar routes={routes} title={title} />

        <div className="page-wrapper" ref={mainPanel}>
          <Switch>{getRoutes(routes)}</Switch>
        </div>
      </div> 
      <div className="sidebar-overlay"></div> */}
    </>
  )
}

export default Main
