import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCog,
  faCamera,
  faUserLock,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons"; 
import { Card, Table, Container, Form, Row, Col, Spinner } from 'react-bootstrap'

import { useForm } from 'react-hook-form'

import useFetch from '../../utils/useFetch'; 
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import http from '../../utils/http'
import axios from 'axios'
import { history } from '../../helpers/history' 
import TimezoneSelect from 'react-timezone-select' 

const Resetpassword = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));  
  const urlAction ="/profile/edit"; 
  const urlActionCallAPI ="/student/setting/resetpassword";

  const [errorReset, setErrorReset] = useState(null);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const validationSchema = yup.object().shape({
  
      currentPassword: yup.string()
        .required('รหัสผ่านปัจจุบัน ต้องไม่เป็นค่าว่าง'),
      password: yup.string()
          .required('รหัสผ่านใหม่ ต้องไม่เป็นค่าว่าง')
          .min(6, 'รหัสผ่านใหม่ must be at least 6 characters'),
      confirmPassword: yup.string()
          .required('ยืนยันรหัสผ่านใหม่ต้องไม่เป็นค่าว่าง')
          .oneOf([yup.ref('password')], 'รหัสผ่านไม่ตรงกัน')
          
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {

    setIsLoadingReset(true);
    // display form data on success
    //console.log('onSubmit ',data);
    if(data){  
      let dataObj = { 
        current_password: data.currentPassword, 
        password: data.password, 
        confirm_password: data.confirmPassword 
      }; 
  
      let dataRes = await http.post(`${urlActionCallAPI}`, dataObj);
      
      if(dataRes.status == 200){
        setIsLoadingReset(false);
        setErrorReset(<div class="alert alert-success text-white" role="alert">  { dataRes.data } </div>);
        history.push(`${urlAction}`)
      }else if(dataRes.status == 400){
        setIsLoadingReset(false);
        setErrorReset(<div class="alert alert-danger text-white" role="alert"> { dataRes.data } </div>);
      }
      

    }
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    //return false;
  }


  const [currentPasswordHidden, setCurrentPasswordHidden] = useState('password')
  const [passwordHidden, setPasswordHidden] = useState('password')
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState('password')

  const togglePassword = (type) => { 
    if(type == 'password'){
      if (passwordHidden === 'password') {
        setPasswordHidden('text')
      }else{
        setPasswordHidden('password')
      }
    }else if(type === 'currentPassword'){ 
      if (currentPasswordHidden === 'password') {
        setCurrentPasswordHidden('text')
      }else{
        setCurrentPasswordHidden('password')
      }
      
    }else if(type == 'confirmPassword'){
      if (confirmPasswordHidden === 'password') {
        setConfirmPasswordHidden('text')
      }else{
        setConfirmPasswordHidden('password')
      }
      
    }
   
  }

  return (
 
        <div className="card border-0 mb-3">
          <div className="card-header pt-4 bg-white border-0">
            <div className="d-md-flex">
              <div>
                <h5 className="card-title d-flex align-items-center me-auto">
                  <div className="bg-icon">
                    <FontAwesomeIcon icon={faUserLock} className="" /> 
                  </div>
                  <span>เปลี่ยนรหัสผ่าน</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="card-body reset-password">
            <div className="profile-password-form w-auto"> 
                <div className="form-group ps-4"> 
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    { errorReset } 
                    <Row>
                      <Col lg={6} className="mb-3"> 
                        <div className="form-group d-flex flex-wrap">
                            <label>รหัสผ่านปัจจุบัน</label>
                            <input 
                            name="currentPassword" 
                            type={currentPasswordHidden}
                            {...register('currentPassword')} 
                            placeholder="รหัสผ่านปัจจุบัน" 
                            className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`} />

                            {currentPasswordHidden === 'password' ? ( 
                              <FontAwesomeIcon icon={faEyeSlash} className="togglePasswordType" onClick={(e)=>togglePassword('currentPassword')} />
                            ) : ( 
                              <FontAwesomeIcon icon={faEye} className="togglePasswordType"  onClick={(e)=>togglePassword('currentPassword')}/>
                            )}

                            <div className="invalid-feedback">{errors.currentPassword?.message}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="mb-3"> 
                        <div className="form-group d-flex flex-wrap">
                            <label>รหัสผ่านใหม่</label>
                            <input 
                            name="password" 
                            type={passwordHidden}
                            {...register('password')} 
                            placeholder="รหัสผ่านใหม่" 
                            className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            {passwordHidden === 'password' ? ( 
                              <FontAwesomeIcon icon={faEyeSlash} className="togglePasswordType" onClick={(e)=>togglePassword('password')} />
                            ) : ( 
                              <FontAwesomeIcon icon={faEye} className="togglePasswordType"  onClick={(e)=>togglePassword('password')}/>
                            )}

                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="mb-3"> 
                        <div className="form-group d-flex flex-wrap">
                            <label>ยืนยันรหัสผ่านใหม่</label>
                            <input 
                            name="confirmPassword" 
                            type={confirmPasswordHidden}
                            {...register('confirmPassword')} 
                            placeholder="ยืนยันรหัสผ่านใหม่"
                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
                            {confirmPasswordHidden === 'password' ? ( 
                              <FontAwesomeIcon icon={faEyeSlash} className="togglePasswordType" onClick={(e)=>togglePassword('confirmPassword')} />
                            ) : ( 
                              <FontAwesomeIcon icon={faEye} className="togglePasswordType"  onClick={(e)=>togglePassword('confirmPassword')}/>
                            )}
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                        </div>
                
                      </Col>
                    </Row>
                    <div className="form-group pt-3 text-end col-md-12">
                 
                        {  !isLoadingReset ? (
                              <button type="submit" className="btn btn-primary mr-1">ตั้งรหัสผ่านใหม่</button>
                            ):(
                              <button type="submit" className="btn btn-primary mr-1">กำลังตั้งรหัสผ่านใหม่...</button>
                        ) }  
                    </div>
                  </Form>

              

                </div> 
            </div>
          </div>
        </div>
       
  );
};

export default Resetpassword;
