import React, { useState, useEffect } from 'react'
import { useForm, useFormContext, Controller } from 'react-hook-form'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPhone, faHome, faUser } from '@fortawesome/free-solid-svg-icons'

import { useLocation, NavLink, Link } from 'react-router-dom'
import { selectStyles } from './styled'
import useFetch from '../../../utils/useFetch'
import _ from '../../../utils/utils'
import { useParentsStatusOptions, useRelationshipOptions } from '../../../hooks/options/useOptions'

const Parents = (props) => {
  // const { results: responseProvince, loading: loadingProvince } = useService('/province')

  const { response: responseProvince, loading: loadingProvince } = useFetch(
    '/student/masterdata/province'
  )

  const provinceOptions = responseProvince?.map((item) => {
    return {
      value: item.id,
      label: item.name_th,
    }
  })

  const { options: relationshipOptions, loading: relationshipLoading } = useRelationshipOptions()
  const { options: parentsStatusOptions, loading: parentsStatusLoading } = useParentsStatusOptions()

  const [fatherDistrictOptions, setFatherDistrictOptions] = useState([])
  const [fatherSubdistrictOptions, setFatherSubdistrictOptions] = useState([])
  const [fatherZipcode, setFatherZipcode] = useState('')

  const [fatherDistrictDefault, setFatherDistrictDefault] = useState(null)

  const [motherDistrictOptions, setMotherDistrictOptions] = useState([])
  const [motherSubdistrictOptions, setMotherSubdistrictOptions] = useState([])
  const [motherZipcode, setMotherZipcode] = useState('')

  const [motherDistrictDefault, setMotherDistrictDefault] = useState(null)

  const [otherDistrictOptions, setOtherDistrictOptions] = useState([])
  const [otherSubdistrictOptions, setOtherSubdistrictOptions] = useState([])
  const [otherZipcode, setOtherZipcode] = useState('')

  const [otherDistrictDefault, setOtherDistrictDefault] = useState(null)

  const [loadingDefaultData, setLoadingDefaultData] = useState(false)

  const {
    register,
    setValue,
    errors,
    clearErrors,
    control,
    prefixOptions,
    studentData,
    nationalityOptions,
    loadingNationality,
    occupationOptions,
    loadingOccupation,

    isLoadingDefault,
    // fatherStatus,
    // isCreateMode,
  } = props

  //console.log(studentData?.parents);
  const fatherOccupation = {
    label: studentData?.parents?.father?.occupation,
    value: studentData?.parents?.father?.occupation,
  }

  const fatherNationality =
    studentData?.parents?.father?.nationality !== null
      ? {
          label: studentData?.parents?.father?.nationality,
          value: studentData?.parents?.father?.nationality,
        }
      : null

  const motherOccupation = {
    label: studentData?.parents?.mother?.occupation,
    value: studentData?.parents?.mother?.occupation,
  }

  const motherNationality =
    studentData?.parents?.mother?.nationality !== null
      ? {
          label: studentData?.parents?.mother?.nationality,
          value: studentData?.parents?.mother?.nationality,
        }
      : null

  const fatherStatus = {
    value: studentData?.parents?.father?.parents_status_name,
    label: studentData?.parents?.father?.parents_status_name,
  }

  const motherStatus = {
    value: studentData?.parents?.mother?.parents_status_name,
    label: studentData?.parents?.mother?.parents_status_name,
  }

  const otherStatus = {
    value: studentData?.parents?.other?.parents_status_name,
    label: studentData?.parents?.other?.parents_status_name,
  }

  const handleSelectChange = (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleSelectFatherProvinceChange = async (value, fieldName) => {
    let options = ''
    // let districtOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const district = await fetch(`${baseUrl}/district?province_id=${value?.value}`)

      clearErrors(fieldName)
      const json = await district.json()
      let districtOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setFatherDistrictOptions(districtOptions)
    } else {
      setFatherDistrictDefault('')
    }

    setValue(fieldName, options)
  }

  const handleSelectFatherDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const currentSubDistrict = await fetch(`${baseUrl}/sub-district?district_id=${value?.value}`)
      const json = await currentSubDistrict.json()
      let subDistrictOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setFatherSubdistrictOptions(subDistrictOptions)
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleSelectFatherSubDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const subDistrict = await fetch(`${baseUrl}/zipcode?sub_district_id=${value?.value}`)
      const json = await subDistrict.json()
      setFatherZipcode(json?.data[0]?.zipcode)
      setValue('father_zipcode', json?.data[0]?.zipcode)
    }

    setValue(fieldName, options)
  }

  const handleSelectMotherProvinceChange = async (value, fieldName) => {
    let options = ''
    // let districtOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const district = await fetch(`${baseUrl}/district?province_id=${value?.value}`)

      clearErrors(fieldName)
      const json = await district.json()
      let districtOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setMotherDistrictOptions(districtOptions)
    } else {
      setMotherDistrictDefault('')
    }

    setValue(fieldName, options)
  }

  const handleSelectMotherDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const currentSubDistrict = await fetch(`${baseUrl}/sub-district?district_id=${value?.value}`)
      const json = await currentSubDistrict.json()
      let subDistrictOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setMotherSubdistrictOptions(subDistrictOptions)
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleSelectMotherSubDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }

      const subDistrict = await fetch(`${baseUrl}/zipcode?sub_district_id=${value?.value}`)
      const json = await subDistrict.json()
      setMotherZipcode(json?.data[0]?.zipcode)
      setValue('mother_zipcode', json?.data[0]?.zipcode)
    }

    setValue(fieldName, options)
  }

  const handleSelectOtherProvinceChange = async (value, fieldName) => {
    let options = ''
    // let districtOptions = []
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const district = await fetch(`${baseUrl}/district?province_id=${value?.value}`)

      clearErrors(fieldName)
      const json = await district.json()
      let districtOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setOtherDistrictOptions(districtOptions)
    } else {
      setOtherDistrictDefault('')
    }

    setValue(fieldName, options)
  }

  const handleSelectOtherDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }
      const currentSubDistrict = await fetch(`${baseUrl}/sub-district?district_id=${value?.value}`)
      const json = await currentSubDistrict.json()
      let subDistrictOptions = json?.data.map((item, index) => {
        return {
          value: item.id,
          label: item.name_th,
        }
      })
      setOtherSubdistrictOptions(subDistrictOptions)
      clearErrors(fieldName)
    }
    setValue(fieldName, options)
  }

  const handleSelectOtherSubDistrictChange = async (value, fieldName) => {
    let options = ''
    if (!_.isNull(value)) {
      options = {
        value: value?.value,
        label: value?.label,
      }

      const subDistrict = await fetch(`${baseUrl}/zipcode?sub_district_id=${value?.value}`)
      const json = await subDistrict.json()
      setOtherZipcode(json?.data[0]?.zipcode)
      setValue('other_zipcode', json?.data[0]?.zipcode)
    }

    setValue(fieldName, options)
  }

  const baseUrl = process.env.REACT_APP_API_URL

  const fatherNameTitle = {
    value: studentData?.parents?.father?.name_title_id,
    label: studentData?.parents?.father?.name_title_th,
  }
  const fatherProvince = {
    value: studentData?.parents?.father?.address?.province?.id,
    label: studentData?.parents?.father?.address?.province?.name_th,
  }
  const fatherDistrict = {
    value: studentData?.parents?.father?.address?.district?.id,
    label: studentData?.parents?.father?.address?.district?.name_th,
  }
  const fatherSubDistrict = {
    value: studentData?.parents?.father?.address?.sub_district?.id,
    label: studentData?.parents?.father?.address?.sub_district?.name_th,
  }
  const motherNameTitle = {
    value: studentData?.parents?.mother?.name_title_id,
    label: studentData?.parents?.mother?.name_title_th,
  }
  const motherProvince = {
    value: studentData?.parents?.mother?.address?.province?.id,
    label: studentData?.parents?.mother?.address?.province?.name_th,
  }
  const motherDistrict = {
    value: studentData?.parents?.mother?.address?.district?.id,
    label: studentData?.parents?.mother?.address?.district?.name_th,
  }
  const motherSubDistrict = {
    value: studentData?.parents?.mother?.address?.sub_district?.id,
    label: studentData?.parents?.mother?.address?.sub_district?.name_th,
  }
  const otherNameTitle = {
    value: studentData?.parents?.other?.name_title_id,
    label: studentData?.parents?.other?.name_title_th,
  }
  const otherRelationship =
    studentData?.parents?.other?.relationship !== null
      ? {
          value: studentData?.parents?.other?.relationship,
          label: studentData?.parents?.other?.relationship,
        }
      : null
  const otherNationality =
    studentData?.parents?.other?.nationality !== null
      ? {
          value: studentData?.parents?.other?.nationality,
          label: studentData?.parents?.other?.nationality,
        }
      : null

  const otherOccupation =
    studentData?.parents?.other?.occupation !== null
      ? {
          value: studentData?.parents?.other?.occupation,
          label: studentData?.parents?.other?.occupation,
        }
      : null

  const otherProvince = {
    value: studentData?.parents?.other?.address?.province?.id,
    label: studentData?.parents?.other?.address?.province?.name_th,
  }
  const otherDistrict = {
    value: studentData?.parents?.other?.address?.district?.id,
    label: studentData?.parents?.other?.address?.district?.name_th,
  }
  const otherSubDistrict = {
    value: studentData?.parents?.other?.address?.sub_district?.id,
    label: studentData?.parents?.other?.address?.sub_district?.name_th,
  }

  if (studentData && isLoadingDefault && loadingDefaultData === false) {
    setValue('father_name_title_id', fatherNameTitle)
    setValue('father_province_id', fatherProvince)
    setValue('father_district_id', fatherDistrict)
    setValue('father_subdistrict_id', fatherSubDistrict)
    setValue('mother_name_title_id', motherNameTitle)
    setValue('mother_province_id', motherProvince)
    setValue('mother_district_id', motherDistrict)
    setValue('mother_subdistrict_id', motherSubDistrict)
    setValue('other_name_title_id', otherNameTitle)
    setValue('other_relationship', otherRelationship)
    setValue('other_nationality', otherNationality)
    setValue('other_province_id', otherProvince)
    setValue('other_district_id', otherDistrict)
    setValue('other_subdistrict_id', otherSubDistrict)
    setValue('father_nationality', fatherNationality)
    setValue('mother_nationality', motherNationality)
    setValue('father_occupation', fatherOccupation)
    setValue('mother_occupation', motherOccupation)
    setValue('other_occupation', otherOccupation)
    setLoadingDefaultData(true)
  }

  return (
    <>
      <div>
        <h5 className="card-title d-flex align-items-left">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faUser} className="" />
          </div>
          <span className="fw-bolder">ข้อมูลบิดา</span>
        </h5>

        <div className="ps-5">
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="father_name_title_id" className="">
                คำนำหน้า
              </label>
              <Controller
                name="father_name_title_id"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'คำนำหน้า'}
                    isClearable
                    isSearchable={false}
                    styles={selectStyles}
                    options={prefixOptions}
                    defaultValue={fatherNameTitle}
                    placeholder="เลือกคำนำหน้า"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'father_name_title_id')}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="father_firstname_th">ชื่อ (ภาษาไทย)</label>
              <input
                placeholder="ชื่อ (ภาษาไทย)"
                {...register('father_firstname_th')}
                defaultValue={studentData?.parents?.father?.firstname_th}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="father_lastname_th" className="">
                นามสกุล (ภาษาไทย)
              </label>
              <input
                placeholder="นามสกุล (ภาษาไทย)"
                {...register('father_lastname_th')}
                defaultValue={studentData?.parents?.father?.lastname_th}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="father_firstname_en">ชื่อ (English)</label>
              <input
                placeholder="ชื่อ (English)"
                {...register('father_firstname_en')}
                defaultValue={studentData?.parents?.father?.firstname_en}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="father_lastname_en" className="">
                นามสกุล (English)
              </label>
              <input
                placeholder="นามสกุล (English)"
                {...register('father_lastname_en')}
                defaultValue={studentData?.parents?.father?.lastname_en}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="firstname_en">เลขบัตรประจำตัวประชาชน</label>
              <input
                placeholder="เลขบัตรประจำตัวประชาชน"
                {...register('father_citizen_id_number')}
                defaultValue={studentData?.parents?.father?.citizen_id_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="father_phone_number" className="">
                หมายเลขโทรศัพท์
              </label>
              <input
                placeholder="หมายเลขโทรศัพท์"
                {...register('father_phone_number')}
                defaultValue={studentData?.parents?.father?.phone_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="father_email" className="">
                อีเมล
              </label>
              <input
                placeholder="อีเมล"
                {...register('father_email')}
                defaultValue={studentData?.parents?.father?.email}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="father_nationality" className="">
                สัญชาติ
              </label>
              <Controller
                name="father_nationality"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'สัญชาติ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={nationalityOptions}
                    defaultValue={fatherNationality}
                    placeholder="เลือกสัญชาติ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'father_nationality')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="father_occupation" className="">
                อาชีพ
              </label>
              <Controller
                name="father_occupation"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'อาชีพ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={occupationOptions}
                    defaultValue={fatherOccupation}
                    placeholder="เลือกอาชีพ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'father_occupation')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="father_organization" className="">
                สถานที่ทำงาน
              </label>
              <input
                placeholder="สถานที่ทำงาน"
                {...register('father_organization')}
                defaultValue={studentData?.parents?.father?.organization}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="father_parents_status">สถานภาพผู้ปกครอง</label>
              <Controller
                name="father_parents_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="สถานภาพผู้ปกครอง"
                    isClearable
                    isDisabled={parentsStatusLoading}
                    isLoading={parentsStatusLoading}
                    isSearchable={true}
                    styles={selectStyles}
                    options={parentsStatusOptions}
                    defaultValue={fatherStatus}
                    placeholder="เลือกสถานภาพผู้ปกครอง"
                    onChange={(value) => handleSelectChange(value, 'father_parents_status')}
                  />
                )}
              />
            </Col>

            <Col md={4} className="mb-3">
              <label htmlFor="father_spouse_name" className="">
              ชื่อคู่สมรส
              </label>
              <input
                placeholder="ชื่อคู่สมรส" 
                {...register('father_spouse_name')}
                defaultValue={studentData?.parents?.father?.spouse_name}
                className="form-control"
              />
            </Col>

          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="father_alive_status" className="label-with-required">
                สถานะการมีชีวิต
              </label>
              <div>
                <div className="form-check form-check-inline">
                  <input
                    id="father_alive_status"
                    className="form-check-input"
                    type="radio"
                    {...register('father_alive_status')}
                    defaultChecked="1"
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="father_alive_status">
                    มีชีวิต
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    id="father_alive_status-2"
                    className="form-check-input"
                    type="radio"
                    {...register('father_alive_status')}
                    value="2"
                  />
                  <label className="form-check-label" htmlFor="father_alive_status-2">
                    ถึงแก่กรรม
                  </label>
                </div>
              </div>
              {errors.father_alive_status && (
                <div className="help-block">{errors.father_alive_status.message}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="father_address" className="font-weight-bold">
                ที่อยู่
              </label>
            </Col>

            <Col md={3} className="mb-3">
              <label htmlFor="father_address_no" className="">
                เลขที่
              </label>
              <input
                placeholder="เลขที่"
                {...register('father_address_no')}
                defaultValue={studentData?.parents?.father?.address?.address_no}
                className="form-control"
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="father_village_no" className="">
                หมู่ที่
              </label>
              <input
                placeholder="หมู่ที่"
                {...register('father_village_no', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.father?.address?.village_no}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="father_alley" className="">
                ตรอก/ซอย
              </label>
              <input
                placeholder="ตรอก/ซอย"
                {...register('father_alley')}
                defaultValue={studentData?.parents?.father?.address?.alley}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="father_road" className="">
                ถนน
              </label>
              <input
                placeholder="ถนน"
                {...register('father_road', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.father?.address?.road}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="father_province_id">จังหวัด</label>
              <Controller
                name="father_province_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="จังหวัด"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={provinceOptions}
                    defaultValue={fatherProvince}
                    placeholder="เลือกจังหวัด"
                    onChange={(value) =>
                      handleSelectFatherProvinceChange(value, 'father_province_id')
                    }
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="father_district_id">อำเภอ/เขต</label>
              <Controller
                name="father_district_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="อำเภอ/เขต"
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={fatherDistrictOptions}
                    defaultValue={fatherDistrict}
                    placeholder="เลือกอำเภอ/เขต"
                    onChange={(value) =>
                      handleSelectFatherDistrictChange(value, 'father_district_id')
                    }
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="father_subdistrict_id">ตำบล/แขวง</label>
              <Controller
                name="father_subdistrict_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="ตำบล/แขวง"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={fatherSubdistrictOptions}
                    defaultValue={fatherSubDistrict}
                    placeholder="เลือกตำบล/แขวง"
                    onChange={(value) =>
                      handleSelectFatherSubDistrictChange(value, 'father_subdistrict_id')
                    }
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="father_zipcode">รหัสไปรษณีย์</label>
              <input
                placeholder="รหัสไปรษณีย์"
                {...register('father_zipcode')}
                defaultValue={
                  fatherZipcode ? fatherZipcode : studentData?.parents?.father?.address?.zipcode
                }
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h5 className="card-title d-flex align-items-left">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faUser} className="" />
          </div>
          <span className="fw-bolder">ข้อมูลมารดา</span>
        </h5>

        <div className="ps-5">
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_name_title_id" className="">
                คำนำหน้า
              </label>
              <Controller
                name="mother_name_title_id"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'คำนำหน้า'}
                    isClearable
                    isSearchable={false}
                    styles={selectStyles}
                    options={prefixOptions}
                    defaultValue={motherNameTitle}
                    placeholder="เลือกคำนำหน้า"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'mother_name_title_id')}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_firstname_th">ชื่อ (ภาษาไทย)</label>
              <input
                placeholder="ชื่อ (ภาษาไทย)"
                {...register('mother_firstname_th')}
                defaultValue={studentData?.parents?.mother?.firstname_th}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_lastname_th" className="">
                นามสกุล (ภาษาไทย)
              </label>
              <input
                placeholder="นามสกุล (ภาษาไทย)"
                {...register('mother_lastname_th')}
                defaultValue={studentData?.parents?.mother?.lastname_th}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_firstname_en">ชื่อ (English)</label>
              <input
                placeholder="ชื่อ (English)"
                {...register('mother_firstname_en')}
                defaultValue={studentData?.parents?.mother?.firstname_en}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="mother_lastname_en" className="">
                นามสกุล (English)
              </label>
              <input
                placeholder="นามสกุล (English)"
                {...register('mother_lastname_en')}
                defaultValue={studentData?.parents?.mother?.lastname_en}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="firstname_en">เลขบัตรประจำตัวประชาชน</label>
              <input
                placeholder="เลขบัตรประจำตัวประชาชน"
                {...register('mother_citizen_id_number')}
                defaultValue={studentData?.parents?.mother?.citizen_id_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_phone_number" className="">
                หมายเลขโทรศัพท์
              </label>
              <input
                placeholder="หมายเลขโทรศัพท์"
                {...register('mother_phone_number')}
                defaultValue={studentData?.parents?.mother?.phone_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_email" className="">
                อีเมล
              </label>
              <input
                placeholder="อีเมล"
                {...register('mother_email')}
                defaultValue={studentData?.parents?.mother?.email}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_nationality" className="">
                สัญชาติ
              </label>
              <Controller
                name="mother_nationality"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'สัญชาติ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={nationalityOptions}
                    defaultValue={motherNationality}
                    placeholder="เลือกสัญชาติ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'mother_nationality')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_occupation" className="">
                อาชีพ
              </label>
              <Controller
                name="mother_occupation"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'อาชีพ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={occupationOptions}
                    defaultValue={motherOccupation}
                    placeholder="เลือกอาชีพ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'mother_occupation')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_organization" className="">
                สถานที่ทำงาน
              </label>
              <input
                placeholder="สถานที่ทำงาน"
                {...register('mother_organization')}
                defaultValue={studentData?.parents?.mother?.organization}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="mother_parents_status">สถานภาพผู้ปกครอง</label>
              <Controller
                name="mother_parents_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="สถานภาพผู้ปกครอง"
                    isClearable
                    isDisabled={parentsStatusLoading}
                    isLoading={parentsStatusLoading}
                    isSearchable={true}
                    styles={selectStyles}
                    options={parentsStatusOptions}
                    defaultValue={motherStatus}
                    placeholder="เลือกสถานภาพผู้ปกครอง"
                    onChange={(value) => handleSelectChange(value, 'mother_parents_status')}
                  />
                )}
              />
            </Col>

            <Col md={4} className="mb-3">
              <label htmlFor="mother_spouse_name" className="">
              ชื่อคู่สมรส
              </label>
              <input
                placeholder="ชื่อคู่สมรส" 
                {...register('mother_spouse_name')}
                defaultValue={studentData?.parents?.mother?.spouse_name}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="mother_alive_status" className="label-with-required">
                สถานะการมีชีวิต
              </label>
              <div>
                <div className="form-check form-check-inline">
                  <input
                    id="mother_alive_status"
                    className="form-check-input"
                    type="radio"
                    {...register('mother_alive_status')}
                    defaultChecked="1"
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="mother_alive_status">
                    มีชีวิต
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    id="mother_alive_status-2"
                    className="form-check-input"
                    type="radio"
                    {...register('mother_alive_status')}
                    value="2"
                  />
                  <label className="form-check-label" htmlFor="mother_alive_status-2">
                    ถึงแก่กรรม
                  </label>
                </div>
              </div>
              {errors.mother_alive_status && (
                <div className="help-block">{errors.mother_alive_status.message}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="mother_address" className="font-weight-bold">
                ที่อยู่
              </label>
            </Col>

            <Col md={3} className="mb-3">
              <label htmlFor="mother_address_no" className="">
                เลขที่
              </label>
              <input
                placeholder="เลขที่"
                {...register('mother_address_no')}
                defaultValue={studentData?.parents?.mother?.address?.address_no}
                className="form-control"
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="mother_village_no" className="">
                หมู่ที่
              </label>
              <input
                placeholder="หมู่ที่"
                {...register('mother_village_no', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.mother?.address?.village_no}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_alley" className="">
                ตรอก/ซอย
              </label>
              <input
                placeholder="ตรอก/ซอย"
                {...register('mother_alley')}
                defaultValue={studentData?.parents?.mother?.address?.alley}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="mother_road" className="">
                ถนน
              </label>
              <input
                placeholder="ถนน"
                {...register('mother_road', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.mother?.address?.road}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_province_id">จังหวัด</label>
              <Controller
                name="mother_province_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="จังหวัด"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={provinceOptions}
                    defaultValue={motherProvince}
                    placeholder="เลือกจังหวัด"
                    onChange={(value) => handleSelectMotherProvinceChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_district_id">อำเภอ/เขต</label>
              <Controller
                name="mother_district_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="อำเภอ/เขต"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={motherDistrictOptions}
                    defaultValue={motherDistrict}
                    placeholder="เลือกอำเภอ/เขต"
                    onChange={(value) => handleSelectMotherDistrictChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_subdistrict_id">ตำบล/แขวง</label>
              <Controller
                name="mother_subdistrict_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="ตำบล/แขวง"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={motherSubdistrictOptions}
                    defaultValue={motherSubDistrict}
                    placeholder="เลือกตำบล/แขวง"
                    onChange={(value) => handleSelectMotherSubDistrictChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="mother_zipcode">รหัสไปรษณีย์</label>
              <input
                placeholder="รหัสไปรษณีย์"
                {...register('mother_zipcode')}
                defaultValue={motherZipcode ? motherZipcode : studentData?.parents?.mother?.address?.zipcode}
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <h5 className="card-title d-flex align-items-left">
          <div className="bg-icon">
            <FontAwesomeIcon icon={faUser} className="" />
          </div>
          <span className="fw-bolder">ข้อมูลผู้ปกครอง</span>
        </h5>

        <div className="ps-5">
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="other_name_title_id" className="">
                คำนำหน้า
              </label>
              <Controller
                name="other_name_title_id"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'คำนำหน้า'}
                    isClearable
                    isSearchable={false}
                    styles={selectStyles}
                    options={prefixOptions}
                    defaultValue={otherNameTitle}
                    placeholder="เลือกคำนำหน้า"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'other_name_title_id')}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="other_firstname_th">ชื่อ (ภาษาไทย)</label>
              <input
                placeholder="ชื่อ (ภาษาไทย)"
                {...register('other_firstname_th')}
                defaultValue={studentData?.parents?.other?.firstname_th}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="other_lastname_th" className="">
                นามสกุล (ภาษาไทย)
              </label>
              <input
                placeholder="นามสกุล (ภาษาไทย)"
                {...register('other_lastname_th')}
                defaultValue={studentData?.parents?.other?.lastname_th}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="other_firstname_en">ชื่อ (English)</label>
              <input
                placeholder="ชื่อ (English)"
                {...register('other_firstname_en')}
                defaultValue={studentData?.parents?.other?.firstname_en}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="other_lastname_en" className="">
                นามสกุล (English)
              </label>
              <input
                placeholder="นามสกุล (English)"
                {...register('other_lastname_en')}
                defaultValue={studentData?.parents?.other?.lastname_th}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="other_citizen_id_number">เลขบัตรประจำตัวประชาชน</label>
              <input
                placeholder="เลขบัตรประจำตัวประชาชน"
                {...register('other_citizen_id_number')}
                defaultValue={studentData?.parents?.other?.citizen_id_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_phone_number" className="">
                หมายเลขโทรศัพท์
              </label>
              <input
                placeholder="หมายเลขโทรศัพท์"
                {...register('other_phone_number')}
                defaultValue={studentData?.parents?.other?.phone_number}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_email" className="">
                อีเมล
              </label>
              <input 
                placeholder="อีเมล" 
                {...register('other_email')} 
                defaultValue={studentData?.parents?.other?.email}
                className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <label htmlFor="other_relationship" className="">
                ความสัมพันธ์
              </label>
              <Controller
                name="other_relationship"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'ความสัมพันธ์'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={relationshipOptions}
                    defaultValue={otherRelationship}
                    placeholder="เลือกความสัมพันธ์"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'other_relationship')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_nationality" className="">
                สัญชาติ
              </label>
              <Controller
                name="other_nationality"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'สัญชาติ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={nationalityOptions}
                    defaultValue={otherNationality}
                    placeholder="เลือกสัญชาติ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'other_nationality')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_occupation" className="">
                อาชีพ
              </label>
              <Controller
                name="other_occupation"
                control={control}
                render={(field) => (
                  <Select
                    {...field}
                    label={'อาชีพ'}
                    isClearable
                    isSearchable={true}
                    styles={selectStyles}
                    options={occupationOptions}
                    defaultValue={otherOccupation}
                    placeholder="เลือกอาชีพ"
                    noOptionsMessage={() => 'ไม่พบข้อมูล'}
                    onChange={(value) => handleSelectChange(value, 'other_occupation')}
                  />
                )}
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_organization" className="">
                สถานที่ทำงาน
              </label>
              <input
                placeholder="สถานที่ทำงาน"
                {...register('other_organization')}
                defaultValue={studentData?.parents?.other?.organization}
                className="form-control"
              />
            </Col>
            <Col md={4} className="mb-3">
              <label htmlFor="other_parents_status">สถานภาพผู้ปกครอง</label>
              <Controller
                name="other_parents_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="สถานภาพผู้ปกครอง"
                    isClearable
                    isDisabled={parentsStatusLoading}
                    isLoading={parentsStatusLoading}
                    isSearchable={true}
                    styles={selectStyles}
                    options={parentsStatusOptions}
                    defaultValue={otherStatus}
                    placeholder="เลือกสถานภาพผู้ปกครอง"
                    onChange={(value) => handleSelectChange(value, 'other_parents_status')}
                  />
                )}
              />
            </Col>

            <Col md={4} className="mb-3">
              <label htmlFor="other_spouse_name" className="">
              ชื่อคู่สมรส
              </label>
              <input
                placeholder="ชื่อคู่สมรส" 
                {...register('other_spouse_name')}
                defaultValue={studentData?.parents?.other?.spouse_name}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="other_alive_status" className="label-with-required">
                สถานะการมีชีวิต
              </label>
              <div>
                <div className="form-check form-check-inline">
                  <input
                    id="other_alive_status"
                    className="form-check-input"
                    type="radio"
                    {...register('other_alive_status')}
                    defaultChecked="1"
                    value="1"
                  />
                  <label className="form-check-label" htmlFor="other_alive_status">
                    มีชีวิต
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    id="other_alive_status-2"
                    className="form-check-input"
                    type="radio"
                    {...register('other_alive_status')}
                    value="2"
                  />
                  <label className="form-check-label" htmlFor="other_alive_status-2">
                    ถึงแก่กรรม
                  </label>
                </div>
              </div>
              {errors.other_alive_status && (
                <div className="help-block">{errors.other_alive_status.message}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <label htmlFor="other_address" className="font-weight-bold">
                ที่อยู่
              </label>
            </Col>

            <Col md={3} className="mb-3">
              <label htmlFor="other_address_no" className="">
                เลขที่
              </label>
              <input
                placeholder="เลขที่"
                {...register('other_address_no')}
                defaultValue={studentData?.parents?.other?.address?.address_no}
                className="form-control"
              />
            </Col>
            <Col md={3} className="mb-3">
              <label htmlFor="other_village_no" className="">
                หมู่ที่
              </label>
              <input
                placeholder="หมู่ที่"
                {...register('other_village_no', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.other?.address?.village_no}
                className="form-control"
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="other_alley" className="">
                ตรอก/ซอย
              </label>
              <input
                placeholder="ตรอก/ซอย"
                {...register('other_alley')}
                defaultValue={studentData?.parents?.other?.address?.alley}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="other_road" className="">
                ถนน
              </label>
              <input
                placeholder="ถนน"
                {...register('other_road', {
                  required: false,
                })}
                defaultValue={studentData?.parents?.other?.address?.road}
                className="form-control"
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="other_province_id">จังหวัด</label>
              <Controller
                name="other_province_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="จังหวัด"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={provinceOptions}
                    defaultValue={otherProvince}
                    placeholder="เลือกจังหวัด"
                    onChange={(value) => handleSelectOtherProvinceChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="other_district_id">อำเภอ/เขต</label>
              <Controller
                name="other_district_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="อำเภอ/เขต"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={otherDistrictOptions}
                    placeholder="เลือกอำเภอ/เขต"
                    defaultValue={otherDistrict}
                    onChange={(value) => handleSelectOtherDistrictChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="other_subdistrict_id">ตำบล/แขวง</label>
              <Controller
                name="other_subdistrict_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="ตำบล/แขวง"
                    isClearable
                    isDisabled={loadingProvince}
                    isLoading={loadingProvince}
                    isSearchable={true}
                    styles={selectStyles}
                    options={otherSubdistrictOptions}
                    defaultValue={otherSubDistrict}
                    placeholder="เลือกตำบล/แขวง"
                    onChange={(value) => handleSelectOtherSubDistrictChange(value, field.name)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mb-3">
              <label htmlFor="other_zipcode">รหัสไปรษณีย์</label>
              <input
                placeholder="รหัสไปรษณีย์"
                {...register('other_zipcode')}
                defaultValue={
                  otherZipcode ? otherZipcode : studentData?.parents?.other?.address?.zipcode
                }
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Parents
