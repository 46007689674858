import React, { Fragment, useState, useEffect, useCallback } from 'react'; 

import Upload from '../../components/contract/Upload';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {  
    faHome,  
} from "@fortawesome/free-solid-svg-icons"; 
import { Link } from "react-router-dom"; 

const ContractUpload = (props) => {
    const [error, setError] = useState(null);

    const title = "อัปโหลดเอกสาร";
    
    let content = <Upload id={props.match.params.id}/>;

    if (error) {
        content = <p>{error}</p>;
    }
    return (
        <>
         <nav aria-label="breadcrumb">
            <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
                <li className="breadcrumb-item">
                    <Link to="/">
                    <FontAwesomeIcon icon={faHome} />
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/contract"> สัญญา </Link>
                </li>
                <li
                    className="breadcrumb-item active font-sarabun"
                    aria-current="page"  >
                อัปโหลดเอกสารสัญญา
                </li>
        
            </ol>
        </nav> 
        {content}
        </> 
    );
}


export default ContractUpload
 