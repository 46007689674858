import React, { Fragment } from 'react'
import './Header.css'
import logo from '../../../assets/images/logo/IPST_Logo.png'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <Fragment>
      <header>
        <nav id="w0" className="navbar navbar-expand-xl navbar-dark fixed-top bg-primary py-0">
          <div className="container-fluid px-0">
            <Link to="/" className="ps-3 pe-2 mx-auto navbar-brand">
              {' '}
              <img src={logo} alt="Scholarship Self-service" />
            </Link>

            <div id="w0-collapse" className="collapse navbar-collapse">
              <ul id="w2" className="navbar-nav ms-auto nav">
                <li className="nav-login pe-3 nav-item">
                  <Link to="/" className="btn btn-info text-white nav-link">
                    เข้าสู่ระบบ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </Fragment>
  )
}

export default Header
