import Skeleton from 'react-loading-skeleton'

const SkeletonLoading = (props) => {
  return (
    <>
      <Skeleton
        baseColor="#dee2e6"
        highlightColor="#dee2e6"
        duration={4}
        height={props?.height}
        width={props?.width}
        count={props?.count}
        className={props?.className}
      />
    </>
  )
}
export default SkeletonLoading
