import React, { Fragment, useState, useEffect, useCallback } from 'react'
import ExpenseCreate from '../../components/petition/Create'
import StatusCreate from '../../components/petition-student-status/Create'
import OtherCreate from '../../components/petition-other/Create'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import warning from '../../assets/images/warning.png'

const PetitionCreate = (props) => {
  const [bankAccount, setBankAccount] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)


  const auth = localStorage.getItem('user')
  const userData = JSON.parse(auth)
  
  const hasContract = userData?.has_contract
  const hasEducation = userData?.has_education

  if (!hasContract || !hasEducation) {
    return (
     <div className="mt-4">
        <section className="bg-light">
          <div className="container">
            <div className="card border-0 mb-3 d-flex py-4">
            
              <img src={warning} className="img-alert-warning"/>
              <h1 className="text-center text-primary">สัญญาของคุณยังไม่สมบูรณ์</h1>
            </div>
          </div>
        </section>
      </div>
    )
  }
  
  const pathname = props.match.url

  let title = 'ยื่นคำร้องใหม่'
  let breadcrumb = 'คำร้องสถานภาพนักเรียนทุน'
  let pathbreadcrumb = '/petition-student-status'
  let content = <StatusCreate />
  if (pathname == '/petition-student-status/create') {
    breadcrumb = 'คำร้องสถานภาพนักเรียนทุน'
    pathbreadcrumb = '/petition-student-status'
    content = <StatusCreate />
  } else if (pathname == '/petition-expense/create') {
    breadcrumb = 'คำร้องค่าใช้จ่าย'
    pathbreadcrumb = '/petition-expense'
    content = <ExpenseCreate />
  } else if (pathname == '/petition-other/create') {
    breadcrumb = 'คำร้องอื่น ๆ'
    pathbreadcrumb = '/petition-other'
    content = <OtherCreate />
  }

  if (error) {
    content = <p>{error}</p>
  }

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol id="w9" className="breadcrumb container py-2 mb-0 px-3">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={pathbreadcrumb}> {breadcrumb} </Link>
          </li>
          <li className="breadcrumb-item active font-sarabun" aria-current="page">
            ยื่นคำร้องใหม่
          </li>
        </ol>
      </nav>
      {content}
    </>
  )
}

export default PetitionCreate
